export const candidateTitle = "Candidate";
export const viewCandidateTitle = "View Candidate";
export const centerTitle = "Center";
export const courseTitle = "Course";
export const categoryTitle = "Category";
export const careerTitle = "Career";
export const positionTitle = "Position";
export const stockTitle = "Stock";
export const vendorTitle = "Vendor";
export const billTitle = "Bill";
export const billApprovalTitle = "Bill Approval";
export const hostelTitle = "Hostel";
export const classTitle = "Class";
export const staffTitle = "Staff";
export const totTitle = "Tot";
export const placementTitle = "Placement";
export const inventoryTitle = "Inventory";
export const editInquiryTitle = "Edit Inquiry";
export const inquiryTitle = "Inquiry";
export const holidayTitle = "Holiday";
export const holidayTemplateTitle = "Holiday Template";
export const staffAttendance = "Staff Attendance";
export const leaveTitle = "Leave Template";
export const salaryOverviewTitle = "salary Overview";
export const salaryStructureTitle = "salary Structure";
export const salaryRevisionHistoryTitle = "Salary Revision History";
export const reportTitle = "Report";
export const billPaymentTile = "Bill Payment";
export const placementEmployerTitle = "Placement Employer";
export const jobPositionTitle = "Job Position";
export const changePasswordTitle = "Change Password";
export const forgotPasswordTitle = "Forgot Password";
export const leaveApprovalTitle = "Leave Approval";
export const attendanceApprovalTitle = "Attendance Approval";
export const leaveSummaryTitle = "Leave Summary";
export const leaveBalanceTitle = "Leave Balance";