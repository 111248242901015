import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../BaseComponents/BaseTable";
import {
  Action,
  ContactNo,
  Email,
  Loading,
  Search,
  Submit,
  Update,
  notFound,
} from "../../Constant";
import Spinner from "../../BaseComponents/BaseLoader";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseSelect from "../../BaseComponents/BaseSelect";
import {
  SelectPlaceHolder,
  digitRegex,
  emailRegex,
  numberRegex,
  validationMessages,
} from "../../Constant/validation";
import BaseButton from "../../BaseComponents/BaseButton";
import {
  RiEditFill,
  RiDeleteBin7Fill,
  RiSearchLine,
  RiQuestionFill,
} from "react-icons/ri";
import { centerApi } from "../../Api/common";
import { useFormik } from "formik";
import * as yup from "yup";
import BaseModal from "../../BaseComponents/BaseModal";
import { toast } from "react-toastify";
import { DeleteMessage } from "../../Constant/Center";
import { checkStatusCodeSuccess, handleArrayResponse, handleEditClick } from "../../Constant/common";
import { placementEmployerTitle } from "../../Constant/title";
import { EmployerLabel } from "../../Constant/PlacementEmployer/PlacementEmployer";
import { addEmployerApi, deleteEmployerApi, editEmployerApi, listOfEmployerApi, viewEmployerApi } from "../../Api/PlacementEmployer";
import { useSelectedCenter } from "../CenterContext";

const PlacementEmployer = () => {
  document.title = placementEmployerTitle;
  const [EmployerData, setEmployerData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState();
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [centerData, setCenterData] = useState();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [editId, setEditId] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [employerDetails, setEmployerDetails] = useState(null);
  const [employerId, setEmployerId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { selectedCenterPayload } = useSelectedCenter();

  const handleSearchValueChange = (value) => {
    setSearchValue(value);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
    setBtnLoader(false);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const fetchEmployerData = async () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfEmployerApi( payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setEmployerData(resp?.data?.listOfPlacementEmployer);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchData = () => {
    centerApi({ ...selectedCenterPayload })
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          let data = resp?.data;
          setCenterData(
            data?.map((item) => ({
              value: item?.id,
              label: item?.center_name,
              id: item?.id,
            }))
          );
        }
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    fetchData();
    fetchEmployerData(null);
  }, [currentPage, customPageSize, columnName, sortOrder]);

  const employerForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: employerDetails ? employerDetails?.company_name : null,
      contactName: employerDetails
        ? employerDetails?.contact_person_name
        : null,
      contact_no: employerDetails ? employerDetails?.contact_no : null,
      email: employerDetails ? employerDetails?.email : null,
      gst: employerDetails ? employerDetails?.GSTIN_no : null,
      center: employerDetails ? employerDetails?.center?.id : null,
    },
    validationSchema: yup.object({
      center: yup
        .string()
        .required(validationMessages.required(EmployerLabel.Center)),
      companyName: yup
        .string()
        .required(validationMessages.required(EmployerLabel.CompanyName)),
      contactName: yup
        .string()
        .required(validationMessages.required(EmployerLabel.ContactPersonName)),
      contact_no: yup
        .string()
        .matches(numberRegex, validationMessages.contactLength(ContactNo, 10))
        .required(validationMessages.required(ContactNo)),
      email: yup
        .string()
        .matches(emailRegex, validationMessages.format(Email))
        .required(validationMessages.required(Email)),
      gst: yup
        .string()
        .notRequired()
        .length(
          15,
          validationMessages.characterLength(
            EmployerLabel.GSTINNumberValidation,
            15
          )
        ),
    }),
    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      const payload = {
        company_name: values.companyName,
        contact_person_name: values.contactName,
        email: values.email,
        contact_no: String(values.contact_no),
        center_id: values.center,
        GSTIN_no: values.gst,
      };
      if (editId !== null) {
        editEmployerApi(editId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchEmployerData(null);
              setEmployerDetails(null);
              resetForm();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
            setEditId(null);
          });
      } else {
        setEditId(null);
        addEmployerApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchEmployerData(null);
              resetForm();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
          });
      }
    },
  });

  const editEmployer = (id) => {
    setEditId(id);
    fetchEmployerDetails(id);
  };

  const fetchEmployerDetails = (id) => {
    setLoader(true);
    viewEmployerApi(id)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          setEmployerDetails(response?.data);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || error?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const deleteEmployer = async () => {
    setDeleteLoader(true);
    await deleteEmployerApi(employerId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          fetchEmployerData(null);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
        return err;
      })
      .finally(() => {
        setDeleteLoader(false);
        setModalDelete(false);
        setLoader(false);
      });
  };

  const toggleDelete = (id) => {
    setEmployerId(id);
    setModalDelete(!modalDelete);
  };

  const columns = useMemo(() => [
    {
      header: EmployerLabel.CompanyName,
      accessorKey: "company_name",
      enableColumnFilter: false,
    },
    {
      header: EmployerLabel.ContactPersonName,
      accessorKey: "contact_person_name",
      enableColumnFilter: false,
    },
    {
      header: ContactNo,
      accessorKey: "contact_no",
      enableColumnFilter: false,
    },
    {
      header: Email,
      accessorKey: "email",
      enableColumnFilter: false,
    },
    {
      header: EmployerLabel.GSTNo,
      accessorKey: "GSTIN_no",
      enableColumnFilter: false,
    },
    {
      header: EmployerLabel.Center,
      accessorKey: "center.center_name",
      enableColumnFilter: false,
    },
    {
      header: Action,
      accessorKey: "action",
      enableSorting: true,
      cell: (cell) => (
        <div className="d-flex justify-content-center icon">
          <span>
            <RiEditFill
              className="fs-5 text-primary mx-2"
              onClick={() => {
                editEmployer(cell?.row?.original?.id);
                handleEditClick();
              }}
              title="Edit"
            />
          </span>
          <span>
            <RiDeleteBin7Fill
              className={`fs-5 mx-2 text-danger ${
                cell?.row?.original?.status === "Pending" ? `icon-disabled` : ``
              }`}
              onClick={() => toggleDelete(cell?.row?.original?.id)}
              title="Delete"
            />
          </span>
        </div>
      ),
      enableColumnFilter: false,
    },
  ]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <span className="p-2 d-flex justify-content-start text-dark">
          <h5>{EmployerLabel.PlacementEmployer}</h5>
          <div className="tooltip-container">
            <RiQuestionFill
              className="tooltip-icon"
              size={18}
              data-placement="right"
              title={EmployerLabel.placementRemark}
            />
          </div>
        </span>
      </div>
      <BaseModal
        isOpen={modalDelete}
        title="Delete"
        toggler={toggleDelete}
        submit={() => deleteEmployer()}
        submitText="Delete"
        disabled={deleteLoader}
        loader={deleteLoader}
      >
        {DeleteMessage}
      </BaseModal>

      <div className="card p-4 rounded mb-0 mx-3">
        <div className="">
          <form onSubmit={employerForm.handleSubmit}>
            <div className="row justify-content-center">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseSelect
                    name="center"
                    label={EmployerLabel.Center}
                    className="select-border"
                    options={centerData}
                    placeholder={SelectPlaceHolder(EmployerLabel.Center)}
                    handleChange={(field, value) => {
                      employerForm.setFieldValue(field, value);
                    }}
                    handleBlur={() =>
                      employerForm.setFieldTouched(EmployerLabel.Center, true)
                    }
                    value={employerForm.values.center}
                    touched={employerForm.touched.center}
                    error={employerForm.errors.center}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    label={EmployerLabel.CompanyName}
                    name="companyName"
                    type="text"
                    placeholder={PlaceHolderFormat(EmployerLabel.CompanyName)}
                    value={employerForm.values.companyName}
                    touched={employerForm.touched.companyName}
                    error={employerForm.errors.companyName}
                    handleBlur={employerForm.handleBlur}
                    handleChange={employerForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    label={EmployerLabel.ContactPersonName}
                    name="contactName"
                    type="text"
                    placeholder={PlaceHolderFormat(
                      EmployerLabel.ContactPersonName
                    )}
                    value={employerForm.values.contactName}
                    touched={employerForm.touched.contactName}
                    error={employerForm.errors.contactName}
                    handleBlur={employerForm.handleBlur}
                    handleChange={employerForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="contact_no"
                    type="text"
                    label={ContactNo}
                    placeholder={PlaceHolderFormat(ContactNo)}
                    value={employerForm.values.contact_no}
                    touched={employerForm.touched.contact_no}
                    error={employerForm.errors.contact_no}
                    handleBlur={employerForm.handleBlur}
                    handleChange={(e) => {
                      if (
                        e?.target?.value?.length <= 10 &&
                        digitRegex.test(e.target.value)
                      ) {
                        employerForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="email"
                    type="text"
                    label={Email}
                    placeholder={PlaceHolderFormat(Email)}
                    value={employerForm.values.email}
                    touched={employerForm.touched.email}
                    error={employerForm.errors.email}
                    handleBlur={employerForm.handleBlur}
                    handleChange={employerForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="gst"
                    type="text"
                    label={EmployerLabel.GSTNo}
                    placeholder={PlaceHolderFormat(EmployerLabel.GSTNo)}
                    value={employerForm.values.gst}
                    touched={employerForm.touched.gst}
                    error={employerForm.errors.gst}
                    handleBlur={employerForm.handleBlur}
                    handleChange={(e) => {
                      if (e?.target?.value?.length <= 15) {
                        employerForm.handleChange(e);
                      }
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-end align-items-end">
                    <div className="mt-4 mt-lg-0">
                      <BaseButton
                        className="btn btn-pill"
                        color="primary"
                        type="submit"
                        children={
                          editId !== null
                            ? btnLoader
                              ? Loading
                              : Update
                            : Submit
                        }
                        disabled={btnLoader}
                        loader={btnLoader}
                      ></BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-6 py-2 px-3">
        <h5 className="f-w-600">{EmployerLabel.STAFF_LIST}</h5>
      </div>
      <div className="card mx-3">
        <div className="card-body text-center">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {EmployerData && EmployerData?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              fetchSortingData={handleFetchSorting}
              manualPagination={true}
              columns={columns}
              data={searchValue ? [] : EmployerData || []}
              isGlobalFilter={true}
              SearchPlaceholder={Search}
              onSearch={handleSearchValueChange}
              tableClass="table table-bordered text-center"
            />
          )}
          {!loader && EmployerData?.length === 0 && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlacementEmployer;