import { RiCalendarScheduleLine, RiExpandRightLine, RiHomeLine, RiMoneyRupeeCircleLine, RiTimeLine, RiTimer2Line, RiUser2Line } from "react-icons/ri";

export const menuItems = [
  { label: "Attendance Mode", icon:<RiUser2Line size={40} className="text-primary" />, link: "", openModal: true },
  {
    label: "Holiday",
    link: "/holidayTemplate",
    icon:  <RiHomeLine size={40} className="text-warning" />,
    templates: "",
    openModal: false,
  },
  {
    label: "Leave",
    link: "/leaveTemplate",
    icon: <RiExpandRightLine size={40} className="text-danger" />,
    templates: "",
    openModal: false,
  },
  {
    label: "Shift",
    link: "/ShiftSetting",
    icon: <RiTimeLine size={40} className="text-success" />,
    templates: "",
    openModal: false,
  },
  // {
  //   label: "Automation Rule | Late Entry",
  //   icon: <RiHourglassLine size={40} className="text-warning" />,
  //   link: "/LateEntry",
  //   templates: "",
  // },
  { label: "Month Duration", link: "",icon: <RiTimer2Line size={40} className="text-info" />, templates: "", openModal: true },
  {
    label: "Salary Access",
    link: "/SalaryDetails",
    templates: "",
    icon: <RiMoneyRupeeCircleLine size={40} className="text-success" />,
    openModal: false,
  },
  {
    label: "Report",
    link: "/report",
    icon: <RiCalendarScheduleLine size={40} className="text-primary" />, 
    templates: "Staff Wise Holidays Assigned",
    openModal: false,
  },
];
