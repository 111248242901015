import { ADDLEAVETEMPLATE, DELETELEAVECATEGORIES, EDITLEAVESTAFF, EDITLEAVETEMPLATE, LISTOFLEAVETEMPLATE, VIEWLEAVESTAFF, VIEWLEAVETEMPLATE } from "./apiRoutes";
import { authData } from "./Service";

export const listOfLeaveTemplateApi = async (id) => {
    const response = await authData.get(`${LISTOFLEAVETEMPLATE}${id}`);
    return response?.data;
}
export const addLeaveTemplateApi = async (data) => {
    const response = await authData.post(`${ADDLEAVETEMPLATE}`, data);
    return response?.data;
}
export const viewLeaveTemplateApi = async (id) => {
    const response = await authData.get(`${VIEWLEAVETEMPLATE}${id}`);
    return response?.data;
}
export const editLeaveTemplateApi = async (id, data) => {
    const response = await authData.put(`${EDITLEAVETEMPLATE}${id}`, data);
    return response?.data;
}
export const deleteLeaveCategoryApi = async (id) => {
    const response = await authData.delete(`${DELETELEAVECATEGORIES}${id}`);
    return response?.data;
}
export const viewLeaveStaffListApi = async (id, data) => {
    const response = await authData.post(`${VIEWLEAVESTAFF}${id}`, data);
    return response?.data;
}
export const editLeaveStaffListApi = async (id, data) => {
    const response = await authData.post(`${EDITLEAVESTAFF}${id}`, data);
    return response?.data;
}