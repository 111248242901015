
import { ADDEMPLOYER, DELETEEMPLOYER, EDITEMPLOYER, LISTOFEMPLOYER, VIEWEMPLOYER } from "./apiRoutes";
import { authData } from "./Service";

export const listOfEmployerApi = async (data) => {
  const response = await authData.post(LISTOFEMPLOYER, data);
  return response?.data;
};
export const addEmployerApi = async (data) => {
  const response = await authData.post(ADDEMPLOYER, data);
  return response?.data;
};
export const editEmployerApi = async (id, data) => {
  const response = await authData.put(`${EDITEMPLOYER}${id}`, data);
  return response?.data;
};
export const deleteEmployerApi = async (id) => {
  const response = await authData.delete(`${DELETEEMPLOYER}${id}`);
  return response?.data;
};
export const viewEmployerApi = async (id) => {
  const response = await authData.get(`${VIEWEMPLOYER}${id}`);
  return response?.data;
};
