import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiContractUpDownLine,
  RiDownloadLine,
  RiExpandUpDownLine,
  RiSearchLine,
} from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.min.css";
import CountUp from "react-countup";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {
  DashboardHr,
  getDaysInMonth,
  tableHeaders,
} from "../../Constant/HR/Dashboard";
import { Link } from "react-router-dom";
import {
  blobErrHandler,
  checkStatusCodeSuccess,
  dateUpdate,
  extractDate,
  extractDay,
  extractYearMonth,
  extractYearWiseDate,
  fileDownload,
  formatDate,
  handleResponse,
  isRequired,
} from "../../Constant/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../BaseComponents/BaseLoader";
import {
  attendanceMusterRollReport,
  countStaffAttendanceStatus,
} from "../../Api/HrDashboard";
import { notFound, Submit } from "../../Constant";
import { attendanceLabel } from "../../Constant/HR_Attendance/hrAttendance";
import { toast } from "react-toastify";
import { useSelectedCenter } from "../CenterContext";
import { staffAttendanceMusterApi } from "../../Api/reportApi";
import { cycleDateRadio, formikvalidationFunction, pdfExcelRadio, reportEnums, reportPayloadFunction } from "../../Constant/Report/report";
import { useFormik } from "formik";
import BaseModal from "../../BaseComponents/BaseModal";
import BaseRadioGroup from "../../BaseComponents/BaseRadio";
import BaseInput from "../../BaseComponents/BaseInput";
import { PlaceHolderFormat } from "../../Constant/requireMessage";

const HrDashboard = () => {
  document.title = DashboardHr.title
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedReportDate, setSelectedReportDate] = useState(new Date());
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month"));
  const [expandAll, setExpandAll] = useState(false);
  const [attendanceStats, setAttendanceStats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [attendanceReportData, setAttendanceReportData] = useState([]);
  const [attendanceReport, setAttendanceReport] = useState([]);
  const { selectedCenterPayload } = useSelectedCenter();
  const [modal, setModal] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const incrementDate = () => {
    const newDate = dateUpdate(selectedDate, attendanceLabel.add);
    setSelectedDate(newDate);
  };

  const decrementDate = () => {
    const newDate = dateUpdate(selectedDate, attendanceLabel.subtract);
    setSelectedDate(newDate);
  };

  const handleMonthChange = (e) => {
    const selectedMonthValue = moment(e?.target?.value);
    setSelectedMonth(selectedMonthValue.startOf("month"));
  };
  const handleExpandAll = () => {
    const newExpandAll = !expandAll;
    setExpandAll(newExpandAll);
    if (newExpandAll) {
      fetchMusterReportData(selectedDate);
      setExpandedRow(null);
    } else {
      setExpandedRow(null);
    }
  };

  const fetchData = (date) => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      searchDate: extractYearWiseDate(date),
    };
    countStaffAttendanceStatus(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const transformedStats = mapApiResponseToAttendanceStats(res.data);
          setAttendanceStats(transformedStats);
        } else {
          setAttendanceStats([]);
        }
      })
      .catch((error) => {
        setAttendanceStats([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchMusterReportData = (date) => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      searchDate: extractYearMonth(date),
    };
    attendanceMusterRollReport(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          setAttendanceReport(res.data?.transformedResponse);
          setAttendanceReportData(res.data?.transformedResponse);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        setAttendanceReport([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handleReportDataChange = (date, staffId) => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      searchDate: extractYearMonth(date),
      staff_id: staffId,
    };
    attendanceMusterRollReport(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          setAttendanceReportData(res.data?.transformedResponse);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        setAttendanceReportData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const mapApiResponseToAttendanceStats = (apiData) => {
    return [
      { label: DashboardHr.PRESENT, value: apiData.present },
      { label: DashboardHr.ABSENT, value: apiData.absent },
      { label: DashboardHr.NOTMARKED, value: apiData.unmarked },
      { label: DashboardHr.HALFDAY, value: apiData.halfDay },
      { label: DashboardHr.ONLEAVE, value: apiData.paidLeave },
      { label: DashboardHr.COMPOFF, value: apiData.compOff },
      { label: DashboardHr.WEEKOFF, value: apiData.compOff },
      { label: DashboardHr.HOLIDAY, value: apiData.holiday },
      { label: DashboardHr.UNPAID, value: apiData.unpaidLeave },
      {
        label: DashboardHr.DEACTIVATED,
        value: apiData.totalDeactivatedStaff,
      },
    ];
  };
  const toggleRow = (rowId) => {
    if (expandAll) {
      return;
    }
    setExpandedRow(expandedRow === rowId ? null : rowId);
    handleReportDataChange(selectedDate, rowId);
  };

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);
  useEffect(() => {
    fetchMusterReportData(selectedReportDate);
  }, [selectedReportDate]);

  const CalendarData = () => {
    const daysInMonth = getDaysInMonth(selectedMonth);
    const attendanceDataMap = daysInMonth.reduce((acc, { day }) => {
      const dateKey = formatDate(selectedMonth, day);
      acc[dateKey] = {
        total_working_hours: handleResponse.nullData,
        attendance_status: handleResponse.nullData,
      };
      return acc;
    }, {});

    (attendanceReportData[0]?.attendanceData || []).forEach((entry) => {
      const dateKey = extractYearWiseDate(entry.date);
      if (attendanceDataMap[dateKey]) {
        let workingHours = entry?.total_working_hours ?? handleResponse.nullData;
        
        if (typeof workingHours === "string") {
          workingHours = workingHours.replace(" hours", "");
        }
  
        attendanceDataMap[dateKey] = {
          total_working_hours: workingHours,
          attendance_status: entry.attendance_status || handleResponse.nullData,
        };
      }
    });

    return (
      <table className="table mb-0">
      <thead>
        <tr>
          <th className="bg-light">{DashboardHr.DAYS}</th>
          {daysInMonth?.map((day, index) => (
            <th key={index}>
              {day.dayOfWeek}
              <div className="text-muted">{day.day}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="text-center">
        <tr>
          <td className="bg-light">{DashboardHr.WorkingHoursLabel}</td>
          {daysInMonth?.map(({ day }) => {
            const dateKey = formatDate(selectedMonth, day);
            const data = attendanceDataMap[dateKey];
            return <td key={day}>{data.total_working_hours}</td>;
          })}
        </tr>
        <tr>
          <td className="bg-light">{DashboardHr.Status}</td>
          {daysInMonth?.map(({ day }) => {
            const dateKey = formatDate(selectedMonth, day);
            const data = attendanceDataMap[dateKey];
            const attendanceStatusMap = {
              [DashboardHr.PRESENT]: DashboardHr.P,        
              [DashboardHr.ABSENT]: DashboardHr.A,         
              [DashboardHr.COMPOFF]: DashboardHr.CO,       
              [DashboardHr.HOLIDAY]: DashboardHr.H,        
              [DashboardHr.HALFDAY]: DashboardHr.HD,       
              [DashboardHr.PAIDLEAVE]: DashboardHr.PL,  
              [DashboardHr.NOTMARKED]: DashboardHr.NM,
              [DashboardHr.UNPAID]: DashboardHr.UL,
              [DashboardHr.WEEKOFF]: DashboardHr.WO,
            };
            return (
              <td key={day}>
                {attendanceStatusMap[data.attendance_status=== DashboardHr.PaidLeaveStatus ? DashboardHr.PAIDLEAVE : data.attendance_status] || handleResponse.nullData}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
    );
  };

  const rowFields = [
    {
      key: "",
      component: (row) =>
        expandedRow === row.staff_id || expandAll ? (
          <FaAngleUp />
        ) : (
          <FaAngleDown />
        ),
    },
    { key: DashboardHr.name, component: (row) => row.name || handleResponse.nullData },
    { key: DashboardHr.present, component: (row) => row?.statusCount?.present },
    { key: DashboardHr.absent, component: (row) => row?.statusCount?.absent },
    { key: DashboardHr.halfday, component: (row) => row?.statusCount?.halfDay },
    {
      key: DashboardHr.paidleave,
      component: (row) => row?.statusCount?.paidLeave,
    },
    {
      key: DashboardHr.unmarked,
      component: (row) => row?.statusCount?.unmarked,
    },
    { key: DashboardHr.compOff, component: (row) => row?.statusCount?.compOff },
    { key: DashboardHr.holiday, component: (row) => row?.statusCount?.holiday },
    { key: DashboardHr.weekoff, component: (row) => row?.statusCount?.weekOff },
    { key: DashboardHr.unpaid, component: (row) => row?.statusCount?.unpaidLeave },
    { key: DashboardHr.total, component: (row) => row?.statusCount?.totalDays },
  ];
  const renderMonthContent = (month, shortMonth, longMonth) => {
    const fullYear = new Date(selectedDate).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        cycle_date: null,
        date_range_from: null,
        date_range_end: null,
        pdf_excel: null,
      },
      validationSchema: formikvalidationFunction(
        reportEnums.musterRoll,
        dateRange
      ),
      onSubmit: (values) => {
        setLoader(true);
        const payload = reportPayloadFunction(
          reportEnums.musterRoll,
          formik,
          selectedCenterPayload,
          values.cycle_date
        );
        handleDownload(values, payload);
      },
    });
  
  const handleDownload = (values, payload) => {
    staffAttendanceMusterApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp)) {
          const type =
            values.pdf_excel === reportEnums.PDF
              ? "application/pdf"
              : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          fileDownload(resp?.data, type, reportEnums.musterRollName);
          toast.success(resp?.success);
          setModal(false);
          formik.resetForm();
        } else {
          toast.error(resp);
        }
      })
      .catch((err) => {
        blobErrHandler(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <div className="container-fluid">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="text-md-end d-flex align-items-center justify-content-end text-center mb-1 mt-md-0 p-2">
        <Link to="/staff-attendance" className="text-dark">
          {DashboardHr.ATTENDENCEVIEW}
        </Link>
        <RiArrowRightSLine size={20} />
      </div>
      <div className="card p-0 mb-2">
        <div className="card-header mt-0 py-2 px-4 attendence-card-header">
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-md-0">
              <RiArrowLeftSLine
                className="fs-4 cursor-pointer"
                onClick={decrementDate}
              />
              <div className="date-show">
                <span className="mx-3 text-center">
                  <h5 className="transition-smooth">
                    {extractDate(selectedDate)} |{" "}
                    {extractDay(selectedDate).toUpperCase()}
                  </h5>
                </span>
              </div>
              <RiArrowRightSLine
                className="fs-4 cursor-pointer"
                onClick={incrementDate}
              />
            </div>
            <div className="card-body attendence-card-body">
              <div className="row my-2 text-center d-flex justify-content-center align-items-center">
                {attendanceStats?.map((stat, index) => (
                  <div className="col-4 col-sm border-right" key={index}>
                    <span className="fw-normal">{stat.label}</span>
                    <h6 className="fw-600 counter">
                      <CountUp end={stat.value} />
                    </h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid title-fluid pr-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <h5>{DashboardHr.ATTENDENCEREPORT}</h5>
          </div>
          <div className="d-flex align-items-center">
            <span
              className="p-2 pb-1 fs-5 text-success"
              title={DashboardHr.DOWNLOAD}
            >
              <RiDownloadLine onClick={() => setModal(true)} />
            </span>
            <DatePicker
              className="form-control"
              selected={selectedReportDate}
              onChange={(date) => {
                setSelectedDate(date);
                setSelectedReportDate(date);
                handleMonthChange();
              }}
              renderCustomHeader={renderMonthContent}
              showMonthYearPicker
              dateFormat="MM/yyyy"
            />
          </div>
        </div>
      </div>
      <div className="card p-2">
        <div className="row ml-4">
          <h5 className="px-4 mt-2">{DashboardHr.MONTHLYREGULAR}</h5>
        </div>
        <div className="card-body overflow-x-auto">
          <table className="table table-bordered attendance-table text-center">
            <thead>
              <tr>
                <th className="text-center">
                  <span
                    title={
                      expandAll
                        ? DashboardHr.COLLAPSEALL
                        : DashboardHr.EXPANDALL
                    }
                    onClick={handleExpandAll}
                  >
                    {expandAll ? (
                      <RiContractUpDownLine size={20} />
                    ) : (
                      <RiExpandUpDownLine size={20} />
                    )}
                  </span>
                </th>
                {tableHeaders?.map((header, index) => (
                  <th key={index}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {attendanceReport?.length > 0 ? (
                attendanceReport.map((row) => (
                  <React.Fragment key={row?.staff_id}>
                    <tr
                      className="arrow"
                      onClick={() => toggleRow(row?.staff_id)}
                    >
                      {rowFields?.map(({ key, component }, index) => (
                        <td key={index}>{component(row)}</td>
                      ))}
                    </tr>
                    {(expandedRow === row?.staff_id || expandAll) && (
                      <tr className="calendar">
                        <td colSpan="12">{CalendarData()}</td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length + 1}>
                    <div className="py-4 text-center">
                      <div>
                        <RiSearchLine className="fs-2" />
                      </div>
                      <div className="mt-4">
                        <h5>{notFound.dataNotFound}</h5>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <BaseModal
            isOpen={modal}
            title={reportEnums.musterRoll}
            toggler={() => setModal(false)}
            submit={formik.handleSubmit}
            submitText={Submit}
            disabled={loader}
            loader={loader}
          >
            <form onSubmit={formik.handleSubmit}>
              <div className="mx-3">
                <div className="m-n5 form-label">
                  {reportEnums.downloadBy}
                  {isRequired}
                </div>
                <BaseRadioGroup
                  name="cycle_date"
                  options={cycleDateRadio}
                  selectedValue={formik.values.cycle_date}
                  onChange={(e) => {
                    formik.setFieldValue("cycle_date", e.target.value);
                    setDateRange(e.target.value);
                  }}
                  classNameLabel="cycle_date"
                  value={formik.values.cycle_date}
                  touched={formik.touched.cycle_date}
                  error={formik.errors.cycle_date}
                  handleBlur={formik.handleBlur}
                />
              </div>
              {dateRange === reportEnums.dateRange && (
                <div className="mx-3">
                  <div>{reportEnums.dateRange}</div>
                  <div className="d-flex justfy-content-start gap-2 mb-4">
                    <BaseInput
                      name="date_range_from"
                      type="date"
                      placeholder={PlaceHolderFormat(
                        reportEnums.reportCycleFrom
                      )}
                      value={formik.values.date_range_from}
                      touched={formik.touched.date_range_from}
                      error={formik.errors.date_range_from}
                      handleBlur={formik.handleBlur}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      required={true}
                    />
                    <BaseInput
                      name="date_range_end"
                      type="date"
                      placeholder={PlaceHolderFormat(
                        reportEnums.reportCycleEnd
                      )}
                      value={formik.values.date_range_end}
                      touched={formik.touched.date_range_end}
                      error={formik.errors.date_range_end}
                      handleBlur={formik.handleBlur}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      required={true}
                    />
                  </div>
                </div>
              )}
              {dateRange === reportEnums.salaryCycle && (
                <div className="mx-3 mb-3">
                  <label htmlFor="selectedDate">
                    {reportEnums.reportCycle}
                    {isRequired}
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={formik.values.report_cycle}
                    onChange={(date) =>
                      formik.setFieldValue("report_cycle", date)
                    }
                    placeholderText={reportEnums.reportCycle}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                  />
                  {formik.touched.report_cycle && formik.errors.report_cycle ? (
                    <div className="text-danger">
                      {formik.errors.report_cycle}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="mx-3">
                <div className="">
                  {reportEnums.reportType}
                  {isRequired}
                </div>
                <BaseRadioGroup
                  name="pdf_excel"
                  options={pdfExcelRadio}
                  selectedValue={formik.values.pdf_excel}
                  onChange={(e) => {
                    formik.setFieldValue("pdf_excel", e.target.value);
                  }}
                  value={formik.values.pdf_excel}
                  touched={formik.touched.pdf_excel}
                  error={formik.errors.pdf_excel}
                  handleBlur={formik.handleBlur}
                  required={true}
                />
              </div>
            </form>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
