import {
  Bookmark,
  AlertOctagon,
  CheckSquare,
  CreditCard,
  Edit,
  FileText,
  Grid,
  Home,
  Layers,
  Server,
  Trello,
  User,
  UserPlus,
  Users,
  BookOpen,
  Pocket,
  Clipboard,
  MapPin,
  Package,
  Settings,
  Lock,
  Briefcase,
  CheckCircle,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Mobilization",
    Items: [
      {
        title: "Mobilization",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          { path: `/dashboard`, title: "Inquiry", type: "link", icon: Grid },
          {
            path: `/batchcreation`,
            title: "Batch Creation",
            type: "link",
            icon: Edit,
          },
          {
            path: `/batchlist`,
            title: "Batch List",
            type: "link",
            icon: Server,
          },
          { path: `/candidate`, title: "Candidate", type: "link", icon: User },
          {
            path: `/attendance`,
            title: "Attendance",
            type: "link",
            icon: Users,
          },
        ],
      },
    ],
  },
  {
    menutitle: "Master",
    Items: [
      {
        title: "Master",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          { path: `/center`, title: "Center", type: "link", icon: MapPin },
          { path: `/course`, title: "Course", type: "link", icon: FileText },
          { path: `/category`, title: "Category", type: "link", icon: Trello },
          { path: `/career`, title: "Career", type: "link", icon: Users },
          { path: `/position`, title: "Position", type: "link", icon: User },
          { path: `/stock`, title: "Stock", type: "link", icon: Package },
          { path: `/placementEmployer`, title: "Employer", type: "link", icon: Briefcase },
        ],
      },
    ],
  },
  {
    menutitle: "Account",
    Items: [
      {
        title: "Account",
        icon: User,
        type: "sub",
        active: false,
        children: [
          { path: `/vendor`, title: "Vendor", type: "link", icon: User },
          { path: `/bill`, title: "Bill", type: "link", icon: CreditCard },
          {
            path: `/billApproval`,
            title: "Bill Approval",
            type: "link",
            icon: CheckSquare,
          },
          { path: `/billPayment`, title: "Bill Payment", type: "link", icon: CheckSquare },
          { path: `/hostel`, title: "Hostel", type: "link", icon: Home },
          { path: `/class`, title: "Class", type: "link", icon: BookOpen },
        ],
      },
    ],
  },
  {
    menutitle: "Other",
    Items: [
      {
        title: "Other",
        icon: Clipboard,
        type: "sub",
        active: false,
        children: [
          { path: `/tot`, title: "Tot", type: "link", icon: Bookmark },
          {
            path: `/accreditation`,
            title: "Accreditation",
            type: "link",
            icon: AlertOctagon,
          },
          {
            path: `/placement`,
            title: "Placement",
            type: "link",
            icon: Pocket,
          },
          {
            path: `/inventory`,
            title: "Inventory",
            type: "link",
            icon: Clipboard,
          },
          {
            path: `/jobPosition`,
            title: "Job Position",
            type: "link",
            icon: UserPlus,
          },
        ],
      },
    ],
  },

  {
    menutitle: "HR",
    Items: [
      {
        title: "HR",
        icon: Grid,
        type: "sub",
        active: false,
        children: [
          {
            path: `/HrDashboard`,
            title: "Dashboard",
            type: "link",
            icon: Grid,
          },
          {
            path: `/staff-attendance`,
            title: "Staff Attendance",
            type: "link",
            icon: User,
          },
          {
            path: `/attendanceApproval`,
            title: "Attendance Approval",
            type: "link",
            icon: CheckSquare,
          },
          { path: `/leaveApproval`, title: "Leave Approval", type: "link", icon: CheckCircle },
          { path: `/setting`, title: "Setting", type: "link", icon: Settings },
          {
            path: `/staff`,
            title: "Staff",
            type: "link",
            icon: UserPlus,
          },
        ],
      },
    ],
  },
  {
    menutitle: "Role",
    Items: [
      {
        path: "/role",
        icon: Lock,
        title: "Role",
        active: false,
        type: "link",
      },
    ],
  },
];
