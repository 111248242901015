import { ENCASHLEAVE, LEAVESUMMARYLIST, PERDAYSALARY } from "./apiRoutes";
import { authData } from "./Service";

export const listOfLeaveSummaryApi = async (id,data) => {
  const response = await authData.post(`${LEAVESUMMARYLIST}${id}`, data);
  return response?.data;
};
export const perDaySalaryApi = async (id) => {
  const response = await authData.get(`${PERDAYSALARY}${id}`);
  return response?.data;
};
export const encashLeaveApi = async (id, data) => {
  const response = await authData.post(`${ENCASHLEAVE}${id}`, data);
  return response?.data;
};