import { ATTENDACNEMUSTERROLL, COUNTOFSTAFFATTENDANCESTATUS } from "./apiRoutes";
import { authData, nonAuthData } from "./Service";

export const attendanceMusterRollReport = async (data) => {
    const response = await authData.post(ATTENDACNEMUSTERROLL,data);
    return response?.data;
};
export const countStaffAttendanceStatus = async (data) => {
    const response = await nonAuthData.post(COUNTOFSTAFFATTENDANCESTATUS,data);
    return response?.data;
};