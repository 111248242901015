export const accreditationEnums = {
  TITLE: "Accreditation",
  CENTER_NAME: "Center Name",
  COURSE_NAME: "Course Name",
  SECTOR: "Sector",
  ACCREDITATION_DATE: "Accreditation Date",
  EXPIRY_DATE: "Expiry Date",
  expiry_data: "expiry_date",
  REMARKS: "Remarks",
  ADDRESS: "Address",
  QP_CODE: "QP Code",
  ACCREDITATION_DETAILS: "Accreditation Details",
  ACCREDITATION: "Accreditation",
  ACCREDITATION_LIST: "Accreditation List",
  EXPERIENCE: "Experience",
  SALARY: "Salary",
  JOINING_DATE: "Joining Date",
  LEFT_DATE: "Left Date",
  STAFF_LIST: "Staff List",
  PERSONAL_DETAILS: "Personal Details",
  STAFF: "Staff",
  OTHER_DETAILS: " Other Details",
  EXPIRY_DATE_VALIDATION: "Expiry date must be greater than accreditation date",
};
