import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addEvent,
  checkStatusCodeSuccess,
  eventGenerator,
  inventoryEvent,
  inventoryEventMessage,
  notificationEvent,
  notificationLable,
  removeEvent,
} from "../../Constant/common";
import { toast } from "react-toastify";
import BaseModal from "../../BaseComponents/BaseModal";
import {
  listOfNotificationApi,
  markAsReadApi,
  clearNotificationApi,
} from "../../Api/NotificationApi";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import "../../assets/css/notification.css";
import Spinner from "../../BaseComponents/BaseLoader";
import { Deselect_all, Select_all } from "../../Constant/common";

const NotificationList = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [noNotification, setNoNotifications] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const listRef = useRef(null);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await listOfNotificationApi();
      setNotifications(response?.data);
      setNoNotifications(response?.data?.length === 0);
    } catch (error) {
      setNoNotifications(true);
      setSelectedNotifications([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      fetchNotifications();
    };
    addEvent(notificationEvent, handleEvent);
    return () => {
      removeEvent(notificationEvent, handleEvent);
    };
  }, []);

  const handleApiCall = async (apiFunction, payload, successMessage) => {
    setIsLoading(true);
    try {
      const response = await apiFunction(payload);
      if (checkStatusCodeSuccess(response?.statusCode)) {
        toast.success(successMessage);
        await fetchNotifications();
        eventGenerator(inventoryEvent, inventoryEventMessage);
        if (response?.data?.length === 0) {
          setSelectedNotifications([]);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      setSelectedNotifications([]);
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (notification) => {
    setNotificationToDelete(notification);
    setShowModal(true);
  };

  const handleDelete = async() => {
    if (notificationToDelete) {
      const payload = { id: [notificationToDelete?.id] };
      await handleApiCall(
        clearNotificationApi,
        payload,
        notificationLable.toastMessage
      );
      if (notifications?.length <= 1) {        
        setNotifications([]);
      }
      setShowModal(false);
    }
  };

  const handleClearSelected = () => {
    const payload = { id: selectedNotifications };
    handleApiCall(
      clearNotificationApi,
      payload,
      notificationLable.clearSelectedMessage
    );
    setSelectedNotifications([]);
  };

  const handleMarkAsRead = (notification) => {
    const payload = { id: [notification?.id] };
    handleApiCall(markAsReadApi, payload, notificationLable.markAsReadMessage);
  };

  const handleSelectNotification = (id) => {
    setSelectedNotifications((prevSelected) =>
      prevSelected?.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedNotifications(
      selectedNotifications?.length === notifications?.length
        ? []
        : notifications?.map((notification) => notification?.id)
    );
  };

  const handleMarkSelectedAsRead = () => {
    handleApiCall(
      markAsReadApi,
      { id: selectedNotifications },
      notificationLable.markSelectedReadMessage
    );
    setSelectedNotifications([]);
  };

  const handleMarkAllAsRead = () => {
    const allIds = notifications?.map((n) => n?.id);
    handleApiCall(
      markAsReadApi,
      { id: allIds },
      notificationLable.markAllAsReadMessage
    );
    setNotifications([]);
  };

  const handleClearAll = () => {
    const allIds = notifications?.map((n) => n?.id);
    handleApiCall(
      clearNotificationApi,
      { id: allIds },
      notificationLable.clearAllMessage
    );
    setNotifications([]);
  };

  const handleNotificationClick = (moduleName) => {
    switch (moduleName) {
      case notificationLable.Bill:
        navigate("/bill");
        break;
      case notificationLable.Accreditation:
        navigate("/accreditation");
        break;
      case notificationLable.Tot:
        navigate("/tot");
        break;
      case notificationLable.Inventory:
        navigate("/inventory");
        break;
      default:
        navigate("/allnotificationlist");
    }
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <div className="row mb-4 mt-4">
        <div className="col-6">
          <h5 className="f-w-600">{notificationLable.allNotification}</h5>
        </div>
        <div className="col-6 text-end">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="text-end rounded"
          >
            <DropdownToggle caret className="rounded-pill">
              {notificationLable.Action}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={fetchNotifications}>
                {notificationLable.refresh}
              </DropdownItem>
              <DropdownItem onClick={handleSelectAll}>
                {selectedNotifications?.length === notifications?.length
                  ? Deselect_all
                  : Select_all}
              </DropdownItem>
              <DropdownItem onClick={handleMarkAllAsRead}>
                {notificationLable.markAllAsRead}
              </DropdownItem>
              <DropdownItem onClick={handleClearAll}>
                {notificationLable.clearAll}
              </DropdownItem>
              <DropdownItem
                onClick={handleMarkSelectedAsRead}
                disabled={selectedNotifications?.length === 0}
              >
                {notificationLable.markSelectedRead}
              </DropdownItem>
              <DropdownItem
                onClick={handleClearSelected}
                disabled={selectedNotifications?.length === 0}
              >
                {notificationLable.clearSelected}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <div className="card shadow-sm">
        <div className="card-body pt-0 mt-3">
          <div className="todo">
            <div className="todo-list-wrapper">
              <div className="todo-list-container">
                <div className="todo-list-body">
                  <ul id="todo-list" ref={listRef}>
                    {notifications?.map((notification) => (
                      <li
                        key={notification?.id}
                        id={`notification-${notification?.id}`}
                        className="task"
                      >
                        <div className="task-container task-div">
                          <span className="task-action-btn text-start">
                            <BaseCheckbox
                              id={`notification-checkbox-${notification?.id}`}
                              checked={selectedNotifications?.includes(
                                notification?.id
                              )}
                              onChange={() =>
                                handleSelectNotification(notification?.id)
                              }
                            />
                          </span>
                          <span
                            className="mt-2"
                            onClick={(e) => {
                              e.preventDefault();
                              handleNotificationClick(notification?.moduleName);
                            }}
                          >
                            <Link
                              className="text-dark align-bottom"
                              to={notification.link}
                            >
                              {notification.message}
                            </Link>
                          </span>
                          <span className="notification-icon">
                            <span
                              className="action-box large delete-btn"
                              title="Mark as read"
                              onClick={() => handleMarkAsRead(notification)}
                            >
                              <i className="icon">
                                <i className="icofont icofont-inbox text-primary"></i>
                              </i>
                            </span>
                            <span
                              className="action-box large delete-btn"
                              title="Delete"
                              onClick={() => openModal(notification)}
                            >
                              <i className="icon">
                                <i className="icofont icofont-ui-delete text-danger"></i>
                              </i>
                            </span>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {isLoading && <Spinner attrSpinner={{ className: "loader-2" }} />}
            {noNotification && !isLoading && (
              <div className="py-4 text-center">
                <p>{notificationLable.noNotification}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <BaseModal
        isOpen={showModal}
        toggler={() => setShowModal(false)}
        submit={handleDelete}
        title={notificationLable.deleteNotification}
        submitText={notificationLable.Yes}
      >
        <div className="text-left">
          <p>{notificationLable.deleteBody}</p>
        </div>
      </BaseModal>
    </>
  );
};

export default NotificationList;
