export const batchallocationLabel = {
  Filters:"Filters",
  title: "Batch Creation",
  createBtach: "Create Batch",
  candidateList: "Candidate List",
  create: "Create",
  cancel: "Cancel",
  selectCourse: "Select Course",
  course: "course",
  batchList: "Batch List",
  candidateLable: "Candidate Name",
  mobileNo: "Mobile No",
  candidateKey: "candidate_name",
  mobileNoKey: "mobile_no",
  conatactKey: "contact_no",
  bacthNameKey: "batch_id",
  batchName: "Batch Id",
  startDate: "Start Date",
  endDate: "End Date",
  qpCode: "Qp Code",
  courseName: "Course Name",
  totalCandidate: "Maximum Candidate Allowed",
  totalCandidateKey: "no_of_student",
  courseNameKey: "course.course_name",
  action: "Action",
  actionKey: "action",
  editBatch: "Edit Batch",
  searchBar: "Search",
  courseId: "courseId",
  addSelecte: "Add Selected Candidate",
  status: "status",
  statusUpdateTitle: "Status Update",
  submit: "Submit",
  noOfStudent: "Number of Candidate",
  duration: "Duration (In days)",
  durationKey: "Duration",
  endTime: "End Time",
  startTime: "Start Time",
  centerName: "Center Name",
  totalEnrolled: "Total Enrolled Candidate",
  totalEnrolledKey: "candidateCount",
  centerKey: "center.center_name",
  assessmentOfBatchLabel: "Assessment of Batch",
  NoteMessageBatchDelete: " Note: This will also delete the batch - ",
  extended_date:"Extended Date",
  courseHours:"Course Hours",
  companyName: "Company Name",
  address: "Company Address",
};

export const batchallocationValidation = {
  batchNameValidation: "Batch Id",
  isGreaterLabel: "is-greater",
  startDateValidation: "start date",
  endDateValidation: "End date",
  startTimeValidation: "Start Time",
  endTimeValidation: "End Time",
  durationValidation: "Duration",
  noOfCandidateValidation: "No Of Candidate",
  endTime: "End time should be greater than or equal to start time",
};

export const editBatchLable = {
  editBatchTItle: "Edit Batch Details",
  importData: "importData",
  candidateName: "Candidate Name",
  contactNumber: "Contact Number",
  candidateNameKey: "candidate.candidate_name",
  can_idKey: "candidate.can_id",
  emp_idKey: "candidate.emp_id",
  can_id: "Can Id",
  emp_id: "Emp Id",
  contactNoKey: "candidate.contact_no",
  addMore: "Add More Candidate",
  import: "Import",
  export: "Export",
  delete: "Delete",
  deleteBody: "Are you sure you want to delete selected record?",
  uploadImage: "Upload File",
  imageUpload: "Upload Image",
  errorSizeImage: "File size should be less than 1MB",
  upoladImage: "Upload",
  importFile: "Import File",
  file: "File",
  csvValidate: "Only CSV files are allowed",
  fileType: "fileType",
  dateValidation: "End date should be greater than or equal to start date",
  yesLabel: "Yes",
  deleteTitleLabel: "Delete Confirmation",
  exportTitle:"Please select a candidate to export"
};

export const StatusOptions = [
  { id: 0, value: "", label: "Select Status" },
  { id: 1, value: "Pending", label: "Pending" },
  { id: 2, value: "Completed", label: "Completed" },
  { id: 3, value: "In Progress", label: "In Progress" },
];
export const DataError = "Data is not imported due to below errors";
export const BatchListTitle = "Batch List";
export const BatchEditTitle = "Edit Batch";
export const AddCandidate = "Add More Candidate";
