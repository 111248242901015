import React from "react";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseButton from "../../BaseComponents/BaseButton";
import { formFields, hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { digitRegex } from "../../Constant/validation";
import {
  extractDate,
  handleResponse,
  isRequired,
} from "../../Constant/common";
import { RiCheckLine, RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import { Edit } from "../../Constant";
import { BaseStaffURL } from "../../Api/Service";
import { viewFile } from "../../Constant/Inquiry/Inquiry";
import { Link } from "react-router-dom";

const EmploymentDetails = ({
  staffForm,
  viewEmploymentMode,
  handleEmploymentEdit,
  handleEmploymentDiscard,
  editEmploymentMode,
  staffData,
  btnLoader,
  isEditMode,
  handleFileChange,
}) => {
  return (
    <>
      {viewEmploymentMode ? (
        <div id="employment-info" className="info-section">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.EMPLOYMENT_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              <BaseButton
                onClick={handleEmploymentEdit}
                title={Edit}
                className="btn-pill btn-light mx-2"
                children={
                  <RiEditBoxLine
                    size={20}
                  />
                }
              />
            </div>
          </div>
          <div className="border-bottom mb-2 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.JOINING_DATE}</span>
                <br />
                <span className="f-w-600">
                  {(staffData?.staffGeneralInformation?.date_of_joining &&
                    extractDate(
                      staffData?.staffGeneralInformation?.date_of_joining
                    )) ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.UAN}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.uan_no ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.PAN}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.pan_no ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.AADHAR_NUMBER}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.aadhar_no ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <span className="text-secondary fw-normal fs-8">
                {hrEnums.DOCUMENT}
              </span>
              <br />
              {staffData?.staffGeneralInformation?.other_document?.length > 0 ? (
                <span className="fw-normal">
                  <Link
                    target="_blank"
                    className="text-primary"
                    to={`${BaseStaffURL}${staffData?.staffGeneralInformation?.other_document}`}
                  >
                    {viewFile}
                  </Link>
                </span>
              ) : (
                <span>{handleResponse.nullData}</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div id="employment-info" className="info-section">
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.EMPLOYMENT_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              {editEmploymentMode && (
                <>
                  <BaseButton
                    onClick={handleEmploymentDiscard}
                    className="btn btn-pill btn-light"
                    children={<RiCloseLine
                      size={20}
                    />}
                  />
                  <BaseButton
                    className="mx-2 ms-1"
                    color="light"
                    type="submit"
                    loader={btnLoader}
                    children={<RiCheckLine size={20} />}
                  />
                </>
              )}
            </div>
          </div>
          <div className="border-bottom mb-4 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-lg-3">
              <BaseInput
                label={
                  <>
                    {hrEnums.JOINING_DATE}{isRequired}
                  </>
                }
                name={formFields.joining_date}
                type="date"
                placeholder={PlaceHolderFormat(hrEnums.JOINING_DATE)}
                value={staffForm.values.joining_date}
                touched={staffForm.touched.joining_date}
                error={staffForm.errors.joining_date}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.uan}
                type="text"
                label={hrEnums.UAN}
                placeholder={PlaceHolderFormat(hrEnums.UAN)}
                value={staffForm.values.uan}
                touched={staffForm.touched.uan}
                error={staffForm.errors.uan}
                handleBlur={staffForm.handleBlur}
                maxLength={12}
                handleChange={(e) => {
                  if (digitRegex.test(e?.target?.value)) {
                    staffForm.handleChange(e);
                  }
                }}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.pan}
                type="text"
                label={hrEnums.PAN}
                placeholder={PlaceHolderFormat(hrEnums.PAN)}
                value={staffForm.values.pan}
                touched={staffForm.touched.pan}
                error={staffForm.errors.pan}
                handleBlur={staffForm.handleBlur}
                maxLength={10}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.aadhar_number}
                type="text"
                label={hrEnums.AADHAR_NUMBER}
                placeholder={PlaceHolderFormat(hrEnums.AADHAR_NUMBER)}
                value={staffForm.values.aadhar_number}
                touched={staffForm.touched.aadhar_number}
                error={staffForm.errors.aadhar_number}
                handleBlur={staffForm.handleBlur}
                maxLength={12}
                handleChange={(e) => {
                  if (digitRegex.test(e?.target?.value)) {
                    staffForm.handleChange(e);
                  }
                }}
              />
            </div>
            {!isEditMode && (
              <>
                <div className="col-lg-3 mt-4">
                  <BaseInput
                    name={formFields.monthly_salary}
                    type="number"
                    label={
                      <>
                        {hrEnums.MONTHLY_SALARY_INR}{isRequired}
                      </>
                    }
                    placeholder={PlaceHolderFormat(hrEnums.MONTHLY_SALARY)}
                    value={staffForm.values.monthly_salary}
                    touched={staffForm.touched.monthly_salary}
                    error={staffForm.errors.monthly_salary}
                    handleBlur={staffForm.handleBlur}
                    handleChange={(e) => {
                      if (digitRegex.test(e?.target?.value)) {
                        staffForm.handleChange(e);
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 mt-4">
                  <BaseInput
                    name={formFields.monthly_special_allowance}
                    type="number"
                    label={hrEnums.MONTHLY_ALLOWANCE_INR}
                    placeholder={PlaceHolderFormat(hrEnums.MONTHLY_ALLOWANCE)}
                    value={staffForm.values.monthly_special_allowance}
                    touched={staffForm.touched.monthly_special_allowance}
                    error={staffForm.errors.monthly_special_allowance}
                    handleBlur={staffForm.handleBlur}
                    handleChange={(e) => {
                      if (digitRegex.test(e?.target?.value)) {
                        staffForm.handleChange(e);
                      }
                    }}
                  />
                </div>
              </>
            )}
            <div className="col-lg-3 mt-2">
              <BaseInput
                name="document"
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                label={hrEnums.DOCUMENT}
                handleChange={(e) => handleFileChange(e, e.target.name)}
                touched={staffForm.touched.document}
                error={staffForm.errors.document}
                handleBlur={staffForm.handleBlur}
              />
              {staffForm.values.document?.length > 0 && (
                <span className="fw-normal">
                  <Link
                    target="_blank"
                    className="text-dark"
                    to={`${BaseStaffURL}${staffForm.values.document}`}
                  >
                    {viewFile}
                  </Link>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmploymentDetails;
