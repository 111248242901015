import {
  ADDHOLIDAYTEMPLATE,
  DELETEHOLIDAY,
  DELETETEMPLATE,
  EDITHOLIDAYTEMPLATE,
  EDITMANAGESTAFF,
  LISTOFHOLIDAYTEMPLATE,
  MANAGESTAFFLIST,
  VIEWHOLIDAYTEMPLATE,
} from "./apiRoutes";
import { authData } from "./Service";

export const listOfHoliday = async (data) => {
  const response = await authData.post(LISTOFHOLIDAYTEMPLATE, data);
  return response?.data;
};
export const addHoliday = async (data) => {
  const response = await authData.post(ADDHOLIDAYTEMPLATE, data);
  return response?.data;
};
export const editHoliday = async (id, data) => {
  const response = await authData.put(`${EDITHOLIDAYTEMPLATE}${id}`, data);
  return response?.data;
};
export const viewHolidayTemplate = async (id, data) => {
  const response = await authData.get(`${VIEWHOLIDAYTEMPLATE}${id}`, data);
  return response?.data;
};
export const manageStaffList = async (id,data) => {
  const response = await authData.post(`${MANAGESTAFFLIST}${id}`,data);
  return response?.data;
};
export const editManageStaff = async (id, data) => {
  const response = await authData.post(`${EDITMANAGESTAFF}${id}`, data);
  return response?.data;
};
export const deleteHoliday = async (id,data) => {
  const response = await authData.put(`${DELETEHOLIDAY}${id}`,data);
  return response?.data;
};
export const deleteHolidayTemplate = async (id) => {
  const response = await authData.put(`${DELETETEMPLATE}${id}`);
  return response?.data;
};
