import React from "react";
import Forbidden from "../Pages/Forbidden";
import { moduleName } from "../Constant/common";

const ProtectedRoute = ({ path, element, permissions, routes }) => {
  const allowedModules = permissions?.map(({ module_name }) => module_name);
  const route = routes?.find(r => r.path === path);

  if (route) {
    if (Array.isArray(route?.module_name) ? route?.module_name.includes(moduleName.Public) : route?.module_name === moduleName.Public) {
      return element;
    }

    const hasAccess = Array.isArray(route?.module_name)
      ? route?.module_name.some(module => allowedModules?.includes(module))
      : allowedModules?.includes(route?.module_name);

    return hasAccess ? element : <Forbidden />;
  }

  return <Forbidden />;
};

export default ProtectedRoute;
