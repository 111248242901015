export const leaveSummaryEnums = {
  leaveSummary: 'Leave Summary',
  totalLeavesTaken: "Total Leaves Taken",
  balanceLeave: "Balance Leaves",
  approved: "Approved",
  pending: "Pending",
  encashLeave: "Encash Leaves",
  reset: "Reset",
  leaves: "Leave(s)",
  encashableLeave: "Encashable Leaves",
  leaveBalanceEmpty: "There are no leaves for encashment",
  amountPerLeave: "Amount Per Leave",
  editLeaveBalance: "Edit Leave Balance",
  leaveInstruction: "Set Per Leave Compensation. Encashed amount would be added to Staff's salary.",
  selectEncashleave: "Select Total Number of Leaves to Encash",
  totalPayout: "Total Payout",
  noLeaveMsg:"There are no leaves left for encashment",
  day: "Day",
  days:"Days",
}