import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { leaveTemplate } from "../../../../Constant/HR/leaveTemplate";
import { leaveTitle } from "../../../../Constant/title";
import { toast } from "react-toastify";
import {
  centerIDPayload,
  checkStatusCodeSuccess,
  rendorBackButton,
} from "../../../../Constant/common";
import { listOfLeaveTemplateApi } from "../../../../Api/leaveTemplate";
import Spinner from "../../../../BaseComponents/BaseLoader";
import { RiPencilFill, RiSearchLine, RiUserSettingsFill } from "react-icons/ri";
import { notFound } from "../../../../Constant";
import { useSelectedCenter } from "../../../CenterContext";
import BaseButton from "../../../../BaseComponents/BaseButton";

const LeaveTemplate = () => {
  document.title = leaveTitle;
  const navigate = useNavigate();
  const [leaveData, setLeaveData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { selectedCenterPayload } = useSelectedCenter();
  const selectedCenter = selectedCenterPayload.center_id;
  const handleEditTemplate = (id) => {
    navigate(`/editLeaveTemplate/${id}`);
  };

  const fetchData = () => {
    setLoader(true);
    listOfLeaveTemplateApi(
      selectedCenter ? `${centerIDPayload}${selectedCenter}` : `${centerIDPayload}`
    )
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          setLeaveData(res?.data);
        } else {
          setLeaveData([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setLeaveData([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateTemplate = () => {
    navigate("/createLeaveTemplate");
  };

  return (
    <>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{leaveTemplate.LEAVE_TEMPLATE}</h5>
            <div className="d-flex gap-2">
              <BaseButton
                className="btn btn-pill"
                type="button"
                children={leaveTemplate.CREATE}
                onClick={handleCreateTemplate}
            />
            {rendorBackButton(() => navigate("/setting"))}
          </div>
        </div>
        <div className="mt-2">
          <div>
            {loader ? (
              <Spinner attrSpinner={{ className: "loader-2" }} />
            ) : leaveData?.length > 0 ? (
              leaveData.map((data) => (
                <div
                  className="list-group-item holiday-template bg-white py-2 px-5 mb-2"
                  key={data?.id}
                >
                  <div className="row align-items-center">
                    <div className="col-12 col-sm-4 col-md-3 font-weight-bold">
                     {data?.leave_template_name}
                    </div>
                    <div className="col-12 col-sm-4 col-md-3">
                        {data?.totalLeave === 1
                          ? leaveTemplate.LEAVE
                          : leaveTemplate.LEAVES}
                          {" "}
                      <span className="badge badge-pill badge-light text-dark mx-1">
                          {data?.totalLeave}
                      </span>
                    </div>
                    <div className="col-12 col-sm-4 col-md-2">
                        {leaveTemplate.APPLIED_STAFF} {" "}
                      <span className="badge badge-pill badge-light text-dark mx-1">
                      {data?.totalAssignStaff}
                      </span>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="d-flex flex-column flex-md-row align-items-end justify-content-end">
                        <span className="p-2 text-primary" title={leaveTemplate.EDIT_TEMPLATE} onClick={() => handleEditTemplate(data?.id)}><RiPencilFill size={18} /></span>
                        <span className="p-2 text-success" title={leaveTemplate.MANAGE_STAFF} onClick={() =>  navigate(`/manageLeaveStaff/${data?.id}`)}><RiUserSettingsFill size={18} /></span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card py-4 text-center">
                <div>
                  <RiSearchLine className="fs-2" />
                </div>
                <div className="mt-4">
                  <h5>{notFound.dataNotFound}</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveTemplate;
