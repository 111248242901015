import { validationMessages } from "../validation";
import * as yup from "yup";

export const reportEnums = {
  report: "Report",
  exitEmployee: "Exit Employee",
  musterRoll: "Muster Roll",
  leaveSummary: "Leave Summary Report",
  dailyAttendance: "Daily Attendance",
  attendancePayroll: "Attendance Payroll",
  accountDetail: "Account Detail",
  staffDetail: "Staff Detail",
  leaveBalance: "Leave Balance",
  exitEmployeeName: "exit_employee_report",
  musterRollName: "muster_roll_report",
  leaveSummaryName: "leave_summary_report",
  dailyAttendanceName: "daily_attendance_report",
  attendancePayrollName: "attendance_payroll_report",
  accountDetailName: "account_detail_report",
  staffDetailName: "staff_detail_report",
  leaveBalanceName: "leave_balance_report",
  generate: "Generate",
  dateRange: "Date Range",
  reportCycle: "Report Cycle",
  attendanceDetail: "Attendance Detail",
  attendanceDate: "Attendance Date",
  cycle: "Cycle",
  salaryCycle: "Salary Cycle",
  reportType: "Report Type",
  downloadBy: "Download By",
  selectDate: "Select Date",
  PDF: "Pdf",
  reportCycleFrom: "From Date",
  reportCycleEnd: "End Date",
  endDateValidation: "End date must be greater than start date",
  exitSubtitle: "Employee Exit Report",
  musterRollSubtitle: "Muster Roll Report",
  dailyAttendanceReportSubtitle: "Daily Attendance Report",
  payrollSubtitle: "Attendance Payroll Report",
  accountSubtitle: "Account Detail Report",
  staffDetailSubtitle: "Staff Detail Report",
  leaveBalanceSubtitle: "Leave Balance Report",
  confirmationText: "Are you sure you want to generate Report?",
  leaveSummarySubtitle:"Leave Summary Report",
};  


export const cycleDateRadio = [
    { id: "radioinline1", value: "Salary Cycle", label: "Salary Cycle", labelSuffix: "" },
    { id: "radioinline2", value: "Date Range", label: "Date Range", labelSuffix: "" },
  ];

export const pdfExcelRadio = [
    { id: "radiopdf", value: "Pdf", label: "Pdf", labelSuffix: "" },
    { id: "radioexcel", value: "Excel", label: "Excel", labelSuffix: "" },
  ];

export const cycleOption = [
    { label: "Current", value: "Current" },
    { label: "Previous", value: "Previous" },
];

export const exitEmployeeValidation = () => {
  return yup.object({
    report_cycle: yup
      .string()
      .required(validationMessages.required(reportEnums.reportCycle)),
  });
};

export const musterDateValidation = () => {
  return yup.object({
    date_range_from: yup
      .string()
      .required(validationMessages.required(reportEnums.reportCycleFrom)),
    date_range_end: yup
      .string()
      .required(validationMessages.required(reportEnums.reportCycleEnd))
      .when("date_range_from", (date_range_from, schema) => {
        return schema.test({
          name: reportEnums.reportCycleEnd,
          exclusive: false,
          message: reportEnums.endDateValidation,
          test: function (value) {
            if (!value || !date_range_from) return true;
            return new Date(value) > new Date(date_range_from);
          },
        });
      }),
    pdf_excel: yup
      .string()
      .required(validationMessages.required(reportEnums.reportType)),
    });
};
export const musterCycleValidation = () => {
  return yup.object({
    report_cycle: yup
      .string()
      .required(validationMessages.required(reportEnums.reportCycle)),
    pdf_excel: yup
      .string()
      .required(validationMessages.required(reportEnums.reportType)),
  });
};
export const dailyAttendanceValidation = () => {
  return yup.object({
    pdf_excel: yup
      .string()
      .required(validationMessages.required(reportEnums.reportType)),
    attendance_date: yup
      .string()
      .required(validationMessages.required(reportEnums.attendanceDate)),
  });
};
export const payrollValidation = () => {
  return yup.object({
    cycle: yup
      .string()
      .required(validationMessages.required(reportEnums.cycle)),
  });
};

export const getReports = (setCurrentReport) => [
  {
    title: reportEnums.exitEmployee,
    subtitle: reportEnums.exitSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.exitEmployee),
  },
  {
    title: reportEnums.musterRoll,
    subtitle: reportEnums.musterRollSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.musterRoll),
  },
  {
    title: reportEnums.dailyAttendance,
    subtitle: reportEnums.dailyAttendanceReportSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.dailyAttendance),
  },
  {
    title: reportEnums.attendancePayroll,
    subtitle: reportEnums.payrollSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.attendancePayroll),
  },
  {
    title: reportEnums.accountDetail,
    subtitle: reportEnums.accountSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.accountDetail),
  },
  {
    title: reportEnums.staffDetail,
    subtitle: reportEnums.staffDetailSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.staffDetail),
  },
  {
    title: reportEnums.leaveBalance,
    subtitle: reportEnums.leaveBalanceSubtitle,
    stateSetter: () => setCurrentReport(reportEnums.leaveBalance),
  },
  {
    title: reportEnums.leaveSummary,
    subtitle: reportEnums.leaveSummarySubtitle,
    stateSetter: () => setCurrentReport(reportEnums.leaveSummary),
  },
];

export const formikvalidationFunction = (currentReport, dateRange) => {
  switch (currentReport) {
    case reportEnums.exitEmployee:
      return exitEmployeeValidation();

    case reportEnums.musterRoll:
      if(dateRange === reportEnums.dateRange) {
        return musterDateValidation();
      } else {
        return musterCycleValidation();
      }

    case reportEnums.dailyAttendance:
      return dailyAttendanceValidation();

    case reportEnums.attendancePayroll:
      return payrollValidation();

    default:
      return yup.object({});
  }
};
export const reportPayloadFunction = (currentReport, formik, selectedCenterPayload, dateRange) => {
  let payload;
  let effectiveCycle;
  if(formik.values.report_cycle !== null){
    const reportCycleDate = new Date(formik.values.report_cycle);
    effectiveCycle = `${reportCycleDate.toLocaleString('en-US', { month: 'long' })} ${reportCycleDate.getFullYear()}`;
  }
  switch (currentReport) {
    case reportEnums.exitEmployee:
      payload = {
        effectiveCycle: effectiveCycle,
        ...selectedCenterPayload,
      };
      return payload;

    case reportEnums.musterRoll:
      payload = {
        ...selectedCenterPayload,
        downloadOption: formik.values.pdf_excel,
      };
      if (dateRange === reportEnums.dateRange) {
        payload = {
          ...payload,
          startDate: formik.values.date_range_from,
          endDate: formik.values.date_range_end,
        };
      } else {
        payload = {
          ...payload,
          effectiveCycle: effectiveCycle,
        };
      }
      return payload;

    case reportEnums.dailyAttendance:
      payload = {
        ...selectedCenterPayload,
        downloadOption: formik.values.pdf_excel,
        searchDate: formik.values.attendance_date,
      };
      return payload;

    case reportEnums.attendancePayroll:
      payload = {
        ...selectedCenterPayload,
        month_status: formik.values.cycle,
      };
      return payload;

    case reportEnums.accountDetail:
      payload = {
        ...selectedCenterPayload,
      };
      return payload;
    case reportEnums.staffDetail:
      payload = {
        ...selectedCenterPayload,
      };
      return payload;
    case reportEnums.leaveBalance:
      payload = {
        ...selectedCenterPayload,
      };
      return payload;
    case reportEnums.leaveSummary:
      payload = {
        effectiveCycle: effectiveCycle,
        ...selectedCenterPayload,
      };
      return payload;
    default:
      return {};
  }
};