
import React, { useEffect, useState } from "react";
import BaseButton from "../../BaseComponents/BaseButton";
import BaseSelect from "../../BaseComponents/BaseSelect";
import Spinner from "../../BaseComponents/BaseLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Submit } from "../../Constant";
import { useSelectedCenter } from "../CenterContext";
import { All, CENTER_IDS, checkStatusCodeSuccess, getItem, Null, SELECTED_CENTER_ID, setItem } from "../../Constant/common";
import { centerApi } from "../../Api/common";
import { toast } from "react-toastify";

const GlobalConfig = () => {
  const [loader, setLoader] = useState(false);
  const [loadingCenters, setLoadingCenters] = useState(true);  
  const navigate = useNavigate();
  const { setSelectedCenter } = useSelectedCenter();
  const storedCenterIds = getItem(CENTER_IDS);
  const { fromSetting } = useLocation().state || {};
  const centerDataStorage = storedCenterIds ? JSON.parse(storedCenterIds) : [];
  const [centerData, setCenterData] = useState([]);

  const fetchCenter = async () => {
    setLoadingCenters(true);  
    await centerApi({})
    .then((resp) => {
      if (checkStatusCodeSuccess(resp.statusCode)) {
        let data = resp.data.map((item) => ({
          value: String(item.id),
          label: String(item.center_name),
          id: String(item.id),
        }));

        if (centerDataStorage.includes(All)) {
          data.unshift({ value: All, label: All, id: All });
        }

        setCenterData(data);
      } else {
        setCenterData([]);
        toast.error("Failed to load centers.");
      }
    }) 
    .catch((err) => {
      setCenterData([]);
      toast.error(err.message);
    })
    .finally(()=> {
      setLoadingCenters(false);  
    });
  };

  useEffect(() => {
    fetchCenter();
  }, []);

  useEffect(() => {
    if (centerData.length > 0) {
      const savedCenter = getItem(SELECTED_CENTER_ID) !== Null ? getItem(SELECTED_CENTER_ID) : All;
      if (savedCenter) {
        formik.setFieldValue("center", savedCenter);
      } else {
        const firstCenter = centerData[0].value;
        setItem(SELECTED_CENTER_ID, firstCenter !== All ? Number(firstCenter) : null);
        formik.setFieldValue("center", getItem(SELECTED_CENTER_ID));
      }
    }
  }, [centerData]);

  const initialCenter = getItem(SELECTED_CENTER_ID);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      center: initialCenter !== Null ? Number(initialCenter) : All,
    },
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const newCenterID = (values.center === All || values.center === null || values.center === Null) ? null : Number(values.center);
      setItem(SELECTED_CENTER_ID, newCenterID);
      setSelectedCenter(newCenterID);
      const selectedCenterName = centerData.find((item) => item.value === String(newCenterID))?.label;
      setItem('center_name', selectedCenterName);
      
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        if (fromSetting  !== undefined) {
          navigate('/setting');
        } else {
          navigate(-1);
        }
      }, 1000);
    },
  });

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  return (
    <>
      <div className="card section mb-3 p-4">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <div className="row justify-content-center">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-3">
              {loadingCenters ? (
                <Spinner attrSpinner={{ className: "loader-2" }} />  
              ) : (
                  <BaseSelect
                    id="center"
                    name="center"
                    label={"Center"}
                    className="select-border"
                    options={centerData}
                    placeholder={"Center"}
                    handleChange={(field, value) => {
                      handleChange(field, value);
                    }}
                    value={formik.values.center}
                    touched={formik.touched.center}
                    error={formik.errors.center}
                  />
              )}
            </div>
            <div>
              <BaseButton
                type="submit"
                className="btn btn-pill btn-primary mx-2"
                children={Submit}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default GlobalConfig;