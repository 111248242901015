export const roleLabel = "Role";
export const newRoleLabel = "New Role";
export const addRoleLabel = "Add Role";
export const editRoleLabel = "Edit Role";
export const roleNameLabel = "Role Name";
export const masterModulesLabel = "Master Modules";
export const viewRoleLabel = "View Role";
export const areYouSureLabel = "Are you sure, you want to ";
export const itemLabel = " item?";
export const permissionsLabel = "Permissions";
export const confirmationLabel = "Confirmation";
export const activateLabel = "Activate";
export const plusLabel = "+";
export const deactivateLabel = "Deactivate";

export const ACCESSOR_KEYS = {
    SRNOLABEL: "Sr no.",
    SERIAL_NUMBER: "serial_number",
    ROLE_NAME: "role_name",
    PERMISSIONS: "permissions",
    STATUS: "status",
    ACTION: "action",
};