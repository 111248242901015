import { SALARYACCESS, SALARYDETAILS, UPDATESALARYACCESS } from "./apiRoutes";
import { authData } from "./Service";

export const SalaryDetaillist = async (id) => {
  const response = await authData.get(`${SALARYDETAILS}${id}`);
  return response.data;
};

export const UpdateSalaryAccess = async (data) => {
  const response = await authData.post(UPDATESALARYACCESS, data);
  return response?.data;
};

export const SalaryAccessApi = async () => {
  const response = await authData.post(SALARYACCESS);
  return response?.data;
};
