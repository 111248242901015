export const approvalLabel = {
  AttendanceApproval: "Attendance Approval",
  Add: "add",
  Subtract: "subtract",
  Approved: "Approved",
  Rejected: "Rejected",
  SelectAll: "Select All",
  Approve: "Approve Selected",
  Reject: "Reject Selected",
  PunchOutMessage: "Staff has not punched out yet",
  LeaveDetails: "Leave Details",
  Status: "Status",
  Staff: "Staff",
  LeaveApproval: "Leave Approval",
  LeaveDuration: "Leave Duration",
  LeavesAvailed: "Leaves Availed",
  LeaveType: "Leave Type",
  LeaveSummary: "Leave Summary",
  Description:"Description",
  RejectCurrent: "Reject",
  ApproveCurrent:"Approve",
  Pending: "Pending",
  View:"View",
};