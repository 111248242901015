import React, { useState, useEffect } from "react";
import { Tooltip } from "reactstrap";

const CustomTooltip = ({ target, text, disabled, position}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (disabled) {
      setTooltipOpen(true);
    } else {
      setTooltipOpen(false);
    }
  }, [disabled]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement={position}
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
      autohide={false}
      className={
        disabled ? "custom-tooltip disabled-tooltip" : "custom-tooltip"
      }
    >
      {text}
    </Tooltip>
  );
};

export default CustomTooltip;
