export const SrNo = 'Sr. No.'
export const Center = 'Center'
export const CenterList = 'Center List'
export const pinCode = 'Pin Code'
export const District = 'District'
export const DeleteMessage = 'Are you sure you want to Delete this item?'
export const StaffDeleteMessage = 'Are you sure you want to Delete this Staff?'
export const Scheme = 'Scheme'
export const No = 'No'
export const centerLabel = {
    hostelFacility:"Hostel Facility",
    uniformFees:"Uniform Fees",
    fees:"Fees",
    feesLabel:" Fees(Per Day Per Student) for Hostel"

}
export const SchemeOptions = [
    { id: 1, value: "JSDMS", label: "JSDMS" },
    { id: 2, value: "PMKK", label: "PMKK" },
    { id: 3, value: "Special project", label: "Special project" },
    { id: 4, value: "PM vishwakarma", label: "PM vishwakarma" },
  ]