import { blobResponse } from "../Constant/common";
import { ACCOUNTDETAIL, ATTENDANCEPAYROLL, DAILYATTENDANCE, EXITEMPLOYEE, HALFSALARYSLIP, LEAVEBALANCE, LEAVESUMMARY, MUSTERROLL, STAFFATTENDANCE, STAFFDETAIL } from "./apiRoutes";
import { authData } from "./Service";

export const halfSalarySlipApi = async (data) => {
    const response = await authData.post(HALFSALARYSLIP, data, blobResponse);
    return response;
};
export const payrollAttendanceReportApi = async (data) => {
  const response = await authData.post(ATTENDANCEPAYROLL, data, blobResponse);
  return response;
};
export const dailyAttendanceReportApi = async (data) => {
  const response = await authData.post(DAILYATTENDANCE, data,blobResponse);
  return response;
};
export const staffAttendanceMusterApi = async (data) => {
  const response = await authData.post(MUSTERROLL, data, blobResponse);
  return response;
};
export const exitEmployeeApi = async (data) => {
  const response = await authData.post(EXITEMPLOYEE, data, blobResponse);
  return response;
};
export const accountDetailApi = async (data) => {
  const response = await authData.post(ACCOUNTDETAIL, data, blobResponse);
  return response;
};
export const staffDetailApi = async (data) => {
  const response = await authData.post(STAFFDETAIL, data, blobResponse);
  return response;
};
export const leaveBalanceApi = async (data) => {
  const response = await authData.post(LEAVEBALANCE, data, blobResponse);
  return response;
};
export const leaveSummaryApi = async (data) => {
  const response = await authData.post(LEAVESUMMARY, data, blobResponse);
  return response;
};
export const staffAttendanceApi = async (data) => {
  const response = await authData.post(STAFFATTENDANCE, data, blobResponse);
  return response;
};