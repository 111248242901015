import React, { useEffect, useState } from "react";
import BaseButton from "../../../../BaseComponents/BaseButton";
import BaseInput from "../../../../BaseComponents/BaseInput";
import BaseSelect from "../../../../BaseComponents/BaseSelect";
import {
  ShiftOptions,
  shiftSettingLabel,
} from "../../../../Constant/HR/ShiftSetting";
import { PlaceHolderFormat } from "../../../../Constant/requireMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SelectPlaceHolder,
  validationMessages,
} from "../../../../Constant/validation";
import {
  addShift,
  editShift,
  viewShiftSetting,
} from "../../../../Api/HRshiftSetting";
import {
  checkStatusCodeSuccess,
  handleResponse,
} from "../../../../Constant/common";
import { toast } from "react-toastify";
import Spinner from "../../../../BaseComponents/BaseLoader";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { batchallocationValidation } from "../../../../Constant/BatchAllocation/batchallocation";
import { useSelectedCenter } from "../../../CenterContext";

const AddEditShift = () => {
  const { id } = useParams();
  document.title = id
    ? shiftSettingLabel.EDITSHIFT
    : shiftSettingLabel.ADDSHIFT;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showUnpaidBreak, setShowUnpaidBreak] = useState(false);
  const [netPayableHours, setNetPayableHours] = useState(null);
  const { selectedCenterPayload } = useSelectedCenter();

  const validationSchema = Yup.object().shape({
    shiftType: Yup.string().required(
      validationMessages.required(shiftSettingLabel.shiftType)
    ),
    shiftName: Yup.string().required(
      validationMessages.required(shiftSettingLabel.shiftName)
    ),
    inTime: Yup.string().required(
      validationMessages.required(shiftSettingLabel.in_time)
    ),
    outTime: Yup.string()
    .required(validationMessages.required(shiftSettingLabel.Out_time))
    .test( batchallocationValidation.isGreaterLabel, shiftSettingLabel.outTimeCValidation, function(value) {
      const { inTime } = this.parent;
      if (!inTime || !value) return true;
      const [inHour, inMinute] = inTime.split(':').map(Number);
      const [outHour, outMinute] = value.split(':').map(Number);
      return outHour > inHour || (outHour === inHour && outMinute > inMinute);
    }),
  });

  const shiftFormik = useFormik({
    initialValues: {
      shiftType: shiftSettingLabel.Fixed,
      shiftName: null,
      inTime: null,
      outTime: null,
      unpaidBreak: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        ...selectedCenterPayload,
        shift_type: values.shiftType,
        shift_name: values.shiftName,
        shift_in_time: values.inTime,
        shift_out_time: values.outTime,
        unpaid_break: values.unpaidBreak ? values.unpaidBreak : null,
      };

      setLoader(true);
      const apiCall = id ? editShift(id, payload) : addShift(payload);
      apiCall
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            toast.success(resp?.message);
            resetForm();
            navigate("/ShiftSetting");
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          if (Array.isArray(err?.response?.data?.message)) {
            err?.response?.data?.message?.map((message) => {
              toast.error(message);
            });
          }
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });
  const fetchData = (id) => {
    if (id) {
      setLoader(true);
      viewShiftSetting(id)
        .then((response) => {
          if (checkStatusCodeSuccess(response.statusCode)) {
            const data = response?.data;
            shiftFormik.setValues({
              shiftType: data?.shift_type,
              shiftName: data?.shift_name,
              inTime: data?.shift_in_time,
              outTime: data?.shift_out_time,
              unpaidBreak: data?.unpaid_break,
            });
            setShowUnpaidBreak(
              data?.unpaid_break &&
                data?.unpaid_break !== shiftSettingLabel.time
            );
          } else {
            toast.error(response?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleTimeChange = (e, fieldName) => {
    shiftFormik.setFieldValue(fieldName, e.target.value);
  };
  const toggleUnpaidBreak = () => {
    setShowUnpaidBreak(!showUnpaidBreak);
    if (!showUnpaidBreak) {
      calculateNetHours();
    } else {
      setNetPayableHours(null);
    }
  };

  const calculateNetHours = (inTime, outTime, unpaidBreak) => {
    if (!inTime || !outTime) return null;
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };
    const inMinutes = timeToMinutes(inTime);
    const outMinutes = timeToMinutes(outTime);
    const unpaidMinutes = unpaidBreak ? timeToMinutes(unpaidBreak) : 0;

    const totalMinutes = outMinutes - inMinutes;
    const netMinutes = totalMinutes - unpaidMinutes;

    const netHours = Math.floor(netMinutes / 60);
    const netMin = netMinutes % 60;

    return `${netHours}h ${netMin}m`;
  };

  useEffect(() => {
    const { inTime, outTime, unpaidBreak } = shiftFormik.values;
    const netHours = calculateNetHours(inTime, outTime, unpaidBreak);
    setNetPayableHours(netHours);
  }, [
    shiftFormik.values.inTime,
    shiftFormik.values.outTime,
    shiftFormik.values.unpaidBreak,
  ]);

  const handleBack = () => {
    navigate("/ShiftSetting");
  };

  return (
    <div>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="container mb-1">
        <div className="page-header dash-breadcrumb p-0">
          <div className="row">
            <div className="col-6 px-3 d-flex align-items-center">
              {id ? (
                <h5 className="f-w-600 mr-4">{shiftSettingLabel.EDITSHIFT}</h5>
              ) : (
                <h5 className="f-w-600 mr-4">{shiftSettingLabel.ADDSHIFT}</h5>
              )}
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span className="px-2 btn btn-primary btn-pill" title="Back">
                <RiArrowLeftLine size={22} onClick={handleBack} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={shiftFormik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row mt-2 align-items-start d-flex">
                <span className="col-12 col-sm-3 col-md-2">
                  {shiftSettingLabel.SHIFTTYPE}
                </span>
                <div className="col-12 col-sm-9 col-md-3 mt-2">
                  <div>
                    <BaseSelect
                      name="shiftType"
                      className="select-border"
                      options={ShiftOptions}
                      placeholder={SelectPlaceHolder(
                        shiftSettingLabel.shiftType
                      )}
                      handleChange={(field, value) => {
                        shiftFormik.setFieldValue(field, value);
                      }}
                      handleBlur={() =>
                        shiftFormik.setFieldTouched(
                          shiftSettingLabel.shiftTypeKey,
                          true
                        )
                      }
                      value={shiftFormik.values.shiftType}
                      isDisabled={true}
                      touched={shiftFormik.touched.shiftType}
                      error={shiftFormik.errors.shiftType}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row align-items-start d-flex mt-2">
                <span className="col-12 col-sm-3 col-md-2">
                  {" "}
                  {shiftSettingLabel.SHIFTNAME}
                </span>
                <div className="col-12 col-sm-9 col-md-3">
                  <div className="form-group">
                    <BaseInput
                      type="text"
                      className="form-control"
                      name="shiftName"
                      placeholder={PlaceHolderFormat(
                        shiftSettingLabel.shiftNameLabel
                      )}
                      value={shiftFormik.values.shiftName}
                      touched={shiftFormik.touched.shiftName}
                      error={shiftFormik.errors.shiftName}
                      handleBlur={shiftFormik.handleBlur}
                      handleChange={shiftFormik.handleChange}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row align-items-start d-flex mt-2">
                <span className="col-12 col-sm-3 col-md-2">
                  {shiftSettingLabel.SHIFTTIME}
                </span>
                <div className="col-12 col-sm-9 col-md-4">
                  <div className="form-group">
                    <div className="d-flex flex-column flex-sm-row">
                      {" "}
                      <div className="flex-grow-1">
                        <BaseInput
                          name="inTime"
                          type="time"
                          step="0"
                          value={shiftFormik.values.inTime}
                          handleChange={(e) =>
                            handleTimeChange(e, shiftSettingLabel.inTime)
                          }
                          handleBlur={shiftFormik.handleBlur}
                          touched={shiftFormik.touched.inTime}
                          error={shiftFormik.errors.inTime}
                          className="mb-2 mb-sm-0"
                          required={true}
                        />
                      </div>
                      <span className="mt-2 mx-2">{shiftSettingLabel.TO}</span>
                      <div className="flex-grow-1">
                        <BaseInput
                          name="outTime"
                          type="time"
                          step="0"
                          value={shiftFormik.values.outTime}
                          handleChange={(e) =>
                            handleTimeChange(e, shiftSettingLabel.outTime)
                          }
                          handleBlur={shiftFormik.handleBlur}
                          touched={shiftFormik.touched.outTime}
                          error={shiftFormik.errors.outTime}
                          className="mb-2 mb-sm-0"
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-start mt-2">
                <span className="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0">
                  {shiftSettingLabel.UNPAIDBREAK}
                </span>
                <div className="col-12 col-sm-9 col-md-4">
                  <div className="d-flex flex-column flex-sm-row align-items-start mb-2 gap-2 w-100">
                    {showUnpaidBreak && (
                      <BaseInput
                        name="unpaidBreak"
                        type="time"
                        step="0"
                        value={shiftFormik.values.unpaidBreak}
                        handleChange={(e) =>
                          handleTimeChange(e, shiftSettingLabel.unpaidBreak)
                        }
                        handleBlur={shiftFormik.handleBlur}
                        touched={shiftFormik.touched.unpaidBreak}
                        error={shiftFormik.errors.unpaidBreak}
                        className="mb-2 mb-sm-0"
                      />
                    )}
                    <BaseButton
                      className="btn btn-pill btn-light w-100 w-sm-auto"
                      onClick={toggleUnpaidBreak}
                    >
                      {showUnpaidBreak
                        ? shiftSettingLabel.Remove
                        : shiftSettingLabel.Add}
                    </BaseButton>
                  </div>
                </div>
                {showUnpaidBreak && (
                  <div className="d-block mt-2">
                    <span className="d-inline-flex align-items-center">
                      <strong>{shiftSettingLabel.NetPayableHours}</strong>{" "}
                      {netPayableHours ?? handleResponse.nullData}
                    </span>
                  </div>
                )}
              </div>

              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-end">
                  <BaseButton
                    type="submit"
                    className="btn btn-pill btn-primary"
                  >
                    {id
                      ? shiftSettingLabel.EditShift
                      : shiftSettingLabel.ADDSHIFT}
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditShift;
