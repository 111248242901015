export const BillTitle = "Bill";
export const BillList = "Bill List";
export const BillApprovalText = "Bill Approval";
export const Service = "Service";
export const BillAllowed =
  "Note:In Bill, only (.jpg,.jpeg,.png,.pdf.doc,.docx) file is allowed";
export const Amount = "Amount";
export const ApproveBy = "Approve By";
export const SubmittedBy = "Submitted By";
export const VendorName = "Vendor Name";
export const VendorDetails = "Vendor Details";
export const BillText = "Bill";
export const ApproveBillText = "Approve Bill";
export const RejectBillText = "Reject Bill";
export const UploadBill = "Upload Bill";
export const ViewBillText = "View Bill";
export const EditBillText = "Edit Bill";
export const ChangeStatus = "Change Status";
export const DepartementLevelStatus = "Department Level Approve Status";
export const CEOStatus = "HO Approve Status";
export const ApproveBillMsg = "Are you sure you want to approve this bill?";
export const RejectBillMsg = "Are you sure you want to reject this bill?";
export const Center = "Center";
export const BillPaymentTitle = "Bill Payment";
export const BillPaidMessage = "Are you sure you want to paid this bill?";
export const RevertPaidBill = "Revert Paid Bill";
export const PaidBill = "Paid Bill";
export const Revert = "Revert";
export const BillRevertMessage = "Are you sure you want to revert this paid bill?";
export const ViewPaidBill = "View Bill";
export const semiColon = ":";
export const serviceOptions = [
  { id: 1, value: "Electricty Bill", label: "Electricty Bill" },
  { id: 2, value: "Groccery Bill", label: "Groccery Bill" },
  { id: 3, value: "Maintanence", label: "Maintanence" },
  { id: 4, value: "Other", label: "Other" },
];
export const approveOptions = [
  { id: 1, value: "Admin A", label: "Admin A" },
  { id: 2, value: "Admin B", label: "Admin B" },
  { id: 3, value: "Admin C", label: "Admin C" },
];
export const submitOptions = [
  { id: 1, value: "John", label: "John" },
  { id: 2, value: "Mark", label: "Mark" },
  { id: 3, value: "Jene", label: "Jene" },
];
export const vendorOptions = [
  { id: 1, value: "Vendor A", label: "Vendor A" },
  { id: 2, value: "Vendor B", label: "Vendor B" },
  { id: 3, value: "Vendor C", label: "Vendor C" },
];
