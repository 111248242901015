export const OpeningLabel = {
  CareerOpening: "Job Position",
  JobTitle: "Job Title",
  JobDescription: "Job Description",
  OpeningPosition: "Opening Position",
  Status: "Status",
  activeConfirmation: "Are you sure you want to Deactive this job position?",
  deactiveConfirmation: "Are you sure you want to Active this job position?",
  Deactive: "Deactive",
  Active: "Active",
};