export const TotLabels = {
  totExport: "Export TOT",
  staffName: "Staff Name",
  centerId: "Center Name",
  courseName: "Course Name",
  alterName: "Alternative Name",
  alterContactNo: "Alternative Contact No",
  alterEmailId: "Alternative Email",
  certificateNumber: "Certificate Number",
  issueDate: "Issue Date",
  validUpto: "Valid Upto",
  trId: "TR ID",
  qpCode: "QP Code",
  totList: "Tot List",
  tot: "Tot",
  totDetails: "Tot Details",
  email: "Email",
  contactNumber: "Contact Number",
  center: "Center",
  course: "Course",
  loading: "Loading...",
  submit: "Submit",
  update: "Update",
  action: "Action",
  search: "Search",
  dataNotFound: "Data Not Found",
  candiateAccessKey: "trainerId.name",
  alterAccessKey: "alter_name",
  centerAccessKey: "center.center_name",
  certificateNoAccessKey: "certificate_no",
  issuseDateKey: "issue_date",
  validUptoKey: "valid_upto",
  dateValidate: "Valid upto date should be greater than issue date",
  alterNameValidation: "Must include at least one character",
  alterNoValidation: "Contact number must be 10 digit.",
  alterEmailValidation: "Email should be in correct format",
  Alternativecontactno: "Alternative contact no",
  emailId: "Alternative Email",
  alterRegex: "/[a-zA-Z]/",
};
export const alphabetOnlyRegex = /[a-zA-Z]/;
export const placeHolderTot = "Enter TR ID";
