export const hostelLabel = {
  submissionDate:"Submission Date",
  batchId: "Batch Id",
  fromDate: "From Date",
  toDate: "To Date",
  days: "Days",
  fromDate: "From Date",
  toDate:"To Date",
  claimedBill: "Claimed amount",
  received: "Received amount",
  submissionDate:"Submission Date",
  invoiceNo: "Invoice No.",
  month: "Month",
  projectValued: "Projection Valued",
  projectValuedList: "Projection Valued :",
  totalProjectValue:"Total Projection Valued :",
  centerName:"Center:",
  batchName :"Batch Id:",
  BatchStartDate:"Batch Start Date:",
  BatchEndDate:"Batch End Date:",
  courseName:"Course Name:",
  hostelDetails:"Hostel Details",
  monthDetails:"Month Details",
  remarks:"Remarks",
  status:"Status",
  centerDetails:"Center Details",
  billApprove:"Are you sure you want to approve this bill?",
  approve:"Approved",
  pending:"Pending",
  reject:"Rejected",
  yes:"Yes",
  hostelList:"Hostel List",
  center:"Center Name",
  course:"Course Name",
  batchStart:"Start Date",
  endDate:"End Date",
  approveBill:"Approve Bill",
  addHostelDlt:"Add Hostel",
  search:"Search",
  exportHostel: "Export",
  courseNameKey:"course.course_name",
  batchIdKey:"batch_id",
  startDateKey:"start_date",
  endDateKey:"end_date",
  totalDay:"Total Day",
  totalDayKey:"total_days",
  centerKey:"center.center_name",
  rejectionLabel:"Rejection Request",
  monthSrNoKey:"month_sr_no",
  to_dateKey:"to_date",
  from_dateKey:"from_date",
  daysKey:"days",
  projection_valuedKey:"projection_valued",
  submission_dateKey:"submission_date",
  claimed_billKey:"claimed_bill",
  receivedKey:"received",
  bill_invoice_noKey:"bill_invoice_no",
  statusKey:"status",
  totalClaimedAmount:"Total Claimed Amount :",
  totalReceivedAmount:"Total Received Amount :",
  Rs:"Rs",
  EnrolledCandidate:"Enrolled Candidate :",
  AllocatedCandidatesForHostel :"Allocated Candidates For Hostel :",
  HostelFees:"Hostel Fees :",
  exportWithRejection:"Export With Rejection",
  MonthSort:"Month ",
  remarksKey:"remarks",
  validationMessage:"Please add month details first",
  receivedDate: "Received Date",
  received_dateKey: "received_date"
};

export const HostelRadio = [
  { id: "radioi1", value: "Yes", label: "Yes", labelSuffix: "" },
  { id: "radioi2", value: "No", label: "No", labelSuffix: "" },
];
export const PwdRadio = [
  { id: "radioiOne", value: "Yes", label: "Yes", labelSuffix: "" },
  { id: "radioiTwo", value: "No", label: "No", labelSuffix: "" },
];
export const StatusOptions = [
  { id: 0, value: "", label: "Select Status" },
  { id: 1, value: "Pending", label: "Pending" },
  { id: 2, value: "Rejected", label: "Rejected" },
  { id: 3, value: "Approved", label: "Approved" },
];