export const LOGIN = "login";
export const VERIFYEMAIL = "verifyEmail";
export const FORGOTPASSWORD = "verifyOtpAndUpdatePassword";
export const CHANGEPASSWORD = "resetPassword";

//common
export const LISTOFCENTER = "center/centerList";
export const LISTOFCOURSE = "course/courseList";
export const LISTOFCOURSECENTERRWISE = "center/centerWiseCourse";
export const LISTOFQUALIFICATION = "candidate/listOfQualification";
export const FILEUPLOAD = "common/fileUploads";
export const FILEDELETE = "deleteUploadedFile";
export const LISTOFSTATE = "common/listOfState";
export const LISTOFDISTRICT = "common/listOfDistrict";
export const LISTOFCITY = "common/listOfCity";
export const EDITCAREERSTATUS = "/career/editCareerStatus/";
export const LISTOFROLE = "/role/listOfRole";
export const FILEDOWNLOAD = "downloadFile";

//inquiry
export const INQUIRY = "candidate/addCandidateInquiry";
export const EDITINQUIRY = "candidate/editInquiry/";
export const LISTOFINQUIRY = "candidate/listOfInquiry";
export const VIEWCANDIDATE = "/candidate/viewInquiry/";
export const ENDORSE = "candidate/endorse/";
export const FILTEROFCANDIDATE = "candidate/filterOfCandidate";

//batchallocation
export const ADDBATCH = "batch/addBatch";
export const LISTOFCANDIDATE = "course/listOfCourseWiseCandidate";
export const LISTOFBATCH = "batch/listOfBatch";
export const VIEWBATCH = "batch/batchList";
export const EDITBATCH = "batch/editBatchAssign";
export const DELETEBATCH = "batch/deleteBatchAssign";
export const CANDIDATELIST = "course/listOfCourseCandidate";
export const CANDIDATESATUTS = "batch/editBatchStatus/";
export const IMPORTBATCHDATA = "batch/importBatchData";
export const EXPORTBATCHDATA = "batch/batchWiseStudentExport/";
export const LISTENROLLEDCANDIDATE = "batch/listOfEnrolledCandidate";
export const EDITBATCHASSESSMENT = "batch/editAssessmentDate/";
export const EDITBATCHDETAILS = "batch/editBatch/";
export const VIEWBATCHFROMID = "batch/viewBatch/";
export const VIEWBATCHDETAILS = "batch/viewBatchDetails";
export const DELETEBATCHFROMID = "batch/deleteBatch/";

//candidate status
export const CANDIDATESTATUS = "batch/editBatchAssignStatus/";
export const IMPORTATTENDANCE = "attendance/importAttendanceData";
export const LISTATTENDANCE = "attendance/attendanceList";
export const FILTERATTENDANCE = "attendance/filterOfAttendance";
export const LISTOFCANDIDATEFORFILTER = "attendance/listOfCandidate";
export const VIEWCANDIDATEDETAILS = "/candidate/viewCandidate/";
export const EDITCANDIDATEDETAILS = "candidate/editCandidate/";

//Category
export const LISTOFCATEGORY = "category/listOfCategory";
export const LISTOFADDCATEGORY = "category/listOfCategoryImage";
export const ADDCATEGORYIMAGE = "category/addCategoryImage";
export const DELETECATEGORY = "category/deleteCategoryImage";

//Master-Course
export const ADDCOURSE = "course/addCourse";
export const EDITCOURSE = "course/editCourse/";
export const COURSELIST = "course/listOfCourse";
export const COURSELISTOFCODE = "/course/courseCodeList";
export const CENTERWISECOURSEQPCODE = "center/centerWiseCourseQpCode";
export const COURSEVIEW = "course/viewCourse/";
export const DELETECOURSE = "course/deleteCourse/";
export const LISTOFCAREER = "career/listOfCareer";

//Master-Center
export const ADDCENTER = "center/addCenter";
export const EDITCENTER = "center/editCenter/";
export const CENTERLIST = "center/listOfCenter";
export const DELETECENTER = "center/deleteCenter/";
export const LISTOFSCHEME = "center/listOfScheme"

//Account
export const ADDVENDOR = "vendor/addVendor";
export const LISTOFVENDOR = "vendor/listOfVendor";
export const EDITVENDOR = "vendor/editVendor/";
export const VIEWVENDOR = "vendor/viewVendor/";

//Account-bill
export const LISTOFSERVICE = "bill/listOfService";
export const VIEWBILL = "bill/viewBill/";
export const LISTOFBILL = "bill/listOfBill";
export const EDITBILL = "bill/editBill/";
export const ADDBILL = "bill/generateBill";
export const LISTOFVENDORNAME = "vendor/listOfVendorName";
export const LISTOFUSERS = "common/listOfUser";
export const EDITBILLSTATUS = "bill/editBillStatus/";
export const VIEWVENDORDETAIL = "vendor/viewVendorDetail/";
export const LISTOFBILLPAYMENT = "bill/listOfBillPayment";
export const EDITBILLPAYMENTSTATUS = "bill/editBillPaymentStatus/";

//Staff
export const LISTOFSTAFF = "staff/listOfStaff";
export const ADDSTAFF = "staff/addStaff";
export const EDITSTAFF = "staff/editStaff/";
export const DELETESTAFF = "staff/deleteStaff/";
export const VIEWSTAFF = "staff/viewStaff/";
export const ACTIVEINACTIVESTAFF = "staff/activeInActiveStaff/";

//Position
export const LISTOFPOSITION = "common/listOfPosition";
export const ADDPOSITION = "position/addPosition";
export const EDITPOSITION = "position/editPosition";
export const DELETEPOSITION = "position/deletePosition";

//TOT
export const LISTOFTOT = "tot-detail/listOfTotDetail";
export const EDITTOT = "tot-detail/editTotDetail/";
export const ADDTOT = "tot-detail/addTotDetail";
export const DELETETOT = "tot-detail/deleteTotDetail/";
export const VIEWTOT = "tot-detail/viewTotDetail/";
export const EXPORTTOT = "tot-detail/exportTotDetail";
export const LISTOFTRAINER = "tot-detail/listOfTrainer";

//Placement
export const LISTOFPLACMENT = "placement/listOfPlacement";
export const ADDPLACEMENT = "placement/addPlacement";
export const EDITPLACEMENT = "/placement/editPlacementDetail/";
export const PLACEMENTCANDIDATE = "placement/listOfCandidate";
export const ADDSALARYSLIP = "placement/addSalarySlipDetail";
export const EDITSALARYSLIP = "placement/editSalarySlipDetail/";
export const VIEWPLACEMENT = "placement/viewPlacementDetail/";
export const LISTOFSALARYSLIP = "placement/listOfSalarySlipDetail";
export const EMPLOYERDROPDOWN = "common/listOfPlacementEmployer";
//Accreditation
export const ADDACCREDITATION = "accreditation/addAccreditation";
export const LISTOFACCREDITATION = "accreditation/listOfAccreditation";
export const EDITACCREDITATION = "accreditation/editAccreditation/";
export const VIEWACCREDITATION = "accreditation/viewAccreditation/";
export const DELETEACCREDITATION = "accreditation/deleteAccreditation/";

//export
export const EXPORTBATCHLIST = "batch/exportBatchDetail";
export const EXPORTACCREDITATIONLIST ="accreditation/exportAccreditationDetails";
export const EXPORTPLACEMENTLIST = "placement/exportPlacementDetail";
export const EXPORTASSESSMENTDATA = "attendance/exportAssessmentData";

//notification
export const LISTOFNOTIFICATION = "notification/listOfNotification";
export const MARKASREAD = "notification/markAsRead";
export const CLEARNOTIFICATION = "notification/clearNotification";

//hostel
export const LISTOFHOSTEL = "hostel/listOfHostel";
export const ADDHOSTEL = "hostel/addHostel";
export const VIEWHOSTEL = "hostel/viewHostelDetail/";
export const EDITSTATUSHOSTEL = "/hostel/editHostelStatus/";
export const EDITHOSTEL = "hostel/editHostelDetail/";
export const EXPORTHOSTEL = "hostel/exportHostelDetail";
export const EXPORTHOSTELWITHREJECTION = "hostel/exportRejectedBillDetail";

//class
export const LISTOFCLASS = "class/listOfClass";
export const VIEWCLASS = "class/viewClass/";
export const LISTOFMILESTONE = "class/listOfMilestone";
export const EDITMILESTONE = "class/editMilestone/";
export const EDITSTATUS = "class/editMilestoneStatus/";
export const EXPORTCLASS = "class/exportClassDetail/";
export const EXPORTREJECTCLASS = "class/exportRejectedClassDetail";

//Inventry
export const LISTOFINVENTORY = "inventory/listOfInventory";
export const ADDINVENTORY = "inventory/addInventory";
export const EDITINVENTORY = "inventory/editInventory";
export const VIEWINVENTORY = "inventory/viewInventory";
export const STOCKTYPELIST = "stock-type/listOfStockType";
export const DELETEINVENTORYHISTORY = "inventory/deleteInventoryHistory";

//stock
export const LISTOFSTOCK = "stock-type/listOfStockType";
export const ADDSTOCK = "stock-type/addStockType";
export const EDITSTOCK = "stock-type/editStockType/";
export const DELETESTOCK = "stock-type/deleteStockType/";

//HolidayTemplate
export const ADDHOLIDAY = "stock-type/addStockType";
export const EDITHOLIDAY = "stock-type/editStockType/";
export const LISTOFHOLIDAY = "holiday/listOfHolidayTemplate";
export const LISTOFHOLIDAYDROP = "holiday/listOfHolidayTemplateName";
export const DELETETEMPLATE = "holiday/DeleteTemplate/";

//HR-Staff
export const LISTOFSTAFFHR = "staff/listOfStaff";
export const ADDSTAFFHR = "staff/addStaff";
export const EDITSTAFFHR = "staff/editStaff/";
export const VIEWSTAFFHR = "staff/viewStaff/";
export const DELETESTAFFHR = "staff/deleteStaff/";
export const VIEWSTAFFIDS = "staff/staffIds";
export const LISTOFROLEFORSTAFF = "staff/listOfRoleForStaff";
export const STAFFIMAGE = "/staff/viewStaffImage/";
export const VIEWPROFILE = "staff/viewProfile";
export const STAFFACCESS = "updateAppAccess";
//HR-Attendance
export const ADDATTENDACNE = "staff-attendance/addStaffAttendance/";
export const ADDNOTE = "staff-attendance/addStaffAttendanceNote/";
export const VIEWLOGS = "staff-attendance/viewStaffAttendanceHistory/";
export const VIEWATTENDANCE = "staff-attendance/listOfStaffAttendance";
export const LISTOFWORKINGDAY = "staff-attendance/listOfCompOffLeave";
export const REVERTTOHOLIDAY = "staff-attendance/revertHoliday/"
export const REVERTWEEKOFF = "staff-attendance/revertWeekOff/"
export const LEAVEHISTORY = "staff-attendance/leaveHistory/";

//HR-Holiday Template
export const LISTOFHOLIDAYTEMPLATE = "holiday/listOfHolidayTemplate";
export const ADDHOLIDAYTEMPLATE = "holiday/template";
export const EDITHOLIDAYTEMPLATE = "holiday/template/";
export const VIEWHOLIDAYTEMPLATE = "holiday/template/";
export const DELETEHOLIDAY = "holiday/deleteHolidayDetails/";
export const MANAGESTAFFLIST = "holiday/manageStaffList/";
export const EDITMANAGESTAFF = "holiday/editManageStaffList/";

// ATTENDENCE_MODE-SETTING
export const ATTENDENCEMODE = "global-settings/attendance-mode";
export const MONTHDURATION = "global-settings/updateSalaryCalculationMethod"
export const SALARYCALCULATION = "global-settings/getSalaryCalculationMethod";
export const ATTENDANCEDATA = "global-settings/getGlobalAttendance"

// SalaryDetails
export const SALARYDETAILS = "global-settings/listOfSalaryAccess";
export const UPDATESALARYACCESS = "global-settings/updateSalaryAccess";
export const SALARYACCESS = "global-settings/salaryAccess";

//Leave Template
export const LISTOFLEAVETEMPLATEDROP = "leave/listOfLeaveTemplateName";

//Reporting Manager
export const LISTOFREPORTINGDROP = "staff/listOfReportingTo";
export const LISTOFSHIFTDROP = "shift/listOfShiftName";

//master role
export const ADDROLE = "role/addRole"
export const EDITROLE = "role/editRole/"
export const LISTROLE = "role/listOfRole"
export const DELETEROLE = "role/deleteRole/"

//HR-Leave Template
export const LISTOFLEAVETEMPLATE = "leave/listOfLeaveTemplate";
export const ADDLEAVETEMPLATE = "leave/addLeave";
export const VIEWLEAVETEMPLATE = "leave/viewLeaveTemplate/";
export const EDITLEAVETEMPLATE = "leave/editLeaveTemplate/";
export const LISTOFLEAVECATEGORIES = "leave/listOfLeaveCategory";
export const DELETELEAVECATEGORIES = "leave/deleteLeaveCategory/";
export const VIEWLEAVESTAFF = "leave/leaveStaffList/";
export const EDITLEAVESTAFF = "leave/editLeaveStaffList/";

//HR-Shift Setting
export const LISTOFSHIFT = "shift/listOfShift";
export const EDITSHIFTSTAFFLIST = "shift/editShiftStaffList/";
export const SHIFTSTAFFLIST = "shift/shiftStaffList/";
export const EDITSHIFT = "shift/editShift/";
export const VIEWSHIFT = "shift/viewShift/";
export const ADDSHIFT = "shift/addShift";

//HR-Dashborad
export const ATTENDACNEMUSTERROLL =
  "staff-attendance/attendanceMusterRollReport";
export const COUNTOFSTAFFATTENDANCESTATUS =
  "staff-attendance/countOfDayWiseStaffAttendanceStatus";

//Role
export const LISTOFMASTERMODULE = "role-permission/listOfMasterModule"
export const ADDROLEPERMISSION = "role-permission/addRole"
export const VIEWROLEPERMISSION = "role-permission/viewRole/"
export const EDITROLEPERMISSION = "role-permission/editRole/"
export const DELETEROLEPERMISSION = "role-permission/deleteRole/"
export const LISTOFROLEPERMISSION = "role-permission/listOfRole"
export const LISTOFUSERROLEPERMISSION = "role-permission/userRolePermission";

//HR-Salary
export const EFFECTIVECYCLE = "salary/staffWiseEffectiveCycle/";
export const VIEWSALARYSTRUCTURE = "salary/viewSalaryStructure/";
export const EDITSALARYSTRUCTURE = "salary/editSalaryStructure/";
export const REVISESALARY = "salary/reviseSalaryStructure/";
export const REVISIONHISTORY = "salary/listOfReviseSalary/";
export const LISTOFSALARYOVERVIEW = "salary/listOfSalaryOverview/";
export const VIEWSALARYOVERVIEW = "salary/viewSalaryOverview/";

//ATTENDENCE_DETAIL
export const ATTENDENCEMUSTERROLL = "staff-attendance/attendanceMusterRollReport"

//Report
export const HALFSALARYSLIP = "salary/GenerateHalfSalarySlip";
export const EXITEMPLOYEE = "report/exitStaffDetail";
export const MUSTERROLL = "report/staffAttendanceMusterRoll";
export const DAILYATTENDANCE = "report/dailyAttendanceReport";
export const ATTENDANCEPAYROLL = "report/payrollAttendanceReviewReport";
export const ACCOUNTDETAIL = "report/staffAccountDetails";
export const STAFFDETAIL = "report/staffDetails";
export const LEAVEBALANCE = "report/leaveBalanceReport";
export const LEAVESUMMARY = "report/leaveSummary";
export const STAFFATTENDANCE = "staff-attendance/staffAttendanceExport";
//Employer
export const ADDEMPLOYER = "placement-employer/addPlacementEmployer";
export const VIEWEMPLOYER = "placement-employer/viewPlacementEmployer/";
export const EDITEMPLOYER = "placement-employer/editPlacementEmployer/";
export const DELETEEMPLOYER = "placement-employer/deletePlacementEmployer/";
export const LISTOFEMPLOYER = "placement-employer/listOfPlacementEmployer";

//CareerOpening
export const ADDOPENING = "job-position/addJobPosition";
export const VIEWOPENING = "job-position/viewJobPosition/";
export const EDITOPENING = "job-position/editJobPosition/";
export const DELETEOPENING = "job-position/deleteJobPosition/";
export const LISTOFOPENING = "job-position/listOfJobPosition";
export const OPENINGSTATUS = "job-position/activeDeActiveJobPosition/";

//ApprovalApi
export const ATTENDANCEAPPROVALLIST = "staff-attendance/listOfAttendanceApprove";
export const EDITATTENDANCEAPPROVAL = "staff-attendance/editAttendanceApprove";
export const LEAVEAPPROVALLIST = "leave-app/listOfAllLeave";
export const EDITLEAVEAPPROVAL = "leave-app/editLeaveStatus";

//RevenueApi
export const TOTALREVENUE = "revenue/graphOfAllCenterData";
export const CENTERWISEREVENUE = "revenue/graphOfCenterData";

//LeaveSummary
export const LEAVESUMMARYLIST = "staff/monthWiseLeaveCountOfStaff/";
export const PERDAYSALARY = "staff/viewStaffPerDaySaalry/";
export const ENCASHLEAVE = "staff/staffEncashLeaves/";

//LeaveBalance
export const STAFFLEAVEBALANCE = "staff/viewStaffLeaves/";
export const EDITLEAVEBALANCE = "staff/addStaffLeave/";
export const LEAVE_BALANCE_HISTORY = "staff/listOfLeaveBalanceHistory";
