import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BaseCheckbox from "../../../../BaseComponents/BaseCheckbox";
import BaseButton from "../../../../BaseComponents/BaseButton";
import Spinner from "../../../../BaseComponents/BaseLoader";
import BaseCard from "../../../../BaseComponents/BaseCard";
import { notFound } from "../../../../Constant";
import { checkStatusCodeSuccess, rendorBackButton } from "../../../../Constant/common";
import {
  editLeaveStaffListApi,
  viewLeaveStaffListApi,
} from "../../../../Api/leaveTemplate";
import { leaveTemplate } from "../../../../Constant/HR/leaveTemplate";
import { useSelectedCenter } from "../../../CenterContext";

const ManageLeaveStaff = () => {
  const { leaveId } = useParams();
  const navigate = useNavigate();
  const { selectedCenterPayload } = useSelectedCenter();
  const [selectedWithleave, setSelectedWithleave] = useState([]);
  const [selectedWithoutleave, setSelectedWithoutleave] = useState([]);
  const [searchWithleave, setSearchWithleave] = useState("");
  const [searchWithoutleave, setSearchWithoutleave] = useState("");
  const [selectAllWithleave, setSelectAllWithleave] = useState(true);
  const [selectAllWithoutleave, setSelectAllWithoutleave] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchSelectedleave, setSearchSelectedleave] = useState([]);
  const [searchSelectedWithOutleave, setSearchSelectedWithOutleave] = useState(
    []
  );
  const fetchData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      selectedStaffSearch: searchWithleave,
      unselectedStaffSearch: searchWithoutleave,
    };
    viewLeaveStaffListApi(leaveId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const selectedStaff = res?.data?.selectedStaff ?? [];
          const unselectedStaff = res?.data?.unselectedStaff ?? [];
          setSelectedWithleave(selectedStaff);
          setSelectedWithoutleave(unselectedStaff);
        } else {
          setSelectedWithleave([]);
          setSelectedWithoutleave([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setSelectedWithleave([]);
        setSelectedWithoutleave([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchSearchData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      selectedStaffSearch: searchWithleave,
      unselectedStaffSearch: searchWithoutleave,
    };
    viewLeaveStaffListApi(leaveId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const selectedStaff = res?.data?.selectedStaff ?? [];
          const unselectedStaff = res?.data?.unselectedStaff ?? [];
          setSearchSelectedleave(selectedStaff);
          setSearchSelectedWithOutleave(unselectedStaff);
        } else {
          setSelectedWithleave([]);
          setSelectedWithoutleave([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setSelectedWithleave([]);
        setSelectedWithoutleave([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [leaveId]);
  useEffect(() => {
    fetchSearchData();
  }, [leaveId, searchWithleave, searchWithoutleave]);

  const handleCheckboxChange = (staff, isWithleave) => {
    const updateList = (list, setList) => {
      const updatedList = list?.map((item) =>
        item?.id === staff.id ? { ...item, selected: !item?.selected } : item
      );
      setList(updatedList);
      return updatedList?.filter((item) => !item?.selected).length === 0;
    };

    if (isWithleave) {
      setSelectAllWithleave(
        updateList(selectedWithleave, setSelectedWithleave)
      );
    } else {
      setSelectAllWithoutleave(
        updateList(selectedWithoutleave, setSelectedWithoutleave)
      );
    }
  };

  const handleCheckAll = (isWithleave) => {
    if (isWithleave) {
      setSelectAllWithleave(!selectAllWithleave);
      setSelectedWithleave((prev) =>
        prev?.map((item) => ({ ...item, selected: !selectAllWithleave }))
      );
    } else {
      setSelectAllWithoutleave(!selectAllWithoutleave);
      setSelectedWithoutleave((prev) =>
        prev?.map((item) => ({ ...item, selected: !selectAllWithoutleave }))
      );
    }
  };

  const getStaffIds = (staffList, isSelected) =>
    staffList
      ?.filter((staff) => staff?.selected === isSelected)
      .map((staff) => staff?.id);
  const authIds = [
    ...getStaffIds(selectedWithleave, true),
    ...getStaffIds(selectedWithoutleave, true),
  ];
  const unassignedUserIds = [
    ...getStaffIds(selectedWithleave, false),
    ...getStaffIds(selectedWithoutleave, false),
  ];

  const handleSave = () => {
    setLoader(true);
    const payload = {
      authIds,
      unassignedUserIds,
    };
    editLeaveStaffListApi(leaveId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          toast.success(res?.message);
          navigate("/leaveTemplate");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const renderStaffItem = (staff, isWithleave) => (
    <BaseCheckbox
      id={`checkbox-${staff.id}-${
        isWithleave ? leaveTemplate.with : leaveTemplate.without
      }`}
      label={staff.name}
      checked={staff.selected}
      badge={!staff.isActive && leaveTemplate.Deactive}
      badgeClassName={!staff.isActive && "bg-light text-dark"}
      onChange={() => handleCheckboxChange(staff, isWithleave)}
    />
  );

  return (
    <div className="container-fluid">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="d-flex justify-content-between align-items-center w-full">
        <h5 className="f-w-600">{leaveTemplate.MANAGE_STAFF_LIST}</h5>
        <div className="col-sm-auto col-md-auto mb-2">
          {rendorBackButton(() => navigate("/leaveTemplate"))}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <BaseCard
            title={leaveTemplate.STAFF_WITH_LEAVE}
            searchPlaceholder={leaveTemplate.SEARCH_STAFF}
            searchValue={searchWithleave}
            onSearchChange={setSearchWithleave}
            noDataMessage={notFound.dataNotFound}
            searchItems={searchSelectedleave}
            items={selectedWithleave ?? []}
            renderItem={(staff) => renderStaffItem(staff, true)}
            checkAllLabel={`${leaveTemplate.MONTHLY_REGULAR_STAFF} (${selectedWithleave?.length})`}
            onCheckAllChange={() => handleCheckAll(true)}
            selectAllChecked={selectAllWithleave}
          />
          <BaseCard
            title={leaveTemplate.STAFF_WITHOUT_LEAVE}
            searchPlaceholder={leaveTemplate.SEARCH_STAFF}
            searchValue={searchWithoutleave}
            onSearchChange={setSearchWithoutleave}
            noDataMessage={notFound.dataNotFound}
            searchItems={searchSelectedWithOutleave}
            items={selectedWithoutleave ?? []}
            renderItem={(staff) => renderStaffItem(staff, false)}
            checkAllLabel={`${leaveTemplate.MONTHLY_REGULAR_STAFF} (${selectedWithoutleave?.length})`}
            onCheckAllChange={() => handleCheckAll(false)}
            selectAllChecked={selectAllWithoutleave}
          />
        </div>
      </div>

      <div className="mt-3 sticky-save-button">
        <div className="col-12 d-flex justify-content-end">
          <BaseButton
            type="submit"
            onClick={handleSave}
            className="btn btn-success"
          >
            {leaveTemplate.SAVE}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default ManageLeaveStaff;
