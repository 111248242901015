import React from 'react';

const SidebarLogo = () => {

  return (
    <div>
    </div>
  );
};

export default SidebarLogo;