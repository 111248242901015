import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import TableContainer from "../../BaseComponents/BaseTable";
import {
  AddCandidate,
  batchallocationLabel,
} from "../../Constant/BatchAllocation/batchallocation";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import Spinner from "../../BaseComponents/BaseLoader";
import { checkStatusCodeSuccess, getCourseIdFromSessionStorage, getItem, handleArrayResponse, rendorBackButton } from "../../Constant/common";
import { editBatch, ListOfCandidate } from "../../Api/BatchApi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BaseButton from "../../BaseComponents/BaseButton";
import { RiSearchLine } from "react-icons/ri";
import { notFound } from "../../Constant";

let totalNumber = 0;
const AddMoreCandidate = () => {
  document.title = AddCandidate;
  const [candidateData, setCandidateData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { batchId } = useParams();
  const [checkedItems, setCheckedItems] = useState({});
  const [isCheckedAll, setIsCheckedAll] = useState({});
  const [customPageSize, setCustomPageSize] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [candidateSearchData, setCandidateSearchData] = useState([]);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState();
  const [checkId, setCheckId] = useState([]);

  const history = useNavigate();
  const courseId = getCourseIdFromSessionStorage();

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };
  const handleCheckAll = (currentPage) => {
    const currentCheckedAll = isCheckedAll[currentPage] || false;
    setIsCheckedAll({
      ...isCheckedAll,
      [currentPage]: !currentCheckedAll,
    });

    const updatedCheckedItems = currentCheckedAll
      ? []
      : candidateData?.map((item) => item.id);
    setCheckedItems({
      ...checkedItems,
      [currentPage]: updatedCheckedItems,
    });
  };

  const handleCheckItem = (id, currentPage) => {
    const currentCheckedItems = checkedItems[currentPage] || [];
    const isAlreadyChecked = currentCheckedItems?.includes(id);
    const updatedCheckedItems = isAlreadyChecked
      ? currentCheckedItems?.filter((itemId) => itemId !== id)
      : [...currentCheckedItems, id];

    setCheckedItems({
      ...checkedItems,
      [currentPage]: updatedCheckedItems,
    });

    setIsCheckedAll({
      ...isCheckedAll,
      [currentPage]: updatedCheckedItems?.length === totalNumber,
    });
  };

  useEffect(() => {
    if (isCheckedAll[currentPage] && candidateData) {
      const allCandidateIds = candidateData?.map((item) => item.id);
      setCheckedItems({
        ...checkedItems,
        [currentPage]: allCandidateIds,
      });
    }
  }, [isCheckedAll, candidateData, currentPage]);

  useEffect(() => {
    if (!Boolean(isCheckedAll[currentPage])) {
      setIsCheckedAll({ ...isCheckedAll, [currentPage]: false });
    }
  }, [currentPage]);

  useEffect(() => {
    const allCheckedIds = Object?.values(checkedItems)?.flat();
    setCheckId(allCheckedIds);
  }, [checkedItems]);
  const columns = useMemo(
    () => [
      {
        header: (
          <BaseCheckbox
            id="checkBoxAll"
            checked={isCheckedAll[currentPage] || false}
            onChange={() => handleCheckAll(currentPage)}
            className="custom-checkbox-class d-felx justify-content-center"
            labelClassName="custom-label-class"
          />
        ),
        enableSorting: true,
        cell: (cell) => (
          <BaseCheckbox
            id={`checkbox${cell.row.original.id}`}
            checked={
              checkedItems[currentPage]?.includes(cell.row.original.id) || false
            }
            onChange={() => handleCheckItem(cell.row.original.id, currentPage)}
            className="custom-checkbox-class taskCheckBox"
            labelClassName="custom-label-class"
          />
        ),
        id: "#id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: batchallocationLabel.candidateLable,
        accessorKey: batchallocationLabel.candidateKey,
        enableColumnFilter: false,
      },
      {
        header: batchallocationLabel.mobileNo,
        accessorKey: batchallocationLabel.conatactKey,
        enableColumnFilter: false,
      },
    ],
    [checkedItems, isCheckedAll]
  );

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };

  const fetchData = () => {
    setLoader(true);
    const payload = {
      center_id: Number(getItem("BatchCenter")),
      courseId: parseInt(courseId),
      is_batch_assign: false,
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    ListOfCandidate(payload)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          setCandidateData(response?.data?.candidate);
          setTotalPages(response?.data?.totalPages);
          setTotalRecords(response?.data?.totalRecordsCount);
          setTotalNumberOfRows(response?.data?.numberOfRows);
          setCurrentPage(response?.data?.currentPage);
          totalNumber = response?.data?.numberOfRows;
        } else {
          toast.error(response?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchSearchData = () => {
    setLoader(true);
    const payload = {
      center_id: Number(getItem("BatchCenter")),
      courseId: parseInt(courseId),
      is_batch_assign: false,
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
      search: searchValue,
    };
    ListOfCandidate(payload)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          setCandidateSearchData(response?.data?.candidate);
          setTotalPages(response?.data?.totalPages);
          setTotalRecords(response?.data?.totalRecordsCount);
          setTotalNumberOfRows(response?.data?.numberOfRows);
          setCurrentPage(response?.data?.currentPage);
          totalNumber = response?.data?.numberOfRows;
        } else {
          toast.error(response?.message);
          setCandidateSearchData([]);
        }
      })
      .catch((err) => {
        setCandidateSearchData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (searchValue) {
      fetchSearchData();
    } else {
      fetchData();
    }
  }, [currentPage, searchValue, customPageSize, columnName, sortOrder]);

  useEffect(() => {
    if (searchValue === "") {
      setCurrentPage(1);
    }
  }, [searchValue]);

  const handleAddMoreCandidatedidate = () => {
    setLoader(true);
    const payload = {
      candidate_id: checkId,
    };
    editBatch(batchId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          setCheckedItems([]);
          setIsCheckedAll(false);
          history(`/editbatch/${batchId}`);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        handleArrayResponse(err);
      })
      .finally(() => {
        setLoader(false);
        setCheckedItems([]);
        setIsCheckedAll(false);
      });
  };
  const isCreateButtonDisabled = checkId.length === 0;

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <Form>
        <div className="d-flex justify-content-between p-0 px-0">
          <h5 className="f-w-600">{batchallocationLabel.candidateList}</h5>
          <div className="flex">
          {rendorBackButton(() => history(`/editbatch/${batchId}`))}
        </div>
        </div>
        <Card>
          <CardBody className="mb-0 pb-0 mx-0">
            <div className="d-flex mb-3 justify-content-end">
              <BaseButton
                color="primary"
                onClick={() => handleAddMoreCandidatedidate()}
                disabled={isCreateButtonDisabled}
              >
                {batchallocationLabel.addSelecte}
              </BaseButton>
            </div>

            <div className="pt-0">
              <div className="pt-0">
                <div>
                  {candidateData?.length > 0 ? (
                    <TableContainer
                    shouldLineBreak={false}
                      columns={columns}
                      totalPages={totalPages}
                      totalRecords={totalRecords}
                      manualPagination={true}
                      totalNumberOfRows={totalNumberOfRows}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      fetchData={handleFetchData}
                      data={
                        searchValue ? candidateSearchData : candidateData || []
                      }
                      isGlobalFilter={true}
                      manualFiltering={true}
                      setCustomPageSize={setCustomPageSize}
                      customPageSize={customPageSize}
                      theadClass="table-light text-muted"
                      SearchPlaceholder={batchallocationLabel.searchBar}
                      onSearch={handleSearchValueChange}
                      fetchSortingData={handleFetchSorting}
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <RiSearchLine className="fs-2" />
                      </div>
                      <div className="mt-4">
                        <h5>{notFound.dataNotFound}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
};

export default AddMoreCandidate;
