import {
  ADDSHIFT,
  EDITSHIFT,
  EDITSHIFTSTAFFLIST,
  LISTOFSHIFT,
  SHIFTSTAFFLIST,
  VIEWSHIFT,
} from "./apiRoutes";
import { authData } from "./Service";

export const listOfShift = async (id) => {
  const response = await authData.get(`${LISTOFSHIFT}${id}`);
  return response?.data;
};
export const addShift = async (data) => {
  const response = await authData.post(ADDSHIFT, data);
  return response?.data;
};
export const editShift = async (id, data) => {
  const response = await authData.put(`${EDITSHIFT}${id}`, data);
  return response?.data;
};
export const viewShiftSetting = async (id, data) => {
  const response = await authData.get(`${VIEWSHIFT}${id}`, data);
  return response?.data;
};
export const manageStaffList = async (id, data) => {
  const response = await authData.post(`${SHIFTSTAFFLIST}${id}`, data);
  return response?.data;
};
export const editManageStaff = async (id, data) => {
  const response = await authData.post(`${EDITSHIFTSTAFFLIST}${id}`, data);
  return response?.data;
};
