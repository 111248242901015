export const Course = "Course";
export const CourseName = "Course Name";
export const CourseList = "Course List";
export const Duration = "Duration";
export const Seat = "Seat";
export const qp_code = "QP Code";
export const CourseDetail = "Course Detail";
export const CourseDetailUrl = " Course Detail(URL)"
export const CourseHours = "Course Hours";
export const QpHours = 'Qp Hours';
export const OjtHours = 'Ojt Hours'
export const TotalHours = 'Total Hours';
export const Rate = "Rate";
export const RateHurs = "Rate(Per Hours)";
export const ViewImage = "View Image"
export const CourseImage = "Course Image"
export const Note ="Note:In Course Image, only (.jpg,.jpeg,.png) file is allowed";
export const ImageValidationMessage = "Only image files are allowed.";
export const ImageSizeValidationMessage = "File size exceeds the limit of 1MB.";
