export const active = "Active";
export const action = "Action";
export const DeActive = "Deactive";
export const DeleteStaff = "Delete Staff";
export const Attendance = "Attendance";
export const SalaryOverView = "Salary Overview";
export const SalaryStructure = "Salary Structure";
export const Profile = "Profile";
export const AttendanceDetails = "Attendance Details";
export const LeaveSummary = "Leave Summary";
export const LeaveBalance = "Leave Balance";
export const Salary = "Salary";
export const HR = "HR";
export const fileName = "_profile_image";
export const ImgError = "Profile Image is not available";
export const ImgTitle = "Download Image";
export const StaffImageMessage = "Are you sure you want to Download Profile Image?";
export const ImgSuccess = "Image downloaded successfully!";
export const InvalidImage = "Profile Image is not in valid format.";