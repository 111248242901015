export const leaveTemplate = {
    LEAVE_TEMPLATE: 'Leave Template',
    SUB_TITLE: 'Add and Save templates of Leave policies',
    LEAVE_PER_MONTH: '3 Leaves Per Month',
    APPLIED_STAFF: 'Staff Applied',
    MANAGE_STAFF: 'Manage Staff List',
    EDIT_TEMPLATE: 'Edit Leave Template',
    CREATE_TEMPLATE: 'Create Leave Template',
    CREATE:"Create",
    TEMPLATE_SETTING: 'Template Setting',
    LEAVE_CATEGORIES: 'Leave Categories',
    OTHER_LEAVE: "Unpaid leave",
    TEMPLATE_NAME: 'Template Name',
    LEAVE_POLICY_CYCLE: 'Leave Policy Cycle',
    ACCRUAL_TYPE: 'Accrual Type',
    LEAVE_PERIOD: 'Leave Period',
    SANDWICH_LEAVE: 'Sandwich Leaves',
    COUNT: 'Count',
    STAFF: 'Staff',
    IGNORE: 'Ignore',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    ADD: 'Add',
    DAYS: 'Days',
    TO_DATE: 'To Date',
    FROM_DATE: 'From Date',
    LEAVE_CATEGORY_NAME: 'Leave Category Name',
    LEAVE_COUNT: 'Leave Count',
    CARRY_LIMIT: 'Carry Forward Limit',
    ADD_LEAVE_CATEGORY: 'Add Leave Category',
    LEAVE_APPROVAL: 'Leave Approval',
    SET_MULTIPLE_APPROVAL: 'Set Multiple Approval',
    DEFAULT_MULTIPLE: 'Multilevel Approval Settings is set to Level 1 by default',
    DAYS_PER_YEAR: 'days per year',
    CASUAL_LEAVE: 'Casual Leave',
    SICK_LEAVE: 'Sick Leave',
    ANNUAL_LEAVE: 'Annual Leave',
    CARRY_FORWARD: 'Carry Forward',
    ENCASH: 'Encash',
    LEAVES: 'Leaves',
    LEAVE: 'Leave',
    YES: 'Yes',
    SAVE: 'Save',
    LAPSE: 'Lapse',
    TO: 'To',
    MANAGE_STAFF_LIST: "Apply Template To Staff",
    STAFF_WITH_LEAVE: "Staff With Leave",
    SEARCH_STAFF: "Search staff",
    STAFF_WITHOUT_LEAVE: "Staff Without Leave",
    STAFF_WITH_LEAVE: 'Staff with Leave policy',
    STAFF_WITHOUT_LEAVE: 'Staff without or other Leave policies',
    MONTHLY_REGULAR_STAFF: "Monthly Regular Staff",
    SAVE: "Save & Apply",
    SEARCH_STAFF: "Search staff",
    centerID: 19,
    DELETE_CONFIRMATION: "Delete Confirmation",
    DELETE_MESSAGE: "Are you Sure, You want to delete this Leave Category?",
    DELETE_ERROR: "Minimum one leave category is required",
    CARRY_LIMIT_ERR_MESSAGE : "Carry Forward Limit cannot be greater than Leave count",
};

export const staffList = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Michael Brown" },
    { id: 4, name: "Emily Davis" },
];