import React, { Fragment } from 'react';
import {ShivInfotech} from '../../Constant/index'
import { Link } from 'react-router-dom';

const Footer = () => {
      return (
        <Fragment>
          <footer className="footer d-flex justify-content-between align-items-center">
                  <p className="mb-0 fs-9">
                    Copyright 2024 © <Link to="https://shivinfotech.co/" target="_blank">{ShivInfotech}</Link> All rights reserved.
                  </p>
                  <p className="pull-right mb-0 fs-9">
                    Hand crafted & made with{' '}
                    <i className="fa fa-heart font-secondary"></i>
                  </p>
          </footer>
        </Fragment>
      );
};
export default Footer;
