export const AttendanceText = "Attendance";
export const AttendanceList = "Attendance List";
export const EMPID = "Emp ID";
export const StartDate = "Start Date";
export const StartDateKey = "start_date";
export const EndDate = "End Date";
export const fileType = "fileType";
export const csvValidation = "Only CSV or Excel files is allowed";
export const fileValidation = "File is required";
export const BatchText = "Batch";
export const Candidate = "Candidate";
export const Filter = "Filter";
export const ClearFilters = "Clear Filter";
export const DivisionUnits = "Division/Units";
export const CenterName = "Center Name";
export const OfficeLocations = "Office Locations";
export const InTime = "In Time";
export const OutTime = "Out Time";
export const Title = "Attendance";
export const DataError = "Data is not imported due to below errors";
export const fileImport = "Import File";
export const sampleFile = "Sample file";
export const Note = "Note";
