import React, { useEffect, useState } from "react";
import { leaveSummaryTitle } from "../../Constant/title";
import { leaveSummaryEnums } from "../../Constant/HR/leaveSummary";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import BaseModal from "../../BaseComponents/BaseModal";
import { RiArrowDownSLine, RiArrowUpSLine, RiSearchLine } from "react-icons/ri";
import { Action, notFound, Submit } from "../../Constant";
import { encashLeaveApi, listOfLeaveSummaryApi, perDaySalaryApi } from "../../Api/leaveSummaryApi";
import { checkStatusCodeSuccess, errHandler, getFinancialYear, getItem, handleResponse, isUserProfile, moduleName, rendorBackButton, RupeesSymbol } from "../../Constant/common";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseInput from "../../BaseComponents/BaseInput";
import { toast } from "react-toastify";
import { HR } from "../../Constant/hrLayout";

const LeaveSummary = () => {
  document.title = leaveSummaryTitle;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [leaveCount, setLeaveCount] = useState(0);
  const [salary, setSalary] = useState(0);
  const [resetSalary, setResetSalary] = useState(false);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(null);
  const { staffId } = useParams();
  const currentDate = new Date();
  const financialYear = getFinancialYear(currentDate);
  const finalSalary = resetSalary ? 0 : salary;
  const navigate = useNavigate();
  const isProfile = JSON.parse(getItem(isUserProfile) || false);
  const profileId = getItem("id");
  const finalId = isProfile ? profileId : staffId;
  const permission = JSON.parse(getItem("permissions"));
  const hasLeaveSummary = permission?.some(
    (perm) => perm.parent_module_name === HR && perm.module_name === moduleName.LeaveSummary
  );

  const fetchLeaveData = () => {
    setLoader(true);
    const payload = {};
    listOfLeaveSummaryApi(finalId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          const { totalLeavesTaken, totalRemainingLeave, ...months } = res?.data;
          const formattedData = Object.entries(months)?.map(
            ([month, leaves]) => ({
              month,
              totalDays: leaves[0]?.leaveCount || 0,
              details: leaves.slice(1),
            })
          );
          setLeaveData(formattedData);
          setLeaveCount({
            totalLeave: totalLeavesTaken,
            leaveBalance: totalRemainingLeave || 0,
          });
        } else {
          setLeaveData([]);
        }
      })
      .catch((err) => {
        setLeaveData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    fetchLeaveData();
  }, []);

  const toggleDetails = (month) => {
    setExpandedSections((prev) => ({
      ...prev,
      [month]: !prev[month],
    }));
  };

  const handleSelectAction = (action) => {
    setType(action);
    setLoader(true)
    if (action === leaveSummaryEnums.encashLeave) {
      setModal(true);
      perDaySalaryApi(finalId)
        .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          setSalary(res?.data);
        }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
      })
    }
  };

  const handleSubmit = () => {
    setBtnLoader(true);
    const payload = {
      leave_count: leaveCount?.leaveBalance,
    };
    encashLeaveApi(finalId,payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          toast.success(res?.message);
          setModal(false);
          fetchLeaveData();
        } else {
          toast.error(res?.message);
      }
      })
      .catch((err) => {
        errHandler(err);
      })
      .finally(() => {
        setBtnLoader(false);
      }
    )
  }

  useEffect(() => {
    if (!modal) {
      setResetSalary(null);
    }
  }, [modal]);

  return (
    <>
      <div className="page-content">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <div className="d-flex justify-content-end mb-2">
          {rendorBackButton(() => isProfile ? navigate("/profile") : navigate(`/staffProfile/${staffId}`))}
        </div>
        <div className="leave-card card p-2">
          <div className="d-flex gap-2">
            <h5>{leaveSummaryEnums.leaveSummary}</h5>
            <span>{financialYear}</span>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="border-end px-3">
                {leaveSummaryEnums.totalLeavesTaken}
                <div className="f-w-600">{leaveCount?.totalLeave ?? handleResponse.nullData}</div>
              </div>
              <div className=" px-3">
                {leaveSummaryEnums.balanceLeave}
                <div className="f-w-600">{leaveCount?.leaveBalance ?? handleResponse.nullData}</div>
              </div>
            </div>
            {hasLeaveSummary && (
              <>
                <div className="d-flex">
                  <div className="px-2">
                    <div className="col-3 justify-content-end mt-2 px-2">
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                        className="text-end rounded"
                      >
                        <DropdownToggle caret className="rounded-pill fw-normal">
                          {Action}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() =>
                              handleSelectAction(leaveSummaryEnums.encashLeave)
                            }
                          >
                            {leaveSummaryEnums.encashLeave}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="leave-summary-page">
          {leaveData?.length > 0 ? (
            leaveData?.map((section, index) => (
              <>
                <div key={index} className={`custom-card`}>
                  <div
                    className="card-header"
                    onClick={() => toggleDetails(section?.month)}
                  >
                    <div className="row align-items-center">
                      <div className="col-6 d-flex align-items-center gap-2">
                        {expandedSections[section?.month] ? (
                          <RiArrowUpSLine
                            className={`mb-2 ${
                              expandedSections[section?.month]
                            }`}
                            size={25}
                          />
                        ) : (
                          <RiArrowDownSLine
                            className={`mb-2 ${
                              expandedSections[section?.month]
                            }`}
                            size={25}
                          />
                        )}
                        <h5>{section?.month}</h5>
                      </div>
                      <div className="col-6 mt-1">
                        <h5>{`${section?.totalDays}${" "}${Number(section?.totalDays) > 1 ? leaveSummaryEnums.days : leaveSummaryEnums.day}`}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                {expandedSections[section?.month] && (
                  <div className="card-body px-4">
                    {section?.details?.map((detail, idx) => (
                      <div key={idx} className="detail-row">
                        <div className="row">
                          <div className="col-6">
                            {`${detail?.date}${" | "}${detail?.leave_duration}${" "}${Number(detail?.leave_duration) > 1 ? leaveSummaryEnums.days : leaveSummaryEnums.day}`}
                          </div>
                          <div className="col-3">{detail?.leave_name}</div>
                          <div className="col-3 text-end status">
                            <span
                              className={`${
                                detail?.leave_status === leaveSummaryEnums.approved
                                  ? `text-success badge badge-approved`
                                  : detail?.leave_status === leaveSummaryEnums.pending
                                  ? `text-warning badge badge-pending`
                                  : `text-danger badge badge-rejected`
                              }`}
                            >
                              <div>{detail?.leave_status}</div>
                            </span>
                          </div>
                        </div>
                        {idx < section?.details?.length - 1 && <hr />}
                      </div>
                    ))}
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="card py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
        <BaseModal
          isOpen={modal}
          title={type}
          toggler={() => {
            setModal(false);
          }
          }
          submit={() => handleSubmit()}
          submitText={Submit}
          disabled={btnLoader || !leaveCount?.leaveBalance}
          loader={btnLoader}
        >
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex">{leaveSummaryEnums.encashableLeave}</div>
              <div className="d-flex gap-1"><strong>{leaveCount?.leaveBalance}</strong>{leaveSummaryEnums.leaves}</div>
            </div>
            <BaseInput
              name="encashable_leave"
              type="text"
              label={leaveSummaryEnums.selectEncashleave}
              value={leaveCount?.leaveBalance}
              disabled={true}
            />
            <div className="mt-2">{leaveSummaryEnums.leaveInstruction}</div>
            <label className="mt-2">{leaveSummaryEnums.amountPerLeave}</label>
            <div className="input-group">
              <BaseInput
                name="amount_per_leave"
                type="number"
                value={finalSalary}
                disabled={true}
                icon={"Rs."}
              />
              <div className="input-group-text cursor" onClick={()=> setResetSalary(!resetSalary)}>{leaveSummaryEnums.reset}</div>
            </div>
            <div className="mt-2">
              <div>{leaveSummaryEnums.totalPayout}</div>
              <span className="f-w-600">{`${
                leaveCount?.leaveBalance
              } x ${finalSalary} =  ${RupeesSymbol((leaveCount?.leaveBalance * finalSalary).toFixed(2))}`}</span>
              {!leaveCount?.leaveBalance && (
                <div className="text-danger">
                  {leaveSummaryEnums.noLeaveMsg}
                </div>
              )}
            </div>
          </>
        </BaseModal>
      </div>
    </>
  );
};

export default LeaveSummary;
