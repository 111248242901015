export const leaveBalanceEnums = {
  leaveBalance: "Leave Balance",
  leaveCategory: "Leave Category",
  remainingLeave: "Remaining Leaves",
  leaveCount: "Added Leave",
  totalLeave: "Total Leave",
  balanceLeave: "Balance Leaves",
  reset: "Reset",
  unpaidLeave: "Unpaid leave",
  addedBy: "Added By",
  updatedOn: "Updated On",
  previousCount: "Remaining Leave",
  finalCount: "Total Leave",
  leaveBalanceHistory: "Leave Balance History",
  SrNo: "Sr No.",
};
