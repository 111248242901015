export const Details = {
  SALARYACCESS: "Salary detail access to Staff",
  STAFFAPP:
    " Staff with salary detail access can see their salary slips and payment details in their Staff App",
  BACK: "Back",
  SALARYDETAILS: "Salary Details Access",
  SALARYDETAILSLABEL: "Salary Details",
  SELECTALL: "Select All",
  NO: "+91",
  SAVE: "Save",
  CURRENTSALARY: "allow current cycle salary access",
  SEARCH: "Search...",
  SalaryAccess: "salaryAccess",
  NOACCESS: "NoAccess",
  SELECTEDSTAFF: "Only Selected Staff",
  ALLSTAFF:"All Staff",
  NOACCESSSTATUS:"No Access",
  ERROROCCURED: "An error occurred"
};

export const SalaryAccess = [
  {
    id: "1",
    value: "All Staff",
    label: "All staff",
  },
  {
    id: "2",
    value: "No Access",
    label: "No Access",
  },
  {
    id: "3",
    value: "Only Selected Staff",
    label: "Only selected staff",
  },
];

export const data = {
  SalaryDetails: [
    { id: "checkbox-primary-1", name: "Kaushik Trivedi", phone: "" },
    { id: "checkbox-primary-2", name: "Riya Shah", phone: "911543252346" },
    { id: "checkbox-primary-3", name: "Ramesh Kumar", phone: "1234567890" },
    {
      id: "checkbox-primary-4",
      name: "Jitendra Sharma",
      phone: "911543252348",
    },
    {
      id: "checkbox-primary-5",
      name: "Prachi Rathod",
      phone: "911543252349",
    },
    { id: "checkbox-primary-6", name: "Devangi Dube", phone: "1234567890" },
    { id: "checkbox-primary-7", name: "Amit Sharma", phone: "911543252351" },
    { id: "checkbox-primary-8", name: "Kishan Kumar", phone: "1234567890" },
    { id: "checkbox-primary-9", name: "Ram Gopal", phone: "911543252353" },
    { id: "checkbox-primary-10", name: "Amit Sharma", phone: "1234567890" },
    {
      id: "checkbox-primary-11",
      name: "Kishan Kumar",
      phone: "911543252355",
    },
    { id: "checkbox-primary-12", name: "Ram Gopal", phone: "911543252356" },
  ],
};
