import { ATTENDANCEDATA, ATTENDENCEMODE, MONTHDURATION, SALARYCALCULATION } from "./apiRoutes";
import { authData } from "./Service";

export const AttendenceMode = async (data) => {
  const response = await authData.put(ATTENDENCEMODE, data);
  return response?.data;
};

export const CalculationMethod = async (data) => {
  const response = await authData.put(MONTHDURATION, data);
  return response?.data;
};

export const salaryDurationApi = async () => {
  const response = await authData.get(SALARYCALCULATION);
  return response?.data;
};

export const attendanceDataApi = async () => {
  const response = await authData.get(ATTENDANCEDATA);
  return response?.data;
};