import React, { useEffect, useMemo, useState } from "react";
import { salaryEnums } from "../../../Constant/HR/staffSalary";
import { revisionHistoryApi } from "../../../Api/salaryApi";
import {
  checkStatusCodeSuccess,
  extractDate,
  getItem,
  handleResponse,
  isUserProfile,
  RouteTag,
  RupeesSymbol,
} from "../../../Constant/common";
import { toast } from "react-toastify";
import Spinner from "../../../BaseComponents/BaseLoader";
import TableContainer from "../../../BaseComponents/BaseTable";
import { Back, Edit, notFound, Search } from "../../../Constant";
import { useParams } from "react-router-dom";
import { salaryRevisionHistoryTitle } from "../../../Constant/title";
import { RiArrowLeftLine, RiDraftLine, RiEditBoxLine, RiSearchLine } from "react-icons/ri";

const SalaryHistory = () => {
  document.title = salaryRevisionHistoryTitle;
  const { staffId } = useParams();
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState();
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [columnName, setColumnName] = useState("id");
  const [searchValue, setSearchValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [salaryHistory, setSalaryHistory] = useState(null);
  const [historySearchList, setHistorySearchList] = useState(null);
  const isProfile = JSON.parse(sessionStorage.getItem(isUserProfile) || false);
  const profileId = getItem("id");
  const finalId = isProfile ? profileId : staffId;

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };
  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setSearchValue(value);
      setCurrentPage(1);
    }
  };
  const fetchData = () => {
    const payload = {
      order: [columnName, sortOrder],
      pageSize: customPageSize,
      pageNumber: currentPage,
      search: searchValue,
    };
    setLoader(true);
    revisionHistoryApi(finalId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          setSalaryHistory(resp?.data?.listOfReviseStaffSalary);
          setHistorySearchList(resp?.data?.listOfReviseStaffSalary);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        setSalaryHistory([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, customPageSize, columnName, sortOrder, searchValue]);

  const columns = useMemo(
    () => [
      {
        header: salaryEnums.SR_NO,
        accessorKey: "id",
        cell: (cell) => cell.row.index + 1,
        enableColumnFilter: false,
      },
      {
        header: salaryEnums.PREVIOUS_SALARY,
        accessorKey: "previous_salary",
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            RupeesSymbol(
              cell.row.original.previous_salary
            ) ?? handleResponse.nullData
          );
        },
      },
      {
        header: salaryEnums.REVISED_SALARY,
        accessorKey: "revised_monthly_salary",
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            RupeesSymbol(
              cell.row.original.revised_monthly_salary
            ) ?? handleResponse.nullData
          );
        },
      },
      {
        header: salaryEnums.PERCENT_CHANGE,
        accessorKey: "revised_percentage",
        enableColumnFilter: false,
      },
      {
        header: salaryEnums.EFFECTIVE_DATE,
        accessorKey: "effective_date",
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            extractDate(
              cell.row.original.effective_date
            ) ?? handleResponse.nullData
          );
        },
      },
      {
        header: salaryEnums.CREATED_DATE,
        accessorKey: "created_date",
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            extractDate(
              cell.row.original.created_date
            ) ?? handleResponse.nullData
          );
        },
      },
      {
        header: salaryEnums.REVISED_BY,
        accessorKey: "authUser.name",
        enableColumnFilter: false,
      },
    ],
    []
  );
  return (
    <>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-6">
          <h5 className="m-0">{salaryEnums.REVISION_HISTORY}</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          {isProfile ? (
            <RouteTag
              link={`/salaryStructure`}
              title={Back}
              Icon={RiArrowLeftLine}
            />
          ) : (
            <>
              <RouteTag
                link={`/salaryStructure/${staffId}`}
                title={Back}
                Icon={RiArrowLeftLine}
              />
              <RouteTag
                link={`/reviseSalaryStructure/${staffId}`}
                title={salaryEnums.REVISE}
                Icon={RiDraftLine}
              />
              <RouteTag
                link={`/editSalaryStructure/${staffId}`}
                title={Edit}
                Icon={RiEditBoxLine}
              />
            </>
          )}
        </div>
      </div>
      <div className="email-wrap bookmark-wrap">
        <div className="card border month-card p-4 mb-3">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          <div className="details">
            <div className="card-body">
              <div className="details">
                {salaryHistory && salaryHistory?.length > 0 && (
                  <TableContainer
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    totalNumberOfRows={totalNumberOfRows}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    fetchData={handleFetchData}
                    columns={columns}
                    data={searchValue ? historySearchList : salaryHistory || []}
                    isGlobalFilter={true}
                    manualFiltering={true}
                    onSearch={handleSearchValueChange}
                    customPageSize={customPageSize}
                    setCustomPageSize={setCustomPageSize}
                    manualPagination={true}
                    tableClass="table table-bordered text-center"
                    fetchSortingData={handleFetchSorting}
                    SearchPlaceholder={Search}
                  />
                )}
                {(!salaryHistory || salaryHistory.length < 1) && (
                  <div className="py-4 text-center">
                    <div>
                      <RiSearchLine className="fs-2" />
                    </div>
                    <div className="mt-4">
                      <h5>{notFound.dataNotFound}</h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalaryHistory;
