import {
  ADDOPENING,
  DELETEOPENING,
  EDITOPENING,
  LISTOFOPENING,
  OPENINGSTATUS,
  VIEWOPENING,
} from "./apiRoutes";
import { authData } from "./Service";

export const listOfOpeningApi = async (data) => {
  const response = await authData.post(LISTOFOPENING, data);
  return response?.data;
};
export const addOpeningApi = async (data) => {
  const response = await authData.post(ADDOPENING, data);
  return response?.data;
};
export const editOpeningApi = async (id, data) => {
  const response = await authData.put(`${EDITOPENING}${id}`, data);
  return response?.data;
};
export const deleteOpeningApi = async (id) => {
  const response = await authData.delete(`${DELETEOPENING}${id}`);
  return response?.data;
};
export const viewOpeningApi = async (id) => {
  const response = await authData.get(`${VIEWOPENING}${id}`);
  return response?.data;
};
export const openingStatusApi = async (id, data) => {
  const response = await authData.put(`${OPENINGSTATUS}${id}`, data);
  return response?.data;
};