import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notFound } from "../../../../Constant";
import Spinner from "../../../../BaseComponents/BaseLoader";
import { leaveTemplate } from "../../../../Constant/HR/leaveTemplate";
import BaseInput from "../../../../BaseComponents/BaseInput";
import { PlaceHolderFormat } from "../../../../Constant/requireMessage";
import BaseButton from "../../../../BaseComponents/BaseButton";
import { RiDeleteBin7Fill, RiSearchLine } from "react-icons/ri";
import { leaveTitle } from "../../../../Constant/title";
import { validationMessages } from "../../../../Constant/validation";
import { useFormik } from "formik";
import * as yup from "yup";
import { checkStatusCodeSuccess, isRequired, rendorBackButton } from "../../../../Constant/common";
import { toast } from "react-toastify";
import BaseModal from "../../../../BaseComponents/BaseModal";
import {
  addLeaveTemplateApi,
  deleteLeaveCategoryApi,
  editLeaveTemplateApi,
  viewLeaveTemplateApi,
} from "../../../../Api/leaveTemplate";
import { useSelectedCenter } from "../../../CenterContext";

const AddEditLeaveTemplate = () => {
  document.title = leaveTitle;
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leaveIndexToDelete, setLeaveIndexToDelete] = useState(null);
  const [dltBtnLoader, setDltBtnLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isYearly, setIsYearly] = useState(false)
  const { selectedCenterPayload } = useSelectedCenter();

  const yearlySchema= yup.object({
    leave_template_name: yup
      .string()
      .required(validationMessages.required(leaveTemplate.TEMPLATE_NAME)),
    leave_start_date: yup.string().test(
      'leave-start-date-validation',
      validationMessages.required(leaveTemplate.FROM_DATE),
      function (value) {
        const { leave_policy_cycle } = this.parent;
        if (leave_policy_cycle === leaveTemplate.YEARLY) {
          return !!value;
        }
        return true;
      }
    ),
    leave_end_date: yup.string().test(
      'leave-end-date-validation',
      validationMessages.required(leaveTemplate.TO_DATE),
      function (value) {
        const { leave_policy_cycle } = this.parent;
        if (leave_policy_cycle === leaveTemplate.YEARLY) {
          return !!value;
        }
        return true;
      }
    ),
    leave_policy_cycle: yup
      .string()
      .required(validationMessages.required(leaveTemplate.LEAVE_POLICY_CYCLE)),
    leaves: yup.array().of(
      yup.object({
        leave_category_name: yup
          .string()
          .required(validationMessages.required(leaveTemplate.LEAVE_CATEGORY_NAME)),
        leave_count: yup
          .string()
          .required(validationMessages.required(leaveTemplate.LEAVE_COUNT)),
        carry_forward_limit: yup
          .string()
          .required(validationMessages.required(leaveTemplate.CARRY_LIMIT))
          .test('carry-limit-validation', leaveTemplate.CARRY_LIMIT_ERR_MESSAGE, function (value) {
            const { leave_count } = this.parent;
            return parseInt(value, 10) <= parseInt(leave_count, 10);
          }),
      })
    ),
  });

  const monthlyschema = yup.object({
    leave_template_name: yup
      .string()
      .required(validationMessages.required(leaveTemplate.TEMPLATE_NAME)),
    leave_policy_cycle: yup
      .string()
      .required(validationMessages.required(leaveTemplate.LEAVE_POLICY_CYCLE)),
    leaves: yup.array().of(
      yup.object({
        leave_category_name: yup
          .string()
          .required(validationMessages.required(leaveTemplate.LEAVE_CATEGORY_NAME)),
        leave_count: yup
          .string()
          .required(validationMessages.required(leaveTemplate.LEAVE_COUNT)),
        carry_forward_limit: yup
          .string()
          .required(validationMessages.required(leaveTemplate.CARRY_LIMIT))
          .test('carry-limit-validation', leaveTemplate.CARRY_LIMIT_ERR_MESSAGE, function (value) {
            const { leave_count } = this.parent;
            return parseInt(value, 10) <= parseInt(leave_count, 10);
          }),
      })
    ),
  });

  const leaveForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      leave_template_name: null,
      leave_start_date: null,
      leave_end_date: null,
      leave_policy_cycle: leaveTemplate.MONTHLY,
      leaves: [
        { leave_category_name: null, leave_count: null, carry_forward_limit: null },
      ],
    },
    validationSchema: isYearly ? yearlySchema : monthlyschema,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        ...selectedCenterPayload,
        leave_template_name: values.leave_template_name,
        leave_policy_cycle: values.leave_policy_cycle,
        leaves: values.leaves?.map((leave) => ({
          ...leave,
          carry_forward_limit: leave?.carry_forward_limit
            ? Number(leave?.carry_forward_limit)
            : 0, 
          leave_count: leave?.leave_count ? Number(leave?.leave_count) : 0,
        })),
      };
  
      if (values.leave_policy_cycle === leaveTemplate.YEARLY) {
        payload.leave_start_date = values.leave_start_date;
        payload.leave_end_date = values.leave_end_date;
      }
  
      const apiCall = id
        ? editLeaveTemplateApi(id, payload)
        : addLeaveTemplateApi(payload);
      apiCallFunction(apiCall);
    },
  });
  const apiCallFunction = (apiCall) => {
    setBtnLoader(true);
    apiCall
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          toast.success(resp?.message);
          leaveForm.resetForm();
          navigate("/leaveTemplate");
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };
  const calculateEndDate = (startDate, cycle) => {
    const date = new Date(startDate);
    if (cycle === leaveTemplate.MONTHLY) {
      date.setMonth(date.getMonth() + 1);
    } else if (cycle === leaveTemplate.YEARLY) {
      date.setFullYear(date.getFullYear() + 1);
    }
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (
      leaveForm.values.leave_start_date &&
      leaveForm.values.leave_policy_cycle
    ) {
      const endDate = calculateEndDate(
        leaveForm.values.leave_start_date,
        leaveForm.values.leave_policy_cycle
      );
      leaveForm.setFieldValue("leave_end_date", endDate);
    }
  }, [leaveForm.values.leave_start_date, leaveForm.values.leave_policy_cycle]);

  const fetchData = (id) => {
    if (id) {
      setLoader(true);
      viewLeaveTemplateApi(id)
        .then((response) => {
          if (checkStatusCodeSuccess(response.statusCode)) {
            const data = response?.data;
            leaveForm.setValues({
              leave_template_name: data?.leave_template_name,
              leave_policy_cycle: data?.leave_policy_cycle,
              leave_start_date: data?.leave_start_date,
              leave_end_date: data?.leave_end_date,
              leaves: data?.LeaveCategory,
            });
  
            if (data?.leave_policy_cycle === leaveTemplate.YEARLY) {
              setIsYearly(true);
            } else {
              setIsYearly(false);
            }
          } else {
            toast.error(response?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message ?? err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleDelete = (index) => {
    setLeaveIndexToDelete(index);
    setIsModalOpen(true);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const confirmDelete = () => {
    const updatedLeaves = [...leaveForm.values.leaves];
      setDltBtnLoader(true);
      const leaveToDelete = updatedLeaves[leaveIndexToDelete];
      if (leaveToDelete.id) {
        const payload = {
          is_deleted: true,
        };
        deleteLeaveCategoryApi(leaveToDelete.id, payload)
          .then((response) => {
            if (checkStatusCodeSuccess(response.statusCode)) {
              toast.success(response?.message);
              fetchData(id);
            } else {
              toast.error(response?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message ?? err?.message);
          })
          .finally(() => {
            setIsModalOpen(false);
            setDltBtnLoader(false);
          });
      } else {
        updatedLeaves.splice(leaveIndexToDelete, 1);
        leaveForm.setValues({
          ...leaveForm.values,
          leaves: updatedLeaves,
        });

        setIsModalOpen(false);
        setDltBtnLoader(false);
      }
  };

  const addNewLeave = () => {
    leaveForm.setFieldValue("leaves", [
      ...leaveForm.values.leaves,
      {
        leave_category_name: null,
        leave_count: null,
        carry_forward_limit: null,
        isNew: true,
      },
    ]);
  };

  return (
    <>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <form onSubmit={leaveForm.handleSubmit}>
        <div className="container-fluid">
          <div className="page-header dash-breadcrumb py-0">
            <div className="row">
              <div className="col-6">
                <h5 className="f-w-600">{leaveTemplate.CREATE_TEMPLATE}</h5>
              </div>
              <div className="col-6 d-flex justify-content-end">
                {rendorBackButton(() => navigate("/leaveTemplate"))}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pb-0 mb-0">
          <div>
            <span className="f-w-600 text-secondary">
              {leaveTemplate.TEMPLATE_SETTING}
            </span>
            <div className="card">
              <div className="card-body p-4">
                <div className="row mb-3">
                  <h6 className="col-12 col-md-4">
                    {leaveTemplate.TEMPLATE_NAME}
                    {isRequired}
                  </h6>
                  <div className="col-12 col-md-4">
                    <BaseInput
                      name="leave_template_name"
                      type="text"
                      placeholder={PlaceHolderFormat(
                        leaveTemplate.TEMPLATE_NAME
                      )}
                      handleChange={leaveForm.handleChange}
                      value={leaveForm.values.leave_template_name}
                      touched={leaveForm.touched.leave_template_name}
                      error={leaveForm.errors.leave_template_name}
                      handleBlur={leaveForm.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <h6 className="col-12 col-md-4">
                    {leaveTemplate.LEAVE_POLICY_CYCLE}
                    {isRequired}
                  </h6>
                  <div className="col-12 col-md-4 d-flex">
                    <div className="btn-group" role="group" aria-label="Leave Policy Cycle">
                      <BaseButton
                        type="button"
                        className={`btn ${leaveForm.values.leave_policy_cycle === leaveTemplate.MONTHLY && !isYearly
                            ? "btn-primary"
                            : "btn-light"
                          }`}
                        onClick={() => {
                          leaveForm.setFieldValue("leave_policy_cycle", leaveTemplate.MONTHLY);
                          setIsYearly(false);
                        }}
                      >
                        {leaveTemplate.MONTHLY}
                      </BaseButton>
                    </div>
                  </div>
                </div>
                {
                  isYearly &&
                  <div className="row mb-3">
                    <h6 className="col-12 col-md-4">
                      {leaveTemplate.LEAVE_PERIOD}{isRequired}
                    </h6>
                    <div className="col-12 col-md-5 col-lg-4">
                      <div className="d-flex align-items-center">
                        <BaseInput
                          name="leave_start_date"
                          type="date"
                          placeholder={PlaceHolderFormat(leaveTemplate.FROM_DATE)}
                          handleChange={leaveForm.handleChange}
                          value={leaveForm.values.leave_start_date}
                          touched={leaveForm.touched.leave_start_date}
                          error={leaveForm.errors.leave_start_date}
                          handleBlur={leaveForm.handleBlur}
                        />
                        <span className="mx-2">{leaveTemplate.TO}</span>
                        <BaseInput
                          name="leave_end_date"
                          type="date"
                          placeholder={PlaceHolderFormat(leaveTemplate.TO_DATE)}
                          handleChange={leaveForm.handleChange}
                          value={leaveForm.values.leave_end_date}
                          touched={leaveForm.touched.leave_end_date}
                          error={leaveForm.errors.leave_end_date}
                          handleBlur={leaveForm.handleBlur}
                          disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="leave-category container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col">
              <h5 className="f-w-600">{leaveTemplate.LEAVE_CATEGORIES}</h5>
            </div>
            <div className="col text-end mb-1">
              <BaseButton
                type="button"
                className="btn btn-primary"
                onClick={addNewLeave}
              >
                {leaveTemplate.ADD_LEAVE_CATEGORY}
              </BaseButton>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div>
            <div className="card">
              <div className="card-body p-4">
                {leaveForm.values.leaves?.length === 0 ? (
                  <div className="container-fluid">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="py-4 text-center">
                        <div>
                          <RiSearchLine className="fs-2" />
                        </div>
                        <div className="mt-4">
                          <h5>{notFound.dataNotFound}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ul className="list-group list-group-flush">
                      {leaveForm?.values?.leaves?.map((leave, index) => {
                        const isOtherLeave = leave.leave_category_name === leaveTemplate.OTHER_LEAVE;
                        return (
                          <li key={index} className="list-group-item border-Bottom">
                            <div className="row align-items-center">
                              <div className="col-lg-3">
                                <div className="form-group mb-2">
                                  <BaseInput
                                    type="text"
                                    label={leaveTemplate.LEAVE_CATEGORY_NAME}
                                    className="form-control"
                                    name={`leaves.${index}.leave_category_name`}
                                    placeholder={PlaceHolderFormat(leaveTemplate.LEAVE_CATEGORY_NAME)}
                                    value={leave.leave_category_name}
                                    handleChange={leaveForm.handleChange}
                                    handleBlur={leaveForm.handleBlur}
                                    error={leaveForm.errors?.leaves?.[index]?.leave_category_name}
                                    touched={leaveForm.touched?.leaves?.[index]?.leave_category_name}
                                    disabled={isOtherLeave}
                                    required={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group mb-2">
                                  <BaseInput
                                    type="string"
                                    label={leaveTemplate.LEAVE_COUNT}
                                    className="form-control"
                                    name={`leaves.${index}.leave_count`}
                                    placeholder={PlaceHolderFormat(leaveTemplate.LEAVE_COUNT)}
                                    value={leaveForm.values.leaves?.[index].leave_count}
                                    handleChange={leaveForm.handleChange}
                                    handleBlur={leaveForm.handleBlur}
                                    error={leaveForm.errors?.leaves?.[index]?.leave_count}
                                    touched={leaveForm.touched?.leaves?.[index]?.leave_count}
                                    disabled={isOtherLeave}
                                    required={!isOtherLeave}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group mb-2">
                                  <BaseInput
                                    type="string"
                                    label={leaveTemplate.CARRY_LIMIT}
                                    className="form-control"
                                    name={`leaves.${index}.carry_forward_limit`}
                                    placeholder={PlaceHolderFormat(leaveTemplate.CARRY_LIMIT)}
                                    value={leaveForm.values.leaves?.[index].carry_forward_limit}
                                    handleChange={leaveForm.handleChange}
                                    handleBlur={leaveForm.handleBlur}
                                    error={leaveForm.errors?.leaves?.[index]?.carry_forward_limit}
                                    touched={leaveForm.touched?.leaves?.[index]?.carry_forward_limit}
                                    disabled={isOtherLeave}
                                    required={!isOtherLeave}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 d-flex justify-content-end">
                                {!isOtherLeave && 
                                <RiDeleteBin7Fill
                                  onClick={() => handleDelete(index)}
                                  className="fs-5 text-danger cursor-pointer"
                                  title={leaveTemplate.Delete}
                                />}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-1 pt-0 sticky-save-button">
          <div className="col-12 d-flex justify-content-end">
            <BaseButton
              type="submit"
              loader={btnLoader}
              className="btn btn-success"
            >
              {leaveTemplate.SAVE}
            </BaseButton>
          </div>
        </div>
      </form>
      <BaseModal
        isOpen={isModalOpen}
        title={leaveTemplate.DELETE_CONFIRMATION}
        toggler={toggleModal}
        submit={confirmDelete}
        submitText={leaveTemplate.YES}
        loader={dltBtnLoader}
        disabled={dltBtnLoader}
      >
        <p>{leaveTemplate.DELETE_MESSAGE}</p>
      </BaseModal>
    </>
  );
};

export default AddEditLeaveTemplate;
