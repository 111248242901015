import { EDITSALARYSTRUCTURE, EFFECTIVECYCLE, LISTOFSALARYOVERVIEW, REVISESALARY, REVISIONHISTORY, VIEWSALARYOVERVIEW, VIEWSALARYSTRUCTURE } from "./apiRoutes";
import { authData } from "./Service";

export const effectiveCycleApi = async (id) => {
    const response = await authData.get(`${EFFECTIVECYCLE}${id}`);
    return response?.data;
};

export const viewSalaryStructureApi = async (id) => {
    const response = await authData.get(`${VIEWSALARYSTRUCTURE}${id}`);
    return response?.data;
};
export const editSalaryStructureApi = async (id, data) => {
    const response = await authData.put(`${EDITSALARYSTRUCTURE}${id}`, data);
    return response?.data;
};
export const reviseSalaryApi = async (id, data) => {
    const response = await authData.post(`${REVISESALARY}${id}`, data);
    return response?.data;
};
export const revisionHistoryApi = async (id, data) => {
    const response = await authData.post(`${REVISIONHISTORY}${id}`, data);
    return response?.data;
};
export const listOfSalaryOverviewApi = async (id, data) => {
    const response = await authData.post(`${LISTOFSALARYOVERVIEW}${id}`, data);
    return response?.data;
};
export const viewSalaryOverviewApi = async (id, data) => {
    const response = await authData.post(`${VIEWSALARYOVERVIEW}${id}`, data);
    return response?.data;
};