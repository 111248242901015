import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import BaseInput from "../../../../BaseComponents/BaseInput";
import {
  RiAddLine,
  RiArrowLeftLine,
  RiDeleteBin7Fill,
  RiSearchLine,
} from "react-icons/ri";
import BaseButton from "../../../../BaseComponents/BaseButton";
import { validationMessages } from "../../../../Constant/validation";
import { PlaceHolderFormat } from "../../../../Constant/requireMessage";
import { holidayLabel } from "../../../../Constant/HR_HolidayTemplate/holidayTemplate";
import { Label } from "reactstrap";
import { Add, notFound } from "../../../../Constant";
import "../../../../../src/hr.css";
import { holidayTitle } from "../../../../Constant/title";
import Spinner from "../../../../BaseComponents/BaseLoader";
import { toast } from "react-toastify";
import {
  checkStatusCodeSuccess,
  extractYearMonth,
  extractYearWiseDate,
  getEndOfMonth,
  isRequired,
  maxDate,
  minDate,
  yearMonthOnly,
} from "../../../../Constant/common";
import {
  addHoliday,
  deleteHoliday,
  editHoliday,
  viewHolidayTemplate,
} from "../../../../Api/HolidayTemplate";
import BaseModal from "../../../../BaseComponents/BaseModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelectedCenter } from "../../../CenterContext";

const CreateHolidayTemplate = () => {
  document.title = holidayTitle;
  const { id } = useParams();
  const navigate = useNavigate();
  const [holidayStart, setHolidayStart] = useState("");
  const [holidayEnd, setHolidayEnd] = useState("");
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [holidayIndexToDelete, setHolidayIndexToDelete] = useState(null);
  const [dltBtnLoader, setDltBtnLoader] = useState(false);
  const { selectedCenterPayload } = useSelectedCenter();

  const handleBack = () => {
    navigate("/holidayTemplate");
  };

  const calculateHolidayEnd = (start) => {
    const startDate = extractYearMonth(start);
    const endDate = startDate.add(12, holidayLabel.months);
    return endDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (holidayStart) {
      setHolidayEnd(calculateHolidayEnd(holidayStart));
    }
  }, [holidayStart]);

  const initialValues = {
    templateName: "",
    holidayStart: "",
    holidayEnd: "",
    holidays: [{ holiday_name: "", holiday_date: "" }],
  };

  const validationSchema = Yup.object({
    templateName: Yup.string().required(
      validationMessages.required(holidayLabel.templateName)
    ),
    holidayStart: Yup.string().required(
      validationMessages.required(holidayLabel.startMonth)
    ),
    holidays: Yup.array().of(
      Yup.object({
        holiday_name: Yup.string().required(
          validationMessages.required(holidayLabel.holidayName)
        ),
        holiday_date: Yup.string()
          .required(validationMessages.required(holidayLabel.holidayDate))
          .test(
            "is-valid-date",
            `${holidayLabel.dateValidation} ${extractYearWiseDate(
              holidayStart
            )} and ${extractYearWiseDate(holidayEnd)}`,

            (value) => {
              const date = new Date(value);
              return (
                date >= new Date(holidayStart) && date <= new Date(holidayEnd)
              );
            }
          ),
      })
    ),
  });
  const handleSubmit = (values, { resetForm }) => {
    const startMoment = extractYearMonth(values.holidayStart);
    const formattedStartDate = yearMonthOnly(startMoment);
    const EndMoment = extractYearMonth(holidayEnd);
    const formattedEndDate = yearMonthOnly(EndMoment);
    const payload = {
      ...selectedCenterPayload,
      template_name: values.templateName,
      from_date: formattedStartDate,
      to_date: formattedEndDate,
      holidays: values.holidays,
    };

    setLoader(true);

    const apiCall = id ? editHoliday(id, payload) : addHoliday(payload);

    apiCall
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          toast.success(resp?.message);
          resetForm();
          navigate("/holidayTemplate");
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        if (Array.isArray(err?.response?.data?.message)) {
          err?.response?.data?.message?.map((message) => {
            toast.error(message);
          });
        }
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const fetchData = (id) => {
    if (id) {
      setLoader(true);
      viewHolidayTemplate(id)
        .then((response) => {
          if (checkStatusCodeSuccess(response.statusCode)) {
            const data = response?.data;
            formik.setValues({
              templateName: data?.template_name,
              holidayStart: data?.from_date,
              holidayEnd: data?.to_date,
              holidays: data?.holidayDetail,
            });
            const startMoment = extractYearMonth(data.from_date);
            setHolidayStart(startMoment.toDate());
          } else {
            toast.error(response?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleDelete = (index) => {
    setHolidayIndexToDelete(index);
    setIsModalOpen(true);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const confirmDelete = () => {
    setDltBtnLoader(true);
    const updatedHolidays = [...formik.values.holidays];
    const holidayToDelete = updatedHolidays[holidayIndexToDelete];
    if (holidayToDelete.id) {
      const payload = {
        is_deleted: true,
      };
      deleteHoliday(holidayToDelete.id, payload)
        .then((response) => {
          if (checkStatusCodeSuccess(response.statusCode)) {
            toast.success(response?.message);
            fetchData(id);
          } else {
            toast.error(response?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setIsModalOpen(false);
          setDltBtnLoader(false);
        });
    } else {
      updatedHolidays.splice(holidayIndexToDelete, 1);
      formik.setValues({
        ...formik.values,
        holidays: updatedHolidays,
      });

      setIsModalOpen(false);
      setDltBtnLoader(false);
    }
  };

  const addNewHoliday = () => {
    formik.setFieldValue(holidayLabel.holidays, [
      ...formik.values.holidays,
      { holiday_name: "", holiday_date: "", isNew: true },
    ]);
  };

  return (
    <div>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <form onSubmit={formik.handleSubmit}>
        <div className="container mb-1">
          <div className="page-header dash-breadcrumb p-0">
            <div className="row">
              <div className="col-6 px-3 d-flex align-items-center">
                {id ? (
                  <h5 className="f-w-600 mr-4">
                    {holidayLabel.editHolidayTemplateTitle}
                  </h5>
                ) : (
                  <h5 className="f-w-600 mr-4">
                    {holidayLabel.holidayTemplateTitle}
                  </h5>
                )}</div>
              <div className="col-6 d-flex justify-content-end">
                <span className="px-2 btn btn-primary btn-pill" title="Back"><RiArrowLeftLine size={22} onClick={handleBack} /></span>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="card m-0">
            <div className="card-body px-4">
              <div className="row mt-4">
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <BaseInput
                      label={holidayLabel.templateName}
                      type="text"
                      className="form-control"
                      name={holidayLabel.templateNameKey}
                      placeholder={PlaceHolderFormat(holidayLabel.templateName)}
                      value={formik.values.templateName}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      error={formik.errors.templateName}
                      touched={formik.touched.templateName}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-9">
                  <Label htmlFor="holidayPeriod">
                    {holidayLabel.annualHolidayPeriod}
                    {isRequired}
                  </Label>
                  <div className="d-flex flex-wrap align-items-start">
                    <div className="col-12 col-sm-auto">
                      <DatePicker
                        selected={holidayStart}
                        onChange={(date) => {
                          setHolidayStart(date);
                          const formattedDate = extractYearMonth(date);
                          formik.setFieldValue(
                            holidayLabel.holidayStart,
                            formattedDate
                          );
                          setHolidayEnd(getEndOfMonth(date));
                        }}
                        onBlur={formik.handleBlur}
                        dateFormat="yyyy-MM"
                        showMonthYearPicker
                        className="form-control"
                        name={holidayLabel.holidayStart}
                        placeholderText={PlaceHolderFormat(
                          holidayLabel.startMonth
                        )}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                      {formik.touched.holidayStart &&
                      formik.errors.holidayStart ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.holidayStart}
                        </div>
                      ) : null}
                    </div>
                    <span className="mx-1 my-2 col-12 col-sm-auto text-center">
                      {holidayLabel.To}
                    </span>
                    <div className="col-12 col-sm-auto">
                      <BaseInput
                        type="text"
                        className="form-control"
                        value={holidayEnd}
                        placeholder={PlaceHolderFormat(holidayLabel.endMonth)}
                        disabled
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col">
              <h5 className="f-w-600">{holidayLabel.holidayList}</h5>
            </div>
            <div className="col text-end d-flex justify-content-end">
              <BaseButton
                type="button"
                className="btn btn-primary d-flex align-items-center"
                onClick={addNewHoliday}
              >
                <span>{Add}</span>
                <span><RiAddLine size={20} /></span>
              </BaseButton>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-3">
            <div className="card-body">
              {formik.values.holidays?.length === 0 ? (
                <div className="container-fluid">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="py-4 text-center">
                      <div>
                        <RiSearchLine className="fs-2" />
                      </div>
                      <div className="mt-4">
                        <h5>{notFound.dataNotFound}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <ul className="list-group list-group-flush">
                  {formik.values.holidays?.map((holiday, index) => (
                    <li key={index} className="list-group-item">
                      <div className="row align-items-start">
                        <div className="col-lg-3">
                          <div className="form-group mb-0">
                            <BaseInput
                              type="text"
                              label={holidayLabel.holidayName}
                              className="form-control"
                              name={`holidays.${index}.holiday_name`}
                              placeholder={PlaceHolderFormat(
                                holidayLabel.holidayName
                              )}
                              value={holiday.holiday_name}
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              error={
                                formik.errors?.holidays?.[index]?.holiday_name
                              }
                              touched={
                                formik.touched?.holidays?.[index]?.holiday_name
                              }
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group mb-0">
                            <BaseInput
                              type="date"
                              label={holidayLabel.holidayDate}
                              className="form-control"
                              name={`holidays.${index}.holiday_date`}
                              value={holiday.holiday_date}
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              error={
                                formik.errors?.holidays?.[index]?.holiday_date
                              }
                              touched={
                                formik.touched?.holidays?.[index]?.holiday_date
                              }
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-end delete-icons">
                          <RiDeleteBin7Fill
                            onClick={() => handleDelete(index)}
                            className="fs-5 text-danger cursor-pointer"
                            title={holidayLabel.Delete}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="mt-3 container sticky-save-button">
          <div className="col-12 d-flex justify-content-end">
            <BaseButton type="submit" className="btn btn-success">
              {holidayLabel.Save}
            </BaseButton>
          </div>
        </div>
      </form>
      <BaseModal
        isOpen={isModalOpen}
        title={holidayLabel.deleteConfirmation}
        toggler={toggleModal}
        submit={confirmDelete}
        submitText={holidayLabel.Yes}
        loader={dltBtnLoader}
        disabled={dltBtnLoader}
      >
        <p>{holidayLabel.deleteMessage}</p>
      </BaseModal>
    </div>
  );
};

export default CreateHolidayTemplate;
