export const salaryEnums = {
  ACTIONS: "Actions",
  REVISE: "Revise",
  EDIT: "Edit",
  HISTORY: "History",
  SALARY_OVERVIEW: "Salary Overview",
  GENERATE_SALARY_SLIP: "Generate Salary Slip",
  SALARY_SLIP: "Salary Slip",
  SELECT_YEAR_MONTH: "Select Month and Year",
  MONTH_YEAR: "Month and Year",
  SYSTEM_BASIC: "Basic Pay",
  FIXED: "Fixed",
  EARNINGS: "Earnings",
  FILENAME: "_half_salary_slip",
  FIXED_EARNINGS: "Fixed Earnings",
  CTC: "CTC",
  FULL: "Full",
  ACTUAL: "Actual",
  DEDUCTIONS: "Deductions",
  GROSS_EARNING: "Gross Earning",
  TOTAL_DEDUCTIONS: "Total Deductions",
  DUE_AMOUNT: "Due Amount : ",
  NET_PAYABLE:
    "Net Payable Amount (Actual Gross Earnings)",
  StaffId: "ID 9 Regular",
  SALARY_VALUE: "Rs.19000",
  ZERO_VALUE: "Rs.0",
  COMPONENTS: "Components",
  CALCULATION: "Calculation",
  MONTHLY_AMOUNT: "Monthly Amount",
  YEARLY_AMOUNT: "Yearly Amount",
  SPECIAL_ALLOWANCE: "Special Allowance",
  REVISE_SALARY: "Revise Salary Structure",
  EDIT_SALARY: "Edit Salary Structure",
  STAFF_TYPE: "Staff Type",
  REGULAR: "Regular",
  WAGE_RATE: "Wage Rate",
  MONTHLY: "Monthly",
  EFFECTIVE_CYCLE: "Effective Cycle",
  CURRENT_CTC: "Current CTC",
  REVISE_BY_PERCENTAGE: "Revise By Percentage",
  EMPLOYEE_SALARY_CTC: "Employee Salary CTC",
  SR_NO:"SrNo.",
  PREVIOUS_SALARY:"Previous Salary",
  REVISED_SALARY:"Revised Salary",
  PERCENT_CHANGE: "% Change",
  EFFECTIVE_DATE: "Effective Date",
  CREATED_DATE: "Created Date",
  INITIATED_BY: "Initiated By",
  REVISION_HISTORY: "Revision History",
  MONTHLY_SALARY: "Monthly Salary",
  MONTHLY_BASIC_PAY: "Monthly Basic Pay",
  REVISED_BY: "Revised By",
  YEARLY_BASIC_PAY: "Yearly Basic Pay",
  MONTHLY_SPECIAL_ALLOWANCE: "Monthly Special Allowance",
  YEARLY_SPECIAL_ALLOWANCE: "Yearly Special Allowance",
  TOTAL_PAYABLE_DAYS: "  Payable Days",
  REVISE_PREFERENCE: "Revise Salary By Monthly Amount",
  dataEntries: [
    {
      id: 1,
      month: "July 2024",
      duration: "01 July 2024 - 31 July 2024",
      earnings: [{ label: "System Basic", full: "₹ 18,000", actual: "₹ 0" }],
      deductions: [
        { label: "Tax", full: "₹ 5,000", actual: "₹ 0" },
        { label: "Insurance", full: "₹ 2,000", actual: "₹ 0" },
      ],
      netPayable: {
        grossEarnings: "₹ 23,000",
        totalDeductions: "₹ 7,000",
        netAmount: "₹ 16,000",
      },
      adjustments: "₹ 0",
      payments: "₹ 0",
      dueAmount: "₹ 0",
    },
    {
      id: 2,
      month: "August 2024",
      duration: "01 August 2024 - 31 August 2024",
      earnings: [
        { label: "System Basic", full: "₹ 18,000", actual: "₹ 0" },
        { label: "Bonus", full: "₹ 10,000", actual: "₹ 0" },
      ],
      deductions: [
        { label: "Tax", full: "₹ 5,000", actual: "₹ 0" },
        { label: "Insurance", full: "₹ 2,000", actual: "₹ 0" },
      ],
      netPayable: {
        grossEarnings: "₹ 28,000",
        totalDeductions: "₹ 7,000",
        netAmount: "₹ 21,000",
      },
      adjustments: "₹ 0",
      payments: "₹ 0",
      dueAmount: "₹ 0",
    },
    {
      id: 3,
      month: "September 2024",
      duration: "01 September 2024 - 30 September 2024",
      earnings: [
        { label: "System Basic", full: "₹ 18,000", actual: "₹ 0" },
        { label: "Commission", full: "₹ 8,000", actual: "₹ 0" },
      ],
      deductions: [
        { label: "Tax", full: "₹ 5,000", actual: "₹ 0" },
        { label: "Insurance", full: "₹ 2,000", actual: "₹ 0" },
      ],
      netPayable: {
        grossEarnings: "₹ 26,000",
        totalDeductions: "₹ 7,000",
        netAmount: "₹ 19,000",
      },
      adjustments: "₹ 0",
      payments: "₹ 0",
      dueAmount: "₹ 0",
    },
  ],
};