export const AttMode = {
  AttendenceMode: "AttendenceMode",
  ATTENDENCE_MODE: "Attendence Mode ",
  MANUAL_ATTENDENCE: "Manual Attendance",
  SUBMIT: "Submit",
  ATTENDENCEMODE: "Attendance Mode",
};

export const MonthDuration = {
  MonthDuration: "MonthDuration",
  MONTH_CALCULATION: "Month Calculation",
  MONTH_DURATION: "Month Duration",
  MONTHLYSALARY: "How do you Calculate Monthly Salary",
};

export const radioOptions = [
  {
    id: "1",
    value: "Manual",
    label: "Manual Attendance",
    labelSuffix: "Attendance is neutral by default, should be marked manually",
  },
  {
    id: "2",
    value: "Selfie_and_location_based",
    label: "Selfie and Location Based",
    labelSuffix:
      "Staff can mark their own attendance with selfie. Location will be captured automatically",
  },
];

export const MonthCalculation = [
  {
    id: "1",
    value: "Calender Month",
    label: "Calender Month",
    labelSuffix:
      "Ex: March - 31 days, April - 30 Days etc (Per day salary = Salary/No. of days in month)",
  },
  {
    id: "2",
    value: "Every Month 30 Days",
    label: "Every Month 30 Days",
    labelSuffix:
      "Ex: March - 31 days, April - 30 Days etc (Per day salary = Salary/30)",
  },
  {
    id: "3",
    value: "Exclude Weekly Offs",
    label: "Exclude Weekly Offs",
    labelSuffix:
      "Ex: Month with 31 days and 4 weekly-offs will have 27 payable days (Per day salary = Salary/Payable Days)",
  },
];
