import Select from "react-select";
import { FormFeedback, Label } from 'reactstrap';
import { RequiredSign } from "../../Constant/common";

const MultiSelect = ({
  id,
  value,
  onChange,
  options,
  styles,
  touched,
  error,
  name,
  placeholder,
  label,
  handleBlur,
  className,
  isDisabled,
  required,
}) => {
  return (
    <>
      <div className="mb-3">
        {label && (
          <Label htmlFor={name} className="form-label">
            {label}
            {required && <span className="text-danger">{RequiredSign}</span>}
          </Label>
        )}
        <Select
          id={id}
          value={value}
          className={className ? className : "select-border"}
          isMulti={true}
          onChange={onChange}
          options={options}
          styles={styles}
          name={name}
          placeholder={placeholder}
          onBlur={handleBlur}
          isInvalid={!!(touched && error)}
          isDisabled={isDisabled}
        />
        {touched && error ? (
          <FormFeedback className="d-block">{error}</FormFeedback>
        ) : null}
      </div>
    </>
  );
};

export default MultiSelect;
