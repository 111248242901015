export const LateEntryHR = {
  LATEENTRYRULE: "Late Entry Rules",
  BACK: "Back",
  ADD: "Add",
  SHOWINGRESULT: "showing 1 results",
  RULENAME: "Rule Name",
  STAFFASSIGED: "Staff Assigned",
  GRACEPERIOD: "Grace Period",
  MINSALARY: " 15MIN PER MINUTES SALARY AND 60MIN. HALF DAY SALARY DEDUCT",
  EDITSTAFF: "edit staff",
  FIFTEENMIN: "15 min",
};
export const EditLateEntries = {
  SHIFTS: "shifts",
  DEACTIVATEDSTAFF: "deactivatedStaff",
  NOSHIFTASSGINED: "noShiftAssigned",
  EDITRULES: "Edit a Late Entry Rules",
  BACK: "Back",
  RULES: " Rules",
  STAFFSELECTION: "Staff Selection",
  DEDUCTSALARY: " Deduct salary if staff is late by more than",
  LATEBY: " If Staff is Late by",
  DEDUCTIONTYPE: "Deduction Type",
  INCLUDEDLATEFINE: " Include late fine from start time of shift",
  NOLATEFINE: " No Late Fine for 15 mins",
  ADDTIME: "Add time range",
  MiNIMUMOCCURANCE: "Minimum Occurrences (Exclusive)",
  FINETIMES: "Fine will be pardoned up to 3 times",
  TIMES: "times",
  DEDUCTHALFDAY: "Deduct half day salary if staff is late by more than",
  DEDUCTFULLDAY: " Deduct full day salary if staff is late by more than",
  SELECTEDSTAFF: "Selected Staff List Template",
  NOTE: " Note: This setting is only applicable for Non-Hourly Staff with Shift Assigned",
  ASSIGNEDALLSTAFF: "Assigned to all Staff",
  SHIFTTIME: "shift (9:00 AM to 7:00 PM)",
  DEACTIVATED: "Deactived",
  deactivated: " deactived",
  NOSHIFTASSIGNED: " No shift Assigned",
  PLEASEASSIGN: " Please Assign shift to set",
  NEXTASSIGNSTAFF: "Next: Assign Staff",
};
export const data = {
  shifts: [
    { id: "checkbox-primary-1", name: "Kaushik Trivedi" },
    { id: "checkbox-primary-2", name: "Riya Shah" },
    { id: "checkbox-primary-3", name: "Ramesh Kumar" },
    { id: "checkbox-primary-4", name: "Jitendra Sharma" },
    { id: "checkbox-primary-5", name: "Prachi Rathod" },
    { id: "checkbox-primary-6", name: "Devangi Dube" },
    { id: "checkbox-primary-7", name: "Amit Sharma" },
    { id: "checkbox-primary-8", name: "Kishan Kumar" },
    { id: "checkbox-primary-9", name: "Ram Gopal" },
    { id: "checkbox-primary-10", name: "Amit Sharma" },
    { id: "checkbox-primary-11", name: "Kishan Kumar" },
    { id: "checkbox-primary-12", name: "Ram Gopal" },
  ],
  deactivatedStaff: [
    { id: "checkbox-primary-14", name: "Dev Gupta" },
    { id: "checkbox-primary-15", name: "Archana Patel" },
  ],
  noShiftAssigned: [
    { id: "checkbox-primary-17", name: "Dev Gupta" },
    { id: "checkbox-primary-18", name: "Archana Patel" },
  ],
};

export const attendanceStats = [
  { label: "1 x Salary", value: 1 },
  { label: "0.5 x Salary", value: 2 },
  { label: "2 x Salary", value: 3 },
];
