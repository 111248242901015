import React, { Fragment, useEffect, useState } from "react";
import { MoreHorizontal } from "react-feather";
import { Media } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, LI, UL } from "../../AbstractElements";
import { All, CENTER_IDS, clearItem, eventGenerator, getItem, isUserProfile, profileEvent, profileEventMessage, setItem, Undefined } from "../../Constant/common";
import { ChangePassword, GlobalConfig, LogOut, Profile, WelcomeTo } from "../../Constant";
import profileImage from "../../assets/images/avtar/sample.jpg";
import Notification from "../Header/Notification/index";
import "../../../src/hr.css";
const HeaderContain = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const onAdd = () => {
    setOpen(!open);
  };
  const history = useNavigate();
  const centerIDs = JSON.parse(getItem(CENTER_IDS));
  const initialID = centerIDs.includes(All) ? true : ((centerIDs.length > 1) ? true : false );
  const centerName = getItem('center_name');
  const Logout = () => {
    clearItem();
    history("/login");
  };
  const navigateToProfile = () => {
    setItem(isUserProfile, JSON.stringify(true));
    eventGenerator(profileEvent, profileEventMessage);
  }
  useEffect(() => {
      setOpen(false);
  }, [location.pathname]);
  return (
    <Fragment>
      <div className="nav-right col pull-right right-menu">
        <UL
          attrUL={{
            className: `simple-list d-flex flex-row nav-menus ${
              open ? "open" : ""
            }`,
          }}
        >
          {centerName && centerName !== Undefined && (
            <LI>
              <div className="d-flex">
                <span className="pt-2 f-dark fs-large">{WelcomeTo}</span>
                <span className="pt-2 fw-normal mx-1">{centerName}</span>
              </div>
            </LI>
          )}
          <LI attrLI={{ className: "onhover-dropdown" }}>
            <Notification />
          </LI>
          <LI attrLI={{ className: "onhover-dropdown pe-0" }}>
            <Media className="profile-media align-items-center">
              <Image
                attrImage={{
                  className: "rounded-circle",
                  src: profileImage,
                  alt: "profile",
                }}
              />
            </Media>
            <UL
              attrUL={{
                className:
                  "simple-list profile-dropdown onhover-show-div profile-dropdown-size",
              }}
            >
              {initialID && (
                <LI>
                  <Link
                    to={`/globalConfiguration`}
                    className="nav-link fs-8 fw-normal"
                  >
                    {GlobalConfig}
                  </Link>
                </LI>
              )}
              <LI attrLI={{ onClick: navigateToProfile }}>
                <Link
                  to={`/profile`}
                  className={`nav-link fs-8 fw-normal ${initialID && "pt-2"}`}
                >
                  {Profile}
                </Link>
              </LI>
              <LI>
                <Link
                  to={`/changePassword`}
                  className={`nav-link fs-8 fw-normal pt-2`}
                >
                  {ChangePassword}
                </Link>
              </LI>
              <LI attrLI={{ onClick: Logout }}>
                <Link to={`/login`} className=" nav-link fs-8 fw-normal">
                  {LogOut}
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div>
      <div
        className="d-lg-none col mobile-toggle pull-right"
        onClick={() => onAdd()}
      >
        <i>
          {" "}
          <MoreHorizontal />
        </i>
      </div>
    </Fragment>
  );
};
export default HeaderContain;
