export const revenueEnums = {
  revenue: "Revenue",
  duration: "Duration",
  startYear: "Start Year",
  endYear: "End Year",
  yearly: "Yearly",
  monthly: "Monthly",
  center:"Center",
};

export const durationOption = [
  { value: "Monthly", label: "Monthly" },
  { value: "Yearly", label: "Yearly" },
];