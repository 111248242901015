import React, { useEffect, useState } from "react";
import "../../../../src/hr.css";
import { Link, useNavigate } from "react-router-dom";
import { menuItems } from "./Menu";
import { holidayLabel } from "../../../Constant/HR_HolidayTemplate/holidayTemplate";
import BaseModal from "../../../BaseComponents/BaseModal";
import BaseRadioGroup from "../../../BaseComponents/BaseRadio";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import {
  AttMode,
  MonthCalculation,
  MonthDuration,
  radioOptions,
} from "../../../Constant/HR/Attendence_Mode";
import { attendanceDataApi, AttendenceMode, CalculationMethod, salaryDurationApi } from "../../../Api/AttendenceMode";
import { checkStatusCodeSuccess, getItem, settingError } from "../../../Constant/common";
import { useSelectedCenter } from "../../CenterContext";
import { validationMessages } from "../../../Constant/validation";

const Setting = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [attendenceMode, setAttendenceMode] = useState();
  const [monthDuration, setMonthDuration] = useState();
  const [loader, setLoader] = useState(false);
  const userPermission = JSON.parse(getItem("permissions")); 
  const { selectedCenterPayload } = useSelectedCenter();
  const navigate = useNavigate();
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const fetchSalary = () => { 
    salaryDurationApi()
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setMonthDuration(resp?.data?.value);
          formikMonthDuration.setFieldValue("MonthDuration", resp?.data);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
        setModalOpen(false);
      })
  };
  const fetchAttendance = () => { 
    attendanceDataApi()
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setAttendenceMode(resp?.data?.value);
          formikAttendenceMode.setFieldValue("AttendenceMode", resp?.data);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
        setModalOpen(false);
      })
  };

  useEffect(()=>{
    fetchSalary();
    fetchAttendance();
  },[]);

  useEffect(()=>{
    if( selectedCenterPayload.center_id === undefined){
      navigate('/globalConfiguration', { state:{fromSetting: true}, });
      toast.warning(settingError);
    }
  },[navigate, selectedCenterPayload.center_id]);

  const handleMonthDuration = (e) => {
    setMonthDuration(e.target.value);
    formikMonthDuration.setFieldValue(
      MonthDuration.MonthDuration,
      e.target.value
    );
  };

  const handleRadioChange = (e) => {
    setAttendenceMode(e.target.value);
    formikAttendenceMode.setFieldValue(AttMode.AttendenceMode, e.target.value);
  };

  const handleMenuItemClick = (item) => {
    if (item?.openModal) {
      setSelectedItem(item);
      toggleModal();
    }
  };
  
const settingPermissions = userPermission?.filter(permission => permission.parent_module_name === "Setting");

const filteredMenuItems = menuItems.filter(item => {
  return settingPermissions.some(permission => permission.module_name === item.label);
});

  const formikAttendenceMode = useFormik({
    enableReinitialize: true,
    initialValues: {
      AttendenceMode: attendenceMode,
    },
    validationSchema: Yup.object({
      AttendenceMode: Yup.string().required(
        validationMessages.required(AttMode.ATTENDENCE_MODE)
      ),
    }),
    onSubmit: (values) => {
      setLoader(true);
      const payload = {
        attendance_mode: values.AttendenceMode,
      };
      AttendenceMode(payload)
        .then((resp) => {
          if (
            resp?.statusCode === StatusCodes.ACCEPTED ||
            resp?.statusCode === StatusCodes.OK ||
            resp?.statusCode === StatusCodes.CREATED
          ) {
            toast.success(resp?.message);
            fetchAttendance();
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
          formikAttendenceMode.resetForm();
          setAttendenceMode(null);
          setModalOpen(false);
        });
    },
  });

  const formikMonthDuration = useFormik({
    enableReinitialize: true,
    initialValues: {
      MonthDuration: monthDuration,
    },
    validationSchema: Yup.object({
      MonthDuration: Yup.string().required(
        validationMessages.required(MonthDuration.MONTH_CALCULATION)
      ),
    }),
    onSubmit: (values) => {
      setLoader(true);
      const payload = { salary_calculation_method: values.MonthDuration };
      CalculationMethod(payload)
      .then((resp) => {
          if (
            resp?.statusCode === StatusCodes.ACCEPTED ||
            resp?.statusCode === StatusCodes.OK ||
            resp?.statusCode === StatusCodes.CREATED
          ) {
            toast.success(resp?.message);
            fetchSalary();
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setLoader(false);
          formikMonthDuration.resetForm();
          setMonthDuration(null);
          setModalOpen(false);
        });
    },
  });

  return (
    <div>
      <div className="container mt-5">
        <div className="d-flex w-25 justify-content-center align-items-center mb-3 mx-4">
          <h5 className="mb-0">{holidayLabel.businessSetting}</h5>
        </div>
        <div className="d-flex flex-wrap justify-content-center gap-4">
          {filteredMenuItems?.map((item) => {
            return (
              <div
                onClick={() => handleMenuItemClick(item)}
                className="d-flex width-fixed bg-white p-1 rounded mx-2 flex-column align-items-center justify-content-center text-center"
              >
                {item.link ? (
                  <>
                  <Link
                    to={item.link}
                    className="text-decoration-none p-0 text-dark w-100 d-flex flex-column justify-content-center align-items-center"
                    >
                    <span>{item?.icon}</span>
                    <span>{item.label}</span>
                  </Link>
                  </>
                ) : (
                  <>
                    <span>{item?.icon}</span>
                    <span>{item.label}</span>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* commented for future use */}
      {/* <div className="card p-3 mt-5 justify-content-center">
          <div>
            <div className="card-body card-body-custom p-2">
              {menuItems?.map((item, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center border-bottom py-2"
                  onClick={() => handleMenuItemClick(item)}
                >
                  {item.link && !item.openModal ? (
                    <Link
                      to={item.link}
                      className="text-decoration-none p-0 text-dark w-100 d-flex justify-content-between align-items-center"
                    >
                      <span className="text-dark">{item.label}</span>
                      <span>{item.templates}</span>
                    </Link>
                  ) : (
                    <>
                      <span className="text-dark">{item.label}</span>
                      <span>{item.templates}</span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div> */}
      {selectedItem && selectedItem.openModal && (
        <BaseModal
          isOpen={modalOpen}
          title={
            selectedItem.label === AttMode.ATTENDENCEMODE
              ? AttMode.MANUAL_ATTENDENCE
              : MonthDuration.MONTH_CALCULATION
          }
          toggler={() => setModalOpen(false)}
          submit={
            selectedItem.label === AttMode.ATTENDENCEMODE
              ? formikAttendenceMode.handleSubmit
              : formikMonthDuration.handleSubmit
          }
          submitText={
            selectedItem.label === AttMode.ATTENDENCEMODE
              ? AttMode.SUBMIT
              : AttMode.SUBMIT
          }
          loader={loader}
          isDisabled={loader}
        >
          <div>
            {selectedItem.label === AttMode.ATTENDENCEMODE && (
              <ul className="form-check list-group list-group-flush p-0">
                <li className="list-group-item py-0 px-3">
                  <BaseRadioGroup
                    name={AttMode.AttendenceMode}
                    selectedValue={attendenceMode}
                    options={radioOptions}
                    optionClassName="w-100 mb-4 fs-5 "
                    classNameLabel="w-100 mb-2 fs-8 d-flex justify-content-start"
                    onChange={handleRadioChange}
                    value={formikAttendenceMode.values.AttendenceMode}
                    touched={formikAttendenceMode.touched.AttendenceMode}
                    error={formikAttendenceMode.errors.AttendenceMode}
                    handleBlur={formikAttendenceMode.handleBlur}
                    classNameSuffix="d-block text-muted pt-1"
                  />
                </li>
              </ul>
            )}
            {selectedItem.label === MonthDuration.MONTH_DURATION && (
              <div>
                <h6 className="mb-0 text-muted f-w-600">
                  {MonthDuration.MONTHLYSALARY}
                </h6>
                <ul className="form-check list-group list-group-flush ">
                  <li className="list-group-item py-0 px-3">
                    <BaseRadioGroup
                      name={MonthDuration.MonthDuration}
                      selectedValue={monthDuration}
                      options={MonthCalculation}
                      optionClassName="w-100 mb-4"
                      classNameLabel="w-100 mb-2 d-flex justify-content-start"
                      onChange={handleMonthDuration}
                      value={formikMonthDuration.values.MonthDuration}
                      touched={formikMonthDuration.touched.MonthDuration}
                      error={formikMonthDuration.errors.MonthDuration}
                      handleBlur={formikMonthDuration.handleBlur}
                      classNameSuffix="d-block text-muted pt-1 fs-8 "
                    />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </BaseModal>
      )}
    </div>
  );
};

export default Setting;