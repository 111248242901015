import { ADDROLEPERMISSION, DELETEROLEPERMISSION, EDITROLEPERMISSION, LISTOFMASTERMODULE, LISTOFROLEPERMISSION, LISTOFUSERROLEPERMISSION, VIEWROLEPERMISSION } from "./apiRoutes";
import { authData } from "./Service";

export const addRoleApi = async (data) => {
    const response = await authData.post(ADDROLEPERMISSION, data);
    return response?.data;
};
export const editRoleApi = async (id, data) => {
    const response = await authData.put(`${EDITROLEPERMISSION}${id}`, data);
    return response?.data;
};
export const listOfRoleApi = async (data) => {
    const response = await authData.post(LISTOFROLEPERMISSION, data);
    return response?.data;
};
export const deleteRoleApi = async (id, data) => {
    const response = await authData.put(`${DELETEROLEPERMISSION}${id}`, data);
    return response?.data;
};
export const listOfRolePermissionApi = async (data) => {
    const response = await authData.post(LISTOFMASTERMODULE, data);
    return response?.data;
};
export const viewRolePermissionApi = async (id) => {
    const response = await authData.get(`${VIEWROLEPERMISSION}${id}`);
    return response?.data;
};
export const userRolePermissionApi = async (data) => {
    const response = await authData.post(`${LISTOFUSERROLEPERMISSION}`, data);
    return response?.data;
};