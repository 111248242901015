import { EDITLEAVEBALANCE, LEAVE_BALANCE_HISTORY, STAFFLEAVEBALANCE } from "./apiRoutes";
import { authData } from "./Service";

export const staffLeaveBalanceApi = async (id) => {
  const response = await authData.get(`${STAFFLEAVEBALANCE}${id}`);
  return response?.data;
};
export const editLeaveBalanceApi = async (id, data) => {
  const response = await authData.post(`${EDITLEAVEBALANCE}${id}`, data);
  return response?.data;
};
export const leaveBalanceHistoryApi = async (data) => {
  const response = await authData.post(`${LEAVE_BALANCE_HISTORY}`, data);
  return response?.data;
};