import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  deleteBatch,
  editBatchApi,
  exportBatchData,
  importBatchData,
  viewBatchDetailsApi,
  viewBatchFromIdApi,
} from "../../Api/BatchApi";
import {
  batchallocationLabel,
  batchallocationValidation,
  BatchEditTitle,
  DataError,
  editBatchLable,
} from "../../Constant/BatchAllocation/batchallocation";
import { checkStatusCodeSuccess, csv, deleteUploadedfile, handleArrayResponse, rendorBackButton, spaceSpliter } from "../../Constant/common";
import Spinner from "../../BaseComponents/BaseLoader";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import TableContainer from "../../BaseComponents/BaseTable";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import BaseButton from "../../BaseComponents/BaseButton";
import BaseModal from "../../BaseComponents/BaseModal";
import { useFormik } from "formik";
import * as yup from "yup";
import { BaseExportURL } from "../../Api/Service";
import {
  InputPlaceHolder,
  validationMessages,
} from "../../Constant/validation";
import { RiArrowDownLine, RiArrowUpLine, RiSearchLine } from "react-icons/ri";
import { Duration, notFound } from "../../Constant";
import BaseInput from "../../BaseComponents/BaseInput";
import {
  EndDate,
  Note,
  sampleFile,
  StartDate,
} from "../../Constant/Attendance";
import CustomTooltip from "../../BaseComponents/BaseTooltip";

let totalNumber = 0;
const EditBatch = () => {
  document.title = BatchEditTitle;
  const FileRef = useRef();
  const navigate = useNavigate();
  const [batchData, setBatchData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { batchId } = useParams();
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState();
  const [checkedItems, setCheckedItems] = useState({});
  const [isCheckedAll, setIsCheckedAll] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [exportData, setExportData] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [customPageSize, setCustomPageSize] = useState(5);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const history = useNavigate();
  const [error, setError] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [viewBatchData, setViewBatchData] = useState([]);
  const [checkId, setCheckId] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchBatchData, setSearchBatchData] = useState([]);

  const handleViewBatch = () => {
    setLoader(true);
    viewBatchFromIdApi(batchId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          setViewBatchData(resp?.data);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleViewDetails = () => {
    setLoader(true);
    const payload = {
      condition: {
        batch_id: batchId,
      },
      order: [columnName, sortOrder],
      pageSize: customPageSize,
      pageNumber: currentPage,
    };
    viewBatchDetailsApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          setBatchData(resp?.data);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
          totalNumber = resp?.data?.numberOfRows;
          if (searchValue) {
            handleSearchViewDetails();
          }
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSearchViewDetails = () => {
    setLoader(true);
    const payload = {
      condition: {
        batch_id: batchId,
      },
      order: [columnName, sortOrder],
      pageSize: customPageSize,
      pageNumber: currentPage,
      search: searchValue,
    };
    viewBatchDetailsApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          setSearchBatchData(resp?.data);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
          totalNumber = resp?.data?.numberOfRows;
        } else {
          toast.error(resp?.message);
          setSearchBatchData([]);
        }
      })
      .catch((err) => {
        setSearchBatchData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const handleCheckAll = (currentPage) => {
    const currentCheckedAll = isCheckedAll[currentPage] || false;
    setIsCheckedAll({
      ...isCheckedAll,
      [currentPage]: !currentCheckedAll,
    });

    const updatedCheckedItems = currentCheckedAll
      ? []
      : batchData?.listOfBatch?.map((batch) => batch.candidate_id);

    setCheckedItems({
      ...checkedItems,
      [currentPage]: updatedCheckedItems,
    });
  };

  const handleCheckItem = (id, currentPage) => {
    const currentCheckedItems = checkedItems[currentPage] || [];
    const isAlreadyChecked = currentCheckedItems?.includes(id);
    const updatedCheckedItems = isAlreadyChecked
      ? currentCheckedItems?.filter((itemId) => itemId !== id)
      : [...currentCheckedItems, id];

    setCheckedItems({
      ...checkedItems,
      [currentPage]: updatedCheckedItems,
    });

    setIsCheckedAll({
      ...isCheckedAll,
      [currentPage]: updatedCheckedItems?.length === totalNumber,
    });
  };

  useEffect(() => {
    const allCheckedIds = Object?.values(checkedItems)?.flat();
    setCheckId(allCheckedIds);
  }, [checkedItems]);

  useEffect(() => {
    handleViewBatch();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearchViewDetails();
    } else {
      handleViewDetails();
    }
  }, [currentPage, customPageSize, columnName, sortOrder, searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setCurrentPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isCheckedAll[currentPage] && batchData) {
      const allCandidateIds = batchData?.listOfBatch?.map(
        (batch) => batch.candidate_id
      );
      setCheckedItems({
        ...checkedItems,
        [currentPage]: allCandidateIds,
      });
    }
  }, [isCheckedAll, batchData, currentPage]);

  useEffect(() => {
    if (!Boolean(isCheckedAll[currentPage])) {
      setIsCheckedAll({ ...isCheckedAll, [currentPage]: false });
    }
  }, [currentPage]);

  const handleDelete = () => {
    let pageNumber = currentPage;
    if (checkedItems[currentPage]?.length === totalNumber) {
      pageNumber -= 1;
    }
    setBtnLoader(true);
    const payload = {
      candidate_id: checkId,
    };
    deleteBatch(batchId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setBatchData(resp?.data);
          toast.success(resp?.message);
          setCheckedItems([]);
          setIsCheckedAll(false);
          if (checkId?.length === totalRecords) {
            history("/batchlist");
          } else {
            if (pageNumber === currentPage) {
              handleViewDetails();
            } else {
              setCurrentPage(pageNumber);
            }
          }
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setBtnLoader(false);
        setCheckedItems([]);
        setIsCheckedAll(false);
        setShowModal(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <BaseCheckbox
            id="checkBoxAll"
            checked={isCheckedAll[currentPage] || false}
            onChange={() => handleCheckAll(currentPage)}
            className="custom-checkbox-class"
            labelClassName="custom-label-class"
          />
        ),
        cell: (cell) => (
          <BaseCheckbox
            id={`checkbox${cell.row.original.candidate_id}`}
            checked={
              checkedItems[currentPage]?.includes(
                cell.row.original.candidate_id
              ) || false
            }
            onChange={() =>
              handleCheckItem(cell.row.original.candidate_id, currentPage)
            }
            className="custom-checkbox-class taskCheckBox"
            labelClassName="custom-label-class"
          />
        ),
        enableSorting: true,
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: editBatchLable.can_id,
        accessorKey: editBatchLable.can_idKey,
        enableColumnFilter: false,
      },
      {
        header: editBatchLable.emp_id,
        accessorKey: editBatchLable.emp_idKey,
        enableColumnFilter: false,
      },
      {
        header: editBatchLable.candidateName,
        accessorKey: editBatchLable.candidateNameKey,
        enableColumnFilter: false,
      },
      {
        header: editBatchLable.contactNumber,
        accessorKey: editBatchLable.contactNoKey,
        enableColumnFilter: false,
      },
    ],
    [checkedItems, isCheckedAll, customPageSize]
  );

  const toggleImportModal = () => {
    setShowImportModal(!showImportModal);
  };

  const handleAddMOre = (batchId) => {
    history(`/addcandidate/${batchId}`);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchvalue = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      importData: "",
    },
    validationSchema: yup.object({
      importData: yup
        .mixed()
        .required(validationMessages.required(editBatchLable.file))
        .test(editBatchLable.fileType, editBatchLable.csvValidate, (value) => {
          if (value) {
            return value.type === "text/csv" || value.name.endsWith(".csv");
          }
          return false;
        }),
    }),

    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      const formData = new FormData();
      formData.append("file", values.importData);
      importBatchData(formData)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp.message);
            formik.setFieldValue(resp?.data);
            setShowImportModal(false);
            handleViewDetails();
          } else {
            toast.error(resp?.message);
            setError(resp?.message);
            setErrorModal(true);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
          if (
            Array.isArray(err?.response?.data?.message) &&
            err?.response?.data?.message?.length > 0
          ) {
            setError(err?.response?.data?.message);
            setErrorModal(true);
          }
        })
        .finally(() => {
          setBtnLoader(false);
          FileRef.current.value = "";
          resetForm();
        });
    },
  });

  const editBatchForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      batchName: viewBatchData.batch_id || null,
      qp_code: viewBatchData.qp_code || null,
      startDate: viewBatchData.start_date || null,
      endDate: viewBatchData.end_date || null,
      startTime: spaceSpliter(viewBatchData?.batch_start_time) || null,
      endTime: spaceSpliter(viewBatchData?.batch_end_time) || null,
      duration: viewBatchData.batch_duration || null,
      no_of_student: viewBatchData.no_of_student || null,
      extended_date: viewBatchData.extended_date || null,
      company_name: viewBatchData.company_name || null,
      address: viewBatchData.company_address || null,
    },
    validationSchema: yup.object({
      batchName: yup
        .string()
        .required(
          validationMessages.required(
            batchallocationValidation.batchNameValidation
          )
        ),
      startDate: yup.date().required(validationMessages.required(StartDate)),
      endDate: yup
        .date()
        .required(validationMessages.required(EndDate))
        .test(
          batchallocationValidation.isGreaterLabel,
          validationMessages.greaterThan(StartDate, EndDate),
          function (value) {
            const { startDate } = this.parent;
            return (
              !startDate || !value || new Date(value) >= new Date(startDate)
            );
          }
        ),
      startTime: yup
        .string()
        .required(validationMessages.required(batchallocationLabel.startTime)),
      endTime: yup
        .string()
        .required(validationMessages.required(batchallocationLabel.endTime))
        .test(
          batchallocationValidation.isGreaterLabel,
          validationMessages.greaterThan(
            batchallocationLabel.startTime,
            batchallocationLabel.endTime
          ),
          function (value) {
            const { startTime } = this.parent;
            return !startTime || !value || value >= startTime;
          }
        ),
      duration: yup
        .number()
        .required(validationMessages.required(Duration))
        .min(1, validationMessages.greaterThan(0, Duration)),
      no_of_student: yup
        .number()
        .required(validationMessages.required(batchallocationLabel.noOfStudent))
        .min(
          1,
          validationMessages.greaterThan(0, batchallocationLabel.noOfStudent)
        ),
    }),
    onSubmit: (values) => {
      const payload = {
        batch_id: values.batchName,
        qp_code: values.qp_code,
        batch_start_time: values.startTime ? values.startTime : null,
        batch_end_time: values.endTime ? values.endTime : null,
        batch_duration: values.duration,
        start_date: values.startDate ? values.startDate : null,
        end_date: values.endDate ? values.endDate : null,
        no_of_student: values.no_of_student,
        extended_date: values.extended_date ? values.extended_date : null,
        company_name: values.company_name,
        company_address: values.address,
      };
      setBtnLoader(true);
      editBatchApi(batchId, payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp?.message);
            setModalOpen(!modalOpen);
            handleViewBatch();
            handleViewDetails();
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          handleArrayResponse(err);
        })
        .finally(() => {
          setBtnLoader(false);
        });
    },
  });

  const hnaldeExportData = () => {
    setLoader(true);
    const payload = {
      candidate_id: checkId,
    };
    exportBatchData(batchId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setExportData(resp?.data);
          const filename = exportData;
          const url = `${BaseExportURL}${resp?.data}`;
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          toast.success(resp.success);
          if (url) {
            deleteUploadedfile(filename, true, csv);
          }
        } else {
          toast.error(resp.message);
        }
      })
      .catch((error) => {
        handleArrayResponse(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <div>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="px-3 mb-1 d-flex justify-content-between align-items-center">
        <h5 className="f-w-600">
          {" "}
          {viewBatchData && viewBatchData?.batch_id}
          &nbsp;-&nbsp;
          {batchallocationLabel.editBatch}
        </h5>
        <div className="flex">
          <BaseButton
            className="mx-2"
            color="success"
            onClick={() => setModalOpen(!modalOpen)}
          >
            {editBatchLable.editBatchTItle}
          </BaseButton>
          {rendorBackButton(() => navigate("/batchlist"))}
        </div>
      </div>
      <BaseModal
        isOpen={modalOpen}
        toggler={() => setModalOpen(!modalOpen)}
        title={`Edit Details - ${viewBatchData?.batch_id}`}
        submit={editBatchForm.handleSubmit}
        submitText="Update"
        size="lg"
        loader={btnLoader}
        disabled={btnLoader}
      >
        <div className="row">
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.batchName}
              name="batchName"
              type="text"
              placeholder={InputPlaceHolder(batchallocationLabel.batchName)}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              value={editBatchForm.values.batchName}
              touched={editBatchForm.touched.batchName}
              error={editBatchForm.errors.batchName}
              passwordToggle={false}
              required={true}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.qpCode}
              name="qp_code"
              type="text"
              readOnly
              disabled={true}
              value={editBatchForm.values.qp_code}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.qp_code}
              error={editBatchForm.errors.qp_code}
              required={true}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.startDate}
              name="startDate"
              type="date"
              value={editBatchForm.values.startDate}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.startDate}
              error={editBatchForm.errors.startDate}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.endDate}
              name="endDate"
              type="date"
              value={editBatchForm.values.endDate}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.endDate}
              error={editBatchForm.errors.endDate}
              required={true}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.startTime}
              name="startTime"
              type="time"
              step="0"
              value={editBatchForm.values.startTime}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.startTime}
              error={editBatchForm.errors.startTime}
              required={true}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.endTime}
              name="endTime"
              type="time"
              step="0"
              value={editBatchForm.values.endTime}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.endTime}
              error={editBatchForm.errors.endTime}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.duration}
              name="duration"
              type="number"
              placeholder={InputPlaceHolder(batchallocationLabel.durationKey)}
              value={editBatchForm.values.duration}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.duration}
              error={editBatchForm.errors.duration}
              required={true}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.noOfStudent}
              name="no_of_student"
              type="number"
              placeholder={InputPlaceHolder(batchallocationLabel.noOfStudent)}
              value={editBatchForm.values.no_of_student}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.no_of_student}
              error={editBatchForm.errors.no_of_student}
              required={true}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.extended_date}
              name="extended_date"
              type="date"
              placeholder={InputPlaceHolder(batchallocationLabel.extended_date)}
              value={editBatchForm.values.extended_date}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
              touched={editBatchForm.touched.extended_date}
              error={editBatchForm.errors.extended_date}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.companyName}
              name="company_name"
              type="text"
              placeholder={InputPlaceHolder(batchallocationLabel.companyName)}
              value={editBatchForm.values.company_name}
              error={editBatchForm.errors.company_name}
              touched={editBatchForm.touched.company_name}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
            />
          </div>
          <div className="col-4">
            <BaseInput
              label={batchallocationLabel.address}
              name="address"
              type="textarea"
              placeholder={InputPlaceHolder(batchallocationLabel.address)}
              value={editBatchForm.values.address}
              error={editBatchForm.errors.address}
              touched={editBatchForm.touched.address}
              handleChange={editBatchForm.handleChange}
              handleBlur={editBatchForm.handleBlur}
            />
          </div>
        </div>
      </BaseModal>
      <Card className="mx-3">
        <CardBody>
          <div className="row justify-content-between">
            <div className="d-flex col-sm-12 col-md-auto mb-2">
              <div className="mx-2">
                <BaseButton
                  color="warning"
                  className="text-white"
                  onClick={() => toggleImportModal()}
                >
                  <RiArrowDownLine size={20} /> {editBatchLable.import}
                </BaseButton>
              </div>
              <div className="mx-2" id="exportButton">
                <BaseButton
                  color="success"
                  onClick={hnaldeExportData}
                  disabled={checkId?.length === 0}
                >
                  <RiArrowUpLine size={20} /> {editBatchLable.export}
                </BaseButton>
                <CustomTooltip
                  target="exportButton"
                  text={editBatchLable.exportTitle}
                  disabled={checkId?.length > 1}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-auto mb-2 d-flex">
              <div className="mx-2">
                <BaseButton onClick={() => handleAddMOre(batchId)}>
                  {editBatchLable.addMore}
                </BaseButton>
              </div>
              {checkId?.length > 0 && (
                <div className="mx-2">
                  <BaseButton color="danger" onClick={toggleModal}>
                    {editBatchLable.delete}
                  </BaseButton>
                </div>
              )}
            </div>
          </div>
          <div className="pt-0">
            <div>
              {batchData?.listOfBatch?.length > 0 && (
                <div key={batchData?.listOfBatch}>
                  <TableContainer
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    totalNumberOfRows={totalNumberOfRows}
                    columns={columns}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    fetchData={handleFetchData}
                    data={
                      searchValue
                        ? searchBatchData?.listOfBatch
                        : batchData?.listOfBatch || []
                    }
                    isGlobalFilter={true}
                    shouldLineBreak={false}
                    manualPagination={true}
                    manualFiltering={true}
                    customPageSize={customPageSize}
                    setCustomPageSize={setCustomPageSize}
                    onSearch={handleSearchvalue}
                    tableClass="table table-bordered text-center"
                    SearchPlaceholder={batchallocationLabel.searchBar}
                    fetchSortingData={handleFetchSorting}
                  />
                </div>
              )}
              {!loader && batchData?.length === 0 && (
                <div className="py-4 text-center">
                  <div>
                    <RiSearchLine className="fs-2" />
                  </div>
                  <div className="mt-4">
                    <h5>{notFound.dataNotFound}</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <BaseModal
        isOpen={showImportModal}
        toggler={toggleImportModal}
        title={editBatchLable.importFile}
        submitText={editBatchLable.upoladImage}
        disabled={btnLoader}
        loader={btnLoader}
        submit={formik.handleSubmit}
      >
        <div className="row col-12 px-0">
          {/* ReactStrap Input Is not Working so that's why i have use Input */}
          <div className="d-inline-flex align-items-center mb-3">
            {Note}:&nbsp;
            <a href="/Batch Sample.csv" download="Batch Sample.csv">
              {sampleFile}
            </a>
          </div>
          <input
            name="importData"
            type="file"
            ref={FileRef}
            accept=".csv, application/vnd.ms-excel"
            onChange={(e) => {
              formik.setFieldValue(
                editBatchLable.importData,
                e.currentTarget.files[0]
              );
            }}
            onBlur={formik.handleBlur}
            className={
              formik.touched.importData && formik.errors.importData
                ? "is-invalid"
                : ""
            }
          />
          {formik.touched.importData && formik.errors.importData ? (
            <div className="invalid-feedback">{formik.errors.importData}</div>
          ) : null}
        </div>
      </BaseModal>

      <BaseModal
        isOpen={showModal}
        toggler={toggleModal}
        title={editBatchLable.deleteTitleLabel}
        submitText={editBatchLable.yesLabel}
        submit={() => {
          handleDelete();
        }}
        loader={btnLoader}
        disabled={btnLoader}
      >
        <ul className="list-group list-group-flush">
          <li className="list-group-item">{editBatchLable.deleteBody}</li>
        </ul>
        {checkId?.length === totalRecords && (
          <li className="list-group-item text-danger pt-2 mx-3">
            {batchallocationLabel.NoteMessageBatchDelete}
            <span className="font-weight-bold">{viewBatchData?.batch_id}</span>
          </li>
        )}
      </BaseModal>

      <BaseModal
        isOpen={errorModal}
        toggler={() => setErrorModal(false)}
        title={DataError}
        hasSubmitButton={false}
      >
        <ul className="list-group list-group-flush">
          {error &&
            error?.map((errorMessage, index) => (
              <li key={index} className="list-group-item text-danger">
                {index + 1}. {errorMessage}
              </li>
            ))}
        </ul>
      </BaseModal>
    </div>
  );
};

export default EditBatch;
