import React from "react";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseButton from "../../BaseComponents/BaseButton";
import { formFields, hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { digitRegex } from "../../Constant/validation";
import { handleResponse } from "../../Constant/common";
import { RiCheckLine, RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import { Edit } from "../../Constant";

const BankDetails = ({
  staffForm,
  viewBankMode,
  handleBankEdit,
  handleBankDiscard,
  editBankMode,
  staffData,
  btnLoader,
}) => {
  return (
    <>
      {viewBankMode ? (
        <div id="bank-info" className="info-section">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.BANK_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              <BaseButton
                onClick={handleBankEdit}
                title={Edit}
                className="mx-2 btn-pill btn-light"
                children={
                  <RiEditBoxLine
                    size={20}
                  />
                }
              />
            </div>
          </div>
          <div className="border-bottom mb-2 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.NAME_OF_BANK}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffBankDetails?.name_of_bank ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.IFSC_CODE}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffBankDetails?.IFSC_code ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">{hrEnums.ACCOUNT_NO}</span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffBankDetails?.account_no ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.ACCOUNT_HOLDER_NAME}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffBankDetails?.account_holder_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="bank-info" className="info-section">
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.BANK_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              {editBankMode && (
                <>
                  <BaseButton
                    onClick={handleBankDiscard}
                    className="btn btn-pill btn-light"
                    children={<RiCloseLine
                      size={20}
                    />}
                  />
                  <BaseButton
                    className="mx-2 ms-1"
                    color="light"
                    type="submit"
                    loader={btnLoader}
                    children={<RiCheckLine size={20} />}
                  />
                </>
              )}
            </div>
          </div>
          <div className="border-bottom mb-4 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-lg-3">
              <BaseInput
                label={hrEnums.NAME_OF_BANK}
                name={formFields.bank_name}
                type="text"
                placeholder={PlaceHolderFormat(hrEnums.NAME_OF_BANK)}
                value={staffForm.values.bank_name}
                touched={staffForm.touched.bank_name}
                error={staffForm.errors.bank_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.ifsc}
                type="text"
                label={hrEnums.IFSC_CODE}
                placeholder={PlaceHolderFormat(hrEnums.IFSC_CODE)}
                value={staffForm.values.ifsc}
                touched={staffForm.touched.ifsc}
                error={staffForm.errors.ifsc}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.account_number}
                type="text"
                label={hrEnums.ACCOUNT_NO}
                placeholder={PlaceHolderFormat(hrEnums.ACCOUNT_NO)}
                value={staffForm.values.account_number}
                touched={staffForm.touched.account_number}
                error={staffForm.errors.account_number}
                handleBlur={staffForm.handleBlur}
                maxLength={17}
                handleChange={(e) => {
                  if (digitRegex.test(e?.target?.value)) {
                    staffForm.handleChange(e);
                  }
                }}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.account_holder_name}
                type="text"
                label={hrEnums.ACCOUNT_HOLDER_NAME}
                placeholder={PlaceHolderFormat(hrEnums.ACCOUNT_HOLDER_NAME)}
                value={staffForm.values.account_holder_name}
                touched={staffForm.touched.account_holder_name}
                error={staffForm.errors.account_holder_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BankDetails;
