import React from "react";
import BaseInput from "../../BaseComponents/BaseInput";
import { formFields, hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { digitRegex, SelectPlaceHolder } from "../../Constant/validation";
import BaseSelect from "../../BaseComponents/BaseSelect";
import {
  ContactNo,
  Designation,
  Discard,
  Edit,
  Email,
  Update,
} from "../../Constant";
import { handleResponse, RequiredSign } from "../../Constant/common";
import { RiCheckLine, RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import BaseButton from "../../BaseComponents/BaseButton";
import MultiSelect from "../../BaseComponents/BaseSelect/MultiSelect";

const ProfileDetails = ({
  staffForm,
  viewProfileMode,
  handleProfileEdit,
  handleProfileDiscard,
  editProfileMode,
  positionData,
  roleData,
  centerData,
  fetchReportingManager,
  reportingManager,
  currentStaffId,
  lastStaffId,
  btnLoader,
  staffData,
  handleMultiCenter,
  selectedMultiCenter,
  isProfile,
  selectedCenterIds,
  isEditMode,
}) => {
  return (
    <>
      {viewProfileMode ? (
        <div id="profile-info" className="info-section">
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.PROFILE_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              <BaseButton
                title={Edit}
                className="btn btn-light rounded-pill px-0"
                onClick={handleProfileEdit}
                children={<RiEditBoxLine size={20} />}
              />
            </div>
          </div>
          <div className="border-bottom mb-2 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="fw-normal fs-8 text-secondary">
                  {hrEnums.STAFF_NAME}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.name ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.ID}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staffGeneralInformation?.staff_id ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {Designation}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.position?.position_name ??
                    handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.ROLE}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.roleModel?.role_name ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.STAFF_TYPE}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.staff_type ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {Email}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.email ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {ContactNo}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.contact_no ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.CENTER}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.center?.map((item, index) => (
                    <span key={index}>
                      {item.center_name}
                      {index < staffData?.center?.length - 1 && ", "}
                    </span>
                  )) ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.PRIMARY_CENTER}
                  <span className="text-danger">{RequiredSign}</span>
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.primaryCenter?.center_name ?? handleResponse.nullData}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div>
                <span className="text-secondary fw-normal fs-8">
                  {hrEnums.REPORTING_MANAGER}
                </span>
                <br />
                <span className="f-w-600">
                  {staffData?.reportingTo?.name ?? handleResponse.nullData}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="profile-info" className="info-section">
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h5 className="f-w-600">{hrEnums.PROFILE_INFO}</h5>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              {editProfileMode && (
                <>
                  <BaseButton
                    title={Discard}
                    className="btn btn-light"
                    onClick={handleProfileDiscard}
                    children={<RiCloseLine size={20} />}
                  />
                  <BaseButton
                    className="mx-2 ms-1 btn btn-light"
                    color="success"
                    type="submit"
                    title={Update}
                    loader={btnLoader}
                    children={<RiCheckLine size={20} />}
                  />
                </>
              )}
            </div>
          </div>
          <div className="border-bottom mb-4 mt-2 mr-4"></div>
          <div className="row custom-section">
            <div className="col-lg-3">
              <BaseInput
                label={
                  <>
                    {hrEnums.FIRST_NAME}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                name={formFields.first_name}
                type="text"
                placeholder={PlaceHolderFormat(hrEnums.FIRST_NAME)}
                value={staffForm.values.first_name}
                touched={staffForm.touched.first_name}
                error={staffForm.errors.first_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.middle_name}
                type="text"
                label={<>{hrEnums.MIDDLE_NAME}</>}
                placeholder={PlaceHolderFormat(hrEnums.MIDDLE_NAME)}
                value={staffForm.values.middle_name}
                touched={staffForm.touched.middle_name}
                error={staffForm.errors.middle_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.last_name}
                type="text"
                label={
                  <>
                    {hrEnums.LAST_NAME}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                placeholder={PlaceHolderFormat(hrEnums.LAST_NAME)}
                value={staffForm.values.last_name}
                touched={staffForm.touched.last_name}
                error={staffForm.errors.last_name}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.designation}
                name={formFields.designation}
                label={
                  <>
                    {Designation}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={positionData}
                placeholder={SelectPlaceHolder(Designation)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() => staffForm.setFieldTouched(Designation, true)}
                value={staffForm.values.designation}
                touched={staffForm.touched.designation}
                error={staffForm.errors.designation}
                isDisabled={isProfile}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.role}
                name={formFields.role}
                label={
                  <>
                    {hrEnums.ROLE}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={roleData}
                placeholder={SelectPlaceHolder(hrEnums.ROLE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() => staffForm.setFieldTouched(hrEnums.ROLE, true)}
                value={staffForm.values.role}
                touched={staffForm.touched.role}
                error={staffForm.errors.role}
                isDisabled={isProfile}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.staff_type}
                name={formFields.staff_type}
                label={
                  <>
                    {hrEnums.STAFF_TYPE}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={hrEnums.STAFF_TYPE_OP}
                placeholder={SelectPlaceHolder(hrEnums.STAFF_TYPE)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.STAFF_TYPE, true)
                }
                value={staffForm.values.staff_type}
                touched={staffForm.touched.staff_type}
                error={staffForm.errors.staff_type}
                isDisabled={true}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                label={
                  <>
                    {Email}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                name={formFields.email}
                type="text"
                placeholder={PlaceHolderFormat(Email)}
                value={staffForm.values.email}
                touched={staffForm.touched.email}
                error={staffForm.errors.email}
                handleBlur={staffForm.handleBlur}
                handleChange={staffForm.handleChange}
                disabled={isProfile|| isEditMode}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.contact_no}
                type="text"
                label={
                  <>
                    {ContactNo}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                placeholder={PlaceHolderFormat(ContactNo)}
                value={staffForm.values.contact_no}
                touched={staffForm.touched.contact_no}
                error={staffForm.errors.contact_no}
                handleBlur={staffForm.handleBlur}
                handleChange={(e) => {
                  if (digitRegex.test(e?.target?.value)) {
                    staffForm.handleChange(e);
                  }
                }}
                maxLength={10}
              />
            </div>
            <div className="col-lg-3">
              <MultiSelect
                id={formFields.center}
                name={formFields.center}
                label={
                  <>
                    {hrEnums.CENTER}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                className="select-border"
                options={centerData}
                placeholder={SelectPlaceHolder(hrEnums.CENTER)}
                handleBlur={staffForm.handleBlur}
                onChange={handleMultiCenter}
                value={selectedMultiCenter ?? null}
                touched={staffForm.touched.center}
                error={staffForm.errors.center}
                isDisabled={isProfile}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.primary_center_id}
                name={formFields.primary_center_id}
                label={hrEnums.PRIMARY_CENTER}
                className="select-border"
                options={selectedCenterIds}
                placeholder={SelectPlaceHolder(hrEnums.PRIMARY_CENTER)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                  fetchReportingManager(value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.PRIMARY_CENTER, true)
                }
                value={staffForm.values.primary_center_id}
                touched={staffForm.touched.primary_center_id}
                error={staffForm.errors.primary_center_id}
                isDisabled={isProfile}
                required={true}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id={formFields.reporting_manager}
                name={formFields.reporting_manager}
                label={hrEnums.REPORTING_MANAGER}
                className="select-border"
                options={reportingManager}
                placeholder={SelectPlaceHolder(hrEnums.REPORTING_MANAGER)}
                handleChange={(field, value) => {
                  staffForm.setFieldValue(field, value);
                }}
                handleBlur={() =>
                  staffForm.setFieldTouched(hrEnums.REPORTING_MANAGER, true)
                }
                value={staffForm.values.reporting_manager}
                touched={staffForm.touched.reporting_manager}
                error={staffForm.errors.reporting_manager}
                isDisabled={isProfile}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name={formFields.staff_id}
                type="text"
                label={
                  <>
                    {hrEnums.STAFF_ID}
                    <span className="text-danger">{RequiredSign}</span>
                  </>
                }
                placeholder={PlaceHolderFormat(hrEnums.STAFF_ID)}
                value={staffForm.values.staff_id}
                touched={staffForm.touched.staff_id}
                error={staffForm.errors.staff_id}
                handleBlur={staffForm.staff_id}
                handleChange={staffForm.handleChange}
                disabled={true}
              />
              {!editProfileMode && (
                <div className="fs-8">
                  {hrEnums.LAST_STAFF_ID}
                  {lastStaffId}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileDetails;
