import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseCheckbox from "../../../BaseComponents/BaseCheckbox";
import { RiDeleteBin7Fill, RiSearchLine } from "react-icons/ri";
import BaseSelect from "../../../BaseComponents/BaseSelect";
import BaseInput from "../../../BaseComponents/BaseInput";
import moment from "moment";
import {
  attendanceStats,
  data,
  EditLateEntries,
} from "../../../Constant/HR_LateEntry/LateEntry";

const EditLateEntry = () => {
  const [activeTab, setActiveTab] = useState("rules");
  const [selectedTime, setSelectedTime] = useState(moment("00:15", "HH:mm"));
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [selectedDeactivated, setSelectedDeactivated] = useState([]);
  const [selectedNoShiftAssigned, setSelectedNoShiftAssigned] = useState([]);
  const [allSelected, setAllSelected] = useState({
    shifts: false,
    deactivatedStaff: false,
    noShiftAssigned: false,
  });
  const handleSelectAll = (category) => {
    const isSelected = !allSelected[category];
    setAllSelected((prevState) => ({
      ...prevState,
      category: isSelected,
    }));

    if (category === EditLateEntries.SHIFTS) {
      setSelectedShifts(
        isSelected ? data?.shifts?.map((item) => item?.id) : []
      );
    } else if (category === EditLateEntries.DEACTIVATEDSTAFF) {
      setSelectedDeactivated(
        isSelected ? data?.deactivatedStaff?.map((item) => item?.id) : []
      );
    } else if (category === EditLateEntries.NOSHIFTASSGINED) {
      setSelectedNoShiftAssigned(
        isSelected ? data?.noShiftAssigned?.map((item) => item?.id) : []
      );
    }
  };

  const handleCheckbox = (category, id) => {
    if (category === EditLateEntries.SHIFTS) {
      setSelectedShifts((prevState) =>
        prevState.includes(id)
          ? prevState.filter((item) => item !== id)
          : [...prevState, id]
      );
    } else if (category === EditLateEntries.DEACTIVATEDSTAFF) {
      setSelectedDeactivated((prevState) =>
        prevState.includes(id)
          ? prevState.filter((item) => item !== id)
          : [...prevState, id]
      );
    } else if (category === EditLateEntries.NOSHIFTASSGINED) {
      setSelectedNoShiftAssigned((prevState) =>
        prevState.includes(id)
          ? prevState.filter((item) => item !== id)
          : [...prevState, id]
      );
    }
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  const [detailsVisibility, setDetailsVisibility] = useState({
    rule1: false,
    rule2: false,
    rule3: false,
  });
  const handleTimeChange = (e) => {
    const selectedTimeValue = moment(e.target.value, "HH:mm");
    setSelectedTime(selectedTimeValue);
  };

  const handleCheckboxChange = (rule) => {
    setDetailsVisibility((prevState) => ({
      ...prevState,
      [rule]: !prevState[rule],
    }));
  };

  useEffect(() => {
    setAllSelected({
      shifts: selectedShifts.length === data.shifts.length,
      deactivatedStaff:
        selectedDeactivated.length === data.deactivatedStaff.length,
      noShiftAssigned:
        selectedNoShiftAssigned.length === data.noShiftAssigned.length,
    });
  }, [selectedShifts, selectedDeactivated, selectedNoShiftAssigned]);

  return (
    <div>
      <div className="container-fluid">
        <div className="page-header dash-breadcrumb py-3">
          <div className="row">
            <div className="col-6">
              <div>
                <h4 className="f-w-600">{EditLateEntries.EDITRULES}</h4>
              </div>
            </div>
            <div className="col-6  d-flex justify-content-end align-items-right">
              <Link to="/hr_setting">
                <button className="btn btn-pill btn-primary">
                  {EditLateEntries.BACK}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs " id="myTab" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link custom-nav-link ${
                      activeTab === "rules" ? "active" : ""
                    }`}
                    onClick={() => handleTabSelect("rules")}
                    id="rules-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#rules"
                    role="tab"
                    type="button"
                    aria-controls="rules"
                    aria-selected={activeTab === "rules"}
                  >
                    {EditLateEntries.RULES}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link custom-nav-link ${
                      activeTab === "staff-selection" ? "active" : ""
                    }`}
                    onClick={() => handleTabSelect("staff-selection")}
                    id="staff-selection-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#staff-selection"
                    role="tab"
                    type="button"
                    aria-controls="staff-selection"
                    aria-selected={activeTab === "staff-selection"}
                  >
                    {EditLateEntries.STAFFSELECTION}
                  </button>
                </li>
              </ul>
              <div className="tab-content " id="myTabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "rules" ? "show active" : ""
                  }`}
                  id="rules"
                  role="tabpanel"
                  aria-labelledby="rules-tab"
                >
                  <div className="mx-0 py-2 align-items-start d-flex font-bold">
                    <div className="col d-flex align-items-center">
                      <BaseCheckbox
                        type="checkbox"
                        value=""
                        id="rule1"
                        checked={detailsVisibility.rule1}
                        onChange={() => handleCheckboxChange("rule1")}
                      />
                      <label
                        className="form-check-label ms-2 mt-3"
                        htmlFor="rule1"
                      >
                        {EditLateEntries.DEDUCTSALARY}
                      </label>
                    </div>
                  </div>
                  <div
                    id="details1"
                    className={`mt-3 checkbox-setting  ${
                      detailsVisibility.rule1 ? "" : "d-none"
                    }`}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-2 col-sm-6 ">
                        <div className="form-group">
                          <label className="text-muted">
                            {EditLateEntries.LATEBY}
                          </label>
                          <div className="input-group">
                            <BaseInput
                              type="time"
                              className="form-control"
                              defaultValue="00:15"
                              handleChange={handleTimeChange}
                              value={selectedTime.format("HH:mm")}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">hrs</span>
                            </div>
                          </div>
                          <small className="form-text text-muted">
                            {EditLateEntries.NOLATEFINE}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 mb-4">
                        <BaseSelect
                          name="center_name"
                          label={EditLateEntries.DEDUCTIONTYPE}
                          className="select-border"
                          options={attendanceStats}
                        />
                      </div>
                      <div className="col-md-2 col-sm-12 mb-2 text-start">
                        <RiDeleteBin7Fill
                          className="fs-5 text-danger"
                          title="Delete"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-primary mt-1 mb-2 f-w-600">
                        {EditLateEntries.ADDTIME}
                      </div>
                    </div>
                    <div className="mx-0 row py-2 align-items-center d-flex font-bold ">
                      <div className="col left-content">
                        <BaseCheckbox
                          label={`Set Occurrences`}
                          type="checkbox"
                          value=""
                          id={`checkbox`}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-2 col-sm-6 mb-2">
                        <div className="form-group">
                          <label className="text-muted mt-2">
                            {EditLateEntries.MiNIMUMOCCURANCE}
                          </label>
                          <BaseSelect
                            name="center_name"
                            className="select-border  mt-2"
                            options={attendanceStats}
                          />
                          <small className="form-text text-muted">
                            {EditLateEntries.FINETIMES}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 mb-3">
                        <div className="form-group">
                          <div className="input-group mt-3">
                            <BaseInput
                              type="text"
                              className="form-control"
                              value="3"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {" "}
                                {EditLateEntries.TIMES}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-0 py-2 align-items-start d-flex font-bold ">
                    <div className="col d-flex align-items-center">
                      <BaseCheckbox
                        type="checkbox"
                        value=""
                        id="rule2"
                        checked={detailsVisibility.rule2}
                        onChange={() => handleCheckboxChange("rule2")}
                      />
                      <label
                        className="form-check-label ms-2 mt-3"
                        htmlFor="rule1"
                      >
                        {EditLateEntries.DEDUCTHALFDAY}
                      </label>
                    </div>
                  </div>
                  <div
                    id="details2"
                    className={`mt-3 checkbox-setting ${
                      detailsVisibility.rule2 ? "" : "d-none"
                    }`}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-2 col-sm-6">
                        <div className="form-group">
                          <label className="text-muted">
                            {EditLateEntries.LATEBY}
                          </label>
                          <div className="input-group">
                            <BaseInput
                              type="time"
                              className="form-control"
                              handleChange={handleTimeChange}
                              value={selectedTime.format("HH:mm")}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">hrs</span>
                            </div>
                          </div>
                          <small className="form-text text-muted">
                            {EditLateEntries.NOLATEFINE}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="text-primary mt-1 mb-2 f-w-600">
                        {EditLateEntries.ADDTIME}
                      </div>
                    </div>
                    <div className="mx-0 row py-2 align-items-center d-flex font-bold ">
                      <div className="col left-content">
                        <BaseCheckbox
                          label={`Set Occurrences`}
                          type="checkbox"
                          value=""
                          id={`checkbox`}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-2 col-sm-6">
                        <div className="form-group">
                          <label className="text-muted">
                            {EditLateEntries.MiNIMUMOCCURANCE}
                          </label>
                          <BaseSelect
                            name="center_name"
                            className="select-border  mt-2"
                            options={attendanceStats}
                          />
                          <small className="form-text text-muted">
                            {EditLateEntries.FINETIMES}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 ">
                        <div className="form-group">
                          <div className="input-group">
                            <BaseInput
                              type="text"
                              className="form-control"
                              value="3"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {EditLateEntries.TIMES}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mx-0 py-2 align-items-start d-flex font-bold ">
                    <div className="col d-flex align-items-center">
                      <BaseCheckbox
                        type="checkbox"
                        value=""
                        id="rule3"
                        checked={detailsVisibility.rule3}
                        onChange={() => handleCheckboxChange("rule3")}
                      />
                      <label
                        className="form-check-label ms-2 mt-3"
                        htmlFor="rule1"
                      >
                        {EditLateEntries.DEDUCTFULLDAY}
                      </label>
                    </div>
                  </div>
                  <div
                    id="details3"
                    className={`mt-3 checkbox-setting ${
                      detailsVisibility.rule3 ? "" : "d-none"
                    }`}
                  >
                    <div className="form-group mt-2 ">
                      <label htmlFor="ifLateBy" className="mt-2">
                        {EditLateEntries.LATEBY}
                      </label>
                      <BaseInput
                        type="text"
                        className="form-control"
                        id="ifLateBy2"
                        placeholder="01:00 hrs"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="deductionType">
                        {EditLateEntries.DEDUCTIONTYPE}
                      </label>
                      <BaseInput
                        type="text"
                        className="form-control"
                        id="deductionType2"
                        placeholder="Not applicable up to 60 mins"
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3 d-flex align-items-center gap-2">
                    <div className="flex-grow-1">
                      <label
                        htmlFor="includeLateFine"
                        className="checkbox-setting mt-2"
                      >
                        {EditLateEntries.INCLUDEDLATEFINE}
                      </label>
                    </div>
                    <div class="media ">
                      <div class="media-body text-end switch-sm ">
                        <label class="switch ">
                          <input type="checkbox" checked="false" />
                          <span class="switch-state"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      type="button"
                      className="btn btn-pill btn-primary"
                      id="assignStaffButton"
                    >
                      {EditLateEntries.NEXTASSIGNSTAFF}
                    </button>
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${
                    activeTab === "staff-selection" ? "show active" : ""
                  }`}
                  id="staff-selection"
                  role="tabpanel"
                  aria-labelledby="staff-selection-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-md-3 fs-6  f-w-600 text-dark">
                      {EditLateEntries.SELECTEDSTAFF}
                    </div>
                    <div className="col-md-6 fs-6">{EditLateEntries.NOTE}</div>
                    <div className="col-md-2 fs-6">
                      <Link className="">
                        {EditLateEntries.ASSIGNEDALLSTAFF}
                      </Link>
                    </div>
                    <div className="col-md-1 fs-6">
                      <div class="media ">
                        <div class="media-body text-end switch-sm ">
                          <label class="switch ">
                            <BaseInput type="checkbox" checked="false" />
                            <span class="switch-state"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="leave-categories">
                    <div className="card">
                      <div className="card-body">
                        <div className="">
                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="input-group mb-3">
                                <span className="input-group-text">
                                  {" "}
                                  <RiSearchLine className="fs-size" />
                                </span>
                                <BaseInput
                                  className="form-control"
                                  type="text"
                                  placeholder="Search"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12">
                              <div className="d-flex border-bottom bg-light pb-0 pt-1 mt-4">
                                <div className="d-flex align-items-center">
                                  <BaseCheckbox
                                    id="checkbox-primary-0"
                                    type="checkbox"
                                    checked={allSelected.shifts}
                                    onChange={() => handleSelectAll("shifts")}
                                  />
                                  <label
                                    htmlFor="checkbox-primary-0"
                                    className=" mt-3"
                                  >
                                    <h6 className="text-dark">
                                      {EditLateEntries.SHIFTTIME}
                                    </h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {data?.shifts?.map((item) => (
                              <div key={item.id} className="col-12">
                                <div className="d-flex border-bottom">
                                  <div className="d-flex align-items-center">
                                    <BaseCheckbox
                                      id={item.id}
                                      type="checkbox"
                                      checked={selectedShifts.includes(item.id)}
                                      onChange={() =>
                                        handleCheckbox("shifts", item.id)
                                      }
                                    />
                                    <label htmlFor={item.id} className=" mt-3">
                                      <h6 className="text-dark">{item.name}</h6>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="col-12">
                              <div className="d-flex border-bottom bg-light pb-0 pt-1 mt-4">
                                <div className="d-flex align-items-center">
                                  <BaseCheckbox
                                    id="checkbox-primary-13"
                                    type="checkbox"
                                    checked={allSelected.deactivatedStaff}
                                    onChange={() =>
                                      handleSelectAll("deactivatedStaff")
                                    }
                                  />
                                  <label
                                    htmlFor="checkbox-primary-13"
                                    className=" mt-3"
                                  >
                                    <div className=" btn-pill">
                                      <span className="bg-light btn-pill text-dark   f-w-600">
                                        {EditLateEntries.DEACTIVATED}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {data.deactivatedStaff?.map((item) => (
                              <div key={item.id} className="col-12">
                                <div className="d-flex border-bottom">
                                  <div className="d-flex align-items-center">
                                    <BaseCheckbox
                                      id={item.id}
                                      type="checkbox"
                                      checked={selectedDeactivated.includes(
                                        item.id
                                      )}
                                      onChange={() =>
                                        handleCheckbox(
                                          "deactivatedStaff",
                                          item.id
                                        )
                                      }
                                    />
                                    <label htmlFor={item.id} className=" mt-2">
                                      {item.name}
                                    </label>
                                  </div>
                                  <div className="mt-2 btn-pill">
                                    <span className="bg-light btn-pill text-dark px-3 py-1 f-w-600">
                                      {EditLateEntries.deactivated}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="row mt-4">
                              <div className="col-12">
                                <div className="d-flex border-bottom bg-light pb-2 pt-2">
                                  <div className="d-flex align-items-center">
                                    <BaseCheckbox
                                      id="checkbox-primary-16"
                                      type="checkbox"
                                      checked={allSelected.noShiftAssigned}
                                      onChange={() =>
                                        handleSelectAll("noShiftAssigned")
                                      }
                                    />
                                    <label
                                      htmlFor="checkbox-primary-16"
                                      className=" "
                                    >
                                      <h6 className="text-muted mt-3">
                                        {EditLateEntries.NOSHIFTASSIGNED}{" "}
                                        <span className="ms-3 d-block d-sm-inline">
                                          {EditLateEntries.PLEASEASSIGN}
                                        </span>
                                      </h6>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {data?.noShiftAssigned?.map((item) => (
                              <div key={item.id} className="col-12">
                                <div className="d-flex border-bottom">
                                  <div className="d-flex align-items-center">
                                    <BaseCheckbox
                                      id={item.id}
                                      type="checkbox"
                                      checked={selectedNoShiftAssigned.includes(
                                        item.id
                                      )}
                                      onChange={() =>
                                        handleCheckbox(
                                          "noShiftAssigned",
                                          item.id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={item.id}
                                      className="checkbox-setting mt-2"
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLateEntry;
