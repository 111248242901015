import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormFeedback, Label } from "reactstrap";
import "flatpickr/dist/flatpickr.css";
import { RiArrowDownLine, RiSearchLine } from "react-icons/ri";
import BaseButton from "../../BaseComponents/BaseButton";
import BaseModal from "../../BaseComponents/BaseModal/index";
import BaseSelect from "../../BaseComponents/BaseSelect/index";
import {
  Designation,
  Duration,
  Filter,
  Filters,
  Import,
  Name,
  Search,
  Submit,
  notFound,
} from "../../Constant";
import Flatpickr from "react-flatpickr";
import { courseApi, viewBatch } from "../../Api/common";
import {
  SelectPlaceHolder,
  validationMessages,
} from "../../Constant/validation";
import * as yup from "yup";
import {
  filterAttendanceData,
  importAttendanceData,
  // listAttendanceData,
  listCandidateFilter,
} from "../../Api/BatchApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import TableContainer from "../../BaseComponents/BaseTable";
import Spinner from "../../BaseComponents/BaseLoader";
import moment from "moment";
import {
  AttendanceList,
  AttendanceText,
  BatchText,
  Candidate,
  CenterName,
  ClearFilters,
  csvValidation,
  DataError,
  DivisionUnits,
  EMPID,
  EndDate,
  fileImport,
  fileType,
  fileValidation,
  InTime,
  Note,
  OfficeLocations,
  OutTime,
  sampleFile,
  StartDate,
  StartDateKey,
  Title,
} from "../../Constant/Attendance";
import { Course } from "../../Constant/Course";
import { checkStatusCodeSuccess, csv, deleteUploadedfile, extractDate, extractTime } from "../../Constant/common";
import { useSelectedCenter } from "../CenterContext";
import { editBatchLable } from "../../Constant/BatchAllocation/batchallocation";
import { exportAssessmentData } from "../../Api/exportBtn";
import { BaseExportURL } from "../../Api/Service";

const Attendance = () => {
  document.title = Title;
  const startDateRef = useRef();
  const endDateRef = useRef();
  const [btnLoader, setBtnLoader] = useState(false);
  const [loader, setLoader] = useState([]);
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [enableFilter, setEnableFilter] = useState(true);
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceSearchList, setAttendanceSearchList] = useState([]);
  const [courseData, setCourseData] = useState();
  const [batchData, setBatchData] = useState();
  const [candidateData, setCandidateData] = useState([]);
  const [importModal, setImportModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(null);
  const [customPageSize, setCustomPageSize] = useState(5);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [error, setError] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [courseId, setCourseId] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const {selectedCenterPayload} = useSelectedCenter();

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };
  const filterForm = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
      course: null,
      batch: null,
      candidate: null,
    },
    validationSchema: yup.object({
      start_date: yup.date().nullable().notRequired(),
      end_date: yup
        .date()
        .nullable()
        .notRequired()
        .min(
          yup.ref(StartDateKey),
          validationMessages.greaterThan(StartDate, EndDate)
        ),
    }),
    onSubmit: (values) => {
      setShowClearFilter(true);
      setLoader(true);
      const payload = {
        ...selectedCenterPayload,
        startDate: values.start_date,
        endDate: values.end_date,
        course_id: values.course,
        batch_id: values.batch,
        candidate_id: values.candidate,
        order: [columnName, sortOrder],
        pageSize: customPageSize,
        pageNumber: currentPage,
      };
      filterAttendanceData(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            setAttendanceList(resp?.data?.listOfCandidate);
            setTotalRecords(resp.data.totalRecordsCount);
            setTotalPages(resp?.data?.totalPages);
            setTotalNumberOfRows(resp?.data?.numberOfRows);
            setCurrentPage(resp?.data?.currentPage);
          } else {
            setAttendanceList([]);
          }
        })
        .catch((err) => {
          setAttendanceList([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });

  const toggleImport = () => {
    setImportModal(!importModal);
  };

  const handleClearFilter = () => {
    setShowClearFilter(false);
    startDateRef.current.flatpickr.clear();
    endDateRef.current.flatpickr.clear();
    filterForm.resetForm();
    setCourseId(null);
    setBatchId(null);
    fetchData();
    fetchBatch();
    fetchCandidate(null, true);
  };

  const handleDateChange = (date, field) => {
    let value = moment(date && date[0]).format("YYYY-MM-DD");
    filterForm.setFieldValue(field, value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      importData: "",
    },
    validationSchema: yup.object({
      importData: yup
        .mixed()
        .required(fileValidation)
        .test(fileType, csvValidation, (value) => {
          if (value) {
            return value.type === "text/csv" || value.name.endsWith(".csv");
          }
          return false;
        }),
    }),
    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      const formData = new FormData();
      formData.append("file", values.importData);
      importAttendanceData(formData)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            toast.success(resp.message);
            formik.setFieldValue(resp?.data);
          } else {
            toast.error(resp?.message);
            setError(resp?.message);
            setErrorModal(true);
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.message || err?.message);
          setErrorModal(true);
        })
        .finally(() => {
          setBtnLoader(false);
          toggleImport();
          fetchData();
          resetForm();
        });
    },
  });

  const fetchList = async () => {
    setLoader(true);
    let data;
    const payload = {
      ...selectedCenterPayload,
    };
    await courseApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          data = resp?.data;
          setCourseData(
            data?.map((item) => ({
              value: item?.id,
              label: item?.course_name,
              id: item?.id,
            }))
          );
        }
      })
      .catch((err) => {
        return err;
      });
  };
  
  const fetchBatch = (id) => {
    setLoader(true);
    let data;
    const payload = {
      condition: {
        ...selectedCenterPayload,
        course_id: id,
      },
    };
    viewBatch(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          data = resp?.data;
          setBatchData(
            data?.map((item) => ({
              value: item?.id,
              label: item?.batch_id,
              id: item?.id,
            }))
          );
        } else {
          setBatchData([]);
        }
      })
      .catch((err) => {
        setBatchData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
    };
    
  const fetchCandidate = (id, isCourseChanged) => {
    setLoader(true);
    let data;
    const payload = {
      ...selectedCenterPayload,
      course_id: isCourseChanged ? id : courseId,
      batch_id: isCourseChanged ? null : id,
    };
    listCandidateFilter(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          data = resp?.data;
          setCandidateData(
            data?.map((item) => ({
              value: item?.id,
              label: item?.candidate_name,
              id: item?.id,
            }))
          );
        } else {
          setCandidateData([]);
        }
      })
      .catch((err) => {
        setCandidateData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchData = async () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      order: [columnName, sortOrder],
      pageSize: customPageSize,
      pageNumber: currentPage,
      search: searchValue,
    };
    filterAttendanceData(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setAttendanceList(resp?.data?.listOfCandidate);
          setAttendanceSearchList(resp?.data?.listOfCandidate);
          setTotalRecords(resp.data.totalRecordsCount);
          setTotalPages(resp?.data?.totalPages);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        } else {
          setAttendanceSearchList([]);
        }
      })
      .catch((err) => {
        setAttendanceSearchList([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        header: EMPID,
        accessorKey: "emp_id",
        enableColumnFilter: false,
      },
      {
        header: Name,
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: Designation,
        accessorKey: "users_designation",
        enableColumnFilter: false,
      },
      {
        header: DivisionUnits,
        accessorKey: "division",
        enableColumnFilter: false,
      },
      {
        header: CenterName,
        accessorKey: "center_name",
        enableColumnFilter: false,
      },
      {
        header: OfficeLocations,
        accessorKey: "office_locations",
        enableColumnFilter: false,
      },
      {
        header: InTime,
        accessorKey: "in_time",
        enableColumnFilter: false,
        cell: (cell) => {
          const originalDateTime = cell.getValue();
          const datePart = extractDate(originalDateTime);
          const timePart = extractTime(originalDateTime);
          return <span className="fw-normal">{`${datePart} ${timePart}`}</span>;
        },
      },
      {
        header: OutTime,
        accessorKey: "out_time",
        enableColumnFilter: false,
        cell: (cell) => {
          const originalDateTime = cell.getValue();
          const datePart = extractDate(originalDateTime);
          const timePart = extractTime(originalDateTime);
          return <span className="fw-normal">{`${datePart} ${timePart}`}</span>;
        },
      },
      {
        header: Duration,
        accessorKey: "duration",
        enableColumnFilter: false,
      },
    ],
    []
  );

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const hasAnyValue = Object.values(filterForm.values).some((value) => value);
    setEnableFilter(!hasAnyValue);
  }, [filterForm.values]);

  useEffect(() => {
    fetchList();
    fetchBatch();
    fetchCandidate();
  }, []);

  useEffect(() => {
    if (showClearFilter === true) {
      filterForm.handleSubmit();
    } else {
      fetchData();
    }
  }, [currentPage, customPageSize, sortOrder, columnName,searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setCurrentPage(1);
    }
  }, [searchValue]);

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };

  const handleExportAssesment = () => {
    setLoader(true);
    const payload = {
      course_id: courseId,
      batch_id: batchId,
      ...selectedCenterPayload,
    };
    exportAssessmentData(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const filename = resp?.data;
          const url = `${BaseExportURL}${resp?.data}`;
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          toast.success(resp.success);
          if(url){
            deleteUploadedfile(filename,true,csv);
          }
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <div className="page-content">
      <BaseModal
        isOpen={importModal}
        toggler={toggleImport}
        title={fileImport}
        submitText={Submit}
        disabled={btnLoader}
        loader={btnLoader}
        submit={formik.handleSubmit}
      >
        <div className="row col-12 px-0">
          <div className="d-inline-flex align-items-center mb-3">
            {Note}:&nbsp;
            <a href="/Attendance Sample.csv" download="Attendance Sample.csv">
              {sampleFile}
            </a>
          </div>
          {/* ReactStrap Input Is not Working so that's why i have use Input */}
          <input
            name="importData"
            type="file"
            accept=".csv, application/vnd.ms-excel"
            onChange={(e) => {
              formik.setFieldValue("importData", e.currentTarget.files[0]);
            }}
            onBlur={formik.handleBlur}
            className={
              formik.touched.importData && formik.errors.importData
                ? "is-invalid border-0"
                : "border-0"
            }
          />
          {formik.touched.importData && formik.errors.importData ? (
            <div className="invalid-feedback">{formik.errors.importData}</div>
          ) : null}
        </div>
      </BaseModal>
      <div className="px-3 d-flex justify-content-between align-items-center mb-1">
        <h5 className="f-w-600">{AttendanceText}</h5>
        <BaseButton
          className="btn btn-pill btn-warning text-white"
          onClick={toggleImport}
          children={<><RiArrowDownLine size={20} />{Import}</>}
        />
      </div>
      <div className="card mx-3 mb-2">
        <div className="container-fluid">
          <div className="row d-flex align-items-center px-2">
            <div className="d-flex justify-content-between pt-2">
              <h6>{Filters}</h6>
            </div>
            <div className="col-12 row d-flex justify-content-between">
              <div className="col-12 col-lg-2">
                <BaseSelect
                  name="course"
                  label={Course}
                  className="select-border"
                  options={courseData}
                  placeholder={SelectPlaceHolder("Course")}
                  handleChange={(field, value) => {
                    filterForm.setFieldValue(field, value);
                    fetchBatch(value);
                    setCourseId(value); 
                    fetchCandidate(value, true);
                  }}
                  value={filterForm.values.course}
                  handleBlur={() => filterForm.setFieldTouched(Course, true)}
                  touched={filterForm.touched.course}
                  error={filterForm.errors.course}
                />
              </div>
              <div className="col-12 col-lg-3">
                <BaseSelect
                  name="batch"
                  label={BatchText}
                  className="select-border"
                  options={batchData}
                  placeholder={SelectPlaceHolder("Batch")}
                  handleChange={(field, value) => {
                    filterForm.setFieldValue(field, value);
                    setBatchId(value);
                    fetchCandidate(value, false);
                  }}
                  value={filterForm.values.batch}
                  handleBlur={() => filterForm.setFieldTouched(BatchText, true)}
                  touched={filterForm.touched.batch}
                  error={filterForm.errors.batch}
                />
              </div>
              <div className="col-12 col-lg-3">
                <BaseSelect
                  name="candidate"
                  label={Candidate}
                  className="select-border"
                  options={candidateData}
                  placeholder={SelectPlaceHolder("Candidate")}
                  handleChange={(field, value) => {
                    filterForm.setFieldValue(field, value);
                  }}
                  value={filterForm.values.candidate}
                  handleBlur={() => filterForm.setFieldTouched(Candidate, true)}
                  touched={filterForm.touched.candidate}
                  error={filterForm.errors.candidate}
                />
              </div>
              <div className="col-12 col-lg-2">
                <Label>{StartDate}</Label>
                <Flatpickr
                  name="start_date"
                  className="form-control clickable"
                  ref={startDateRef}
                  placeholder={SelectPlaceHolder("Start Date")}
                  onChange={(date) => handleDateChange(date, "start_date")}
                  options={{
                    dateFormat: "d-m-Y",
                    mode: "single",
                  }}
                />
                {filterForm.touched.start_date &&
                filterForm.errors.start_date ? (
                  <FormFeedback className="d-block">
                    {filterForm.errors.start_date}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="col-12 col-lg-2">
                <Label>{EndDate}</Label>
                <Flatpickr
                  name="end_date"
                  ref={endDateRef}
                  className="form-control clickable"
                  placeholder={SelectPlaceHolder("End Date")}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  options={{
                    dateFormat: "d-m-Y",
                    mode: "single",
                  }}
                />
                {filterForm.touched.end_date && filterForm.errors.end_date ? (
                  <FormFeedback className="d-block">
                    {filterForm.errors.end_date}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-end pb-2 gap-1">
              {showClearFilter && (
                <BaseButton
                  onClick={handleClearFilter}
                  className="btn btn-danger mx-2"
                  children={ClearFilters}
                />
              )}
              <BaseButton
                onClick={filterForm.handleSubmit}
                disabled={enableFilter}
                children={Filter}
              />
              <BaseButton color="success" onClick={handleExportAssesment} disabled={!courseId && !batchId}>
                {editBatchLable.export}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 d-flex flex-row justify-content-between">
        <h5 className="f-w-600">{AttendanceList}</h5>
      </div>
      <div className="card mx-3">
        <div className="card-body text-center">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {attendanceList?.length > 0 && (
              <TableContainer
                columns={columns}
                customPageSize={customPageSize}
                totalRecords={totalRecords}
                totalNumberOfRows={totalNumberOfRows}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                fetchData={handleFetchData}
                isGlobalFilter={true}
                manualFiltering={true}
                onSearch={handleSearchValueChange}
                setCustomPageSize={setCustomPageSize}
                fetchSortingData={handleFetchSorting}
                tableClass="table table-bordered"
                data={searchValue ? attendanceSearchList : attendanceList || []}
                SearchPlaceholder={Search}
                manualPagination={true}
              />
          )}
          {!loader && attendanceList.length === 0 && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
      <BaseModal
        isOpen={errorModal}
        toggler={() => setErrorModal(false)}
        title={DataError}
        hasSubmitButton={false}
      >
        <ul className="list-group list-group-flush">
          {error && Array.isArray(error) ?
            error?.map((errorMessage, index) => (
              <li key={index} className="list-group-item text-danger">
                {index + 1}. {errorMessage}
              </li>
            )) : (
              <li className="list-group-item text-danger">{error}</li>
            )}
        </ul>
      </BaseModal>
    </div>
  );
};

export default Attendance;
