import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BaseCheckbox from "../../../../BaseComponents/BaseCheckbox";
import BaseButton from "../../../../BaseComponents/BaseButton";
import Spinner from "../../../../BaseComponents/BaseLoader";
import BaseCard from "../../../../BaseComponents/BaseCard";
import { notFound } from "../../../../Constant";
import { holidayLabel } from "../../../../Constant/HR_HolidayTemplate/holidayTemplate";
import { checkStatusCodeSuccess, rendorBackButton } from "../../../../Constant/common";
import { shiftSettingLabel } from "../../../../Constant/HR/ShiftSetting";
import {
  editManageStaff,
  manageStaffList,
} from "../../../../Api/HRshiftSetting";
import { useSelectedCenter } from "../../../CenterContext";

const ManageShiftStaff = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  document.title = shiftSettingLabel.ManageStaff;
  const [selectedWithShift, setSelectedWithShift] = useState([]);
  const [selectedWithOutShift, setSelectedWithOutShift] = useState([]);
  const [searchWithShift, setSearchWithShift] = useState("");
  const [searchWithOutShift, setSearchWithOutShift] = useState("");
  const [selectedAllShift, setSelectedAllShift] = useState(true);
  const [selectedAllWithOutShift, setSelectedAllWithOutShift] = useState(false);
  const [searchSelectedShift, setSearchSelectedShift] = useState([]);
  const [searchSelectedWithOutShift, setSearchSelectedWithOutShift] = useState([]);
  const [loader, setLoader] = useState(false);
  const { selectedCenterPayload } = useSelectedCenter();

  const fetchStaffData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      selectedStaffSearch: searchWithShift,
      unselectedStaffSearch: searchWithOutShift,
    };
    manageStaffList(id, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const selectedStaff = res?.data?.selectedStaff || [];
          const unselectedStaff = res?.data?.unselectedStaff || [];
          setSelectedWithShift(selectedStaff);
          setSelectedWithOutShift(unselectedStaff);
        } else {
          setSelectedWithShift([]);
          setSelectedWithOutShift([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setSelectedWithShift([]);
        setSelectedWithOutShift([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchSearchData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      selectedStaffSearch: searchWithShift,
      unselectedStaffSearch: searchWithOutShift,
    };
    manageStaffList(id, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const selectedStaff = res?.data?.selectedStaff || [];
          const unselectedStaff = res?.data?.unselectedStaff || [];
          setSearchSelectedShift(selectedStaff);
          setSearchSelectedWithOutShift(unselectedStaff);
        } else {
          setSelectedWithShift([]);
          setSelectedWithOutShift([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setSelectedWithShift([]);
        setSelectedWithOutShift([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    fetchStaffData();
  }, [id]);
  useEffect(() => {
    fetchSearchData();
  }, [id, searchWithShift, searchWithOutShift]);

  const handleCheckboxChange = (staff, isWithHoliday) => {
    const updateList = (list, setList) => {
      const updatedList = list?.map((item) =>
        item?.id === staff.id ? { ...item, selected: !item?.selected } : item
      );
      setList(updatedList);
      return updatedList?.filter((item) => !item?.selected).length === 0;
    };

    if (isWithHoliday) {
      setSelectedAllShift(updateList(selectedWithShift, setSelectedWithShift));
    } else {
      setSelectedAllWithOutShift(
        updateList(selectedWithOutShift, setSelectedWithOutShift)
      );
    }
  };

  const handleCheckAll = (isWithHoliday) => {
    if (isWithHoliday) {
      setSelectedAllShift(!selectedAllShift);
      setSelectedWithShift((prev) =>
        prev?.map((item) => ({ ...item, selected: !selectedAllShift }))
      );
    } else {
      setSelectedAllWithOutShift(!selectedAllWithOutShift);
      setSelectedWithOutShift((prev) =>
        prev?.map((item) => ({ ...item, selected: !selectedAllWithOutShift }))
      );
    }
  };
  // Function to get IDs of staff based on their selected status
  const getStaffIds = (staffList, isSelected) =>
    staffList
      ?.filter((staff) => staff?.selected === isSelected)
      .map((staff) => staff?.id);
  // Get IDs of staff who are selected (with holiday or without holiday)
  const authIds = [
    ...getStaffIds(selectedWithShift, true),
    ...getStaffIds(selectedWithOutShift, true),
  ];
  // Get IDs of staff who are not selected (with holiday or without holiday)
  const unassignedUserIds = [
    ...getStaffIds(selectedWithShift, false),
    ...getStaffIds(selectedWithOutShift, false),
  ];

  const handleSave = () => {
    setLoader(true);
    const payload = {
      authIds,
      unassignedUserIds,
    };
    editManageStaff(id, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          toast.success(res?.message);
          navigate("/ShiftSetting");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const renderStaffItem = (staff, isWithHoliday) => (
    <BaseCheckbox
      id={`checkbox-${staff.id}-${
        isWithHoliday ? holidayLabel.with : holidayLabel.without
      }`}
      label={staff.name}
      checked={staff.selected}
      badge={!staff.isActive && holidayLabel.Deactive}
      badgeClassName={!staff.isActive && "bg-light text-dark"}
      onChange={() => handleCheckboxChange(staff, isWithHoliday)}
    />
  );

  return (
    <div className="container-fluid">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="d-flex justify-content-between align-items-center w-full">
        <h5 className="f-w-600">{shiftSettingLabel.ApplyShift}</h5>
        <div className="col-sm-auto col-md-auto mb-2">
          {rendorBackButton(() => navigate("/setting"))}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {/* Staff with Holiday */}
          <BaseCard
            title={shiftSettingLabel.staffWithShift}
            searchPlaceholder={holidayLabel.searchStaff}
            searchValue={searchWithShift}
            onSearchChange={setSearchWithShift}
            noDataMessage={notFound.dataNotFound}
            searchItems={searchSelectedShift}
            items={selectedWithShift || []}
            renderItem={(staff) => renderStaffItem(staff, true)}
            checkAllLabel={`${holidayLabel.monthRegularStaff} (${selectedWithShift?.length})`}
            onCheckAllChange={() => handleCheckAll(true)}
            selectAllChecked={selectedAllShift}
          />
          {/* Staff without Holiday */}
          <BaseCard
            title={shiftSettingLabel.staffWithOutShift}
            searchPlaceholder={holidayLabel.searchStaff}
            searchValue={searchWithOutShift}
            onSearchChange={setSearchWithOutShift}
            noDataMessage={notFound.dataNotFound}
            searchItems={searchSelectedWithOutShift}
            items={selectedWithOutShift || []}
            renderItem={(staff) => renderStaffItem(staff, false)}
            checkAllLabel={`${holidayLabel.monthRegularStaff} (${selectedWithOutShift?.length})`}
            onCheckAllChange={() => handleCheckAll(false)}
            selectAllChecked={selectedAllWithOutShift}
          />
        </div>
      </div>

      <div className="sticky-save-button">
        <div className="col-12 d-flex justify-content-end">
          <BaseButton
            type="submit"
            onClick={handleSave}
            className="btn btn-success"
          >
            {holidayLabel.Save}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default ManageShiftStaff;
