import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Badge, Label } from "reactstrap";

const BaseCheckbox = ({
  id,
  label,
  name,
  checked,
  indeterminate,
  onChange,
  className,
  checkboxClassName,
  labelClassName,
  badge,
  badgeClassName, 
  disabled,
  required,
  ...props
}) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className={` ${className}`}>
      <input
        name={name}
        id={id}
        type="checkbox"
        ref={checkboxRef}
        className={`me-2 ${checkboxClassName}`}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <Label className={`mb-0 ${labelClassName}`} htmlFor={id}>
        {label}
        {required && <span className="text-danger">*</span>}
      </Label>
      {badge !== undefined && (
        <Badge className={`ms-2 ${badgeClassName}`} color="primary">
          {badge}
        </Badge>
      )}
    </div>
  );
};

BaseCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  badge: PropTypes.node,
  badgeClassName: PropTypes.string,
};

BaseCheckbox.defaultProps = {
  checked: false,
  indeterminate: false,
  onChange: () => {},
  className: "",
  labelClassName: "",
    badge: null,
  badgeClassName: ""
};

export default BaseCheckbox;