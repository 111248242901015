import { ATTENDANCEAPPROVALLIST, EDITATTENDANCEAPPROVAL, EDITLEAVEAPPROVAL, LEAVEAPPROVALLIST } from "./apiRoutes";
import { authData } from "./Service";

export const attendanceApprovalList = async (data) => {
  const response = await authData.post(ATTENDANCEAPPROVALLIST, data);
  return response?.data;
};
export const editAttendanceApproval = async (data) => {
  const response = await authData.put(`${EDITATTENDANCEAPPROVAL}`, data);
  return response?.data;
};
export const leaveApprovalList = async (data) => {
  const response = await authData.post(LEAVEAPPROVALLIST, data);
  return response?.data;
};
export const editLeaveApproval = async (data) => {
  const response = await authData.put(`${EDITLEAVEAPPROVAL}`, data);
  return response?.data;
};