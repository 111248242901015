import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { toast } from "react-toastify";
import { centerApi } from "../../Api/common";
import {
  All,
  CENTER_IDS,
  checkStatusCodeSuccess,
  getItem,
  handleResponse,
  RupeesSymbol,
} from "../../Constant/common";
import BaseSelect from "../../BaseComponents/BaseSelect";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseInput from "../../BaseComponents/BaseInput";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { centerWiseRevenueApi, totalRevenueApi } from "../../Api/revenueApi";
import { durationOption, revenueEnums } from "../../Constant/Revenue/revenue";
import { SelectPlaceHolder } from "../../Constant/validation";
import { notFound } from "../../Constant";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        color: "rgba(200, 200, 200, 0.2)",
      },
    },
  },
  maxBarThickness: 50,
};

const Revenue = () => {
  const [selectedCenter, setSelectedCenter] = useState(All);
  const [startYear, setStartYear] = useState(2024);
  const [endYear, setEndYear] = useState(2024);
  const [centerData, setCenterData] = useState([]);
  const [duration, setDuration] = useState("Monthly");
  const [loader, setLoader] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [total, setTotal] = useState(null);
  const storedCenterIds = getItem(CENTER_IDS);
  const centerDataStorage = storedCenterIds ? JSON.parse(storedCenterIds) : [];

  const fetchData = () => {
    setLoader(true);
    centerApi({})
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          let data = resp?.data?.map((item) => ({
            value: String(item?.id),
            label: String(item?.center_name),
            id: String(item?.id),
          }));
          if (centerDataStorage?.includes(All)) {
            data.unshift({ value: All, label: All, id: All });
          }
          setCenterData(data);
        } else {
          setCenterData([]);
        }
      })
      .catch((err) => {
        setCenterData([]);
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const graphData = () => {
    if (selectedCenter === All) {
      const payload = { key: duration };
      if (duration === revenueEnums.yearly) {
        payload.startYear = String(startYear);
        payload.endYear = String(endYear);
      } else {
        payload.Year = String(startYear);
      }

      totalRevenueApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            const years = Object.keys(resp?.data?.yearlyData?.years);
            setTotal(resp?.data?.yearlyData);
            const revenue = years?.map(
              (year) => resp?.data?.yearlyData?.years[year]?.revenue || 0
            );
            const chart = {
              labels: years,
              datasets: [
                {
                  label: revenueEnums.revenue,
                  data: revenue,
                  backgroundColor: "rgba(75, 192, 192, 0.5)",
                },
              ],
            };
            setChartData(chart);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      const payload =
        duration === revenueEnums.yearly
          ? {
              key: duration,
              startYear: String(startYear),
              endYear: String(endYear),
              center_id: Number(selectedCenter),
            }
          : {
              key: duration,
              Year: String(startYear),
              center_id: Number(selectedCenter),
            };
      centerWiseRevenueApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            const years = Object.keys(resp?.data?.yearlyData?.years);
            setTotal(resp?.data?.yearlyData);
            const revenue = years?.map(
              (year) => resp?.data?.yearlyData?.years[year]?.revenue || 0
            );
            const chart = {
              labels: years,
              datasets: [
                {
                  label: revenueEnums.revenue,
                  data: revenue,
                  backgroundColor: "rgba(75, 192, 192, 0.5)",
                },
              ],
            };
            setChartData(chart);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    graphData();
  }, [selectedCenter, startYear, endYear, duration]);

  return (
    <div>
      <h5>{revenueEnums.revenue}</h5>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="card rounded mb-0 mx-0">
        <div className="row justify-content-center">
          <div className="col-lg-12 row">
            <div className="col-lg-3">
              <BaseSelect
                id="center"
                name="center"
                label={revenueEnums.center}
                className="select-border"
                options={centerData}
                placeholder={SelectPlaceHolder(revenueEnums.center)}
                handleChange={(field, value) => {
                  setSelectedCenter(value);
                }}
                value={selectedCenter}
              />
            </div>
            <div className="col-lg-3">
              <BaseSelect
                id="duration"
                name="duration"
                label={revenueEnums.duration}
                className="select-border"
                options={durationOption}
                placeholder={SelectPlaceHolder(revenueEnums.duration)}
                handleChange={(field, value) => {
                  setDuration(value);
                }}
                value={duration}
              />
            </div>
            <div className="col-lg-3">
              <BaseInput
                name="start_year"
                type="number"
                label={revenueEnums.startYear}
                placeholder={PlaceHolderFormat(revenueEnums.startYear)}
                value={startYear}
                handleChange={(e) => setStartYear(e.target.value)}
              />
            </div>
            {duration === revenueEnums.yearly && (
              <div className="col-lg-3">
                <BaseInput
                  name="end_year"
                  type="number"
                  label={revenueEnums.endYear}
                  placeholder={PlaceHolderFormat(revenueEnums.endYear)}
                  value={endYear}
                  handleChange={(e) => setEndYear(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div class="row">
          <div class="col-sm-6 col-xl-3 col-lg-3">
            <div class="card o-hidden">
              <div class="bg-secondary b-r-4 card-body">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag"
                    >
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                  </div>
                  <div class="media-body">
                    <span class="m-0">Total Income</span>
                    <h4 class="mb-0 counter">
                      {total?.totalIncome
                        ? RupeesSymbol(total?.totalIncome)
                        : handleResponse.nullData}
                    </h4>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag icon-bg"
                    >
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <i data-feather="database"></i>
                  </div>
                  <div class="media-body">
                    <span class="m-0">Total Centers:</span>
                    <h4 class="mb-0 counter">{"5"}</h4>
                    <i class="icon-bg" data-feather="database"></i>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 col-lg-3">
            <div class="card o-hidden">
              <div class="bg-warning b-r-4 card-body">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <i data-feather="database"></i>
                  </div>
                  <div class="media-body">
                    <span class="m-0">Total Expense:</span>
                    <h4 class="mb-0 counter">
                      {" "}
                      {total?.totalExpense
                        ? RupeesSymbol(total?.totalExpense)
                        : handleResponse.nullData}
                    </h4>
                    <i class="icon-bg" data-feather="database"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 col-lg-3">
            <div class="card o-hidden">
              <div class="bg-danger b-r-4 card-body">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <i data-feather="database"></i>
                  </div>
                  <div class="media-body">
                    <span class="m-0">Total Revenue:</span>
                    <h4 class="mb-0 counter">
                      {" "}
                      {total?.totalRevenue
                        ? RupeesSymbol(total?.totalRevenue)
                        : handleResponse.nullData}
                    </h4>
                    <i class="icon-bg" data-feather="database"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 col-lg-3">
            <div class="card o-hidden">
              <div class="bg-success b-r-4 card-body">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <i data-feather="database"></i>
                  </div>
                  <div class="media-body">
                    <span class="m-0">Total Center:</span>
                    <h4 class="mb-0 counter">{centerData?.length}</h4>
                    <i class="icon-bg" data-feather="database"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
        className="card mt-4"
      >
        <div style={{ width: "80%", maxWidth: "800px", aspectRatio: "4 / 3" }}>
          {chartData ? (
            <Bar data={chartData} options={chartOptions} />
          ) : (
            <>
              <div className="card p-4 text-center">
                {notFound.dataNotFound}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Revenue;
