import { authData } from "./Service";
import { EXPORTACCREDITATIONLIST, EXPORTASSESSMENTDATA, EXPORTBATCHLIST, EXPORTPLACEMENTLIST } from "./apiRoutes";

export const exportBatchList = async (data) => {
  const response = await authData.post(EXPORTBATCHLIST, data);
  return response?.data;
};

export const exportPlacementList = async (data) => {
  const response = await authData.post(EXPORTPLACEMENTLIST, data);
  return response?.data;
};

export const exportAccreditation = async (data) => {
  const response = await authData.post(EXPORTACCREDITATIONLIST, data);
  return response?.data;
};

export const exportAssessmentData = async (data) => {
  const response = await authData.post(EXPORTASSESSMENTDATA, data);
  return response?.data;
};