export const careerLable = {
    contactNo : 'Contact No',
    candidateName : 'Candidate Name',
    candidateNameKey : 'candidate_name',
    contactNoKey : 'contact_no',
    center : 'Center',
    centerKey : 'center.center_name',
    portalRemarks : 'Portal Remarks',
    candidateRemarks : 'candidate_remarks',
    remarks : 'Remarks',
    remarksKey : 'remarks',
    careerStatus: "Status Update",
    careerList : 'Career List',
    address:"Address",
    position:"Position",
    resume:"Resume",
    email:"Email Address",
    viewResume:"View Resume"
}
export const StatusOption = [
    { id: 0, value: "", label: "Select Status" },
    { id: 1, value: "Connected", label: "Connected" },
    { id: 2, value: "Pending", label: "Pending" },
  ];