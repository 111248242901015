export const categoryLable = {
    id:'Id',
    idkey:'id',
    Action: 'Action',
    categoryName: 'Category',
    categoryNameKey: 'category_name',
    category:"Category",
    categoryforname:'category',
    categoryList: 'Category List',
    description: 'Description',
    uploadImage: 'Upload Image',
    sizeError: 'File size exceeds the maximum limit of 1MB.',
    imageError:'Image is required',
    deleteBoady:' Are you sure you want to delete this image?',
    dltTitle: 'Delete Confirmation',
    yes:"Yes"
}