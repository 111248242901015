import React, { useEffect, useState } from "react";
import {
  attendanceApprovalList,
  editAttendanceApproval,
} from "../../Api/ApprovalApi";
import {
  checkStatusCodeSuccess,
  dateUpdate,
  extractDate,
  extractDay,
  extractYearWiseDate,
  handleResponse,
  onErrorImage,
  TruncateWithTooltip,
} from "../../Constant/common";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSearchLine,
} from "react-icons/ri";
import BaseInput from "../../BaseComponents/BaseInput";
import { approvalLabel } from "../../Constant/HR/attendanceApproval";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import BaseButton from "../../BaseComponents/BaseButton";
import { toast } from "react-toastify";
import { All, notFound } from "../../Constant";
import { BaseStaffURL } from "../../Api/Service";
import Spinner from "../../BaseComponents/BaseLoader";
import CustomPagination from "../../BaseComponents/BasePagination";
import { useSelectedCenter } from "../CenterContext";
import { attendanceApprovalTitle } from "../../Constant/title";

const StaffAttendance = () => {
    document.title = attendanceApprovalTitle;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [groupedAttendance, setGroupedAttendance] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tooltipStates, setTooltipStates] = useState({});
  const toggleTooltip = (id, type) => {
    setTooltipStates((prevState) => ({
      ...prevState,
      [`${id}-${type}`]: !prevState[`${id}-${type}`],
    }));
  };

  const { selectedCenterPayload } = useSelectedCenter();

  let selectedStaffArray = Object.values(selectedStaff)?.reduce(
    (acc, details) => {
      if (details?.punchInId) {
        acc.push(details?.punchInId);
      }
      if (details?.punchOutId) {
        acc.push(details?.punchOutId);
      }
      return acc;
    },
    []
  );

  const fetchData = async (query) => {
    try {
      setLoader(true);
      const payload = {
        ...selectedCenterPayload,
        date: selectedDate,
        search: query || "",
        pageSize: pageSize,
        pageNumber: currentPage,
      };
      const resp = await attendanceApprovalList(payload);
      if (checkStatusCodeSuccess(resp?.statusCode)) {
        const attendance = resp?.data?.shiftWiseResult || [];
        const groupedAttendance = groupAttendanceByShift(attendance);
        setGroupedAttendance(groupedAttendance);
        setTotalPages(resp?.data?.totalPages);
        setTotalRecords(resp?.data?.totalRecordsCount);
      }
    } catch (err) {
      return err;
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setSelectedStaff({});
    fetchData(searchQuery);
  }, [selectedDate, searchQuery, currentPage, pageSize]);

  const groupAttendanceByShift = (data) => {
    const grouped = data?.reduce((acc, item) => {
      const { attendance_data } = item;
      const { shift } = attendance_data?.staffGeneralInformation || {};
      if (!shift?.id) return acc;

      if (!acc[shift?.id]) {
        acc[shift?.id] = {
          shiftId: shift?.id,
          shiftName: shift?.shift_name,
          listOfStaffShiftWise: [],
        };
      }
      acc[shift?.id]?.listOfStaffShiftWise?.push({
        id: attendance_data?.id,
        staffName: attendance_data?.name,
        punchInDetails: attendance_data?.addedBy[0],
        punchOutDetails: attendance_data?.addedBy[1],
      });
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const handleApprovalEdit = async (approve) => {
    try {
      setLoader(true);
      const payload = {
        attendance_approve: approve
          ? approvalLabel.Approved
          : approvalLabel.Rejected,
        id: selectedStaffArray,
      };
      const resp = await editAttendanceApproval(payload);
      if (checkStatusCodeSuccess(resp?.statusCode)) {
        toast.success(resp?.message);
        setSelectedStaff({});
        setSelectAll(false);
        fetchData();
      } else {
        toast.error(resp?.message);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoader(false);
    }
  };

  const decrementDate = () => {
    setSelectedDate(dateUpdate(selectedDate, approvalLabel.Subtract));
  };

  const incrementDate = () => {
    setSelectedDate(dateUpdate(selectedDate, approvalLabel.Add));
  };

  const handleDateChange = (event) => {
    const newDate = new Date(event?.target?.value);
    if (!isNaN(newDate)) {
      setSelectedDate(newDate);
    }
  };

  const updateStaffSelection = (
    staffList,
    isChecked,
    prevSelectedStaff = {}
  ) => {
    const updatedSelectedStaff = { ...prevSelectedStaff };

    staffList?.forEach((staff) => {
      if (isChecked) {
        if (staff?.punchInDetails?.id) {
          updatedSelectedStaff[staff?.punchInDetails?.id] = {
            punchInId: staff?.punchInDetails?.id,
            punchOutId: staff?.punchOutDetails?.id || null,
          };
        }
      } else {
        delete updatedSelectedStaff[staff?.punchInDetails?.id];
      }
    });

    return updatedSelectedStaff;
  };

  const handleHeaderCheckboxChange = (shiftId, isChecked) => {
    const staffList = groupedAttendance?.find(
      (shift) => shift?.shiftId === shiftId
    )?.listOfStaffShiftWise;

    const updatedSelectedStaff = updateStaffSelection(
      staffList,
      isChecked,
      selectedStaff
    );
    setSelectedStaff(updatedSelectedStaff);
  };

  const handleStaffCheckboxChange = (staff, isChecked) => {
    setSelectedStaff((prev) => updateStaffSelection([staff], isChecked, prev));
  };

  useEffect(() => {
    let updatedSelectedStaff = [];
    let staffSelection = {};

    if (selectAll) {
      const allStaff = groupedAttendance?.flatMap(
        (shift) => shift?.listOfStaffShiftWise || []
      );
      staffSelection = updateStaffSelection(allStaff, true);
      updatedSelectedStaff = Object.keys(staffSelection);
    }

    selectedStaffArray = updatedSelectedStaff; 
    setSelectedStaff(staffSelection);
  }, [selectAll, groupedAttendance]);

  const isStaffSelected = (staff) => {
    return !!selectedStaff[staff.punchInDetails?.id];
  };

  const isShiftSelected = (shiftId) => {
    const shift = groupedAttendance?.find(
      (shift) => shift?.shiftId === shiftId
    );
    return shift?.listOfStaffShiftWise?.every((staff) =>
      isStaffSelected(staff)
    );
  };
  const handleSelectAllChange = (isChecked) => {
    setSelectAll(isChecked);
  };

  const handlePageChange = (pageNumber) => {
    setSelectedStaff({});
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageSizeChange = (e) => {
    const value = e.target.value;
    if (value === All) {
      setPageSize(null);
    } else {
      setPageSize(Number(e.target.value));
    }
    setCurrentPage(1);
  };
  const handleSearchChange = (value) => {
    if (value) {
      setSearchQuery(value);
    }
  };
  return (
    <>
      <div className="mx-3 d-block d-sm-flex justify-content-between align-items-center">
        <h5>{approvalLabel.AttendanceApproval}</h5>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3 p-4">
            <div className="card-body d-md-flex d-block justify-content-between align-items-center">
              <div className="d-flex align-items-center flex-wrap content-size">
                <RiArrowLeftSLine
                  className="fs-4 arrow-icon"
                  onClick={decrementDate}
                />
                <span className="text-center text-date">
                  {extractDate(selectedDate)} |{" "}
                  {extractDay(selectedDate).toUpperCase()}
                </span>
                <RiArrowRightSLine
                  className="fs-4 arrow-icon"
                  onClick={incrementDate}
                />
                <BaseInput
                  type="date"
                  name="date"
                  className="form-control mx-3 flex-grow-1 flex-md-grow-0 w-auto"
                  value={extractYearWiseDate(selectedDate)}
                  handleChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomPagination
        pageSize={pageSize}
        totalRecords={totalRecords}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onNextPage={handleNextPage}
        defaultPageSize={All}
        onPreviousPage={handlePreviousPage}
        onPageSizeChange={handlePageSizeChange}
        onSearchChange={handleSearchChange}
        children={
          <>
            <div className="autoScroll-x overflow-auto py-0">
              {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
              {groupedAttendance?.length === 0 ? (
                <div className="card my-1 py-4 text-center">
                  <div>
                    <RiSearchLine className="fs-2" />
                  </div>
                  <div className="mt-4">
                    <h5>{notFound.dataNotFound}</h5>
                  </div>
                </div>
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    <BaseCheckbox
                      id="select-all-checkbox"
                      label={<h5>{approvalLabel.SelectAll}</h5>}
                      checked={selectAll}
                      onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                  </div>
                  {groupedAttendance?.map((shiftData) => (
                    <div className="card" key={shiftData?.shiftId}>
                      <div className="card-body">
                        <div className="shift-card">
                          <div className="d-flex align-items-center mt-2 mb-2">
                            <BaseCheckbox
                              id={`header-checkbox-${shiftData?.shiftId}`}
                              label={shiftData?.shiftName}
                              checked={isShiftSelected(shiftData?.shiftId)}
                              onChange={(e) =>
                                handleHeaderCheckboxChange(
                                  shiftData?.shiftId,
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                          <div className="staff-list">
                            {shiftData?.listOfStaffShiftWise?.map((staff) => (
                              <div key={staff?.id} className="px-3 py-2 mb-2 border">
                                <div>
                                  <div className="row autoScroll-x overflow-auto">
                                    <div className="col-1 flex-shrink-0 flex-grow-1 mb-0 pb-2">
                                      <BaseCheckbox
                                        className="mt-2"
                                        id={`staff-checkbox-${staff?.id}`}
                                        checked={isStaffSelected(staff)}
                                        onChange={(e) =>
                                          handleStaffCheckboxChange(
                                            staff,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-3 flex-shrink-0 flex-grow-1">
                                      <div>
                                        <div>{staff?.staffName}</div>
                                        <div className="text-muted">
                                          {staff?.punchOutDetails
                                            ?.total_working_hours ??
                                            handleResponse.nullData}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-3 flex-shrink-0 flex-grow-1 align-items-center">
                                      <div className="d-flex">
                                        <img
                                          src={`${BaseStaffURL}${staff?.punchInDetails?.staff_image}`}
                                          alt={staff?.staffName}
                                          className="object-fit-cover me-2"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = onErrorImage;
                                          }}
                                          height={40}
                                          width={40}
                                        />
                                      <div>
                                        <div>
                                          {staff?.punchInDetails?.in_time ??
                                            handleResponse.nullData}
                                        </div>
                                        <div>
                                          {staff?.punchInDetails?.location ?
                                            <TruncateWithTooltip
                                              text={staff?.punchInDetails?.location}
                                              maxLength={25}
                                              tooltipId={`location-${staff?.id}`}
                                              isOpen={tooltipStates[`${staff?.id}-punchIn`] || false}
                                              toggleTooltip={() => toggleTooltip(staff?.id, 'punchIn')}
                                            />
                                            : (
                                              handleResponse.nullData
                                            )}
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                    <div className="col-3 flex-shrink-0 flex-grow-1 align-items-center">
                                      {staff?.punchOutDetails ? (
                                        <>
                                          <div className="d-flex">
                                            <img
                                              src={`${BaseStaffURL}${staff?.punchOutDetails?.staff_image}`}
                                              className="object-fit-cover me-2"
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = onErrorImage;
                                              }}
                                              height={40}
                                              width={40}
                                            />
                                          <div>
                                            <div>
                                              {staff?.punchOutDetails?.out_time ??
                                                handleResponse.nullData}
                                            </div>
                                            <div>
                                              {staff?.punchOutDetails?.location ?
                                              <TruncateWithTooltip
                                                text={staff?.punchOutDetails?.location}
                                                maxLength={25}
                                                tooltipId={`locationOut-${staff?.id}`}
                                                isOpen={tooltipStates[`${staff?.id}-punchOut`] || false}
                                                toggleTooltip={() => toggleTooltip(staff?.id, 'punchOut')}
                                              />
                                              : (
                                                handleResponse.nullData
                                              )}
                                            </div>
                                          </div>
                                          </div>
                                        </>
                                      ) : (
                                        <p>{approvalLabel.PunchOutMessage}</p>
                                      )}
                                    </div>
                                    <div className="col-1 flex-shrink-0 flex-grow-1 align-items-center">
                                      {staff?.punchInDetails
                                        ?.attendance_approve ??
                                        handleResponse.nullData}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        }
      />
      <div className="fixed-bottom-div">
        <div className="row">
          <div className="col-6 d-flex justify-content-start mt-2 ml-4">
            <h5>{`${selectedStaffArray?.length} selected`}</h5>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <BaseButton
              onClick={() => handleApprovalEdit(true)}
              label={approvalLabel.Approve}
              className="btn btn-success me-2"
            />
            <BaseButton
              onClick={() => handleApprovalEdit(false)}
              label={approvalLabel.Reject}
              className="btn btn-danger"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default StaffAttendance;