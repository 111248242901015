import React from "react";
import { RiSearchLine } from "react-icons/ri";
import BaseCheckbox from "../BaseCheckbox";
import { DebouncedInput } from "../../Constant/common";

const BaseCard = ({
  title,
  searchPlaceholder,
  searchValue,
  onSearchChange,
  showSearch = true,
  noDataIcon = <RiSearchLine className="fs-2" />,
  noDataMessage,
  searchItems = [],
  items = [],
  renderItem,
  checkAllLabel,
  onCheckAllChange,
  selectAllChecked = false,
  onItemCheckChange,
  children,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="col-12 row mt-1">
          <h6 className="f-w-600">{title}</h6>
          {items?.length > 0 && (
            <div>
              <DebouncedInput
                type="text"
                placeholder={searchPlaceholder}
                value={searchValue}
                onChange={onSearchChange}
              />
            </div>
          )}
        </div>
        {searchValue ? (
          searchItems?.length === 0 ? (
            <div className="container-fluid">
              <div className="col-12 d-flex justify-content-center">
                <div className="py-4 text-center">
                  <div>{noDataIcon}</div>
                  <div className="mt-4">
                    <h5>{noDataMessage}</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="mx-0 row py-2 align-items-center d-flex border-bottom font-bold">
                <div className="col">
                  <BaseCheckbox
                    id="checkAll"
                    label={checkAllLabel}
                    checked={selectAllChecked}
                    onChange={onCheckAllChange}
                  />
                </div>
              </div>
              {searchItems?.map((searchItems) => (
                <div
                  className="col row mx-0 py-2 align-items-center d-flex border-bottom"
                  key={searchItems.id}
                >
                  <div className="col-12 col-sm-6 col-md-4 col-lg-12">
                    {renderItem(searchItems, onItemCheckChange)}
                  </div>
                  {children && <div className="col">{children}</div>}
                </div>
              ))}
            </div>
          )
        ) : searchItems?.length === 0 ? (
          <div className="container-fluid">
            <div className="col-12 d-flex justify-content-center">
              <div className="py-4 text-center">
                <div>{noDataIcon}</div>
                <div className="mt-4">
                  <h5>{noDataMessage}</h5>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="mx-0 row py-2 align-items-center d-flex border-bottom font-bold">
              <div className="col">
                <BaseCheckbox
                  id="checkAll"
                  label={checkAllLabel}
                  checked={selectAllChecked}
                  onChange={onCheckAllChange}
                />
              </div>
            </div>
            {items?.map((item) => (
              <div
                className="col row mx-0 py-2 align-items-center d-flex border-bottom"
                key={item.id}
              >
                <div className="col-12 col-sm-6 col-md-4 col-lg-12">
                  {renderItem(item, onItemCheckChange)}
                </div>
                {children && <div className="col">{children}</div>}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseCard;
