import { CHANGEPASSWORD, FORGOTPASSWORD, LOGIN, VERIFYEMAIL } from "./apiRoutes";
import { authData, nonAuthData } from "./Service";

export const loginApi = async (data) => {
    const response = await nonAuthData.post(LOGIN, data);
    return response?.data;
  };
export const verifyOtpApi = async (data) => {
  const response = await nonAuthData.post(VERIFYEMAIL, data);
  return response?.data;
};
export const forgotPasswordApi = async (data) => {
  const response = await nonAuthData.post(FORGOTPASSWORD, data);
  return response?.data;
};
export const changePasswordApi = async (data) => {
  const response = await authData.put(CHANGEPASSWORD, data);
  return response?.data;
};