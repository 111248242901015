export const placementLable = {
  candidateName: "Candidate Name",
  candidateKey: "candidate.candidate_name",
  employmentType: "Employment Type",
  employmentTypeKey: "employment_type",
  companyName: "Company Name",
  companyNameKey: "placementEmployer.company_name",
  appointmentLetter: "Appointment Letter",
  appointmentLetterKey: "appointment_letter",
  employmentNature: "Employment Nature",
  jobLocation: "Job Location",
  salary: "Salary",
  salaryPositive: "Salary must be positive",
  joiningDate: "Joining Date",
  closingDate: "Closing Date",
  businessName: "Business Name",
  businessCertificate: "Business Certificate",
  businessNameKey: "business_name",
  businessDetails: "Business Details",
  businessDetailsKey: "business_detail",
  businessCertificateKey: "business_document",
  month: "Month",
  totalWorkingDays: "Total Working Days",
  totalWorkingKey: "total_working_days",
  totalWorkingDaysValidation: "Must be 0 or more",
  salarySlip: "Salary Slip",
  Business: "Business",
  personaldetails: "Personal Details",
  viewLetter: "View Letter",
  viewCertificate: "View Certificate",
  addPlacement: "Add Placement",
  placementList: "Placement List",
  status: "Status",
  batchId: "Batch Id",
  centerName: "Center Name",
  courseName: "Course Name",
  contactNumber: "Contact Number",
  closingDateValidation: "Closing date must be after start date",
  noLetter: "No Letter",
  noCertificate: "No Certificate",
  viewSlip: "View Slip",
  noSlip: "No Slip",
  noBankStatement: "No Statement",
  workingDayKey: "working_days",
  monthKey: "month",
  salarySlipKey: "salary_slip",
  bankStatementKey: "bank_statement",
  fileSizeValidation: "File size exceeds the limit (1MB)",
  fileAllowed:
    " Note:In Salary slip, only (.jpg,.jpeg,.png,.pdf.doc,.docx) file is allowed",
  fileAllowedAppointmentLetter:
    " Note:In Appointment Letter, only (.jpg,.jpeg,.png,.pdf.doc,.docx) file is allowed",
  fileAllowedBussinessCrestificate:
    "Note:In Business Certificate, only (.jpg,.jpeg,.png,.pdf.doc,.docx) file is allowed",
  fileAllowedSalarySlip:
    "Note:In Salary Slip, only (.jpg,.jpeg,.png,.pdf.doc,.docx) file is allowed",
  contactName: "Contact Person Name",
  contactNo: "Contact Number",
  bankStatement: "Bank Statement",
  viewBankStatement: "View Statement",
};

export const placementViewLable = {
  candidateName: "Candidate Name:",
  employmentType: "Employment Type:",
  contactNumber: "Contact Number:",
  emailAddress: "Email Address:",
  slarySlipDetails: "Salary Slip Details",
  salarySlip: "Salary Slip",
  viewFile: " View File",
  notDataFound: "No salary slip details found.",
  CandidatePlacementDetails: "Candidate Placement Details",
  editSlipTitle: "Edit Salary Slip",
  addSlipTitle: "Add Salary Slip",
};
export const Options = [
  {
    value: "Business",
    label: "Business",
  },
  { value: "Job", label: "Job" },
];
export const EmploymentNatureOptions = [
  { value: "Permanent", label: "Permanent" },
  { value: "Contract", label: "Contract" },
];
