export const DashboardHr = {
  title:"Dashboard",
  ATTENDENCEVIEW: "Detailed Attendance View",
  PRESENT: "Present",
  ABSENT: "Absent",
  NOTMARKED: "Not Marked",
  UNPAID:"Unpaid",
  HALFDAY: "Half Day",
  ONLEAVE: "On Leave",
  COMPOFF: "Comp Off",
  HOLIDAY: "Holiday",
  WEEKOFF: "Week Off",
  DEACTIVATED: "Deactivated",
  ATTENDENCEREPORT: "Attendance Muster Roll Reports",
  EXPANDALL: "Expand All",
  COLLAPSEALL: "Collapse All",
  DOWNLOAD: "Download",
  MONTHLYREGULAR: "Monthly Regular",
  STAFFNAME: "Staff Name",
  PAIDLEAVE: "Leave",
  UNMARKED: "Unpaid Leave",
  PaidLeaveStatus: "Paid Leave",
  TOTALATTENDENCE: "Total Attendance",
  DAYS: "DAYS",
  WorkingHoursLabel: "WH",
  PresentLabel: "P",
  AbsentLabel: "A",
  MONTH: "Month",
  name: "name",
  present: "present",
  absent: "absent",
  halfday: "halfDay",
  paidleave: "paidLeave",
  unmarked: "Unpaid",
  compOff: "compOff",
  holiday: "holiday",
  weekoff: "weekoff",
  unpaid:"unpaidLeave",
  total: "total",
  P: "P",
  A: "A",
  CO: "CO",
  H: "H",
  HD: "HD",
  PL: "L",
  NM: "NM",
  UL:"UL",
  WO: "WO",
  Status: "Status",
};
export const rows = [
  {
    id: 1,
    name: "Pratik...",
    present: 1,
    absent: "-",
    halfDay: "-",
    paidLeave: 2,
    unmarked: "-",
    compOff: "-",
    total: "1P",
  },
  {
    id: 2,
    name: "Amit...",
    present: 6,
    absent: "-",
    halfDay: "-",
    paidLeave: 2,
    unmarked: "-",
    compOff: "-",
    total: "6P",
  },
  {
    id: 3,
    name: "Vidhi...",
    present: 10,
    absent: 2,
    halfDay: "-",
    paidLeave: 2,
    unmarked: "-",
    compOff: "-",
    total: "8P",
  },
  {
    id: 4,
    name: "Riya...",
    present: 9,
    absent: 1,
    halfDay: "-",
    paidLeave: 2,
    unmarked: "-",
    compOff: "-",
    total: "8P",
  },
];

export const tableHeaders = [
  { label: DashboardHr.STAFFNAME, key: DashboardHr.name },
  { label: DashboardHr.PRESENT, key: DashboardHr.present },
  { label: DashboardHr.ABSENT, key: DashboardHr.absent },
  { label: DashboardHr.HALFDAY, key: DashboardHr.halfday },
  { label: DashboardHr.PAIDLEAVE, key: DashboardHr.paidleave },
  { label: DashboardHr.NOTMARKED, key: DashboardHr.unmarked },
  { label: DashboardHr.COMPOFF, key: DashboardHr.compOff },
  { label: DashboardHr.HOLIDAY, key: DashboardHr.compOff },
  { label: DashboardHr.WEEKOFF, key: DashboardHr.weekoff },
  { label: DashboardHr.UNPAID, key: DashboardHr.unpaid },
  { label: DashboardHr.TOTALATTENDENCE, key: DashboardHr.total },
];

export const attendanceStats = [
  { label: DashboardHr.PRESENT, value: 13 },
  { label: DashboardHr.ABSENT, value: 0 },
  { label: DashboardHr.NOTMARKED, value: 0 },
  { label: DashboardHr.HALFDAY, value: 0 },
  { label: DashboardHr.ONLEAVE, value: 0 },
  { label: DashboardHr.COMPOFF, value: 2 },
  { label: DashboardHr.DEACTIVATED, value: 4 },
];

export const getDaysInMonth = (month) => {
  return Array.from({ length: month.daysInMonth() }, (_, index) => ({
    day: index + 1,
    dayOfWeek: month.date(index + 1).format("ddd")[0],
  }));
};
