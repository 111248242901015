export const holidayLabel = {
  editTemplate: "Edit Template",
  manageStaff: "Manage Staff List",
  holidayTemplate: "Holiday Template",
  holidayList: "Holiday List",
  addHoliday: "Add Holiday",
  holidayName: "Holiday Name",
  holidayDate: "Holiday Date",
  annualHolidayPeriod: "Annual Holiday Period",
  templateName: "Template Name",
  holidayTemplateTitle: "Holiday Calendar Template",
  editHolidayTemplateTitle: "Edit Holiday Calendar Template",
  Back: "Back",
  Save: "Save & Apply",
  businessSetting: "Business Settings",
  startMonth: "Start Month",
  manageStaffList: "Apply Template To Staff",
  searchStaff: "Search staff",
  staffWithHoliday: "Staff With Holiday",
  staffWithoutHoliday: "Staff Without Holiday",
  staffApplied: "Staff Applied",
  deleteConfirmation: "Delete Confirmation",
  deleteMessage: "Are you sure you want to delete this holiday?",
  Yes: "Yes",
  holidays: "holidays",
  holidayStart: "holidayStart",
  holidayEnd: "holidayEnd",
  Delete: "Delete",
  createTemplate: "Create",
  holiday: "holiday",
  monthRegularStaff: "Monthly Regular Staff",
  templateNameKey:"templateName",
  Deactive:"Deactive",
  with:"with",
  without:"without",
  months:"months",
  endMonth:"End Month",
  To:"To",
  dateValidation:"Holiday Date must be between",
  holidayDateValidation:"Holiday date is "
};

export const cardData = [
  {
    id: 1,
    title: "Holiday",
    holidays: "4 Holidays",
    appliedStaff: "Applied 17 Staff(s)",
  },
  {
    id: 2,
    title: "New Year",
    holidays: "9 Holidays",
    appliedStaff: "Applied 10 Staff(s)",
  },
];
export const staffList = [
  { id: 1, name: "John Doe" },
  { id: 2, name: "Jane Smith" },
  { id: 3, name: "Michael Brown" },
  { id: 4, name: "Emily Davis" },
];
export const staffWithoutHolidayList = [
  { id: 1, name: "John Doe" },
  { id: 2, name: "Jane Smith" },
  { id: 3, name: "Michael Brown" },
  { id: 4, name: "Emily Davis" },
];
