import { ACTIVEINACTIVESTAFF, ADDSTAFFHR, DELETESTAFFHR, EDITSTAFFHR, LISTOFHOLIDAYDROP, LISTOFLEAVETEMPLATEDROP, LISTOFREPORTINGDROP, LISTOFROLEFORSTAFF, LISTOFSHIFTDROP, LISTOFSTAFFHR, STAFFACCESS, STAFFIMAGE, VIEWPROFILE, VIEWSTAFFHR, VIEWSTAFFIDS } from "./apiRoutes";
import { authData } from "./Service";

export const listOfStaffApi = async (data) => {
    const response = await authData?.post(LISTOFSTAFFHR, data);
    return response?.data;
}
export const addStaffApi = async (data) => {
    const response = await authData?.post(ADDSTAFFHR, data);
    return response?.data;
}
export const viewStaffApi = async (id) => {
    const response = await authData?.get(`${VIEWSTAFFHR}${id}`);
    return response?.data;
}
export const viewProfileApi = async (id) => {
  const response = await authData?.get(`${VIEWPROFILE}${id}`);
  return response?.data;
};
export const editStaffApi = async (id, data) => {
    const response = await authData?.put(`${EDITSTAFFHR}${id}`, data);
    return response?.data;
}
export const deleteStaffApi = async (id, data) => {
    const response = await authData?.put(`${DELETESTAFFHR}${id}`, data);
    return response?.data;
}
export const viewStaffIDApi = async () => {
    const response = await authData?.get(VIEWSTAFFIDS);
    return response?.data;
}
export const listOfLeaveTemplateApi = async (data) => {
    const response = await authData?.post(LISTOFLEAVETEMPLATEDROP,data);
    return response?.data;
}
export const listOfHolidayApi = async (data) => {
    const response = await authData?.post(`${LISTOFHOLIDAYDROP}`,data);
    return response?.data;
}
export const listOfShiftApi = async (data) => {
    const response = await authData?.post(LISTOFSHIFTDROP,data);
    return response?.data;
}
export const listOfReportingManagerApi = async (data) => {
    const response = await authData?.post(`${LISTOFREPORTINGDROP}`,data);
    return response?.data;
}
export const listOfRoleStaffApi = async () =>{
    const response = await authData?.get(`${LISTOFROLEFORSTAFF}`);
    return response?.data;
}
export const activeInactiveStaffApi = async (id, data) => {
    const response = await authData?.put(`${ACTIVEINACTIVESTAFF}${id}`, data);
    return response?.data;
}
export const hrStaffImageApi = async (id) => {
    const response = await authData?.get(`${STAFFIMAGE}${id}`);
    return response?.data;
}
export const staffAccessApi = async (data) => {
    const response = await authData?.put(`${STAFFACCESS}`,data);
    return response?.data;
}