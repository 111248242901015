export const CandidateLabel = {
  candidateDetails: "Candidate Details",
  candidateIdKey: "candidate_id",
  back: "Back",
  personalDetails: "Personal Details",
  name: "Name:",
  age: "Age:",
  email: "Email:",
  contactNumber: "Contact Number:",
  aadharNumber: "Aadhar Number:",
  address: "Address:",
  gender: "Gender:",
  otherDetails: "Other Details",
  fatherName: "Father Name:",
  motherName: "Mother Name:",
  qualification: "Qualification:",
  category: "Category:",
  sector: "Sector:",
  centerName: "Center Name:",
  courseName: "Course Name:",
  batchId: "Batch Id:",
  status: "Status:",
  reference: "Reference:",
  staffName: "Staff Name",
  referral: 'Referral',
  source: "Source:",
  placementDetails: "Placement Details (If any)",
  companyName: "Company Name:",
  position: "Position:",
  joiningDate: "Joining Date:",
  appointmentLetter: "Appointment Letter:",
  viewAppointmentLetter: "View Appointment Letter",
  certified: "Certified",
  uploadCertificate: "Upload Certificate",
  assessment: "Assessment",
  assessmentDate: "Select Assessment Date",
  certificate: "Certificate",
  certificate1: "Certificate:",
  assessmentDateValidation: "Assessment date",
  assessmentLable: "Assessment Date:",
  viewCertificate: "View Document", 
  editCandidate: "Edit Candidate",
  dateOfBirth: "Date Of Birth",
};

export const forbiddenErr = "403 | This page is forbidden."