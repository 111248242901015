import React from "react";
import { Link } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { LateEntryHR } from "../../../Constant/HR_LateEntry/LateEntry";
import BaseButton from "../../../BaseComponents/BaseButton";

const LateEntry = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="page-header dash-breadcrumb py-3">
          <div className="row">
            <div className="col-6">
              <div>
                <h4 className="f-w-600">{LateEntryHR.LATEENTRYRULE}</h4>
              </div>
            </div>
            <div className="col-6  d-flex justify-content-end align-items-right">
              <Link to="/hr_setting">
                <BaseButton className="btn btn-pill btn-primary">
                  {LateEntryHR.BACK}
                </BaseButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12  d-flex justify-content-end align-items-right">
            <a
              href="/hr_edit_late_entry"
              className="btn btn-pill btn-primary mb-3"
            >
              {LateEntryHR.ADD}
            </a>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card project-box">
          <div className="card-body border">
            <div className="row pb-2 border-bottom">
              {LateEntryHR.SHOWINGRESULT}
            </div>
            <div className="row mt-4 pb-2 border-bottom">
              <div className="col-6">
                <div>
                  <h6 className="f-w-600">{LateEntryHR.RULENAME}</h6>
                </div>
              </div>
              <div className="col-3">
                <h6>{LateEntryHR.STAFFASSIGED}</h6>
              </div>
              <div className="col-3">
                <h6>{LateEntryHR.GRACEPERIOD}</h6>
              </div>
            </div>
            <div className="row mt-4 pb-2 d-flex border-bottom">
              <div className="col-6">
                <div>
                  <div className="">{LateEntryHR.MINSALARY}</div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  9 <a href="/hr_edit_late_entry">{LateEntryHR.EDITSTAFF}</a>
                </div>
              </div>
              <div className="col-2">
                <div>{LateEntryHR.FIFTEENMIN}</div>
              </div>
              <div className="col-1 justify-content-end text-right">
                <a href="/hr_edit_late_entry">
                  <div className="fs-size">
                    <BiDotsVerticalRounded />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LateEntry;
