import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BaseCheckbox from "../../../../BaseComponents/BaseCheckbox";
import BaseButton from "../../../../BaseComponents/BaseButton";
import Spinner from "../../../../BaseComponents/BaseLoader";
import BaseCard from "../../../../BaseComponents/BaseCard";
import { notFound } from "../../../../Constant";
import { holidayLabel } from "../../../../Constant/HR_HolidayTemplate/holidayTemplate";
import { checkStatusCodeSuccess, rendorBackButton } from "../../../../Constant/common";
import {
  editManageStaff,
  manageStaffList,
} from "../../../../Api/HolidayTemplate";
import { useSelectedCenter } from "../../../CenterContext";

const ManageStaff = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const [selectedWithHoliday, setSelectedWithHoliday] = useState([]);
  const [selectedWithoutHoliday, setSelectedWithoutHoliday] = useState([]);
  const [searchWithHoliday, setSearchWithHoliday] = useState("");
  const [searchWithoutHoliday, setSearchWithoutHoliday] = useState("");
  const [selectAllWithHoliday, setSelectAllWithHoliday] = useState(true);
  const [selectAllWithoutHoliday, setSelectAllWithoutHoliday] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchSelectedHoliday, setSearchSelectedHoliday] = useState([]);
  const [searchSelectedWithOutHoliday, setSearchSelectedWithOutHoliday] =
    useState([]);
  const { selectedCenterPayload } = useSelectedCenter();
  const fetchData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      selectedStaffSearch: searchWithHoliday,
      unselectedStaffSearch: searchWithoutHoliday,
    };
    manageStaffList(templateId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const selectedStaff = res?.data?.selectedStaff || [];
          const unselectedStaff = res?.data?.unselectedStaff || [];
          setSelectedWithHoliday(selectedStaff);
          setSelectedWithoutHoliday(unselectedStaff);
        } else {
          setSelectedWithHoliday([]);
          setSelectedWithoutHoliday([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setSelectedWithHoliday([]);
        setSelectedWithoutHoliday([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchSearchData = () => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      selectedStaffSearch: searchWithHoliday,
      unselectedStaffSearch: searchWithoutHoliday,
    };
    manageStaffList(templateId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          const selectedStaff = res?.data?.selectedStaff || [];
          const unselectedStaff = res?.data?.unselectedStaff || [];
          setSearchSelectedHoliday(selectedStaff);
          setSearchSelectedWithOutHoliday(unselectedStaff);
        } else {
          setSelectedWithHoliday([]);
          setSelectedWithoutHoliday([]);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setSelectedWithHoliday([]);
        setSelectedWithoutHoliday([]);
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [templateId]);
  useEffect(() => {
    fetchSearchData();
  }, [templateId, searchWithHoliday, searchWithoutHoliday]);

  const handleCheckboxChange = (staff, isWithHoliday) => {
    const updateList = (list, setList) => {
      const updatedList = list?.map((item) =>
        item?.id === staff.id ? { ...item, selected: !item?.selected } : item
      );
      setList(updatedList);
      return updatedList?.filter((item) => !item?.selected).length === 0;
    };

    if (isWithHoliday) {
      setSelectAllWithHoliday(
        updateList(selectedWithHoliday, setSelectedWithHoliday)
      );
    } else {
      setSelectAllWithoutHoliday(
        updateList(selectedWithoutHoliday, setSelectedWithoutHoliday)
      );
    }
  };

  const handleCheckAll = (isWithHoliday) => {
    if (isWithHoliday) {
      setSelectAllWithHoliday(!selectAllWithHoliday);
      setSelectedWithHoliday((prev) =>
        prev?.map((item) => ({ ...item, selected: !selectAllWithHoliday }))
      );
    } else {
      setSelectAllWithoutHoliday(!selectAllWithoutHoliday);
      setSelectedWithoutHoliday((prev) =>
        prev?.map((item) => ({ ...item, selected: !selectAllWithoutHoliday }))
      );
    }
  };
  // Function to get IDs of staff based on their selected status
  const getStaffIds = (staffList, isSelected) =>
    staffList
      ?.filter((staff) => staff?.selected === isSelected)
      .map((staff) => staff?.id);
  // Get IDs of staff who are selected (with holiday or without holiday)
  const authIds = [
    ...getStaffIds(selectedWithHoliday, true),
    ...getStaffIds(selectedWithoutHoliday, true),
  ];
  // Get IDs of staff who are not selected (with holiday or without holiday)
  const unassignedUserIds = [
    ...getStaffIds(selectedWithHoliday, false),
    ...getStaffIds(selectedWithoutHoliday, false),
  ];

  const handleSave = () => {
    setLoader(true);
    const payload = {
      authIds,
      unassignedUserIds,
    };
    editManageStaff(templateId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res.statusCode)) {
          toast.success(res?.message);
          navigate("/holidayTemplate");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const renderStaffItem = (staff, isWithHoliday) => (
    <BaseCheckbox
      id={`checkbox-${staff.id}-${
        isWithHoliday ? holidayLabel.with : holidayLabel.without
      }`}
      label={staff.name}
      checked={staff.selected}
      badge={!staff.isActive && holidayLabel.Deactive}
      badgeClassName={!staff.isActive && "bg-light text-dark"}
      onChange={() => handleCheckboxChange(staff, isWithHoliday)}
    />
  );

  return (
    <div className="container-fluid">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="d-flex justify-content-between align-items-center w-full">
        <h5 className="f-w-600">{holidayLabel.manageStaffList}</h5>
        <h1></h1>
        <div className="col-sm-auto col-md-auto mb-2">
          {rendorBackButton(() => navigate("/holidayTemplate"))}
          </div>
      </div>
      <div className="row">
        <div className="col-12">
          {/* Staff with Holiday */}
          <BaseCard
            title={holidayLabel.staffWithHoliday}
            searchPlaceholder={holidayLabel.searchStaff}
            searchValue={searchWithHoliday}
            onSearchChange={setSearchWithHoliday}
            noDataMessage={notFound.dataNotFound}
            searchItems={searchSelectedHoliday}
            items={selectedWithHoliday || []}
            renderItem={(staff) => renderStaffItem(staff, true)}
            checkAllLabel={`${holidayLabel.monthRegularStaff} (${selectedWithHoliday?.length})`}
            onCheckAllChange={() => handleCheckAll(true)}
            selectAllChecked={selectAllWithHoliday}
          />
          {/* Staff without Holiday */}
          <BaseCard
            title={holidayLabel.staffWithoutHoliday}
            searchPlaceholder={holidayLabel.searchStaff}
            searchValue={searchWithoutHoliday}
            onSearchChange={setSearchWithoutHoliday}
            noDataMessage={notFound.dataNotFound}
            searchItems={searchSelectedWithOutHoliday}
            items={selectedWithoutHoliday || []}
            renderItem={(staff) => renderStaffItem(staff, false)}
            checkAllLabel={`${holidayLabel.monthRegularStaff} (${selectedWithoutHoliday?.length})`}
            onCheckAllChange={() => handleCheckAll(false)}
            selectAllChecked={selectAllWithoutHoliday}
          />
        </div>
      </div>

      <div className="mt-3 sticky-save-button">
        <div className="col-12 d-flex justify-content-end">
          <BaseButton
            type="submit"
            onClick={handleSave}
            className="btn btn-success"
          >
            {holidayLabel.Save}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default ManageStaff;
