import { CENTERWISEREVENUE, TOTALREVENUE } from "./apiRoutes";
import { authData } from "./Service";

export const totalRevenueApi = async (data) => {
  const response = await authData.post(TOTALREVENUE, data);
  return response?.data;
};
export const centerWiseRevenueApi = async (data) => {
  const response = await authData.post(CENTERWISEREVENUE, data);
  return response?.data;
};