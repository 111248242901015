export const stockEnums = {
    SrNo:'Sr. No',
    STOCK:'Stock',
    STOCKLIST: 'Stock List',
    ADD_STOCK: 'Add Stock',
    EDIT_STOCK: 'Edit Stock',
    DELETE: 'Delete',
    UPDATE: 'Update',
    DELETE: 'Delete',
    ADD: 'Add',
    EDIT: 'Edit',
    STOCK_TYPE: 'Stock Type',
    DELETE_MESSAGE: 'Are you sure you want to Delete this item?',
    SUBMIT:"Submit"
}