import React, { useEffect, useState } from "react";
import {
  Aadhar,
  AadharBack,
  AadharFront,
  BankPassbook,
  Endorse,
  FatherName,
  MotherName,
  Qualification,
  QualificationCertificate,
  center,
  course,
  editInquiry,
  passport,
  reference,
  sector,
  CategoryOptions,
  radioOptions,
  fileSizeMessage,
  hostelMessage,
  viewFile,
  PendingLabel,
  errorMessageEndorse,
  editInquiryErrorForFile,
  editInquiryErrorForFileSize,
  courseLabel,
  allowedFileTypesInquiry,
  ReferenceOptions,
  PMKVYStaff,
  Referral,
  referenceName,
  Pwd,
  DOB,
  OtherDocument,
} from "../../Constant/Inquiry/Inquiry";
import {
  Address,
  AlternativeContactNumber,
  Cancel,
  CandidateName,
  CandidateNameAsPerAdharCard,
  Category,
  ContactNumber,
  Documents,
  Email,
  EmailAddress,
  Gender,
  Loading,
  OtherDetails,
  PersonalDetails,
  SaveAsDraft,
} from "../../Constant";
import BaseButton from "../../BaseComponents/BaseButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import {
  SelectPlaceHolder,
  aadharRegex,
  digitRegex,
  emailRegex,
  numberRegex,
  validationMessages,
} from "../../Constant/validation";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseSelect from "../../BaseComponents/BaseSelect";
import { StatusCodes } from "http-status-codes";
import {
  centerApi,
  centerWiseCourse,
  fileDeleteApi,
  fileUploadApi,
  qualificationApi,
} from "../../Api/common";
import BaseRadioGroup from "../../BaseComponents/BaseRadio";
import {
  editInquiryApi,
  endorseApi,
  viewCnadidateData,
} from "../../Api/Inquiry";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import { HostelRadio, PwdRadio } from "../../Constant/Hostel/hostel";
import { BaseImageURL } from "../../Api/Service";
import BaseModal from "../../BaseComponents/BaseModal";
import { editInquiryTitle } from "../../Constant/title";
import { checkStatusCodeSuccess, currentDateMax, isRequired, rendorBackButton, todayDate } from "../../Constant/common";
import { listOfUser } from "../../Api/Tot";
import { TotLabels } from "../../Constant/TOT/tot";
import { useSelectedCenter } from "../CenterContext";
import { RiQuestionFill } from "react-icons/ri";
import { hrEnums } from "../../Constant/HR_Staff/hrEnums";

const defaultValidationSchema = yup.object({
  candidate_name: yup
    .string()
    .required(validationMessages.required(CandidateName)),
  contact_no: yup
    .string()
    .required(validationMessages.required(ContactNumber))
    .matches(numberRegex, validationMessages.contactLength(ContactNumber, 10)),
  address: yup.string().required(validationMessages.required(Address)),
  center: yup.string().required(validationMessages.required(center)),
  course: yup.string().required(validationMessages.required(course)),
  aadhar: yup
    .string()
    .notRequired()
    .matches(aadharRegex, validationMessages.contactLength(Aadhar, 12)),
  email: yup
    .string()
    .notRequired()
    .matches(emailRegex, validationMessages.format(Email)),
  dob: yup.date().max(currentDateMax, hrEnums.DOB_VALIDATION).notRequired(),
  alternative_contact_no: yup
    .string()
    .notRequired()
    .matches(
      numberRegex,
      validationMessages.contactLength(AlternativeContactNumber, 10)
    ),
});

let endorseBtn = false;
const EditInquiry = () => {
  document.title = editInquiryTitle;
  document.title = editInquiryTitle;
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnELoader, setBtnELoader] = useState(false);
  const [viewInquiryData, setViewInquiryData] = useState();
  const [qualificationData, setQualificationData] = useState();
  const [centerData, setCenterData] = useState();
  const [courseData, setCourseData] = useState();
  const [error, setError] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  let fileName;
  let file;
  const [selectedRadio, setSelectedRadio] = useState("Male");
  const [radio, setRadio] = useState("No");
  const [pwdRadio, setPwdRadio] = useState("No");
  const [selectedOption, setSelectedOption] = useState('');
  const [userData, setUserData] = useState();
  let data;
  const {selectedCenterPayload} = useSelectedCenter();
  const [existingFiles, setExistingFiles] = useState({
    passport: null,
    aadharFront: null,
    aadharBack: null,
    qualificationCerti: null,
    bankPassbook: null,
    otherDocs: null,
  });
  const [currentImage, setCurrentImage] = useState(null);

  const fetchCenterData = async () => {
    let data;
    try {
      const centerResp = await centerApi(selectedCenterPayload);
      if (checkStatusCodeSuccess(centerResp?.statusCode)) {
        data = centerResp?.data;
        const center = data?.map((item) => ({
          value: item?.id,
          label: item?.center_name,
          id: item?.id,
        }));
        setCenterData(center);
      } else {
        toast.error(centerResp?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return err;
    }
  };

  const fetchQualificationData = async () => {
    let data;
    try {
      const qualificationResp = await qualificationApi();
      if (checkStatusCodeSuccess(qualificationResp?.statusCode)) {
        data = qualificationResp?.data;
        const qualification = data?.map((item) => ({
          value: item?.id,
          label: item?.name,
          id: item?.id,
        }));
        setQualificationData(qualification);
      } else {
        toast.error(qualificationResp?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return err;
    }
  };

  const fetchCandidateData = async (id, payload) => {
    try {
      const candidateResp = await viewCnadidateData(id, payload);
      if (checkStatusCodeSuccess(candidateResp?.statusCode)) {
        const candidateData = candidateResp?.data;
        setViewInquiryData(candidateData);
        setRadio(candidateData?.is_hostel);
        if (candidateData?.inquiryDetail !== null) {
          setSelectedRadio(candidateData?.inquiryDetail?.gender);
        }
        setExistingFiles({
          passport: candidateData?.inquiryDocument?.passport_photo ?? null,
          aadharFront: candidateData?.inquiryDocument?.aadharCard_front ?? null,
          aadharBack: candidateData?.inquiryDocument?.aadharCard_back ?? null,
          qualificationCerti: candidateData?.inquiryDocument?.qualification_certificate ?? null,
          bankPassbook: candidateData?.inquiryDocument?.bank_passbook ?? null,
          otherDocs: candidateData?.inquiryDocument?.other_document ?? null,
        });
      } else {
        toast.error(candidateResp?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return err;
    }
  };

  const endorse = async () => {
    setBtnELoader(true);
    await endorseApi(id)
      .then((resp) => {
        if (
          resp?.statusCode === StatusCodes.ACCEPTED ||
          resp?.statusCode === StatusCodes.OK ||
          resp?.statusCode === StatusCodes.CREATED
        ) {
          toast.success(resp?.message);
          navigate("/dashboard");
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
        setError(err?.response?.data?.message);
        setErrorModal(true);
      })
      .finally(() => {
        setBtnELoader(false);
      });
  };

  const fetchUsers = () => {
    listOfUser({})
      .then((resp) => {
        if (
          resp?.statusCode === StatusCodes.ACCEPTED ||
          resp?.statusCode === StatusCodes.OK ||
          resp?.statusCode === StatusCodes.CREATED
        ) {
          data = resp?.data;
          setUserData(
            data?.map((item) => ({
              value: item?.id,
              label: item?.name,
              id: item?.id,
              email: item?.email,
              contact_no: item?.contact_no,
            }))
          );
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const inquiryForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      candidate_name: viewInquiryData?.candidate_name ?? null,
      contact_no: viewInquiryData?.contact_no ?? null,
      address: viewInquiryData?.address ?? null,
      center: viewInquiryData?.center_id ?? null,
      course: viewInquiryData?.course_id ?? null,
      reference: viewInquiryData?.reference ?? null,
      referral: viewInquiryData?.referral ?? null,
      gender:  viewInquiryData?.inquiryDetail?.gender ?? "Male",
      aadhar: viewInquiryData?.inquiryDetail?.aadhar_no ?? null,
      is_hostel: viewInquiryData?.is_hostel ?? "No",
      is_pwd: viewInquiryData?.is_pwd ?? "No",
      staff_id: viewInquiryData?.reference === PMKVYStaff
          ? viewInquiryData.authUser?.id
          : null,
      email: viewInquiryData?.email ?? null,
      qualification: viewInquiryData
        ? viewInquiryData?.inquiryDetail?.qualification_id
        : null,
      alternative_contact_no: viewInquiryData
        ? viewInquiryData?.inquiryDetail?.alternative_contact_no
        : null,
      dob: viewInquiryData?.dob ?? null,
      category: viewInquiryData?.inquiryDetail?.caste_category ?? null,
      sector: viewInquiryData?.inquiryDetail?.sector ?? null,
      father_name: viewInquiryData?.inquiryDetail?.father_name ?? null,
      mother_name: viewInquiryData?.inquiryDetail?.mother_name ?? null,
      passport: viewInquiryData?.inquiryDocument?.passport_photo ?? null,
      aadharFront: viewInquiryData?.inquiryDocument?.aadharCard_front ?? null,
      aadharBack: viewInquiryData?.inquiryDocument?.aadharCard_back ?? null,
      qualificationCerti: viewInquiryData?.inquiryDocument?.qualification_certificate ?? null,
      bankPassbook: viewInquiryData?.inquiryDocument?.bank_passbook ?? null,
      otherDocs: viewInquiryData?.inquiryDocument?.other_document ?? null,
    },
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      endorseBtn === true ? setBtnELoader(true) : setBtnLoader(true);
      const payload = {
        inquiry: {
          center_id: values.center,
          course_id: values.course,
          candidate_name: values.candidate_name,
          address: values.address,
          contact_no: values.contact_no,
          email: values.email,
          reference: values.reference,
          is_hostel: values.is_hostel,
          is_pwd: values.is_pwd,
          dob: values.dob ? values.dob : null,
        },
        inquiryDetails: {
          aadhar_no: values.aadhar,
          gender: values.gender,
          father_name: values.father_name,
          mother_name: values.mother_name,
          caste_category: values.category,
          qualification_id: values.qualification,
          sector: values.sector,
          batch_time: values.batch_time,
          alternative_contact_no: values.alternative_contact_no,
        },
        inquiryDocument: {
          passport_photo: values.passport,
          aadharCard_front: values.aadharFront,
          aadharCard_back: values.aadharBack,
          bank_passbook: values.bankPassbook,
          qualification_certificate: values.qualificationCerti,
          other_document: values.otherDocs,
        },
      };

      if (values.staff_id) {
        payload.inquiry.staff_id = values.staff_id;
      }
      if (values.referral) {
        payload.inquiry.referral = values.referral;
      }

      editInquiryApi(id, payload)
        .then((resp) => {
          if (
            resp?.statusCode === StatusCodes.ACCEPTED ||
            resp?.statusCode === StatusCodes.OK ||
            resp?.statusCode === StatusCodes.CREATED
          ) {
            toast.success(resp?.message);
            navigate("/dashboard");
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          endorseBtn ? setBtnELoader(false) : setBtnLoader(false);
        });
    },
  });

  const fetchCourse = () => {
    setLoader(true);
    const payload = {
      condition: {
        id: parseInt(inquiryForm.values.center),
      },
    };
    centerWiseCourse(payload)
      .then((resp) => {
        if (
          resp?.statusCode === StatusCodes.ACCEPTED ||
          resp?.statusCode === StatusCodes.OK ||
          resp?.statusCode === StatusCodes.CREATED
        ) {
          const data = resp?.data;
          const courses = data?.flatMap((center) =>
            center?.centerCourse?.map((item) => ({
              value: item.course.id,
              label: item.course.course_name,
              id: item.course.id,
            }))
          );
          setCourseData(courses);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleFileChange = (e, name) => {
    fileName = name;
    file = e?.target?.files[0];
    if (file) {
      if (!allowedFileTypesInquiry.includes(file.type)) {
        toast.error(editInquiryErrorForFile);
        return;
      } else {
        if (file.size > 1024 * 1024) {
          toast.error(editInquiryErrorForFileSize);
          return;
        } else {
          if (existingFiles[fileName]) {
            deleteFile(existingFiles[fileName])
              .then(() => {
                fileUploads();
              })
              .catch((err) => {
                toast.error(err?.response?.data?.message || err?.message);
              });
          } else {
            fileUploads();
          }
        }
      }
    }
  };

  const deleteFile = async (filePath) => {
    if(currentImage){
      const payload={
        generatedFileNames: [filePath]
      };
      fileDeleteApi(payload)
        .then((resp) => {
          if (!checkStatusCodeSuccess(resp?.StatusCodes)) {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
          return err;
        });
    }
  };

  useEffect(() => {
    if (inquiryForm.values.reference) {
      setSelectedOption(inquiryForm.values.reference);
    }
    if (inquiryForm.values.reference === PMKVYStaff) {
      fetchUsers();
    }
  }, [inquiryForm.values.reference]);

  const handleSelectChange = (field, value) => {
    inquiryForm.setFieldValue(field, value);
    if (field === 'reference') {
      setSelectedOption(value);
    }
  };

  const fileUploads = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("files", file);
    fileUploadApi(formData)
      .then((resp) => {
        if (
          resp?.statusCode === StatusCodes.ACCEPTED ||
          resp?.statusCode === StatusCodes.OK ||
          resp?.statusCode === StatusCodes.CREATED
        ) {
          inquiryForm.setFieldValue(fileName, resp?.data[0]);
          setExistingFiles((prev) => ({
            ...prev,
            [fileName]: resp?.data[0],
          }));
          setCurrentImage(resp?.data[0]);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const scrollTop = (errors) => {
    const firstErrorKey = Object.keys(errors)[0];
    const errorElement = document.getElementsByName(firstErrorKey)[0];

    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      errorElement.focus();
    }
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    inquiryForm.setFieldValue("gender", event.target.value);
  };
  const handleChange = (event) => {
    setRadio(event.target.value);
    inquiryForm.setFieldValue("is_hostel", event.target.value);
  };

  const handleChangePwd = (event) => {
  setPwdRadio(event.target.value);
  inquiryForm.setFieldValue("is_pwd", event.target.value);
};  

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const payload = {
        condition: selectedCenterPayload,
      };
      await Promise.all([
        fetchCenterData(),
        fetchQualificationData(),
        fetchCandidateData(id, payload),
      ]);
      setLoader(false);
    };

    fetchData();
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (inquiryForm.values.center !== null) {
      fetchCourse();
    }
  }, [inquiryForm.values.center]);

  return (
    <>
      <div className="px-3 d-flex justify-content-between align-items-center mb-1">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <h5 className="f-w-600">{editInquiry}</h5>
        {rendorBackButton(() => navigate("/"))}
      </div>
      <div className="card mx-3">
        <div className="card-body">
          <form onSubmit={inquiryForm.handleSubmit}>
            <span className="p-2 d-block border-bottom text-dark">
              {PersonalDetails}
            </span>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    label={CandidateNameAsPerAdharCard}
                    name="candidate_name"
                    type="text"
                    placeholder={PlaceHolderFormat(CandidateName)}
                    value={inquiryForm.values.candidate_name}
                    touched={inquiryForm.touched.candidate_name}
                    error={inquiryForm.errors.candidate_name}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={inquiryForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="contact_no"
                    type="number"
                    label={ContactNumber}
                    placeholder={PlaceHolderFormat(ContactNumber)}
                    value={inquiryForm.values.contact_no}
                    touched={inquiryForm.touched.contact_no}
                    error={inquiryForm.errors.contact_no}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={(e) => {
                      if (
                        e?.target?.value?.length <= 10 &&
                        digitRegex.test(e.target.value)
                      ) {
                        inquiryForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="email"
                    type="text"
                    label={EmailAddress}
                    placeholder={PlaceHolderFormat(EmailAddress)}
                    value={inquiryForm.values.email}
                    touched={inquiryForm.touched.email}
                    error={inquiryForm.errors.email}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={inquiryForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="">{Gender}{isRequired}</label>
                  <BaseRadioGroup
                    name="gender"
                    options={radioOptions}
                    selectedValue={selectedRadio}
                    onChange={handleRadioChange}
                    value={inquiryForm.values.gender}
                    touched={inquiryForm.touched.gender}
                    error={inquiryForm.errors.gender}
                    handleBlur={inquiryForm.handleBlur}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2  mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="dob"
                    type="date"
                    label={DOB}
                    placeholder={PlaceHolderFormat(DOB)}
                    value={inquiryForm.values.dob}
                    touched={inquiryForm.touched.dob}
                    error={inquiryForm.errors.dob}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={(e) => {
                      if (e.target.value < todayDate) {
                        inquiryForm.handleChange(e);
                      }
                    }}
                    required={true}
                    max={todayDate}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="alternative_contact_no"
                    type="number"
                    label={AlternativeContactNumber}
                    placeholder={PlaceHolderFormat(AlternativeContactNumber)}
                    value={inquiryForm.values.alternative_contact_no}
                    touched={inquiryForm.touched.alternative_contact_no}
                    error={inquiryForm.errors.alternative_contact_no}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={(e) => {
                      if (
                        e?.target?.value?.length <= 10 &&
                        digitRegex.test(e.target.value)
                      ) {
                        inquiryForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="aadhar"
                    type="text"
                    label={Aadhar}
                    placeholder={PlaceHolderFormat(Aadhar)}
                    value={inquiryForm.values.aadhar}
                    touched={inquiryForm.touched.aadhar}
                    error={inquiryForm.errors.aadhar}
                    handleBlur={inquiryForm.handleBlur}
                    maxLength={12}
                    handleChange={(e) => {
                      if (digitRegex.test(e?.target?.value)) {
                        inquiryForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseSelect
                    name="center"
                    label={center}
                    className="select-border"
                    options={centerData || []}
                    placeholder={SelectPlaceHolder(center)}
                    handleChange={(field, value) => {
                      inquiryForm.setFieldValue(field, value);
                      inquiryForm.setFieldValue(courseLabel, null);
                    }}
                    handleBlur={() => inquiryForm.setFieldTouched(center, true)}
                    value={inquiryForm.values.center}
                    touched={inquiryForm.touched.center}
                    error={inquiryForm.errors.center}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2  mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseSelect
                    name="course"
                    label={course}
                    className="select-border"
                    options={courseData || []}
                    placeholder={SelectPlaceHolder(course)}
                    handleChange={(field, value) => {
                      inquiryForm.setFieldValue(field, value);
                    }}
                    handleBlur={() => inquiryForm.setFieldTouched(course, true)}
                    value={inquiryForm.values.course}
                    touched={inquiryForm.touched.course}
                    error={inquiryForm.errors.course}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <span className="p-2 d-block border-bottom text-dark">
              {OtherDetails}
            </span>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseSelect
                    name="qualification"
                    label={Qualification}
                    className="select-border"
                    options={qualificationData || []}
                    placeholder={SelectPlaceHolder(Qualification)}
                    handleChange={(field, value) => {
                      inquiryForm.setFieldValue(field, value);
                    }}
                    handleBlur={() =>
                      inquiryForm.setFieldTouched(Qualification, true)
                    }
                    value={inquiryForm.values.qualification}
                    touched={inquiryForm.touched.qualification}
                    error={inquiryForm.errors.qualification}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseSelect
                    name="category"
                    label={Category}
                    className="select-border"
                    options={CategoryOptions || []}
                    placeholder={SelectPlaceHolder(Category)}
                    handleChange={(field, value) => {
                      inquiryForm.setFieldValue(field, value);
                    }}
                    handleBlur={() =>
                      inquiryForm.setFieldTouched(Category, true)
                    }
                    value={inquiryForm.values.category}
                    touched={inquiryForm.touched.category}
                    error={inquiryForm.errors.category}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="sector"
                    type="text"
                    label={sector}
                    placeholder={PlaceHolderFormat(sector)}
                    value={inquiryForm.values.sector}
                    touched={inquiryForm.touched.sector}
                    error={inquiryForm.errors.sector}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={inquiryForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="">
                    {hostelMessage}
                    {isRequired}
                  </label>
                  <BaseRadioGroup
                    name="is_hostel"
                    options={HostelRadio}
                    selectedValue={radio}
                    onChange={handleChange}
                    value={inquiryForm.values.is_hostel}
                    touched={inquiryForm.touched.is_hostel}
                    error={inquiryForm.errors.is_hostel}
                    handleBlur={inquiryForm.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="father_name"
                    type="text"
                    label={FatherName}
                    placeholder={PlaceHolderFormat(FatherName)}
                    value={inquiryForm.values.father_name}
                    touched={inquiryForm.touched.father_name}
                    error={inquiryForm.errors.father_name}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={inquiryForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="mother_name"
                    type="text"
                    label={MotherName}
                    placeholder={PlaceHolderFormat(MotherName)}
                    value={inquiryForm.values.mother_name}
                    touched={inquiryForm.touched.mother_name}
                    error={inquiryForm.errors.mother_name}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={inquiryForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseSelect
                    label={reference}
                    name="reference"
                    options={ReferenceOptions}
                    handleChange={handleSelectChange}
                    value={inquiryForm.values.reference}
                    placeholder={SelectPlaceHolder(reference)}
                    required={true}
                  />
                </div>
                {selectedOption === PMKVYStaff && (
                  <div className="col-lg-3">
                    <BaseSelect
                      label={TotLabels.staffName}
                      name="staff_id"
                      className="select-border"
                      handleChange={(field, value) => {
                        inquiryForm.setFieldValue(field, value);
                        const selectedTrainer = userData?.find(
                          (user) => user?.value === value
                        );
                        inquiryForm.setFieldValue(
                          "trainer_id",
                          selectedTrainer.id
                        );
                      }}
                      value={inquiryForm.values.staff_id}
                      placeholder={SelectPlaceHolder(TotLabels.staffName)}
                      options={userData}
                      touched={inquiryForm.touched.staff_id}
                      error={inquiryForm.errors.staff_id}
                      handleBlur={inquiryForm.handleBlur}
                      required={true}
                    />
                  </div>
                )}
                {selectedOption === Referral && (
                  <div className="col-lg-3">
                    <BaseInput
                      name="referral"
                      type="text"
                      label={referenceName}
                      placeholder={PlaceHolderFormat(referenceName)}
                      value={inquiryForm.values.referral}
                      touched={inquiryForm.touched.referral}
                      error={inquiryForm.errors.referral}
                      handleBlur={inquiryForm.handleBlur}
                      handleChange={inquiryForm.handleChange}
                      required={true}
                    />
                  </div>
                )}
                <div className="col-lg-3">
                  <label htmlFor="">
                    {Pwd}
                    {isRequired}
                  </label>
                  <BaseRadioGroup
                    name="is_pwd"
                    options={PwdRadio}
                    selectedValue={pwdRadio}
                    onChange={handleChangePwd}
                    value={inquiryForm.values.is_pwd}
                    touched={inquiryForm.touched.is_pwd}
                    error={inquiryForm.errors.is_pwd}
                    handleBlur={inquiryForm.handleBlur}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="address"
                    type="textarea"
                    label={Address}
                    placeholder={PlaceHolderFormat(Address)}
                    value={inquiryForm.values.address}
                    touched={inquiryForm.touched.address}
                    error={inquiryForm.errors.address}
                    handleBlur={inquiryForm.handleBlur}
                    handleChange={inquiryForm.handleChange}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <span className="p-2 d-flex justify-content-start border-bottom text-dark">
              <span>{Documents}</span>
              <div className="tooltip-container">
                <RiQuestionFill className="tooltip-icon" size={18} />
                <span className="tooltip-text">{fileSizeMessage}</span>
              </div>
            </span>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="passport"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={passport}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={inquiryForm.touched.passport}
                    error={inquiryForm.errors.passport}
                    handleBlur={inquiryForm.handleBlur}
                    required={true}
                  />
                  {inquiryForm.values.passport?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${inquiryForm.values.passport}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="aadharFront"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={AadharFront}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={inquiryForm.touched.aadharFront}
                    error={inquiryForm.errors.aadharFront}
                    handleBlur={inquiryForm.handleBlur}
                    required={true}
                  />
                  {inquiryForm.values.aadharFront?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${inquiryForm.values.aadharFront}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="aadharBack"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={AadharBack}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={inquiryForm.touched.aadharBack}
                    error={inquiryForm.errors.aadharBack}
                    handleBlur={inquiryForm.handleBlur}
                    required={true}
                  />
                  {inquiryForm.values.aadharBack?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${inquiryForm.values.aadharBack}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="qualificationCerti"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={QualificationCertificate}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={inquiryForm.touched.qualificationCerti}
                    error={inquiryForm.errors.qualificationCerti}
                    handleBlur={inquiryForm.handleBlur}
                    required={true}
                  />
                  {inquiryForm.values.qualificationCerti?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${inquiryForm.values.qualificationCerti}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row px-2 mt-2">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    name="bankPassbook"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={BankPassbook}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={inquiryForm.touched.bankPassbook}
                    error={inquiryForm.errors.bankPassbook}
                    handleBlur={inquiryForm.handleBlur}
                    required={true}
                  />
                  {inquiryForm.values.bankPassbook?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${inquiryForm.values.bankPassbook}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="otherDocs"
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    label={OtherDocument}
                    handleChange={(e) => handleFileChange(e, e.target.name)}
                    touched={inquiryForm.touched.otherDocs}
                    error={inquiryForm.errors.otherDocs}
                    handleBlur={inquiryForm.handleBlur}
                  />
                  {inquiryForm.values.otherDocs?.length > 0 && (
                    <span className="fw-normal">
                      <Link
                        target="_blank"
                        className="text-dark"
                        to={`${BaseImageURL}${inquiryForm.values.otherDocs}`}
                      >
                        {viewFile}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-3"></div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end">
              <BaseButton
                className="mx-2"
                color="danger"
                type="button"
                onClick={() => navigate("/dashboard")}
              >
                {Cancel}
              </BaseButton>
              <BaseButton
                className="mx-2"
                color="primary"
                type="submit"
                onClick={async () => {
                  const errors = await inquiryForm.validateForm();
                  scrollTop(errors);
                }}
                disabled={btnLoader}
                loader={btnLoader}
                children={btnLoader ? Loading : SaveAsDraft}
              ></BaseButton>
              <BaseButton
                onClick={() => {
                  endorse();
                }}
                className="mx-2"
                color="success"
                type="button"
                loader={btnELoader}
                disabled={
                  viewInquiryData?.status === PendingLabel || btnELoader
                }
                children={Endorse}
              ></BaseButton>
            </div>
          </form>
        </div>
        <BaseModal
          isOpen={errorModal}
          toggler={() => setErrorModal(false)}
          title={errorMessageEndorse}
          submitText="Submit"
          hasSubmitButton={false}
          bodyClass="endorse-error-modal"
        >
          <ul className="list-group list-group-flush">
            {error?.map((errorMessage, index) => (
              <li key={index} className="list-group-item text-danger">
                {index + 1}. {errorMessage}
              </li>
            ))}
          </ul>
        </BaseModal>
      </div>
    </>
  );
};

export default EditInquiry;
