import React, { useEffect, useState } from "react";
import { ContactNo, Designation, Email, Submit } from "../../Constant";
import { formFields, hrEnums } from "../../Constant/HR_Staff/hrEnums";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import BankDetails from "./bankDetails";
import EmploymentDetails from "./employmentDetails";
import ProfileDetails from "./profileDetails";
import PersonalDetails from "./personalDetails";
import GeneralDetails from "./generalDetails";
import {
  aadharRegex,
  numberRegex,
  panRegex,
  validationMessages,
  ifscRegex,
  zipcodeRegex,
} from "../../Constant/validation";
import { toast } from "react-toastify";
import { centerApi, fileUploadApi, listOfDistrict, listOfState } from "../../Api/common";
import BaseButton from "../../BaseComponents/BaseButton";
import { listOfPositionApi } from "../../Api/positionApi";
import {
  addStaffApi,
  editStaffApi,
  listOfHolidayApi,
  listOfLeaveTemplateApi,
  listOfReportingManagerApi,
  listOfRoleStaffApi,
  listOfShiftApi,
  viewProfileApi,
  viewStaffApi,
  viewStaffIDApi,
} from "../../Api/HRStaffApi";
import { attendanceDataApi } from "../../Api/AttendenceMode";
import {
  checkStatusCodeSuccess,
  deleteUploadedfile,
  getItem,
  isUserProfile,
  rendorBackButton,
  setItem,
  staff,
} from "../../Constant/common";
import Spinner from "../../BaseComponents/BaseLoader";
import { useSelectedCenter } from "../CenterContext";
import { allowedFileTypesInquiry, editInquiryErrorForFile, editInquiryErrorForFileSize } from "../../Constant/Inquiry/Inquiry";

const StaffProfile = () => {
  const location = useLocation();
  const [viewProfileMode, setViewProfileMode] = useState(true);
  const [viewGeneralMode, setViewGeneralMode] = useState(true);
  const [viewPersonalMode, setViewPersonalMode] = useState(true);
  const [viewEmploymentMode, setViewEmploymentMode] = useState(true);
  const [viewBankMode, setViewBankMode] = useState(true);
  const [editProfileMode, setEditProfileMode] = useState(false);
  const [editPersonalMode, setEditPersonalMode] = useState(false);
  const [editGeneralMode, setEditGeneralMode] = useState(false);
  const [editEmploymentMode, setEditEmploymentMode] = useState(false);
  const [editBankMode, setEditBankMode] = useState(false);
  const [selectedPhysical, setSelectedPhysical] = useState(hrEnums.NO);
  const [selectedGender, setSelectedGender] = useState(hrEnums.MALE);
  const [loader, setLoader] = useState(false);
  const [district, setDistrict] = useState([]);
  const [states, setStates] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [staffData, setStaffData] = useState(null);
  const [reportingManager, setReportingManager] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [lastStaffId, setLastStaffId] = useState(null);
  const [currentStaffId, setCurrentStaffId] = useState(null);
  const [holidayData, setHolidayData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedMultiCenter, setSelectedMultiCenter] = useState([]);
  const [attendaceMode, setAttendenceMode] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [selectedCenterIds, setSelectedCenterIds] = useState([]);
  const isEditMode =
    editProfileMode ||
    editGeneralMode ||
    editPersonalMode ||
    editEmploymentMode ||
    editBankMode;
  const navigate = useNavigate();
  const addressDetails = staffData?.staffAddress?.map((item) => {
    return item;
  });
  const { staffId } = useParams();
  const profileId = getItem("id");
  const isProfile = location.pathname === "/profile";
  const isAddStaff = location.pathname === "/staffProfile";
  const {selectedCenterPayload} = useSelectedCenter();
  const fieldToSectionMap = {
    profile: [
      formFields.first_name,
      formFields.middle_name,
      formFields.last_name,
      formFields.designation,
      formFields.role,
      formFields.staff_type,
      formFields.email,
      formFields.contact_no,
      formFields.center,
      formFields.primary_center_id,
      formFields.reporting_manager,
      formFields.staff_id,
    ],
    general: [
      formFields.week_off,
      formFields.holiday_template,
      formFields.leave_template,
      formFields.shift,
      formFields.salary_access,
      formFields.attendance_mode,
    ],
    personal: [
      formFields.gender,
      formFields.dob,
      formFields.blood_group,
      formFields.emergency_contact,
      formFields.physically_challenged,
      formFields.father_name,
      formFields.mother_name,
      formFields.marital_status,
      formFields.spouse_name,
      formFields.address_line1,
      formFields.address_line2,
      formFields.state,
      formFields.district,
      formFields.postal_code,
      formFields.permanent_address_line1,
      formFields.permanent_address_line2,
      formFields.permanent_district,
      formFields.permanent_state,
      formFields.permanent_postal_code,
    ],
    bank: [
      formFields.bank_name,
      formFields.ifsc,
      formFields.account_number,
      formFields.account_holder_name,
    ],
    employment: [
      formFields.joining_date,
      formFields.uan,
      formFields.pan,
      formFields.aadhar_number
    ],
  };

  const handleProfileEdit = () => {
    setEditProfileMode(true);
    setViewProfileMode(false);
  };
  const handleGeneralEdit = () => {
    setEditGeneralMode(true);
    setViewGeneralMode(false);
  };
  const handlePersonalEdit = () => {
    setEditPersonalMode(true);
    setViewPersonalMode(false);
  };
  const handleEmploymentEdit = () => {
    setEditEmploymentMode(true);
    setViewEmploymentMode(false);
  };
  const handleBankEdit = () => {
    setEditBankMode(true);
    setViewBankMode(false);
  };

  const handleProfileDiscard = () => {
    setEditProfileMode(false);
    setViewProfileMode(true);
  };
  const handleGeneralDiscard = () => {
    setEditGeneralMode(false);
    setViewGeneralMode(true);
  };
  const handlePersonalDiscard = () => {
    setEditPersonalMode(false);
    setViewPersonalMode(true);
  };
  const handleEmploymentDiscard = () => {
    setEditEmploymentMode(false);
    setViewEmploymentMode(true);
  };
  const handleBankDiscard = () => {
    setEditBankMode(false);
    setViewBankMode(true);
  };

  const fetchData = () => {
    setLoader(true);
    let data;
    if (!isEditMode && !isAddStaff) {
      const apiCall = isProfile ? viewProfileApi : viewStaffApi;
      apiCall(isProfile ? "" : staffId)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            data = resp?.data;
            setStaffData(data);
            setSelectedGender(data?.staffGeneralInformation?.gender);
            setSelectedPhysical(data?.staffGeneralInformation?.physically_challenged);
            let editweek = data?.assignedWeekOff?.map((item) => ({
              value: item?.week_off,
              label: item?.week_off,
            }));
            let center = data?.center?.map((item) => ({
              value: item?.id,
              label: item?.center_name,
            }));
            handleMultiCenter(center);
            handleMulti(editweek);
            let staffDetails = {
              name: data?.name,
              id: data?.staffGeneralInformation?.staff_id,
              isActive: data?.isActive,
            };
            setItem("staffData", JSON.stringify(staffDetails));
            window.dispatchEvent(new Event("staffDataUpdated"));
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
    }
    if (isEditMode || isAddStaff || (isProfile && isEditMode)){
      listOfState()
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            data = resp?.data;
            setStates(
              data?.map((item) => ({
                value: item?.id,
                label: item?.state_name,
                id: item?.id,
              }))
            );
          } else {
            setStates([]);
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          setStates([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });

      centerApi({...selectedCenterPayload})
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            data = resp?.data;
            setCenterData(
              data?.map((item) => ({
                value: item?.id,
                label: item?.center_name,
                id: item?.id,
              }))
            );
          } else {
            setCenterData([]);
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          setCenterData([]);
          return err;
        });

      listOfPositionApi()
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            let data = resp?.data?.listOfPosition;
            setPositionData(
              data?.map((item) => ({
                value: item?.id,
                label: item?.position_name,
                id: item?.id,
              }))
            );
          } else {
            setPositionData([]);
          }
        })
        .catch((err) => {
          setPositionData([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });

      listOfRoleStaffApi()
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            let data = resp?.data;
            setRoleData(
              data?.map((item) => ({
                value: item?.id,
                label: item?.role_name,
                id: item?.id,
              }))
            );
          } else {
            setRoleData([]);
          }
        })
        .catch((err) => {
          setRoleData([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });

      attendanceDataApi()
        .then((resp) => {
          if (checkStatusCodeSuccess(resp?.statusCode)) {
            setAttendenceMode(resp?.data?.value);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
    }
    if (isAddStaff) {
      viewStaffIDApi()
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            data = resp?.data;
            setCurrentStaffId(data?.currentStaffID);
            setLastStaffId(data?.lastStaffID);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
      }
  };

  const fetchDistrict = (id) => {
    setLoader(true);
    let data;
    const payload = {
      condition: {
        state_id: id,
      },
    };
    listOfDistrict(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          data = resp?.data;
          setDistrict(
            data?.map((item) => ({
              value: item?.id,
              label: item?.district_name,
              id: item?.id,
            }))
          );
        } else {
          setDistrict([]);
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        setDistrict([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchReportingManager = (id) => {
    setLoader(true);
    let data;
    const payload= {
      center_ids: [id],
    };
    listOfReportingManagerApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          data = resp?.data;
          setReportingManager(
            data?.map((item) => ({
              value: item?.id,
              label: item?.name,
              id: item?.id,
            }))
          );
        } else {
          staffForm.setFieldValue("reporting_manager", null);
          setReportingManager([]);
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        staffForm.setFieldValue("reporting_manager", null);
        setReportingManager([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });

      listOfHolidayApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            let data = resp?.data;
            setHolidayData(
              data?.map((item) => ({
                value: item?.id,
                label: item?.template_name,
                id: item?.id,
              }))
            );
          } else {
            staffForm.setFieldValue("holiday_template", null);
            setHolidayData([]);
          }
        })
        .catch((err) => {
          staffForm.setFieldValue("holiday_template", null);
          setHolidayData([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });

      listOfLeaveTemplateApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            let data = resp?.data;
            setLeaveData(
              data?.map((item) => ({
                value: item?.id,
                label: item?.leave_template_name,
                id: item?.id,
              }))
            );
          } else {
            staffForm.setFieldValue("leave_template", null);
            setLeaveData([]);
          }
        })
        .catch((err) => {
          staffForm.setFieldValue("leave_template", null);
          setLeaveData([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });

      listOfShiftApi(payload)
        .then((resp) => {
          if (checkStatusCodeSuccess(resp.statusCode)) {
            let data = resp?.data;
            setShiftData(
              data?.map((item) => ({
                value: item?.id,
                label: item?.shift_name,
                id: item?.id,
              }))
            );
          } else {
            staffForm.setFieldValue("shift", null);
            setShiftData([]);
          }
        })
        .catch((err) => {
          staffForm.setFieldValue("shift", null);
          setShiftData([]);
          return err;
        })
        .finally(() => {
          setLoader(false);
        });
  };

  useEffect(() => {
    if (isProfile) {
      setItem(isUserProfile, JSON.stringify(true));
    } else {
      setItem(isUserProfile, JSON.stringify(false));
    }
    setEditProfileMode(false);
    setEditGeneralMode(false);
    setEditPersonalMode(false);
    setEditEmploymentMode(false);
    setEditBankMode(false);
    setViewBankMode(true);
    setViewEmploymentMode(true);
    setViewGeneralMode(true);
    setViewPersonalMode(true);
    setViewProfileMode(true);
  }, [isProfile]);
  
  useEffect(() => {
    fetchData();
  }, [isEditMode,isProfile]);

  useEffect(() => {
    if (isEditMode) {
      Promise.all([
        fetchDistrict(staffData?.currentAddress?.state?.id),
        fetchDistrict(staffData?.permanentAddress?.state?.id),
      ]);
    }
  }, [staffData, isEditMode]);

  useEffect(() => {
    if (location.state && location.state.viewProfileMode !== undefined) {
      setViewProfileMode(location.state.viewProfileMode);
    }
    if (location.state && location.state.viewGeneralMode !== undefined) {
      setViewGeneralMode(location.state.viewGeneralMode);
    }
    if (location.state && location.state.viewPersonalMode !== undefined) {
      setViewPersonalMode(location.state.viewPersonalMode);
    }
    if (location.state && location.state.viewEmploymentMode !== undefined) {
      setViewEmploymentMode(location.state.viewEmploymentMode);
    }
    if (location.state && location.state.viewBankMode !== undefined) {
      setViewBankMode(location.state.viewBankMode);
    }
  }, [location.state]);

  const handlePhysicalChange = (event) => {
    setSelectedPhysical(event?.target?.value);
    staffForm.setFieldValue("physically_challenged", event?.target?.value);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event?.target?.value);
    staffForm.setFieldValue("gender", event?.target?.value);
  };
  const handleMultiCenter = (selectedMultiCenter) => {
    const weeks =
    selectedMultiCenter?.length > 0
        ? selectedMultiCenter?.map((item) => {
            return item.value;
          })
        : null;
        setSelectedMultiCenter(selectedMultiCenter);
    staffForm.setFieldValue("center", weeks);
  };
  const handleMulti = (selectedMulti) => {
    const weeks =
      selectedMulti?.length > 0
        ? selectedMulti?.map((item) => {
            return item.value;
          })
        : null;
    setSelectedMulti(selectedMulti);
    staffForm.setFieldValue("week_off", weeks);
  };
  const handleMaritalStatus = (field, value) => {
    staffForm.setFieldValue(field, value);
    if (value !== hrEnums.MARRIED) {
      staffForm.setFieldValue("spouse_name", null);
    }
  };

  const getAddressData = (addressType, staffData) => {
    const address = staffData?.staffAddress?.find(
      (addr) => addr.address_type === addressType
    );

    return {
      address_line_1: address?.address_line_1,
      address_line_2: address?.address_line_2,
      district: address?.district?.id,
      state: address?.state?.id,
      postal_code: address?.postal_code,
    };
  };
  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      if (!allowedFileTypesInquiry?.includes(file.type)) {
        toast.error(editInquiryErrorForFile);
        return;
      } else {
        if (file.size > 1024 * 1024) {
          toast.error(editInquiryErrorForFileSize);
          return;
        } else {
          const formData = new FormData();
          formData.append("files", file);
          formData.append("folderName", staff);
          if (currentImage !== null) {
            deleteUploadedfile(currentImage, true, staff);
            fileUploadApi(formData)
              .then((resp) => {
                if (checkStatusCodeSuccess(resp?.statusCode)) {
                  staffForm.setFieldValue("document", resp?.data[0]);
                  setCurrentImage(resp?.data[0]);
                } else {
                  toast.error(resp?.message);
                }
              })
              .catch((err) => {
                toast.error(err?.response?.data?.message || err?.message);
              })
              .finally(() => {
                setLoader(false);
              });
          } else {
            fileUploadApi(formData)
              .then((resp) => {
                if (checkStatusCodeSuccess(resp?.statusCode)) {
                  staffForm.setFieldValue("document", resp?.data[0]);
                  setCurrentImage(resp?.data[0]);
                } else {
                  toast.error(resp?.message);
                }
              })
              .catch((err) => {
                toast.error(err?.response?.data?.message || err?.message);
              })
              .finally(() => {
                setLoader(false);
              });
          }
          }
        }
      }
  };
  const currentAddress = getAddressData(hrEnums.CURRENT_ADDRESS, staffData);
  const permanentAddress = getAddressData(hrEnums.PERMANENT_ADDRESS, staffData);
  const staffForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      //profile section
      first_name: isEditMode ? staffData?.first_name : null,
      middle_name: isEditMode ? staffData?.middle_name : null,
      last_name: isEditMode ? staffData?.last_name : null,
      email: isEditMode ? staffData?.email : null,
      contact_no: isEditMode ? staffData?.contact_no : null,
      designation: isEditMode ? staffData?.position?.id : null,
      role: isEditMode ? staffData?.roleModel?.id : null,
      staff_type: isEditMode ? staffData?.staff_type || hrEnums.REGULAR : hrEnums.REGULAR,
      center: isEditMode ? staffData?.center?.map((item) => item?.id) : null,
      primary_center_id: isEditMode ? staffData?.primary_center_id : null,
      reporting_manager: isEditMode ? staffData?.reportingTo?.id : null,
      repo: isEditMode ? staffData?.reportingTo?.id : null,

      // general section
      week_off: isEditMode
        ? staffData?.assignedWeekOff?.map((item) => item?.week_off)
        : null,
      holiday_template: isEditMode
        ? staffData?.staffGeneralInformation?.holiday?.id
        : null,
      leave_template: isEditMode
        ? staffData?.staffGeneralInformation?.leave?.id
        : null,
      shift: isEditMode ? staffData?.staffGeneralInformation?.shift?.id : null,
      salary_access: isEditMode
        ? staffData?.staffGeneralInformation?.salary_access
        : null,
      attendance_mode: isEditMode
        ? staffData?.staffGeneralInformation?.attendance_mode
        : attendaceMode,
      staff_id: isEditMode
        ? staffData?.staffGeneralInformation?.staff_id
        : currentStaffId,
      //personal section
      gender: isEditMode
        ? staffData?.staffGeneralInformation?.gender
        : hrEnums.MALE,
      dob: isEditMode ? staffData?.staffGeneralInformation?.dob : null,
      marital_status: isEditMode
        ? staffData?.staffGeneralInformation?.marital_status
        : null,
      blood_group: isEditMode
        ? staffData?.staffGeneralInformation?.blood_group
        : null,
      emergency_contact: isEditMode
        ? staffData?.staffGeneralInformation?.emergency_contact
        : null,
      father_name: isEditMode
        ? staffData?.staffGeneralInformation?.father_name
        : null,
      mother_name: isEditMode
        ? staffData?.staffGeneralInformation?.mother_name
        : null,
      spouse_name: isEditMode
        ? staffData?.staffGeneralInformation?.spouse_name
        : null,
      physically_challenged: isEditMode
        ? staffData?.staffGeneralInformation?.physically_challenged
        : hrEnums.NO,
      address_line1: isEditMode
        ? currentAddress.address_line_1
        : null,
      address_line2: isEditMode
        ? currentAddress.address_line_2
        : null,
      district: isEditMode ? currentAddress.district : null,
      state: isEditMode ? currentAddress.state : null,
      postal_code: isEditMode ? currentAddress.postal_code : null,
      permanent_address_line1: isEditMode
        ? permanentAddress.address_line_1
        : null,
      permanent_address_line2: isEditMode
        ? permanentAddress.address_line_2
        : null,
      permanent_district: isEditMode
        ? permanentAddress.district
        : null,
      permanent_state: isEditMode
        ? permanentAddress.state
        : null,
      permanent_postal_code: isEditMode
        ? permanentAddress.postal_code
        : null,
      //employment section
      joining_date: isEditMode
        ? staffData?.staffGeneralInformation?.date_of_joining
        : null,
      uan: isEditMode ? staffData?.staffGeneralInformation?.uan_no : null,
      pan: isEditMode ? staffData?.staffGeneralInformation?.pan_no : null,
      aadhar_number: isEditMode ? staffData?.staffGeneralInformation?.aadhar_no : null,
      //bank section
      bank_name: isEditMode ? staffData?.staffBankDetails?.name_of_bank : null,
      ifsc: isEditMode ? staffData?.staffBankDetails?.IFSC_code : null,
      account_number: isEditMode
        ? staffData?.staffBankDetails?.account_no
        : null,
      account_holder_name: isEditMode
        ? staffData?.staffBankDetails?.account_holder_name
        : null,
      document: isEditMode
        ? staffData?.staffGeneralInformation?.other_document
        : null,
    },
    validationSchema: yup.object({
      // profile section
      first_name: yup
        .string()
        .required(validationMessages.required(hrEnums.FIRST_NAME)),
      middle_name: yup
        .string()
        .notRequired(),
      last_name: yup
        .string()
        .required(validationMessages.required(hrEnums.LAST_NAME)),
      designation: !isProfile && yup
        .number()
        .required(validationMessages.required(Designation)),
      role: !isProfile && yup.number().required(validationMessages.required(hrEnums.ROLE)),
      staff_type: yup
        .string()
        .required(validationMessages.required(hrEnums.STAFF_TYPE)),
      email: yup
        .string()
        .email(validationMessages.format(Email))
        .required(validationMessages.required(Email)),
      contact_no: yup
        .string()
        .required(validationMessages.required(ContactNo))
        .matches(numberRegex, validationMessages.contactLength(ContactNo, 10)),
      center: !isProfile && yup
        .array()
        .required(validationMessages.required(hrEnums.CENTER)),
      primary_center_id : !isProfile && yup
        .string()
        .required(validationMessages.required(hrEnums.PRIMARY_CENTER)),
      
      // general section
      week_off: !isProfile && yup
        .array()
        .notRequired(),
      holiday_template: !isProfile && yup
        .number()
        .required(validationMessages.required(hrEnums.HOLIDAY_TEMPLATE)),
      leave_template: !isProfile && yup
        .number()
        .required(validationMessages.required(hrEnums.LEAVE_TEMPLATE)),
      shift: !isProfile && yup.string().required(validationMessages.required(hrEnums.SHIFT)),
      salary_access: !isProfile && yup
        .string()
        .required(validationMessages.required(hrEnums.SALARY_ACCESS)),

      //personal section
      gender: yup
        .string()
        .required(validationMessages.required(hrEnums.GENDER)),
      dob: yup
        .date()
        .max(
          new Date(new Date().setDate(new Date().getDate() - 1)),
          hrEnums.DOB_VALIDATION
        )
        .required(validationMessages.required(hrEnums.DOB)),
      emergency_contact: yup
        .string()
        .nullable()
        .matches(
          numberRegex,
          validationMessages.contactLength(hrEnums.EMERGENCY_CONTACT, 10)
        ),
      postal_code: yup
        .string()
        .notRequired()
        .matches(
          zipcodeRegex,
          validationMessages.contactLength(hrEnums.POSTAL_CODE, 6)
        ),
      permanent_postal_code: yup
        .string()
        .notRequired()
        .matches(
          zipcodeRegex,
          validationMessages.contactLength(hrEnums.POSTAL_CODE, 6)
        ),

      // employment section
      joining_date: yup
        .date()
        .required(validationMessages.required(hrEnums.JOINING_DATE)),
      uan: yup
        .string()
        .notRequired()
        .matches(
          aadharRegex,
          validationMessages.contactLength(hrEnums.UAN, 12)
        ),
      pan: yup.string().notRequired().matches(
        panRegex,
        validationMessages.contactLength(hrEnums.PAN, 10)
      ),
      aadhar_number: yup
        .string()
        .notRequired()
        .matches(
          aadharRegex,
          validationMessages.contactLength(hrEnums.AADHAR_NUMBER, 12)
        ),
      monthly_salary: !isEditMode
        ? yup
            .string()
            .required(validationMessages.required(hrEnums.MONTHLY_SALARY))
        : yup.string().notRequired(),

      //bank section
      ifsc: yup.string().nullable()
        .matches(
        ifscRegex, validationMessages.contactLength(hrEnums.IFSC_CODE, 11)
        ),
      account_number: yup
        .string()
        .nullable()
        .min(8, validationMessages.minLength(hrEnums.ACCOUNT_NO, 8))
        .max(17, validationMessages.maxLength(hrEnums.ACCOUNT_NO, 17)),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        staffProfile: {
          first_name: values.first_name,
          middle_name: values.middle_name,
          last_name: values.last_name,
          email: values.email,
          position_id: values.designation,
          center_ids: values.center,
          primary_center_id: values.primary_center_id,
          staff_type: values.staff_type,
          contact_no: values.contact_no,
          reporting_to: values.reporting_manager,
          role_id: values.role,
        },
        staffGeneralInformation: {
          holiday_template_id: values.holiday_template,
          leave_template_id: values.leave_template,
          staff_id: values.staff_id,
          shift_id: values.shift,
          salary_access: values.salary_access,
          attendance_mode: values.attendance_mode,
          date_of_joining: values.joining_date,
          uan_no: values.uan,
          pan_no: values.pan,
          aadhar_no: values.aadhar_number,
          week_off: values.week_off?.length > 0 ? values.week_off : [],
          gender: values.gender,
          dob: values.dob,
          marital_status: values.marital_status,
          blood_group: values.blood_group,
          emergency_contact: values.emergency_contact,
          physically_challenged: values.physically_challenged,
          father_name: values.father_name,
          mother_name: values.mother_name,
          spouse_name: values.spouse_name,
        },
        staffAddress: [
          {
            address_type: hrEnums.CURRENT_ADDRESS,
            address_line_1: values.address_line1 === "" ? null : values.address_line1,
            address_line_2: values.address_line2 === "" ? null : values.address_line2,
            state_id: values.state,
            district_id: values.district,
            postal_code: values.postal_code ? values.postal_code : null,
          },
          {
            address_type: hrEnums.PERMANENT_ADDRESS,
            address_line_1: values.permanent_address_line1 === "" ? null : values.permanent_address_line1,
            address_line_2: values.permanent_address_line2 === "" ? null : values.permanent_address_line2,
            state_id: values.permanent_state,
            district_id: values.permanent_district,
            postal_code: values.permanent_postal_code ? values.permanent_postal_code : null,
          },
        ],
        staffBankDetails: {
          name_of_bank: values.bank_name === "" ? null : values.bank_name,
          IFSC_code: values.ifsc === "" ? null : values.ifsc,
          account_no: values.account_number === "" ? null : values.account_number,
          account_holder_name: values.account_holder_name === "" ? null : values.account_holder_name,
        },
        staffSalaryDetails: {
          monthly_special_allowance: values.monthly_special_allowance === "" ? null : values.monthly_special_allowance,
          monthly_salary: values.monthly_salary,
        },
        otherDocument: {
          other_document: values.document,
        },
      };

      if (staffId || isProfile) {
        setBtnLoader(true);
        editStaffApi(isProfile ? profileId : staffId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp.statusCode)) {
              toast.success(resp?.message);
              handleProfileDiscard();
              handleGeneralDiscard();
              handlePersonalDiscard();
              handleEmploymentDiscard();
              handleBankDiscard();
              staffForm.resetForm();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            if (Array.isArray(err?.response?.data?.message)) {
              err?.response?.data?.message?.map((message) => {
                toast.error(message);
              });
            } else {
              toast.error(err?.response?.data?.message || err?.message);
            }
            return err;
          })
          .finally(() => {
            setBtnLoader(false);
          });
      } else {
        setBtnLoader(true);
        addStaffApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              navigate("/staff");
              staffForm.resetForm();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            if (Array.isArray(err?.response?.data?.message)) {
              err?.response?.data?.message?.map((message) => {
                toast.error(message);
              });
            } else {
              toast.error(err?.response?.data?.message || err?.message);
            }
          })
          .finally(() => {
            setBtnLoader(false);
          });
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = await staffForm.validateForm();
    staffForm.setTouched(errors);
    if (Object.keys(errors).length > 0) {
      openSectionWithError(errors);
      staffForm.handleSubmit();
    } else {
      staffForm.handleSubmit();
    }
  };

  const openSectionWithError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (isEditMode) {
      const sectionToOpen = Object.keys(fieldToSectionMap).find((section) =>
        fieldToSectionMap[section].includes(firstErrorField)
      );
      switch (sectionToOpen) {
        case formFields.profile_section:
          setEditProfileMode(true);
          setViewProfileMode(false);
          break;
        case formFields.personal_section:
          setEditPersonalMode(true);
          setViewPersonalMode(false);
          break;
        case formFields.general_section:
          setEditGeneralMode(true);
          setViewGeneralMode(false);
          break;
        case formFields.bank_section:
          setEditBankMode(true);
          setViewBankMode(false);
          break;
        case formFields.employment_section:
          setEditEmploymentMode(true);
          setViewPersonalMode(false);
          break;
        default:
          break;
      }
    }
    const element = document.getElementById(firstErrorField);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.focus();
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    if (isChecked) {
      staffForm.setFieldValue(
        "permanent_address_line1",
        staffForm.values.address_line1
      );
      staffForm.setFieldValue(
        "permanent_address_line2",
        staffForm.values.address_line2
      );
      staffForm.setFieldValue("permanent_state", staffForm.values.state);
      staffForm.setFieldValue("permanent_district", staffForm.values.district);
      staffForm.setFieldValue(
        "permanent_postal_code",
        staffForm.values.postal_code
      );
    } else {
      staffForm.setFieldValue(
        "permanent_address_line1",
        isEditMode ? permanentAddress.address_line_1 : null
      );
      staffForm.setFieldValue(
        "permanent_address_line2",
        isEditMode ? permanentAddress.address_line_2 : null
      );
      staffForm.setFieldValue(
        "permanent_state",
        isEditMode ? permanentAddress.state : null
      );
      staffForm.setFieldValue(
        "permanent_district",
        isEditMode ? permanentAddress.district : null
      );
      staffForm.setFieldValue(
        "permanent_postal_code",
        isEditMode ? permanentAddress.postal_code : null
      );
    }
  }, [
    staffForm.values.address_line1,
    staffForm.values.address_line2,
    staffForm.values.state,
    staffForm.values.district,
    staffForm.values.postal_code,
    isChecked,
  ]);
  
  useEffect(() => {
    if (staffForm.values?.center!==null && centerData?.length > 0) {
      const matchedCenters = [];
      centerData?.filter((item) => {
        staffForm.values.center?.map((value) => {
          if (item?.value === value) {
            matchedCenters?.push(item);
            return value   
          }
        })
      }
      );
      setSelectedCenterIds(matchedCenters);
    } else {
      setSelectedCenterIds([]);
    }
  },[staffForm.values.center,isEditMode,centerData]);
  
  useEffect(() => {
    if(isEditMode || isAddStaff){
    if (staffForm.values.center!==null && staffForm.values.primary_center_id!==null && !staffForm.values.center?.includes(staffForm.values.primary_center_id)) {
      staffForm.setFieldValue("primary_center_id", null);
      fetchReportingManager(null);
      staffForm.setTouched({
        primary_center_id: true,
        reporting_manager: true,
        leave_template: true,
        holiday_template: true,
        shift: true,
      });
      staffForm.validateForm();
    } else {
      !isAddStaff && fetchReportingManager(staffForm.values.primary_center_id);
      }
    }
  }, [staffForm.values.center]);
  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
        {!isProfile && rendorBackButton(() => navigate("/staff"))}
        </div>
        <div className="card rounded section mb-3 px-4">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          <div className="row justify-content-center">
            <form onSubmit={handleSubmit}>
              <div>
                <ProfileDetails
                  staffForm={staffForm}
                  viewProfileMode={viewProfileMode}
                  handleProfileEdit={handleProfileEdit}
                  handleProfileDiscard={handleProfileDiscard}
                  editProfileMode={editProfileMode}
                  loader={loader}
                  positionData={positionData}
                  roleData={roleData}
                  centerData={centerData}
                  staffData={staffData}
                  btnLoader={btnLoader}
                  fetchReportingManager={fetchReportingManager}
                  reportingManager={reportingManager}
                  currentStaffId={currentStaffId}
                  lastStaffId={lastStaffId}
                  isEditMode={isEditMode}
                  selectedMultiCenter={selectedMultiCenter}
                  handleMultiCenter={handleMultiCenter}
                  isProfile={isProfile}
                  selectedCenterIds={selectedCenterIds}
                />
                <GeneralDetails
                  staffForm={staffForm}
                  viewGeneralMode={viewGeneralMode}
                  handleGeneralEdit={handleGeneralEdit}
                  handleGeneralDiscard={handleGeneralDiscard}
                  editGeneralMode={editGeneralMode}
                  loader={loader}
                  btnLoader={btnLoader}
                  staffData={staffData}
                  handleMulti={handleMulti}
                  selectedMulti={selectedMulti}
                  holidayData={holidayData}
                  leaveData={leaveData}
                  shiftData={shiftData}
                  isEditMode={isEditMode}
                  isProfile={isProfile}
                />
                <PersonalDetails
                  staffForm={staffForm}
                  viewPersonalMode={viewPersonalMode}
                  handlePersonalEdit={handlePersonalEdit}
                  handlePersonalDiscard={handlePersonalDiscard}
                  editPersonalMode={editPersonalMode}
                  handlePhysicalChange={handlePhysicalChange}
                  handleGenderChange={handleGenderChange}
                  selectedGender={selectedGender}
                  selectedPhysical={selectedPhysical}
                  loader={loader}
                  btnLoader={btnLoader}
                  fetchDistrict={fetchDistrict}
                  district={district}
                  states={states}
                  staffData={staffData}
                  handleMaritalStatus={handleMaritalStatus}
                  addressDetails={addressDetails}
                  handleCheckboxChange={handleCheckboxChange}
                  isChecked={isChecked}
                  permanentAddress={permanentAddress}
                  currentAddress={currentAddress}
                  isProfile={isProfile}
                />
                <BankDetails
                  staffForm={staffForm}
                  viewBankMode={viewBankMode}
                  handleBankEdit={handleBankEdit}
                  handleBankDiscard={handleBankDiscard}
                  editBankMode={editBankMode}
                  loader={loader}
                  btnLoader={btnLoader}
                  staffData={staffData}
                  isProfile={isProfile}
                />
                <EmploymentDetails
                  staffForm={staffForm}
                  viewEmploymentMode={viewEmploymentMode}
                  handleEmploymentEdit={handleEmploymentEdit}
                  handleEmploymentDiscard={handleEmploymentDiscard}
                  editEmploymentMode={editEmploymentMode}
                  loader={loader}
                  btnLoader={btnLoader}
                  staffData={staffData}
                  isEditMode={isEditMode}
                  handleFileChange={handleFileChange}
                  isProfile={isProfile}
                />
                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    {!staffId && (
                      <>
                        <BaseButton
                          className="mx-2 mb-2"
                          color="primary"
                          type="submit"
                          loader={btnLoader}
                          children={Submit}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffProfile;
