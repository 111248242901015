export const invEnums = {
  SR_NO: "SrNo",
  INVENTORY: "Inventory",
  INVENTORY_DETAILS: "Inventory Details",
  INVENTORY_LIST: "Inventory List",
  CENTER_NAME: "Center",
  QUANTITY: "Quantity",
  INVALIDQUANTITY: "Invalid quantity",
  STOCK_TYPE: "Stock Type",
  REMARKS: "Remarks",
  STATUS: "Status",
  BALANCE: "Balance",
  QUANTITYDAMADD: "Outward/Inward Quantity",
  Add: "Add",
  DATE: "Date",
  TOTAL_QUANTITY: "Total Quantity",
  OUTWARD_QUANTITY: "Outward Quantity",
  CLOSING_QUNATITY: "Closing Quantity",
  MODEL_TITLE: "Add Damaged stock",
  DELETEMSG: "Are you sure you want to Delete this item?",
  DELETE: "Delete",
  STOCKENTRY: "Stock Entry",
  ADDED_BY: "Added By",
};
export const ACCESSORS_KEYS = {
  STOCK_TYPE: "stock.stock_type",
  CENTER_NAME: "center.center_name",
  STATUS: "status",
  INVENTORY: "inventory",
  ACTION: "action",
  BALANCE: "balance",
  REMAINING_QUANTITY: "inventory.remaining_quantity",
  REMAININGS_QUANTITY: "remaining_quantity",
  CREATED_AT: "createdAt",
  TOTAL_QUANTITY: "total_quantity",
  DAMAGED_QUANTITY: "damaged_quantity",
  STOCK_TYPES: "inventory.stock.stock_type",
  CENTER_NAMES: "inventory.center.center_name",
  ADDED_BY: "inventory.authUser.name",
  STOCK_ENTRY: "inventory_entry",
};

export const StockEntryOptions = [
  { value: "Transfer", label: "Transfer" },
  { value: "Damage", label: "Damage/Repair" },
]
