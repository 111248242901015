import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { LI, UL } from "../../AbstractElements";
import { MENUITEMS } from "./Menu";
import { Back } from "../../Constant";
import { getItem, ROLE, ROLE_PERMISSION } from "../../Constant/common";

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive }) => {
  const { t } = useTranslation();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [permissions, setPermissions] = useState(JSON.parse(sessionStorage.getItem("permissions")));
  const [finalMenu, setFinalMenu]= useState([]);

  const isModuleAllowed = (parentModule, module) => {
    return (
      permissions?.length > 0 &&
      permissions?.some(
        (permission) =>
          permission?.parent_module_name === parentModule &&
          permission?.module_name === module
      )
    );
  };
  useEffect(() => {
    setPermissions(JSON.parse(getItem("permissions")));
  }, [getItem("role")]);
    
  const filterMenuItems = (menuItems) => {
    const duplicateMenu = (menuItems) => {
      return menuItems.map((item) => ({
        ...item,
        Items: item.Items.map((subItem) => ({
          ...subItem,
          icon: subItem.icon, 
          children: subItem.children ? subItem.children.map((child) => ({
            ...child,
            icon: child.icon 
          })) : []
        }))
      }));
    };
    
    const menuCopy = duplicateMenu(MENUITEMS);
    return menuCopy
      .map((menuItem) => {
        if (menuItem?.menutitle !== ROLE) {
          menuItem.Items = menuItem.Items.map((item) => {
            if (item.children) {
              item.children = item.children.filter((child) =>
                isModuleAllowed(menuItem.menutitle, child.title)
              );
            }
            return item;
          }).filter((item) => item.children && item.children.length > 0);
        } else {
          menuItem.Items = menuItem.Items.filter((item) =>
            isModuleAllowed( item.title, ROLE_PERMISSION)
          );
        }
        return menuItem;
        
      })
      .filter((menuItem) => menuItem.Items && menuItem.Items.length > 0);
  };
  useEffect(()=>{
    let MENUITEMSFINAL = filterMenuItems(MENUITEMS);
    
    setFinalMenu(MENUITEMSFINAL);
  },[getItem("role")]);

  const handleMouseEnter = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const handleMouseLeave = (menuItem) => {
    if (activeMenuItem === menuItem) {
      setActiveMenuItem(null);
    }
  };

  const isActive = (menuItem) => {
    return activeMenuItem === menuItem;
  };

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".main-nav").className = "main-nav close_icon ";
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".main-nav").className = "main-nav ";
      }
    }
    if (!item.active) {
      finalMenu?.map((a) => {
        a?.Items?.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    if (item.children) {
      item.active = !item.active;
    }
    setMainMenu({ mainmenu: finalMenu });
  };

  return (
    <Fragment>
      <UL
        attrUL={{
          className:
            "simple-list scroll-list nav-menu custom-scrollbar d-block",
        }}
      >
        <LI attrLI={{ className: "back-btn" }}>
          <div className="mobile-back text-end">
            <span>{Back}</span>
            <i className="fa fa-angle-right ps-2"></i>
          </div>
        </LI>
        {finalMenu?.map((Item, i) => (
          <Fragment key={i}>
            {Item?.Items?.map((menuItem, i) => (
              <LI
                attrLI={{ className: "dropdown" }}
                key={i}
                onMouseLeave={() => handleMouseLeave(menuItem)}
              >
                {menuItem?.type === "sub" && (
                  <a
                    className={`nav-link menu-title ${
                      menuItem.active || isActive(menuItem) ? "active" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(menuItem)}
                    onClick={(event) => {
                      event.preventDefault();
                      setNavActive(menuItem);
                    }}
                  >
                    {menuItem?.icon !== undefined && <menuItem.icon />}
                    <span>{t(menuItem.title)}</span>
                    <div className="according-menu">
                      {menuItem.active ? (
                        <i className="fa fa-caret-down"></i>
                      ) : (
                        <i className="fa fa-caret-right"></i>
                      )}
                    </div>
                  </a>
                )}
                {menuItem?.type === "link" && (
                  <Link
                    to={menuItem?.path}
                    className={`nav-link menu-title ${menuItem.active && "active"}`}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    {menuItem?.icon !== undefined && <menuItem.icon />}
                    <span>{t(menuItem.title)}</span>
                    {menuItem?.badge && (
                      <Label className={menuItem.badge}>
                        {menuItem.badgetxt}
                      </Label>
                    )}
                  </Link>
                )}
                {menuItem?.children && (
                  <UL
                    attrUL={{
                      className: `simple-list nav-submenu menubar ${
                        window.innerWidth > 991 && "shadow"
                      }`,
                      style: isActive(menuItem)
                        ? { display: "block" }
                        : { display: "none" },
                      onMouseEnter: () => handleMouseEnter(menuItem),
                      onMouseLeave: () => handleMouseLeave(menuItem),
                    }}
                  >
                    {menuItem?.children?.map((childrenItem, index) => (
                      <LI key={index}>
                        {childrenItem?.type === "sub" && (
                          <a
                            className={`${
                              childrenItem?.active ? "active" : ""
                            }`}
                            onClick={(event) => {
                              event.preventDefault();
                              toggletNavActive(childrenItem);
                            }}
                          >
                            {t(childrenItem.title)}
                            <div className="according-menu">
                              {childrenItem?.active ? (
                                <i className="fa fa-caret-down"></i>
                              ) : (
                                <i className="fa fa-caret-right"></i>
                              )}
                            </div>
                          </a>
                        )}
                        {childrenItem?.type === "link" && (
                          <>
                            <Link
                              to={childrenItem?.path}
                              className={`${
                                childrenItem?.active ? "active" : ""
                              }`}
                              onClick={() => toggletNavActive(childrenItem)}
                            >
                              {childrenItem?.icon !== undefined && (
                                <childrenItem.icon className="sub-icon" />
                              )}
                              {t(childrenItem?.title)}
                            </Link>
                          </>
                        )}
                        {childrenItem?.children && (
                          <UL
                            attrUL={{
                              className:
                                "simple-list nav-sub-childmenu submenu-content",
                              style: childrenItem.active
                                ? { display: "block" }
                                : { display: "none" },
                            }}
                          >
                            {childrenItem?.children?.map(
                              (childrenSubItem, key) => (
                                <LI key={key}>
                                  {childrenSubItem?.type === "link" && (
                                    <Link
                                      to={childrenSubItem?.path}
                                      className={`${
                                        childrenSubItem?.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenSubItem)
                                      }
                                    >
                                      {t(childrenSubItem?.title)}
                                    </Link>
                                  )}
                                </LI>
                              )
                            )}
                          </UL>
                        )}
                      </LI>
                    ))}
                  </UL>
                )}
              </LI>
            ))}
          </Fragment>
        ))}
      </UL>
    </Fragment>
  );
};

export default SidebarMenuItems;
