import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { viewOfCandidate } from "../../Api/CandidateApi";
import Spinner from "../../BaseComponents/BaseLoader";
import { CandidateLabel } from "../../Constant/Candidate/candidate";
import { BaseImageURL } from "../../Api/Service";
import { checkStatusCodeSuccess, extractDate, handleResponse, rendorBackButton } from "../../Constant/common";
import { viewCandidateTitle } from "../../Constant/title";
import {
  AadharBack,
  AadharFront,
  BankPassbook,
  OtherDocument,
  QualificationCertificate,
  hostelMessage,
  passport,
  pwdMessage,
} from "../../Constant/Inquiry/Inquiry";
import { RiSearchLine } from "react-icons/ri";
import { AlternativeContactNumber, notFound } from "../../Constant";

const ViewCandidate = () => {
  document.title = viewCandidateTitle;
  const { candidateId } = useParams();
  const [loader, setLoader] = useState(false);
  const [candidate, setCandidate] = useState([]);

  const fetchData = () => {
    setLoader(true);
    viewOfCandidate(candidateId)
      .then((response) => {
        checkStatusCodeSuccess(response?.statusCode)
        setCandidate(response?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBack = () => {
    const referrer = document.referrer;
    if (referrer.includes("/candidate")) {
      window.location.href = "/candidate";
    } else if (referrer.includes("/placement")) {
      window.location.href = "/placement";
    } else {
      window.history.back();
    }
  };

  return (
    <div className="page-body">
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="container-fluid mb-1">
        <div className="row d-flex align-items-center">
          <div className="col-6">
            <h5 className="f-w-600">{CandidateLabel.candidateDetails}</h5>
          </div>
          <div className="col-6 d-flex justify-content-end">
            {rendorBackButton(handleBack)}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <div className="section mb-4" id="personalDetails">
              <span className="p-2 d-block border-bottom text-dark">
                {CandidateLabel.personalDetails}
              </span>
              <div className="row p-2 row-gap-2">
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {CandidateLabel.name}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate.candidate_name ?? handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fw-500 fs-8">
                  {CandidateLabel.email}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.email ?? handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {CandidateLabel.contactNumber}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.contact_no ?? handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {CandidateLabel.aadharNumber}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate.candidateDetail?.aadhar_no ?? handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {CandidateLabel.address}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.address ?? handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {CandidateLabel.gender}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.gender ??
                    handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {CandidateLabel.dateOfBirth}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.dob
                    ? extractDate(candidate?.dob)
                    : handleResponse.nullData}
                </div>
                <div className="col-6 col-sm-2 fw-500 fs-8">
                  {AlternativeContactNumber}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.alternative_contact_no ??
                    handleResponse.nullData}
                </div>
              </div>
            </div>
            <div className="section mb-4" id="otherDetails">
              <span className="p-2 d-block border-bottom text-dark">
                {CandidateLabel.otherDetails}
              </span>
              <div className="row p-2 row-gap-2">
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.fatherName}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.father_name ?? handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.motherName}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.mother_name ?? handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.qualification}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.qualification?.name ?? handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.category}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.caste_category ?? handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.sector}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.sector ?? handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.centerName}
                </div>
                <div className="col-6 col-sm-2 fs-8">{candidate?.center?.center_name ?? handleResponse.nullData}</div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.courseName}
                </div>
                <div className="col-6  col-sm-2 fs-8">{candidate?.course?.course_name ?? handleResponse.nullData}</div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.batchId}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.batchAssign?.batch?.batch_id ?? handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.status}
                </div>
                <div className="col-6 col-sm-2 fs-8">{candidate?.batchAssign?.status ?? handleResponse.nullData}</div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.reference}
                </div>
                <div className="col-6 col-sm-2 fs-8">{candidate?.reference === "" || candidate?.reference === null ? handleResponse.nullData : candidate?.reference}</div>
                {
                  (candidate?.reference == 'PMKVY staff') && (
                  <>
                    <div className="col-6 fw-500 col-sm-2 fs-8">
                      {CandidateLabel.staffName}
                    </div>
                      <div className="col-6 col-sm-2 fs-8">{candidate?.authUser?.name === "" || candidate?.authUser?.name === null ? handleResponse.nullData : candidate?.authUser?.name}</div>
                  </>
                  )
                }
                {
                  (candidate?.reference == 'Referral') && (
                  <>
                    <div className="col-6 fw-500 col-sm-2 fs-8">
                      {CandidateLabel.referral}
                    </div>
                      <div className="col-6 col-sm-2 fs-8">{candidate?.referral === "" || candidate?.referral === null ? handleResponse.nullData : candidate?.referral}</div>
                  </>
                  )
                }
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.source}
                </div>
                <div className="col-6 col-sm-2 fs-8">{candidate?.source ?? handleResponse.nullData}</div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {hostelMessage}
                </div>
                <div className="col-6 col-sm-2 fs-8">{candidate?.is_hostel ?? handleResponse.nullData}</div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {pwdMessage}
                </div>
                <div className="col-6 col-sm-2 fs-8">{candidate?.is_pwd ?? handleResponse.nullData}</div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {passport}:
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDocument?.passport_photo ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDocument?.passport_photo}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {AadharFront}:
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDocument?.aadharCard_front ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDocument?.aadharCard_front}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {AadharBack}:
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDocument?.aadharCard_back ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDocument?.aadharCard_back}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {BankPassbook}:
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDocument?.bank_passbook ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDocument?.bank_passbook}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {QualificationCertificate}:
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDocument?.qualification_certificate ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDocument?.qualification_certificate}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {OtherDocument}:
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDocument?.other_document ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDocument?.other_document}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.assessmentLable}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.assessment_date
                    ? extractDate(candidate?.candidateDetail?.assessment_date)
                    : handleResponse.nullData}
                </div>
                <div className="col-6 fw-500 col-sm-2 fs-8">
                  {CandidateLabel.certificate1}
                </div>
                <div className="col-6 col-sm-2 fs-8">
                  {candidate?.candidateDetail?.certificate ? (
                    <a
                      href={`${BaseImageURL}${candidate?.candidateDetail?.certificate}`}
                      className="text-decoration-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CandidateLabel.viewCertificate}
                    </a>
                  ) : (
                    handleResponse.nullData
                  )}
                </div>
              </div>
            </div>
            <div className="section" id="placementDetails">
              <span className="p-2 d-block border-bottom text-dark">
                {CandidateLabel.placementDetails}
              </span>
              {candidate?.placementDetails?.length > 0 ? (
                candidate?.placementDetails?.map((placement, index) => (
                  <div key={index} className="row p-2 row-gap-2">
                    <div className="col-6 fw-100 col-sm-2 fs-8">
                      {CandidateLabel.companyName}
                    </div>
                    <div className="col-6 col-sm-2 fs-8">
                      {placement?.placementEmployer?.company_name ??
                        handleResponse.nullData}
                    </div>
                    <div className="col-6 fw-100 col-sm-2 fs-8">
                      {CandidateLabel.position}
                    </div>
                    <div className="col-6 col-sm-2 fs-8">
                      {placement?.position ?? handleResponse.nullData}
                    </div>
                    <div className="col-6 fw-100 col-sm-2 fs-8">
                      {CandidateLabel.joiningDate}
                    </div>
                    <div className="col-6 col-sm-2 fs-8">
                      {placement?.joining_date ?? handleResponse.nullData}
                    </div>
                    <div className="col-6 fw-100 col-sm-2 fs-8">
                      {CandidateLabel.appointmentLetter}
                    </div>
                    <div className="col-6 col-sm-2 fs-8">
                      {placement?.appointment_letter ? (
                        <a
                          href={`${BaseImageURL}${placement?.appointment_letter}`}
                          className="text-decoration-underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {CandidateLabel.viewAppointmentLetter}
                        </a>
                      ) : (
                        handleResponse.nullData
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="py-4 text-center">
                  <div>
                    <RiSearchLine className="fs-2" />
                  </div>
                  <div className="mt-4">
                    <h5>{notFound.dataNotFound}</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Container-fluid Ends*/}
    </div>
  );
};

export default ViewCandidate;
