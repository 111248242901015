export const classEnums = {
  CLASS: 'Class',
  MILESTONE: 'Milestone',
  UNIFORM: 'Uniform',
  TDS: 'TDS',
  TDS_AMOUNT: 'TDS Amount',
  TOTAL: 'Total Milestone',
  MILESTONE_AMOUNT: 'Milestone Amount',
  BATCH_STATUS: 'Batch Status',
  CLAIMED: 'Claimed',
  RECEIVED: 'Received',
  BILL_INVOICE: 'Bill Invoice',
  CLAIMED_AMOUNT: 'Claimed Amount',
  RECEIVED_AMOUNT: 'Received Amount',
  INVOICE_NO: 'Invoice No.',
  SUBMISSION_DATE: 'Submission Date',
  STATUS: 'Status',
  ACTION: 'Action',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ADD_MILESTONE: 'Add Milestone',
  EDIT_MILESTONE: 'Edit Milestone',
  EDIT: 'Edit',
  ADD: 'Add',
  REMARKS: 'Remarks',
  CLASS_DETAILS: 'Class Details',
  SR_NO: 'SrNo',
  BATCH_ID: 'Batch ID',
  COURSE_NAME: 'Course Name',
  COURSE_HOURS: 'Course Hours',
  CENTER_NAME: 'Center Name',
  ENROLLED_CANDIDATES: 'Enrolled Candidates',
  PLACED_CANDIDATES: "Placed Candidates",
  BATCH_START_DATE: 'Batch Start Date',
  TOTAL_HOURS: 'Total Hours',
  ASSESSED_CANDIDATES: 'Assessed Candidates',
  DROPOUT_CANDIDATES: 'Dropout Candidates',
  PENDING_CANDIDATES: 'Pending Candidates',
  BATCH_END_DATE: 'Batch End Date',
  RATE_PER_HOUR: 'Rate Per Hour',
  TOTAL_MILESTONE: 'Total Milestone',
  CERTIFIED_CANDIDATES: 'Certified Candidates',
  YES: 'Yes',
  BILL_TITLE: 'Approve Bill',
  billApprove: "Are you sure you want to approve this bill?",
  exportClass: "Export",
  ERROR_MESSAGE: "Please add milestone details first",
  MILESTONE_1: "Milestone 1",
  EXPORT_WITH_REJECTION: "Export With Rejection",
  CLASS_EXPORT: "class_data",
  REJECTED_CLASS_EXPORT: "rejected_class_data",
}