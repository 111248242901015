export const vendorLable = {
  vendorName: "Vendor Name",
  mobileNo: "Contact No",
  email: "Email Address",
  mobileNoValidation: "Contact number",
  emailValidation: "Email address",
  bankName: "Bank Name",
  bankBranchName: "Bank Branch Name",
  bankIfscCode: "Bank IFSC Code",
  bankAccountNumber: "Bank Account Number",
  bankIfscCodeValidation: "Bank ifsc code",
  bankAccountNumberValidation: "Bank account number",
  GSTINNumber: "GSTIN Number",
  GSTINNumberValidation: "GSTIN number",
  vendor: "Vendor",
  vendorList: "Vendor List",
};

export const vendorViewLable = {
  BankName: "Bank Name :",
  vendorName: "Vendor Name :",
  bankAccountNumber: "Bank Account Number :",
  bankBranchName: "Bank Branch Name :",
  bankIFSCCode: "Bank IFSC Code :",
  contactNumber: "Contact Number :",
  emailAddress: "Email Address :",
  GSTINNo: "GSTIN No :",
  details: "Vendor Details",
};
