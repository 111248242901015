import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../BaseComponents/BaseTable";
import {
  Action,
  Delete,
  Edit,
  Loading,
  Search,
  Submit,
  Update,
  notFound,
} from "../../Constant";
import Spinner from "../../BaseComponents/BaseLoader";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import BaseInput from "../../BaseComponents/BaseInput";
import {
  digitRegex,
  validationMessages,
} from "../../Constant/validation";
import BaseButton from "../../BaseComponents/BaseButton";
import {
  RiEditFill,
  RiDeleteBin7Fill,
  RiSearchLine,
  RiCheckLine,
  RiCloseLine,
} from "react-icons/ri";
import { useFormik } from "formik";
import * as yup from "yup";
import BaseModal from "../../BaseComponents/BaseModal";
import { toast } from "react-toastify";
import { DeleteMessage } from "../../Constant/Center";
import { checkStatusCodeSuccess, handleArrayResponse, handleEditClick } from "../../Constant/common";
import { jobPositionTitle } from "../../Constant/title";
import { addOpeningApi, deleteOpeningApi, editOpeningApi, listOfOpeningApi, openingStatusApi, viewOpeningApi } from "../../Api/CareerOpening";
import { OpeningLabel } from "../../Constant/CareerOpening";

const CareerOpening = () => {
  document.title = jobPositionTitle;
  const [openingData, setOpeningData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState();
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [editId, setEditId] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [openingDetails, setOpeningDetails] = useState(null);
  const [openingId, setOpeningId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [deactiveModal, setDeactiveModal] = useState(false);
  const handleSearchValueChange = (value) => {
    setSearchValue(value);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
    setBtnLoader(false);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const fetchOpeningData = async () => {
    setLoader(true);
    const payload = {
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfOpeningApi( payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setOpeningData(resp?.data?.listOfJobPosition);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchOpeningData(null);
  }, [currentPage, customPageSize, columnName, sortOrder]);

  const openingForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      jobTitle: openingDetails ? openingDetails?.job_title : null,
      jobDescription: openingDetails ? openingDetails?.job_description : null,
      openingPosition: openingDetails ? openingDetails?.no_of_position : null,
    },
    validationSchema: yup.object({
      jobTitle: yup
        .string()
        .required(validationMessages.required(OpeningLabel.JobTitle)),
      jobDescription: yup
        .string()
        .required(validationMessages.required(OpeningLabel.JobDescription)),
      openingPosition: yup
        .string()
        .required(validationMessages.required(OpeningLabel.OpeningPosition)),
    }),
    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      const payload = {
        job_title: values.jobTitle,
        job_description: values.jobDescription,
        no_of_position: values.openingPosition,
      };
      if (editId !== null) {
        editOpeningApi(editId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchOpeningData(null);
              setOpeningDetails(null);
              resetForm();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
            setEditId(null);
          });
      } else {
        setEditId(null);
        addOpeningApi(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchOpeningData(null);
              resetForm();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
          });
      }
    },
  });

  const editOpening = (id) => {
    setEditId(id);
    fetchOpeningDetails(id);
  };

  const fetchOpeningDetails = (id) => {
    setLoader(true);
    viewOpeningApi(id)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          setOpeningDetails(response?.data);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || error?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const deleteOpening = async () => {
    setDeleteLoader(true);
    await deleteOpeningApi(openingId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          fetchOpeningData(null);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
        return err;
      })
      .finally(() => {
        setDeleteLoader(false);
        setModalDelete(false);
        setLoader(false);
      });
  };

  const statusChange = () => {
    setLoader(true);
    const payload = {
      is_active: activeModal ? true : false,
    };
    openingStatusApi(openingId, payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          toast.success(resp?.message);
          fetchOpeningData(null);
          setActiveModal(false);
          setDeactiveModal(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const toggleDelete = (id) => {
    setOpeningId(id);
    setModalDelete(!modalDelete);
  };
  const toggleActive = (id) => {
    setOpeningId(id);
    setActiveModal(!activeModal);
  };
  const toggleDeactive = (id) => {
    setOpeningId(id);
    setDeactiveModal(!deactiveModal);
  };
  const columns = useMemo(() => [
    {
      header: OpeningLabel.JobTitle,
      accessorKey: "job_title",
      enableColumnFilter: false,
    },
    {
      header: OpeningLabel.JobDescription,
      accessorKey: "job_description",
      enableColumnFilter: false,
    },
    {
      header: OpeningLabel.OpeningPosition,
      accessorKey: "no_of_position",
      enableColumnFilter: false,
    },
    {
      header: OpeningLabel.Status,
      accessorKey: "is_active",
      enableColumnFilter: false,
      cell: ({ cell }) => {
        return (
          <span>{cell?.row?.original?.is_active ? "Active" : "Deactive"}</span>
        );
      },
    },
    {
      header: Action,
      accessorKey: "action",
      enableSorting: true,
      cell: (cell) => (
        <div className="d-flex justify-content-center icon">
          {(cell?.row?.original?.is_active === false) &&
          (<span>
            <RiCheckLine
              className="fs-5 text-primary mx-2"
              onClick={() => {
                toggleActive(cell?.row?.original?.id);
                handleEditClick();
              }}
              title={OpeningLabel.Active}
            />
          </span>
          )}
         {(cell?.row?.original?.is_active === true) &&(
          <RiCloseLine
            className={`fs-5 mx-2 text-danger`}
            onClick={() => toggleDeactive(cell.row.original?.id)}
            title={OpeningLabel.Deactive}
          />)}
          <span>
            <RiEditFill
              className="fs-5 text-primary mx-2"
              onClick={() => {
                editOpening(cell?.row?.original?.id);
                handleEditClick();
              }}
              title={Edit}
            />
          </span>
          <span>
            <RiDeleteBin7Fill
              className={`fs-5 mx-2 text-danger`}
              onClick={() => toggleDelete(cell?.row?.original?.id)}
              title={Delete}
            />
          </span>
        </div>
      ),
      enableColumnFilter: false,
    },
  ]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5 className="f-w-600">{OpeningLabel.CareerOpening}</h5>
      </div>
      <BaseModal
        isOpen={modalDelete}
        title="Delete"
        toggler={toggleDelete}
        submit={() => deleteOpening()}
        submitText="Delete"
        disabled={deleteLoader}
        loader={deleteLoader}
      >
        {DeleteMessage}
      </BaseModal>

      <div className="card p-4 rounded mb-0 mx-3">
        <div className="">
          <form onSubmit={openingForm.handleSubmit}>
            <div className="row justify-content-center">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseInput
                    label={OpeningLabel.JobTitle}
                    name="jobTitle"
                    type="text"
                    placeholder={PlaceHolderFormat(OpeningLabel.JobTitle)}
                    value={openingForm.values.jobTitle}
                    touched={openingForm.touched.jobTitle}
                    error={openingForm.errors.jobTitle}
                    handleBlur={openingForm.handleBlur}
                    handleChange={openingForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    label={OpeningLabel.JobDescription}
                    name="jobDescription"
                    type="textarea"
                    placeholder={PlaceHolderFormat(OpeningLabel.JobDescription)}
                    value={openingForm.values.jobDescription}
                    touched={openingForm.touched.jobDescription}
                    error={openingForm.errors.jobDescription}
                    handleBlur={openingForm.handleBlur}
                    handleChange={openingForm.handleChange}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseInput
                    name="openingPosition"
                    type="number"
                    label={OpeningLabel.OpeningPosition}
                    placeholder={PlaceHolderFormat(
                      OpeningLabel.OpeningPosition
                    )}
                    value={openingForm.values.openingPosition}
                    touched={openingForm.touched.openingPosition}
                    error={openingForm.errors.openingPosition}
                    handleBlur={openingForm.handleBlur}
                    handleChange={(e) => {
                      if (
                        e?.target?.value?.length <= 10 &&
                        digitRegex.test(e.target.value)
                      ) {
                        openingForm.handleChange(e);
                      }
                    }}
                    required={true}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-end align-items-end">
                    <div className="mt-4 mt-lg-0">
                      <BaseButton
                        className="btn btn-pill"
                        color="primary"
                        type="submit"
                        children={
                          editId !== null
                            ? btnLoader
                              ? Loading
                              : Update
                            : Submit
                        }
                        disabled={btnLoader}
                        loader={btnLoader}
                      ></BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BaseModal
        isOpen={activeModal || deactiveModal}
        title="Status"
        toggler={activeModal ? toggleActive : toggleDeactive}
        submit={() => statusChange()}
        submitText={Submit}
        disabled={btnLoader}
        loader={btnLoader}
      >
        {activeModal
          ? OpeningLabel.activeConfirmation
          : OpeningLabel.deactiveConfirmation}
      </BaseModal>
      <div className="col-6 py-2 px-3">
        <h5 className="f-w-600">{OpeningLabel.STAFF_LIST}</h5>
      </div>
      <div className="card mx-3">
        <div className="card-body text-center">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {openingData && openingData?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              fetchSortingData={handleFetchSorting}
              manualPagination={true}
              columns={columns}
              data={searchValue ? [] : openingData || []}
              isGlobalFilter={true}
              SearchPlaceholder={Search}
              onSearch={handleSearchValueChange}
              tableClass="table table-bordered text-center"
            />
          )}
          {!loader && openingData?.length === 0 && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerOpening;