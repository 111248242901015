import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import Layout from "../Layout/Layout";
import NotFound from "../Pages/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import { SelectedCenterProvider } from "../Pages/CenterContext";
import { getItem } from "../Constant/common";

const LayoutRoutes = () => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const userPermissions = JSON.parse(getItem("permissions")) || [];
    setPermissions(userPermissions);
  }, []);

  return (
    <Fragment>
    <SelectedCenterProvider>
      <Routes>
        {routes?.map(({ path, Component }, i) => (
          <Route
            element={<Layout />}
            key={i}
          >
            <Route
              path={path}
              element={
                <ProtectedRoute
                  path={path}
                  element={Component}
                  permissions={permissions}
                  routes={routes}
                />
              }
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SelectedCenterProvider>
  </Fragment>
  );
};

export default LayoutRoutes;
