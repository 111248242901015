import React, { useEffect, useMemo, useState } from "react";
import Spinner from "../../BaseComponents/BaseLoader";
import TableContainer from "../../BaseComponents/BaseTable";
import { Action, notFound } from "../../Constant";
import { RiAddLine, RiArrowUpLine, RiEyeFill, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { hostelLabel } from "../../Constant/Hostel/hostel";
import {
  exportHostelDetail,
  exportHostelWithRejectionDetail,
  hostelAdd,
  listOfHostel,
} from "../../Api/hostel";
import { BaseExportURL } from "../../Api/Service";
import { toast } from "react-toastify";
import BaseButton from "../../BaseComponents/BaseButton";
import { hostelTitle } from "../../Constant/title";
import { useSelectedCenter } from "../CenterContext";
import { checkStatusCodeSuccess, csv, deleteUploadedfile, extractDate, handleArrayResponse } from "../../Constant/common";

const Hostel = () => {
  document.title = hostelTitle;
  const [loader, setLoader] = useState(true);
  const [hostelData, setHostelData] = useState([]);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [searchValue, setSearchValue] = useState("");
  const [searchHostelData, setSearchHostelData] = useState([]);
  const {selectedCenterPayload} = useSelectedCenter();
  const history = useNavigate();

  const ViewHostel = (hostelId) => {
    history(`/viewHostel/${hostelId}`);
  };

  const fetchData = () => {
    setLoader(true);
    const payload = {
      condition:{
        ...(selectedCenterPayload ? { ...selectedCenterPayload } : { value: null }),
      },
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfHostel(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setHostelData(resp?.data?.listOfHostel);
          setSearchHostelData(resp?.data?.listOfHostel);
          setTotalRecords(resp.data.totalRecordsCount);
          setTotalPages(resp?.data?.totalPages);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        setSearchHostelData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [customPageSize, currentPage, columnName, sortOrder, searchValue]);

  const addHostel = (row) => {
    setLoader(true);
    const payload = {
      center_id: row?.center?.id,
      course_id: row?.course?.id,
      batch_id: row?.id,
    };

    hostelAdd(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          let hostelId = resp?.data;
          history(`/viewHostel/${hostelId}`);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        handleArrayResponse(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        header: hostelLabel.center,
        accessorKey: hostelLabel.centerKey,
        enableColumnFilter: false,
      },
      {
        header: hostelLabel.course,
        accessorKey: hostelLabel.courseNameKey,
        enableColumnFilter: false,
      },
      {
        header: hostelLabel.batchId,
        accessorKey: hostelLabel.batchIdKey,
        enableColumnFilter: false,
      },
      {
        header: hostelLabel.batchStart,
        accessorKey: hostelLabel.startDateKey,
        enableColumnFilter: false,
        cell: ({ cell }) => {
          return (
            <span>{`${extractDate(cell?.row?.original?.start_date)}`}</span>
          );
        },
      },
      {
        header: hostelLabel.endDate,
        accessorKey: hostelLabel.endDateKey,
        enableColumnFilter: false,
        cell: ({ cell }) => {
          return (
            <span>{`${extractDate(cell?.row?.original?.end_date)}`}</span>
          );
        },
      },
      {
        header: hostelLabel.totalDay,
        accessorKey: hostelLabel.totalDayKey,
        enableColumnFilter: false,
      },
      {
        header: Action,
        accessorKey: "action",
        enableSorting: false,
        cell: (cell) => {
          const { is_hostel } = cell.row.original;

          return (
            <div className="d-flex justify-content-center icon">
              {is_hostel ? (
                <span>
                  <RiEyeFill
                    className="fs-5 text-success mx-2"
                    onClick={() => ViewHostel(cell.row.original.hostel_id)}
                    title="View"
                  />
                </span>
              ) : (
                <span>
                  <RiAddLine
                    className="fs-5 text-primary mx-2"
                    onClick={() => addHostel(cell.row.original)}
                    title="Add"
                  />
                </span>
              )}
            </div>
          );
        },
        enableColumnFilter: false,
      },
    ],
    []
  );

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setSearchValue(value);
      setCurrentPage(1);
    }
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  const handleExportHostel = () => {
    setLoader(true);
    const payload= {
      condition:{
        ...(selectedCenterPayload ? { ...selectedCenterPayload } : { value: null }),
      }
    }
    exportHostelDetail(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const filename = resp?.data;
          const url = `${BaseExportURL}${resp?.data}`;
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          toast.success(resp.success);
          if (url) {
            deleteUploadedfile(filename, true, csv);
          }
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handleExportWithRejection = () => {
    setLoader(true);
    const payload= {
      condition:{
        ...(selectedCenterPayload ? { ...selectedCenterPayload } : { value: null }),
      }
    }
    exportHostelWithRejectionDetail(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const filename = resp?.data;
          const url = `${BaseExportURL}${resp?.data}`;
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          toast.success(resp.success);
          if (url) {
            deleteUploadedfile(filename, true, csv);
          }
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <div>
      <div className="mx-3 d-block d-sm-flex justify-content-between align-items-center">
        <h5 className="fw-600">{hostelLabel.hostelList}</h5>
        <div className="col-auto d-grid d-sm-flex mb-2">
          <BaseButton
            onClick={() => handleExportHostel()}
            className="mx-2"
            color="success"
          >
            <RiArrowUpLine size={20}/>
            {hostelLabel.exportHostel}
          </BaseButton>
          <BaseButton
            onClick={() => handleExportWithRejection()}
            className="mx-2 text-white"
            color="warning"
          >
            <RiArrowUpLine size={20}/>
            {hostelLabel.exportWithRejection}
          </BaseButton>
        </div>
      </div>{" "}
      <div className="card mx-3">
        <div className="card-body text-center">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {hostelData && hostelData?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              columns={columns}
              data={searchValue ? searchHostelData : hostelData || []}
              isGlobalFilter={true}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              manualPagination={true}
              tableClass="table table-bordered text-center"
              onSearch={handleSearchValueChange}
              SearchPlaceholder={hostelLabel.search}
              fetchSortingData={handleFetchSorting}
              manualFiltering={true}
            />
          )}
          {!loader && hostelData?.length === 0 && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hostel;
